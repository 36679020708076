import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { useNavigate } from "react-router-dom";
import "./expenseType.css"
import { useAlert } from "react-alert"
import { PostExpenseTypeRecord } from "../../../Actions/expenseTypeAction";
import { POST_EXPENSETYPE_SUCCESS } from "../../../Constants/expenseTypeConstants";
const ExpenseTypeForm = () => {
  const dispatch = useDispatch()  
  const alert = useAlert()
  const [expenseType, setExpenseType] = useState()
  const [expenseTypeDescription, setExpenseTypeDescription] = useState()
  const [colorTheme, setColorTheme] = useState()
  const {postExpenseType, postExpenseTypeLoading, postExpenseTypeError} = useSelector((state)=> state.postExpenseType)
  const navigate = useNavigate()
  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])


    const addRecord = () =>{
        dispatch(PostExpenseTypeRecord(expenseType, expenseTypeDescription))
    }

  useEffect(()=>{
    if(!postExpenseTypeLoading && postExpenseType?.success)
    {
      postExpenseTypeSuccessMessage()
    }

  }, [postExpenseType, postExpenseTypeLoading])

  const postExpenseTypeSuccessMessage = () => {
    setExpenseType('')
    setExpenseTypeDescription('')
    dispatch({
      type: POST_EXPENSETYPE_SUCCESS,
      payload: [],
    });
    return alert.success("Record Added Successfully")
  }

  
  useEffect(()=>{
    if(postExpenseTypeError && !postExpenseTypeLoading)
    {
      postExpenseTypeErrorMessage(postExpenseTypeError?.data?.message)
    }
  }, [postExpenseTypeError, postExpenseTypeLoading])

  const postExpenseTypeErrorMessage = (message) => {
    return alert.error(message)
  }

    const back = ()=>{
      navigate('/expenseTypeTable')
    }

    const ShowTableRecord = async () => {
      navigate('/expenseTypeTable')
  }

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`expenseTypeMain ${colorTheme}`}>
        <div className={`expenseType`}>
          <div className="expenseTypeSecond">
          <div className="heading">
            <h3>{"Add Expense Type"}</h3> 
            <button onClick={ShowTableRecord}>
              View Records
            </button>
            </div>
                <div className="expenseTypeInputDiv">
                <div className="labeAndInputDiv">
                    <label className="labelDiv">Type Name <span>*</span></label>
                    <input
                        label={"productCode"}
                        className="inputField"
                        type="text"
                        placeholder={"Enter Test Name"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={expenseType}
                        onChange={(e)=> setExpenseType(e.target.value)}
                        />
                    </div>
                    <div className="labeAndInputDiv">
                    <label className="labelDiv">Type Description <span>*</span></label>
                    <input
                        className="inputField"
                        type="text"
                        placeholder={"Enter Test Description"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={expenseTypeDescription}
                        onChange={(e)=> setExpenseTypeDescription(e.target.value)}
                    />
                     </div>
                   
                </div>
                <div className="buttons">
                <button onClick={back}>Back</button>
                <button onClick={addRecord}>Add Record</button>
                
                </div>
          </div> 
      </div> 
    </div>
    </>
  );
};

export default ExpenseTypeForm;
