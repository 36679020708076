export const GET_BRANCH_REQUEST = "GET_BRANCH_REQUEST"
export const GET_BRANCH_SUCCESS = "GET_BRANCH_SUCCESS"
export const GET_BRANCH_FAIL = "GET_BRANCH_FAIL"


export const GET_BRANCH_DETAIL_REQUEST = "GET_BRANCH_DETAIL_REQUEST"
export const GET_BRANCH_DETAIL_SUCCESS = "GET_BRANCH_DETAIL_SUCCESS"
export const GET_BRANCH_DETAIL_FAIL = "GET_BRANCH_DETAIL_FAIL"

export const UPDATE_BRANCH_DETAIL_REQUEST = "UPDATE_BRANCH_DETAIL_REQUEST"
export const UPDATE_BRANCH_DETAIL_SUCCESS = "UPDATE_BRANCH_DETAIL_SUCCESS"
export const UPDATE_BRANCH_DETAIL_FAIL = "UPDATE_BRANCH_DETAIL_FAIL"


export const DELETE_BRANCH_DETAIL_REQUEST = "DELETE_BRANCH_DETAIL_REQUEST"
export const DELETE_BRANCH_DETAIL_SUCCESS = "DELETE_BRANCH_DETAIL_SUCCESS"
export const DELETE_BRANCH_DETAIL_FAIL = "DELETE_BRANCH_DETAIL_FAIL"

export const POST_BRANCH_REQUEST = "POST_BRANCH_REQUEST"
export const POST_BRANCH_SUCCESS = "POST_BRANCH_SUCCESS"
export const POST_BRANCH_FAIL = "POST_BRANCH_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"