import { CLEAR_ERRORS, GET_ASSINGEDTASK_FAIL, GET_ASSINGEDTASK_REQUEST, GET_ASSINGEDTASK_ROLEANDTASKID_FAIL, GET_ASSINGEDTASK_ROLEANDTASKID_REQUEST, GET_ASSINGEDTASK_ROLEANDTASKID_SUCCESS, GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_FAIL, GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_REQUEST, GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_SUCCESS, GET_ASSINGEDTASK_ROLEID_FAIL, GET_ASSINGEDTASK_ROLEID_REQUEST, GET_ASSINGEDTASK_ROLEID_SUCCESS, GET_ASSINGEDTASK_SUCCESS, GET_ROLEBYID_FAIL, GET_ROLEBYID_REQUEST, GET_ROLEBYID_SUCCESS, POST_ASSIGNEDROLEBYID_FAIL, POST_ASSIGNEDROLEBYID_REQUEST, POST_ASSIGNEDROLEBYID_SUCCESS } from "../Constants/assignedTaskConstants";

export const postAssignedTaskReducer = (state = { postAssignedTask: []}, action)=>{
    switch (action.type){
        case POST_ASSIGNEDROLEBYID_REQUEST:
            return{
                postAssignedTaskLoading: true,
                postAssignedTask: [],
            };
        case POST_ASSIGNEDROLEBYID_SUCCESS: 
            return {
                postAssignedTaskLoading: false,
                postAssignedTask: action.payload
            }
        case POST_ASSIGNEDROLEBYID_FAIL:
            return {
                postAssignedTaskLoading: false,
                postAssignedTaskError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                postAssignedTaskError: null,
            }
            default:
            return state;
            
    }
}
export const getAssignedTaskReducer = (state = { assignedTask: []}, action)=>{
    switch (action.type){
        case GET_ASSINGEDTASK_REQUEST:
            return{
                assignedTaskRecordLoading: true,
                assignedTask: [],
            };
        case GET_ASSINGEDTASK_SUCCESS: 
            return {
                assignedTaskRecordLoading: false,
                assignedTask: action.payload
            }
        case GET_ASSINGEDTASK_FAIL:
            return {
                assignedTaskRecordLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const getAssignedTaskOnRoleAndTaskIdReducer = (state = { assignedTasksOnRoleAndTaskId: []}, action)=>{
    switch (action.type){
        case GET_ASSINGEDTASK_ROLEANDTASKID_REQUEST:
            return{
                assignedTasksOnRoleAndTaskIdLoading: true,
                assignedTasksOnRoleAndTaskId: [],
            };
        case GET_ASSINGEDTASK_ROLEANDTASKID_SUCCESS: 
            return {
                assignedTasksOnRoleAndTaskIdLoading: false,
                assignedTasksOnRoleAndTaskId: action.payload
            }
        case GET_ASSINGEDTASK_ROLEANDTASKID_FAIL:
            return {
                assignedTasksOnRoleAndTaskIdLoading: false,
                assignedTasksOnRoleAndTaskIdError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                assignedTasksOnRoleAndTaskIdError: null,
            }
            default:
            return state;
            
    }
}

export const getAssignedTaskOnRoleIDReducer = (state = { assignedTasksOnRoleId: []}, action)=>{
    switch (action.type){
        case GET_ASSINGEDTASK_ROLEID_REQUEST:
            return{
                assignedTasksOnRoleIdLoading: true,
                assignedTasksOnRoleId: [],
            };
        case GET_ASSINGEDTASK_ROLEID_SUCCESS: 
            return {
                assignedTasksOnRoleIdLoading: false,
                assignedTasksOnRoleId: action.payload
            }
        case GET_ASSINGEDTASK_ROLEID_FAIL:
            return {
                assignedTasksOnRoleIdLoading: false,
                assignedTasksOnRoleIdError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                assignedTasksOnRoleIdError: null,
            }
            default:
            return state;
            
    }
}

export const getAssignedTaskOnRoleIDAndTaskNameReducer = (state = { assignedTasksOnRoleIdAndTaskName: []}, action)=>{
    switch (action.type){
        case GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_REQUEST:
            return{
                assignedTasksOnRoleIdAndTaskNameLoading: true,
                assignedTasksOnRoleIdAndTaskName: [],
            };
        case GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_SUCCESS: 
            return {
                assignedTasksOnRoleIdAndTaskNameLoading: false,
                assignedTasksOnRoleIdAndTaskName: action.payload
            }
        case GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_FAIL:
            return {
                assignedTasksOnRoleIdAndTaskNameLoading: false,
                assignedTasksOnRoleIdAndTaskNameError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                assignedTasksOnRoleIdAndTaskNameError: null,
            }
            default:
            return state;
            
    }
}