import { Dispatch, SetStateAction, useState, useRef, useEffect } from "react";
import "./styles.css";
import images from "./logo.png"
import { Icon } from "./Icon";
import { useLocation, useNavigate } from 'react-router-dom';
import * as AiIcons from "react-icons/ai";
import { useDispatch } from "react-redux";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  HiOutlineCurrencyDollar,
} from "react-icons/hi";
import { logout } from "../../../../Actions/userAction";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const menuItems = [
  {
    name: `Customers`,
    icon: <AiIcons.AiFillHome  className='svgClass'/>,
    cName: "nav-text",
    roles: ["Admin", "Administrator", "superAdmin", "Salesman"],
    items: [
      {
        name: "Add Customer",
        path: "/customerAdd",
        permission: "AddCustomer",
      },
      {
        name: "View Customer",
        path: "/customerTableRecord",
        permission: "ViewCustomer",
      },
     
    ],
  },
  {
    name: `Test`,
    icon: <AiIcons.AiFillHome  className='svgClass'/>,
    cName: "nav-text",
    roles: ["Admin", "Administrator", "superAdmin", "Salesman"],
    items: [
      {
        name: "Add Test",
        path: "/addTestRecord",
        permission: "AddTest",
      },
      {
        name: "View Test",
        path: "/testRecordTable",
        permission: "ViewTest",
      },
     
    ],
  },
  {
    name: `Visa`,
    icon: <AiIcons.AiFillHome  className='svgClass'/>,
    cName: "nav-text",
    roles: ["Admin", "Administrator", "superAdmin", "Salesman"],
    items: [
      {
        name: "Add Visa",
        path: "/visaForm",
        permission: "AddVisa",
      },
      {
        name: "View Visa",
        path: "/visaRecordTable",
        permission: "ViewVisa",
      },
     
    ],
  },
  {
    name: `Test Bookings`,
    icon: <AiIcons.AiFillHome  className='svgClass'/>,
    cName: "nav-text",
    roles: ["Admin", "Administrator", "superAdmin", "Salesman"],
    items: [
      {
        name: "Add Bookings",
        path: "/testBooking",
        permission: "addTestBookings",
      },
      {
        name: "View Bookings",
        path: "/testBookingTable",
        permission: "viewTestBookings",
      },
     
    ],
  },
  {
    name: `Expenses`,
    icon: <AiIcons.AiFillHome  className='svgClass'/>,
    cName: "nav-text",
    roles: ["Admin", "Administrator", "superAdmin"],
    items: [
      {
        name: "Expense Type",
        path: "/expenseTypeTable",
        permission: "AddExpenseType",
      },
      {
        name: "Add Expense",
        path: "/expense",
        permission: "AddExpense",
      },
     
    ],
  },
  {
    name: `Task`,
    icon: <AiIcons.AiFillHome  className='svgClass'/>,
    cName: "nav-text",
    roles: ["superAdmin"],
    items: [
      {
        name: "Add Task",
        path: "/taskForm",
        permission: "AddTask",
      },
    ],
  },
  {
    name: `Branch`,
    icon: <AiIcons.AiFillHome  className='svgClass'/>,
    cName: "nav-text",
    roles: ["superAdmin"],
    items: [
      {
        name: "Add Branch",
        path: "/addBranchRecord",
        permission: "AddBranch",
      },
      {
        name: "View Branch",
        path: "/addBranchRecord",
        permission: "ViewBranch",
      },
    ],
  },
  {
    name: `Invoices`,
    icon: <AiIcons.AiFillHome  className='svgClass'/>,
    cName: "nav-text",
    roles: ["Admin", "Administrator", "superAdmin", "Salesman"],
    items: [
      {
        name: "Test",
        path: "/customerRecordTest",
        permission: "viewTestTable",
      },
      {
        name: "Visa",
        path: "/customerRecordVisa",
        permission: "viewVisaTable",
      },
      {
        name: "Visitors",
        path: "/customerRecordVisitors",
        permission: "viewVisitorTable",
      },
      {
        name: "Expenses",
        path: "/expenseInvoice",
        permission: "viewExpenseTable",
      },
    ],
  },
  {
    name: `Register`,
    icon: <AiIcons.AiFillHome  className='svgClass'/>,
    cName: "nav-text",
    roles: [ "Administrator", "superAdmin"],
    items: [
      {
        name: "Register User",
        path: "/registerUser",
        permission: "registerUser",
      },
    ],
  },
]

const NavHeader = ({ onClick }) => (
  <header className="sidebar-header">
    <button onClick={onClick} type="button">
      <ArrowBackIcon className='svgClass'/> 
    </button>
    <img src={images} className="sidebar-logo" alt="Logo" />
    {/* <h1>SoftWise Sol</h1> */}
  </header>
);

const NavButton = ({ onClick, name, icon, isActive, hasSubNav }) => {
  // const {t} = useTranslation()
  return(
  <button
    type="button"
    onClick={() => onClick(name)}
    className={isActive ? 'active' : ''}
  >
    {icon && <Icon icon={icon} />}
  <span>{`${name}`}</span>
    {hasSubNav && <Icon icon={<ArrowDropDownIcon  className='svgClass'/>} />}
  </button>)
};

const SubMenu = ({ setIsOpen, item, activeItem, handleClick, props }) => {
  const location = useLocation()
  const navRef = useRef(null);
  const [activeSubItem, setActiveSubItem] = useState("");
  const navigate = useNavigate()
  const isSalesman = JSON.parse(localStorage.getItem("isSalesman"));
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
  const isAdministrator = JSON.parse(localStorage.getItem("isAdministrator"));
  const isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));

  useEffect(()=>{
    console.log(item)
    console.log(props)
  }, [])

  const handleLinkClick =(link,name)=>{
    console.log(link, name)
    console.log(location)
    // setActiveSubItem(link !== activeItem ? location.pathname : "");
    navigate(`${link}`)
    setIsOpen(false)
    // handleClick()
  }
  

  
  const isSubNavOpen = (item, items) =>
    items.some((i) => i === activeItem) || item === activeItem;

  return (
    <div
      className={`sub-nav ${isSubNavOpen(item.name, item.items) ? 'open' : ''}`}
      style={{
        height: !isSubNavOpen(item.name, item.items)
          ? 0
          : navRef.current?.clientHeight,
      }}
    >
      <div ref={navRef} className="sub-nav-inner">
        {item?.items.map((subItem) => (
           (props?.permissions?.includes(subItem.permission) ||
           subItem?.permission?.includes("superAdmin")) &&  ( 
           <NavButton
              // onClick={handleClick}
              onClick={() => {
                handleLinkClick(subItem.path, subItem.name);
                // !item.subItems && setShowMenu(false);
              }}
              name={subItem.name}
              isActive={location.pathname === subItem.path}
              key={subItem.name}
            />)
         
        ))}
      </div>
    </div>
  );
};

export const Sidebar = ({ isOpen, setIsOpen, props }) => {
  const [activeItem, setActiveItem] = useState("");
  // const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const isSalesman = JSON.parse(localStorage.getItem("isSalesman"));
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
  const isAdministrator = JSON.parse(localStorage.getItem("isAdministrator"));
  const isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));
  useEffect(()=>{
    console.log(props.permissions)
  }, [])
  const handleDrawerCloseForLogout = async () => {
    setIsOpen(false);
    // await logout()
    dispatch(logout());
    // dispatch({ type: LOAD_USER_FAIL });
    navigate("/login");
  };

  const handleClick = (item) => {
    console.log(item)
    setActiveItem(item !== activeItem ? item : "");
  };

  console.log(menuItems)

  return (
    <aside className={`sidebar ${isOpen ? "open" : ""}`}>
      <nav className="sidebar-nav">
        <NavHeader onClick={() => setIsOpen(false)} />
        {menuItems.map((item) => (
            <div className="items" key={item.name}>
              {!item.items && (
                 ((isSalesman && item?.roles?.includes("Salesman")) ||
                 (isAdmin && item?.roles?.includes("Admin")) ||
                 (isSuperAdmin && item?.roles?.includes("superAdmin")) ||
                 (isAdministrator && item?.roles?.includes("Administrator"))) ? (
                <NavButton
                  onClick={handleClick} 
                  name={item.name}
                  icon={item.icon}
                  isActive={activeItem === item.name}
                  hasSubNav={!!item.items}
                />
                ):(<></>)
              )
              }
              {item.items && (<>{
                        ((isSalesman && item?.roles?.includes("Salesman")) ||
                        (isAdmin && item?.roles?.includes("Admin")) ||
                        (isSuperAdmin && item?.roles?.includes("superAdmin")) ||
                        (isAdministrator && item?.roles?.includes("Administrator")))  ? (<>
                  <NavButton
                    onClick={handleClick}
                    name={item.name}
                    icon={item.icon}
                    isActive={activeItem === item.name}
                    hasSubNav={!!item.items}
                    key={item.name}
                  />
                  <SubMenu
                    setIsOpen = {setIsOpen}
                    activeItem={activeItem}
                    handleClick={handleClick}
                    item={item}
                    props={props}
                  />
                </>):(null)
              }</>
                
              )}
            </div>
          ))}
      </nav>
    </aside>
  );
};
