import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import "./visas.css"
import { useNavigate } from "react-router-dom";
import { PostTask } from "../../../Actions/taskAction";
import { useAlert } from "react-alert";
import { PostVisaRecord } from "../../../Actions/visaAction";
import { POST_VISA_SUCCESS } from "../../../Constants/visaConstants";
const VisasForm = () => {
  const dispatch = useDispatch()  
  const alert = useAlert()
  const [visaName, setVisaName] = useState()
  const [visaPrice, setVisaPrice] = useState()
  const [visaaDescription, setVisaDescription] = useState()
  const [colorTheme, setColorTheme] = useState()
  const {postVisa, postVisaLoading, error} = useSelector((state)=> state.postVisa)
  const navigate = useNavigate()
  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

    const addRecord = () =>{
        dispatch(PostVisaRecord(visaName, visaPrice, visaaDescription))
    }

    useEffect(()=>{
      if(!postVisaLoading && postVisa?.success)
      {
        postVisaSuccessMessage()
      }
    }, [postVisa, postVisaLoading])
  

    useEffect(()=>{
      if(!postVisaLoading && error)
      {
        postVisaFailMessage(error?.data?.message)
      }
    }, [error, postVisaLoading])
  
    const postVisaSuccessMessage =() => {
      setVisaName('')
      setVisaPrice('')
      setVisaDescription('')
      dispatch({
        type: POST_VISA_SUCCESS,
        payload: [],
      });
      return alert.success('Record Added Successfully')
    }
  
    const postVisaFailMessage =(message) => {
      return alert.error(message)
    }

    const clearRecord = ()=>{
        setVisaName('')
        setVisaPrice('')
        setVisaDescription('')
    }
    const ShowTableRecord = async () => {
        navigate('/visaRecordTable')
    }

  return (
    <>
    <MetaData title="Al-Saif ~~Customers" />
    <div className={`visaMain ${colorTheme}`}>
      <div className={`visa`}>
        <div className="visaSecond">
        <div className="heading">
          <h3>{"Add Visa Type"}</h3> 
          <button onClick={ShowTableRecord}>
            View Records
          </button>
          </div>
              <div className="visaInputDiv">
              <div className="labeAndInputDiv">
                  <label className="labelDiv">visa Name  <span>*</span></label>
                  <input
                      label={"productCode"}
                      className="inputField"
                      type="text"
                      placeholder={"Enter Test Name"}
                      name="productCode"
                      autoComplete="off"
                      maxLength="40"
                      required
                      value={visaName}
                      onChange={(e)=> setVisaName(e.target.value)}
                      />
                  </div>
                  <div className="labeAndInputDiv">
                    <label className="labelDiv">visa Price  <span>*</span></label>
                  <input
                      label={"productCode"}
                      className="inputField"
                      type="Number"
                      placeholder={"Enter Test Name"}
                      name="productCode"
                      autoComplete="off"
                      maxLength="40"
                      required
                      value={visaPrice}
                      onChange={(e)=> setVisaPrice(e.target.value)}
                      />
                        </div>
                  <div className="labeAndInputDiv">
                  <label className="labelDiv">Type Description  <span>*</span></label>
                  <input
                      className="inputField"
                      type="text"
                      placeholder={"Enter Test Description"}
                      name="productCode"
                      autoComplete="off"
                      maxLength="150"
                      required
                      value={visaaDescription}
                      onChange={(e)=> setVisaDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="buttons">
              <button onClick={clearRecord}>Clear</button>
              <button onClick={addRecord}>Add Record</button>
              
              </div>
        </div> 
    </div> 
  </div>
  </>
  );
};

export default VisasForm;
