import { CLEAR_ERRORS, REGISTER_USER_FAIL, REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS } from "../Constants/registerConstants";

export const registerUserReducer = (state = { registerUser: []}, action)=>{
    switch (action.type){
        case REGISTER_USER_REQUEST:
            return{
                registerUserLoading: true,
                registerUser: [],
            };
        case REGISTER_USER_SUCCESS: 
            return {
                registerUserLoading: false,
                registerUser: action.payload
            }
        case REGISTER_USER_FAIL:
            return {
                registerUserLoading: false,
                registerUserError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                registerUserError: null,
            }
            default:
            return state;
            
    }
}