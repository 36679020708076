
import './App.css';
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import {Login} from './Components/pages/Login/Login';
import Expense from "./Components/pages/Expense/expense"
import RecordCustomer from './Components/pages/Customer/customerRecord';
import { Concept } from './Components/SideNavbar/concept-1/Concept';
import { Login } from './Components/pages/Login/LoggIn';
import TestForm from './Components/pages/Test/testForm';
import BranchForm from './Components/pages/Branch/branchForm';
import ExpenseTypeForm from './Components/pages/ExpenseType/expenseType';
import ExpenseForm from './Components/pages/Expense/expenseForm';
import { useSelector, useDispatch } from "react-redux";
import ProtectedRoute from './ProtectedRoute';
import store from "./Store/store"
import { loadUser } from './Actions/userAction';
import TestRecordTable from './Components/pages/Test/testTable';
import UpdateTestForm from './Components/pages/Test/updateTable';
import BranchRecordTable from './Components/pages/Branch/branchTable';
import UpdateBranchForm from './Components/pages/Branch/branchUpdate';
import ExpenseTypeTable from './Components/pages/ExpenseType/expenseTypeTable';
import UpdateExpenseTypeForm from './Components/pages/ExpenseType/expenseTypeUpdate';
import VisasForm from './Components/pages/Visas/visasForm';
import TaskForm from './Components/pages/Task/Task';
import VisaRecordTable from './Components/pages/Visas/visaTable';
import UpdateVisaForm from './Components/pages/Visas/updateVisa';
import CustomerRecordTable from './Components/Invoices/customerRecord/customerTableRecord';
import PreviewVisaRecord from './Components/Invoices/CustomerVisa/PreviewRecord';
import PreviewStudentRecord from './Components/Invoices/CustomerStudents/PreviewStudentRecord';
import SettingMainPage from './Components/Settings/SettingPage';
import RolesTable from './Components/pages/Roles/rolesTable';
import ChangePermissions from './Components/pages/Permissions/changePermission';
import CustomerVisaTable from './Components/Invoices/CustomerVisa/visaTableRecord';
import CustomerStudentTable from './Components/Invoices/CustomerStudents/studentTableRecord';
import VisitorsTableRecord from './Components/Invoices/VisitorsRecord/VisitorsTableRecord';
import DashBoard from './Components/Dashboard/DashBoard';
import UpdateVisitor from './Components/pages/Visitors/updateVisitor';
import CustomerTable from './Components/pages/Customer/customersTable';
import UpdateCustomerRecord from './Components/pages/Customer/customerUpdate';
import RegisterForm from './Components/pages/Register/RegisterUser';
import ExpenseTableRecord from './Components/Invoices/ExpenseInvoice/ExpenseTableRecord';
import PreviewExpenseRecord from './Components/Invoices/ExpenseInvoice/PreviewExpense';
import TestBookingForm from './Components/pages/TestBooking/testBookingForm';
import TestBookingRecord from './Components/pages/TestBooking/testBookingTable';
import UpdateTestBookingForm from './Components/pages/TestBooking/testBookingUpdate';
import MainDash from './Components/Dashboard/MainDash/MainDash';
import TestBookingInvoice from './Components/pages/TestBooking/testBookingInvoice';
import ForgotPasswordEmail from "./Components/pages/Login/ForgotPasswordEmail"
import ResetPassword from "./Components/pages/Login/ResetPassword"
function App() {
  const dispatch = useDispatch();
  const [colorTheme, setColorTheme] = useState("theme-gradient");
  useEffect(() => {
    const currentThemeColor = localStorage.getItem("theme-color");
    console.log(localStorage.getItem("theme-color"));
    if (currentThemeColor) {
      setColorTheme(currentThemeColor);
      document.body.className = currentThemeColor;
    }
  }, [colorTheme, localStorage.getItem("color-theme")]);


  useEffect(() => {
    store.dispatch(loadUser());
  });
  return (
    <div className={`app-container ${colorTheme}`}>
    {/* <div className={`background-image ${colorTheme}`}></div> */}
    <div className="content-container">
      <Router>
        <Concept />
        <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route
                exact
                path="/passwordForgot"
                element={<ForgotPasswordEmail />}
              />
              <Route
                exact
                path="/password/reset/:token"
                element={<ResetPassword />}
              />
        </Routes>
        {/* <Route path="/login" element={<Login />} /> */}
        <ProtectedRoute
              exact
              path="/customerAdd"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={RecordCustomer}
            />
          <ProtectedRoute
              exact
              path="/addTestRecord"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={TestForm}
            />
          <ProtectedRoute
              exact
              path="/addBranchRecord"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={BranchForm}
            />
          <ProtectedRoute
              exact
              path="/addExpenseType"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={ExpenseTypeForm}
            />
          <ProtectedRoute
              exact
              path="/expense"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={Expense}
            />
          <ProtectedRoute
              exact
              path="/expenseForm"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={ExpenseForm}
            />
          <ProtectedRoute
              exact
              path="/visaForm"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={VisasForm}
            />
          <ProtectedRoute
              exact
              path="/taskForm"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={TaskForm}
            />
          <ProtectedRoute
              exact
              path="/testRecordTable"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={TestRecordTable}
            />
          <ProtectedRoute
              exact
              path="/updateTestRecord/:id"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={UpdateTestForm}
            />

          <ProtectedRoute
              exact
              path="/visaRecordTable"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={VisaRecordTable}
            />
                <ProtectedRoute
              exact
              path="/updateVisaRecord/:id"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={UpdateVisaForm}
            />
                <ProtectedRoute
              exact
              path="/branchRecordTable"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={BranchRecordTable}
            />
            <ProtectedRoute
              exact
              path="/updateBranchRecord/:id"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={UpdateBranchForm}
            />
                 <ProtectedRoute
              exact
              path="/expenseTypeTable"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={ExpenseTypeTable}
            />  
            <ProtectedRoute
            exact
            path="/updateExpenseTypeRecord/:id"
            allowedRoles={[
              "superAdmin",
              "Administrator",
              "Admin",
              "Salesman",
            ]}
            Component={UpdateExpenseTypeForm}
          />
      
      <ProtectedRoute
            exact
            path="/customerRecord"
            allowedRoles={[
              "superAdmin",
              "Administrator",
              "Admin",
              "Salesman",
            ]}
            Component={CustomerRecordTable}
          />
              <ProtectedRoute
            exact
            path="/PreviewVisaRecord/:id"
            allowedRoles={[
              "superAdmin",
              "Administrator",
              "Admin",
              "Salesman",
            ]}
            Component={PreviewVisaRecord}
          />
              <ProtectedRoute
            exact
            path="/PreviewTestRecord/:id"
            allowedRoles={[
              "superAdmin",
              "Administrator",
              "Admin",
              "Salesman",
            ]}
            Component={PreviewStudentRecord}
          />
              <ProtectedRoute
            exact
            path="/settings"
            allowedRoles={[
              "superAdmin",
              "Administrator",
              "Admin",
              "Salesman",
            ]}
            Component={SettingMainPage}
          />
                <ProtectedRoute
              exact
              path="/rolesTable"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={RolesTable}
            /> 
                 <ProtectedRoute
            exact
            path="/changePermissions/:id"
            allowedRoles={[
              "superAdmin",
              "Administrator",
              "Admin",
              "Salesman",
            ]}
            Component={ChangePermissions}
          />
                   <ProtectedRoute
              exact
              path="/customerRecordVisa"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={CustomerVisaTable}
            /> 
                     <ProtectedRoute
              exact
              path="/customerRecordTest"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={CustomerStudentTable}
            /> 
            <ProtectedRoute
              exact
              path="/customerRecordVisitors"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={VisitorsTableRecord}
            /> 
             <ProtectedRoute
              exact
              path="/dashboard"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={DashBoard}
            /> 
            <ProtectedRoute
              exact
              path="/updateVisitor/:id"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={UpdateVisitor}
            /> 

            <ProtectedRoute
              exact
              path="/customerTableRecord"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={CustomerTable}
            /> 
            <ProtectedRoute
              exact
              path="/updateCustomerRecord/:id"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={UpdateCustomerRecord}
            /> 
            <ProtectedRoute
              exact
              path="/registerUser"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={RegisterForm}
            /> 
              <ProtectedRoute
              exact
              path="/expenseInvoice"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={ExpenseTableRecord}
            /> 
               <ProtectedRoute
              exact
              path="/PreviewExpenseRecord/:id"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={PreviewExpenseRecord}
            /> 
              <ProtectedRoute
              exact
              path="/testBooking"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={TestBookingForm}
            /> 
            <ProtectedRoute
              exact
              path="/testBookingTable"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={TestBookingRecord}
            /> 
               <ProtectedRoute
              exact
              path="/updateTestBooking/:id"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={UpdateTestBookingForm}
            /> 
               <ProtectedRoute
              exact
              path="/previewTestBooking/:id"
              allowedRoles={[
                "superAdmin",
                "Administrator",
                "Admin",
                "Salesman",
              ]}
              Component={TestBookingInvoice}
            /> 
      </Router>    
      </div>
    </div>
  );
}

export default App;
