import { CLEAR_ERRORS, DELETE_EXPENSETYPE_DETAIL_FAIL, DELETE_EXPENSETYPE_DETAIL_REQUEST, DELETE_EXPENSETYPE_DETAIL_SUCCESS, GET_EXPENSETYPE_DETAIL_FAIL, GET_EXPENSETYPE_DETAIL_REQUEST, GET_EXPENSETYPE_DETAIL_SUCCESS, GET_EXPENSETYPE_FAIL, GET_EXPENSETYPE_REQUEST, GET_EXPENSETYPE_SUCCESS, POST_EXPENSETYPE_FAIL, POST_EXPENSETYPE_REQUEST, POST_EXPENSETYPE_SUCCESS, UPDATE_EXPENSETYPE_DETAIL_FAIL, UPDATE_EXPENSETYPE_DETAIL_REQUEST, UPDATE_EXPENSETYPE_DETAIL_SUCCESS } from "../Constants/expenseTypeConstants";

export const postExpenseTypeReducer = (state = { postExpenseType: []}, action)=>{
    switch (action.type){
        case POST_EXPENSETYPE_REQUEST:
            return{
                postExpenseTypeLoading: true,
                postExpenseType: [],
            };
        case POST_EXPENSETYPE_SUCCESS: 
            return {
                postExpenseTypeLoading: false,
                postExpenseType: action.payload
            }
        case POST_EXPENSETYPE_FAIL:
            return {
                postExpenseTypeLoading: false,
                postExpenseTypeError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                postExpenseTypeError: null,
            }
            default:
            return state;
            
    }
}

export const GetExpenseTypeReducer = (state = { expenseTypeRecord: []}, action)=>{
    switch (action.type){
        case GET_EXPENSETYPE_REQUEST:
            return{
                expenseTypeRecordLoading: true,
                expenseTypeRecord: [],
            };
        case GET_EXPENSETYPE_SUCCESS: 
            return {
                expenseTypeRecordLoading: false,
                expenseTypeRecord: action.payload
            }
        case GET_EXPENSETYPE_FAIL:
            return {
                expenseTypeRecordLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const getExpenseTypeDetailReducer = (state = { expenseTypeDetailRecord: []}, action)=>{
    switch (action.type){
        case GET_EXPENSETYPE_DETAIL_REQUEST:
            return{
                expenseTypeDetailRecordLoading: true,
                expenseTypeDetailRecord: [],
            };
        case GET_EXPENSETYPE_DETAIL_SUCCESS: 
            return {
                expenseTypeDetailRecordLoading: false,
                expenseTypeDetailRecord: action.payload
            }
        case GET_EXPENSETYPE_DETAIL_FAIL:
            return {
                expenseTypeDetailRecordLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const updateExpenseTypeReducer = (state = { updateExpenseTypeRecord: []}, action)=>{
    switch (action.type){
        case UPDATE_EXPENSETYPE_DETAIL_REQUEST:
            return{
                updateExpenseTypeLoading: true,
                updateExpenseTypeRecord: [],
            };
        case UPDATE_EXPENSETYPE_DETAIL_SUCCESS: 
            return {
                updateExpenseTypeLoading: false,
                updateExpenseTypeRecord: action.payload
            }
        case UPDATE_EXPENSETYPE_DETAIL_FAIL:
            return {
                updateExpenseTypeLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const deleteExpenseTypeDetailReducer = (state = { deleteExpenseTypeRecord: []}, action)=>{
    switch (action.type){
        case DELETE_EXPENSETYPE_DETAIL_REQUEST:
            return{
                deleteExpenseTypeRecordLoading: true,
                deleteExpenseTypeRecord: [],
            };
        case DELETE_EXPENSETYPE_DETAIL_SUCCESS: 
            return {
                deleteExpenseTypeRecordLoading: false,
                deleteExpenseTypeRecord: action.payload
            }
        case DELETE_EXPENSETYPE_DETAIL_FAIL:
            return {
                deleteExpenseTypeRecordLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}