import { createStore, combineReducers, applyMiddleware , compose} from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools,  } from "redux-devtools-extension";
import { forgotPasswordReducer, logoutReducer, userReducer } from "../reducers/userReducer";
import { deleteTestReducer, getSpecificTestReducer, getTestReducer, postTestReducer, updateSpecificTestReducer } from "../reducers/testReducer";
import { deleteBranchDetailReducer, getBranchDetailReducer, getBranchReducer, postBranchReducer, updateBranchDetailReducer } from "../reducers/branchReducer";
import { GetExpenseTypeReducer, deleteExpenseTypeDetailReducer, getExpenseTypeDetailReducer, postExpenseTypeReducer, updateExpenseTypeReducer } from "../reducers/expenseTypeReducer";
import { GetTaskReducer, postTaskReducer } from "../reducers/taskReducer";
import { getAssignedTaskOnRoleAndTaskIdReducer, getAssignedTaskOnRoleIDAndTaskNameReducer, getAssignedTaskOnRoleIDReducer, getAssignedTaskReducer, postAssignedTaskReducer } from "../reducers/assignedTaskReducer";
import { deleteVisaReducer, getSpecificVisaReducer, getVisaReducer, postVisaReducer, updateSpecificVisaReducer } from "../reducers/visaReducer";
import { customerReducer, deleteCustomerRecordReducer, getCustomerDetailReducer, getCustomerReducer, updateCustomerRecordReducer } from "../reducers/customerReducer";
import { deleteVisaCustomerPaymentReducer, getVisaCustomerPaymentDetailReducer, getVisaCustomerPaymentOnDatesForBranchReducer, getVisaCustomerPaymentOnDatesReducer, getVisaCustomerPaymentOnShopReducer, getVisaCustomerPaymentReducer, getVisaDashboardReducer, getVisaStatisticsOnBranchReducer, getVisaStatisticsReducer, postVisaCustomerPaymentReducer, updateVisaCustomerPaymentReducer } from "../reducers/visaCustomerPaymentReducer";
import { deleteTestCustomerPaymentReducer, getTestAndVisaNotifications, getTestCustomerPaymentDetailReducer, getTestCustomerPaymentOnDatesForBranchReducer, getTestCustomerPaymentOnDatesReducer, getTestCustomerPaymentOnShopReducer, getTestCustomerPaymentReducer, getTestDashboardOnBranchReducer, getTestDashboardReducer, getTestStatisticsOnBranchReducer, getTestStatisticsReducer, postTestCustomerPaymentReducer, updateTestCustomerPaymentReducer, updateTestCustomerPaymentStatusReducer } from "../reducers/testCustomerPaymentReducer";
import { deleteVisitorsDetailReducer, getVisitorsDetailReducer, getVisitorsOnBranchReducer, getVisitorsOnDatesForBranchReducer, getVisitorsReducer, getVistorsOnDatesReducer, postVisitorsReducer, updateVisitorsDetailReducer } from "../reducers/visitorsReducer";
import { GetRoleReducer, deleteRoleDetailReducer, getRoleDetailReducer, postRoleReducer, updateRoleReducer } from "../reducers/roleReducer";
import { getExpenseDasboardReducer, getExpenseDetailReducer, getExpenseOnBranchReducer, getExpenseOnDatesForBranchReducer, getExpenseOnDatesReducer, getExpenseReducer, postExpenseReducer } from "../reducers/expenseReducer";
import { registerUserReducer } from "../reducers/registerReducer";
import { deleteBookingDetailReducer, getBookingDetailReducer, getBookingOnBranchReducer, getBookingOnDatesReducer, getBookingReducer, getBookingsOnDatesForBranchReducer, postBookingReducer, updateBookingDetailReducer } from "../reducers/testBookingReducer";
const reducer = combineReducers({
  user: userReducer,
  testRecord: getTestReducer,
  branchRecord: getBranchReducer,
  postTest: postTestReducer,
  postBranch: postBranchReducer,
  postExpenseType: postExpenseTypeReducer,
  postExpense: postExpenseReducer,
  expenseTypeRecord: GetExpenseTypeReducer,
  postTask: postTaskReducer,
  assignedTask: getAssignedTaskReducer,
  loggedOut: logoutReducer,
  testSpecificRecord: getSpecificTestReducer,
  updateTestRecord: updateSpecificTestReducer,
  deleteTest: deleteTestReducer,
  branchDetailRecord: getBranchDetailReducer,
  updateBranchRecord: updateBranchDetailReducer,
  deleteBranchRecord: deleteBranchDetailReducer,
  expenseTypeDetailRecord: getExpenseTypeDetailReducer,
  updateExpenseTypeRecord: updateExpenseTypeReducer,
  deleteExpenseTypeRecord: deleteExpenseTypeDetailReducer,
  postVisa: postVisaReducer,
  visaRecord: getVisaReducer,
  visaSpecificRecord: getSpecificVisaReducer,
  updateVisaRecord: updateSpecificVisaReducer,
  deleteVisa: deleteVisaReducer,
  customer: customerReducer,
  visaCustomerPaymentRecord: getVisaCustomerPaymentReducer,
  visaCustomerPaymentRecordOnShop: getVisaCustomerPaymentOnShopReducer,
  visaCustomerPaymentDetailRecord: getVisaCustomerPaymentDetailReducer,
  updateVisaCustomerPayment: updateVisaCustomerPaymentReducer,
  deleteVisaCustomerPayment: deleteVisaCustomerPaymentReducer,
  postVisaCustomerPayment: postVisaCustomerPaymentReducer,
  testCustomerPaymentRecord: getTestCustomerPaymentReducer,
  testCustomerPaymentRecordOnShop: getTestCustomerPaymentOnShopReducer,
  testCustomerPaymentDetailRecord: getTestCustomerPaymentDetailReducer,
  updateTestCustomerPayment: updateTestCustomerPaymentReducer,
  updateTestCustomerPaymentStatus: updateTestCustomerPaymentStatusReducer,
  deleteTestCustomerPayment: deleteTestCustomerPaymentReducer,
  postTestCustomerPayment: postTestCustomerPaymentReducer,
  visitorsRecord: getVisitorsReducer,
  visitorsRecordOnBranch: getVisitorsOnBranchReducer,
  visitorsDetailRecord: getVisitorsDetailReducer,
  updateVisitorsRecord: updateVisitorsDetailReducer,
  deleteVisitorsRecord: deleteVisitorsDetailReducer,
  postVisitors: postVisitorsReducer,
  customerRecord: getCustomerReducer,
  customerDetailRecord: getCustomerDetailReducer,
  updateCustomerRecord: updateCustomerRecordReducer,
  deleteCustomerRecord: deleteCustomerRecordReducer,
  postRole: postRoleReducer,
  roleRecord: GetRoleReducer,
  roleRecordDetail: getRoleDetailReducer,
  updateRoleRecord: updateRoleReducer,
  deleteRoleRecord: deleteRoleDetailReducer,
  assignedTasksOnRoleAndTaskId: getAssignedTaskOnRoleAndTaskIdReducer,
  assignedTasksOnRoleId: getAssignedTaskOnRoleIDReducer,
  postAssignedTask: postAssignedTaskReducer,
  taskRecord: GetTaskReducer,
  notificationRecord: getTestAndVisaNotifications,
  assignedTasksOnRoleIdAndTaskName: getAssignedTaskOnRoleIDAndTaskNameReducer,
  registerUser: registerUserReducer,
  forgotPassword: forgotPasswordReducer,
  expenseRecord: getExpenseReducer,
  expenseRecordOnBranch: getExpenseOnBranchReducer,
  expenseRecordDetail: getExpenseDetailReducer,
  bookingRecord: getBookingReducer,
  bookingDetailRecord: getBookingDetailReducer,
  bookingOnBranch: getBookingOnBranchReducer,
  updateBookingRecord: updateBookingDetailReducer,
  deleteBookingRecord: deleteBookingDetailReducer,
  postBooking: postBookingReducer,
  testCustomerPaymentOnDates: getTestCustomerPaymentOnDatesReducer,
  testCustomerPaymentOnDatesForBranch: getTestCustomerPaymentOnDatesForBranchReducer,
  visaCustomerPaymentOnDates: getVisaCustomerPaymentOnDatesReducer,
  visaCustomerPaymentOnDatesForBranch: getVisaCustomerPaymentOnDatesForBranchReducer,
  visitorsOnDates: getVistorsOnDatesReducer,
  visitorsOnDatesForBranch: getVisitorsOnDatesForBranchReducer,
  expenseOnDates: getExpenseOnDatesReducer,
  expenseOnDatesForBranch: getExpenseOnDatesForBranchReducer,
  bookingOnDates: getBookingOnDatesReducer,
  bookingsOnDatesForBranch: getBookingsOnDatesForBranchReducer,
  testStatistics: getTestStatisticsReducer,
  testStatisticsOnBranch: getTestStatisticsOnBranchReducer,
  testDashboard: getTestDashboardReducer,
  testDashboardOnBranch: getTestDashboardOnBranchReducer,
  visaDashboard: getVisaDashboardReducer,
  visaStatistics: getVisaStatisticsReducer,
  visaStatisticsOnBranch: getVisaStatisticsOnBranchReducer,
  expenseDashboardRecord: getExpenseDasboardReducer
  });

  
let initialState = {
  testRecord: [], // Set an empty array as the initial state for products
  branchRecord: [],
  postTest: [],
  postVisa: [],
  user: [],
  postBranch: [],
  postExpenseType: [],
  expenseTypeRecord: [],
  postTask: [],
  assignedTask: [],
  loggedOut: [],
  testSpecificRecord: [],
  updateTestRecord: [],
  deleteTest: [],
  branchDetailRecord: [],
  updateBranchRecord: [],
  deleteBranchRecord: [],
  expenseTypeDetailRecord: [],
  updateExpenseTypeRecord: [],
  deleteExpenseTypeRecord: [],
  visaRecord: [],
  visaSpecificRecord: [],
  updateVisaRecord: [],
  deleteVisa: [],
  customer: [],
  customerRecord: [],
  customerDetailRecord: [],
  updateCustomerRecord: [],
  deleteCustomerRecord: [],
  visaCustomerPaymentRecord: [],
  visaCustomerPaymentRecordOnShop: [],
  visaCustomerPaymentDetailRecord: [],
  updateVisaCustomerPayment: [],
  deleteVisaCustomerPayment: [],
  postVisaCustomerPayment: [],
  testCustomerPaymentRecord: [],
  testCustomerPaymentDetailRecord: [],
  testCustomerPaymentRecordOnShop:[],
  updatetestCustomerPayment: [],
  deleteTestCustomerPayment: [],
  postTestCustomerPayment: [],
  visitorsRecord: [],
  visitorsDetailRecord: [],
  updateVisitorsRecord: [],
  deleteVisitorsRecord: [],
  postVisitors: [],
  postRole: [],
  roleRecord: [],
  roleRecordDetail: [],
  updateRoleRecord: [],
  deleteRoleRecord: [],
  assignedTasksOnRoleAndTaskId: [],
  assignedTasksOnRoleId: [],
  postAssignedTask: [],
  taskRecord: [],
  updateTestCustomerPaymentStatus: [],
  postExpense: [],
  notificationRecord: [],
  assignedTasksOnRoleIdAndTaskName: [],
  registerUser: [],
  visitorsRecordOnBranch: [],
  expenseRecord: [],
  expenseRecordOnBranch: [],
  expenseRecordDetail: [],
  bookingRecord: [],
  bookingDetailRecord: [],
  updateBookingRecord: [],
  bookingOnBranch: [],
  deleteBookingRecord: [],
  postBooking: [],
  testCustomerPaymentOnDates: [],
  testCustomerPaymentOnDatesForBranch: [],
  visaCustomerPaymentOnDates: [],
  visaCustomerPaymentOnDatesForBranch: [],
  visitorsOnDates: [],
  visitorsOnDatesForBranch: [],
  expenseOnDates: [],
  expenseOnDatesForBranch: [],
  bookingOnDates: [],
  bookingsOnDatesForBranch: [],
  testStatistics: [],
  testStatisticsOnBranch: [],
  visaStatistics: [],
  visaStatisticsOnBranch: [],
  testDashboard: [],
  testDashboardOnBranch: [],
  visaDashboard: [],
  expenseDashboardRecord: []
  };

  const middleware = [thunk];
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );
  
  export default store;