import { FORGOTPASSWORD_FAIL, FORGOTPASSWORD_REQUEST, FORGOTPASSWORD_SUCCESS, LOAD_USER_FAIL, LOAD_USER_REQUEST, LOAD_USER_SUCCESS, LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_FAIL, LOGOUT_REQUEST, LOGOUT_SUCCESS } from "../Constants/userConstants";
import axios from "axios";
import axiosInstance from "./baseURL";
export const login = async (email, password) => {
  try {
    console.log(email, password)
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axiosInstance.post(
      `/api/v1/login`,
      {email, password},
      config
    );
    console.log(data);
    return data;
  } catch (error) {
    // return error.response.data;
  }
};

export const loginUser = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axiosInstance.post(`/api/v1/login`, {
      email,
      password,
    });
    console.log(data);
    dispatch({ type: LOGIN_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response);
    dispatch({ type: LOGIN_FAIL, payload: error.response });
  }
};

export const logout = () => async (dispatch) => {
    try {
      dispatch({ type: LOGOUT_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axiosInstance.get("/api/v1/logout");
      // console.log(data)
      // return data;
      console.log(data)
      dispatch({ type: LOGOUT_SUCCESS });
    } catch (error) {
      dispatch({ type: LOGOUT_FAIL });
    }
  };
  export const logedOut =async () =>{
    try {

      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axiosInstance.get("/api/v1/logout");
      // console.log(data)
      return data;
    } catch (error) {
    }
  };

  export const loadUser = () => async (dispatch) => {
    try {
      dispatch({ type: LOAD_USER_REQUEST });
      const token = JSON.parse(localStorage.getItem('token'))
      const { data } = await axiosInstance.get(`/api/v1/me`);
      console.log(data);
      dispatch({ type: LOAD_USER_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: LOAD_USER_FAIL, payload: error.response });
    }
  };
  
  export const resetPasswordToken = async (token, password, confirmPassword) => {
    try {
      console.log(token);
      console.log(password);
      console.log(confirmPassword);
      const config = { headers: { "Content-Type": "application/json" } };
      const  data  = await axiosInstance.put(`/api/v1/password/reset/${token}`, {
        password,
        confirmPassword,
      });
      return data;
    } catch (error) {
      console.warn(error);
    }
  };

  export const forgotPasswordSendEmail = (email) => async (dispatch) => {
    try {
      dispatch({ type: FORGOTPASSWORD_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
  
      const { data } = await axiosInstance.post(`/api/v1/password/forgot`, { email });
      console.log(data);
      dispatch({ type: FORGOTPASSWORD_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: FORGOTPASSWORD_FAIL, payload: error.response });
    }
  };
  