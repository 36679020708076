import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { PostTestRecord, getSpecificTestRecord, getTestRecord, updateTestRecord } from "../../../Actions/testAction";
import { getBranchRecord, getSpecificBranchRecord, updateBranchRecord } from "../../../Actions/branchAction";
import {useNavigate, useParams}  from "react-router-dom"
import "./expenseType.css"
import { getSpecificExpenseTypeRecord, updateExpenseTypeRecord } from "../../../Actions/expenseTypeAction";
const UpdateExpenseTypeForm = () => {
    const [expenseType, setExpenseType] = useState()
    const [expenseTypeDescription, setExpenseTypeDescription] = useState()
    const dispatch = useDispatch()
  const params = useParams()
  const [colorTheme, setColorTheme] = useState()
  const {expenseTypeDetailRecord, expenseTypeDetailRecordLoading } = useSelector((state)=> state.expenseTypeDetailRecord)
  const navigate = useNavigate()

  const ShowTableRecord = async () => {
      navigate('/testRecordTable')
  }

  useEffect(()=>{
    if(!expenseTypeDetailRecordLoading && expenseTypeDetailRecord)
    {
      setExpenseType(expenseTypeDetailRecord?.expenseTypeData?.expenseType)
      setExpenseTypeDescription(expenseTypeDetailRecord?.expenseTypeData?.expenseTypeDescription)
    }
  }, [expenseTypeDetailRecordLoading, expenseTypeDetailRecord])

  useEffect(()=>{
    dispatch(getSpecificExpenseTypeRecord(params.id))
  }, [])

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  const updateRecord = () =>{
        dispatch(updateExpenseTypeRecord(params.id, expenseType, expenseTypeDescription))
        navigate("/expenseTypeTable")
    }

  const Back = ()=>{
       navigate("/expenseTypeTable")
    }

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`expenseTypeMain ${colorTheme}`}>
        <div className={`expenseType`}>
          <div className="expenseTypeSecond">
            <div className="heading">
            <h3 >{"Update Expense Type"}</h3> 
            </div>
            <div className="expenseTypeInputDiv">
                  <div className="labeAndInputDiv">
                    <label className="labelDiv">Type Name <span>*</span></label>
                    <input
                        label={"productCode"}
                        className="inputField"
                        type="text"
                        placeholder={"Enter Test Name"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={expenseType}
                        onChange={(e)=> setExpenseType(e.target.value)}
                        />
                    </div>
                    <div className="labeAndInputDiv">
                    <label className="labelDiv">Type Description <span>*</span></label>
                    <input
                        className="inputField"
                        type="text"
                        placeholder={"Enter Test Description"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={expenseTypeDescription}
                        onChange={(e)=> setExpenseTypeDescription(e.target.value)}
                    />
                </div>
                </div>
                <div className="buttons">
                <button onClick={Back}>Back</button>
                <button onClick={updateRecord}>Update Record</button>
              
              
                </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateExpenseTypeForm;
