import { useState } from "react";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
export default function Header({ selectedPrinter }) {
  const {expenseRecordDetail, expenseRecordDetailLoading} = useSelector((state)=> state.expenseRecordDetail)
  return (
    <>
      {/* {selectedPrinter === "Laser" ? ( */}
        <div className="headerHeading">
          <h3 className="invoiceTitle">Saif Consultancy Firm</h3>
          <p className="invoiceAddress">   <p>{expenseRecordDetail?.expenseData?.expenseTotal}</p></p>
          <p className="invoicePhoneNo">
            <p>
              Phone No:
              {expenseRecordDetail?.expenseData?.expenseTotal}
            </p>
          </p>
        </div>
    </>
  );
}
