import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"

import {useNavigate, useParams}  from "react-router-dom"
import "./visitors.css"
import { getSpecificVisitorRecord } from "../../../Actions/visitorsAction";
import {postTestCustomerPaymentRecord} from "../../../Actions/testCustomerPaymentAction"
import { postVisaCustomerPaymentRecord } from "../../../Actions/visaCustomerPaymentAction";
import { getVisaRecord } from "../../../Actions/visaAction";
import { getTestRecord } from "../../../Actions/testAction";
import { updateCustomerRecord, updateCustomerRecordStatus, updateCustomerRecords } from "../../../Actions/customerAction";
import { useAlert } from "react-alert";
import { POST_VISACUSTOMERPAYMENT_SUCCESS } from "../../../Constants/visaCustomerPaymentConstants";
import { POST_TESTCUSTOMERPAYMENT_SUCCESS } from "../../../Constants/testCustomerPaymentConstants";
import { UPDATE_CUSTOMER_DETAIL_SUCCESS } from "../../../Constants/customerConstants";
const UpdateVisitor = () => {
    const [categoryOptionTest, setCategoryOptionTest] = useState()
    const [categoryOptionVisa, setCategoryOptionVisa] = useState()
    const statusDropdownRef = useRef(null);
    const branchDropdownRef = useRef(null)
    const visaDropdownRef = useRef(null)
    const testDropdownRef = useRef(null)
    const [isCategoryOptionOpen, setIsCategoryOptionOpen] = useState(false);
    const [isVisaOpen, setIsVisaOpen] = useState(false)
    const [isTestOpen, setIsTestOpen] = useState(false)
    const [selectedVisaName, setSelectedVisaName] = useState()
    const [selectedTestName, setSelectedTestName] = useState()
    const [testPrice, setTestPrice] = useState()
    const [totalPayment, setTotalPayment] = useState()
    const [initialPayment, setInitialPayment] = useState()
    const [attempts, setAttempts] = useState()
    const [remainingPayment, setRemainingPayment]= useState()
    const [studentStatus, setStudentStatus] = useState()
    // const [customerStatus, setCustomerStatus] = useState([])
    const [testName, setTestName] = useState()
    const [visaName, setVisaName] = useState()
    const [discount, setDiscount] = useState()
    const [customer, setCustomer] = useState()
    const [paymentStatus, setPaymentStatus] = useState("Unpaid")
    const [previousStatus, setPreviousStatus] = useState()
    const [branch, setBranch] = useState()
    const {loading, testRecord} = useSelector((state)=> state.testRecord)
    const {visaRecord, visaRecordLoading} = useSelector((state)=> state.visaRecord)
    const {updateCustomerRecord, updateCustomerRecordLoading, updateCustomerRecordError} = useSelector((state)=> state.updateCustomerRecord)
    const {visitorsDetailRecord, visitorsDetailRecordLoading, visitorsDetailRecordError} = useSelector((state)=> state.visitorsDetailRecord)
    const {postTestCustomerPaymentLoading,postTestCustomerPayment, postTestCustomerPaymentError} = useSelector((state)=> state.postTestCustomerPayment)
    const {postVisaCustomerPaymentLoading, postVisaCustomerPayment, postVisaCustomerPaymentError} = useSelector((state)=> state.postVisaCustomerPayment)
    const dispatch = useDispatch()
    const alert = useAlert()
    const params = useParams()
    const [payments, setPayments] = useState({
        downPayment: 0,
        paymentPayed: 0,
        remaining: 0,
      });

    const [colorTheme, setColorTheme] = useState()
    const {branchDetailRecord, branchDetailRecordLoading } = useSelector((state)=> state.branchDetailRecord)
    const navigate = useNavigate()

    const categoryOptions = [
        { key: "1", text: "Visa", value: "Visa" },
        { key: "2", text: "Test", value: "Test" },
      ];
    
  useEffect(()=>{
    if(visitorsDetailRecord && !visitorsDetailRecordLoading)
    {
      console.log(visitorsDetailRecord)
      setCustomer(visitorsDetailRecord?.visitorData?.cust_id?._id)
      setPreviousStatus(visitorsDetailRecord?.visitorData?.cust_id?.status)
      setBranch(visitorsDetailRecord?.visitorData?.cust_id?.branch)
    }

  }, [visitorsDetailRecord, visitorsDetailRecordLoading])
      

  useEffect(()=>{
    dispatch(getVisaRecord())
    dispatch(getTestRecord())
  }, [])

  useEffect(()=>{
    dispatch(getSpecificVisitorRecord(params.id))
  }, [])

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  const updateRecord = () =>{
   
    if(categoryOptionTest){
      console.log('fhie')
      dispatch(updateCustomerRecordStatus(customer, studentStatus))
      // dispatch(postTestCustomerPaymentRecord(customer, testName,attempts, payments, discount, totalPayment))
    }else if(categoryOptionVisa)
    {
      dispatch(updateCustomerRecordStatus(customer, categoryOptionVisa))
      // dispatch(postVisaCustomerPaymentRecord(customer, visaName, payments, discount, totalPayment))
    }
    }

  useEffect(()=>{
    if(updateCustomerRecord?.success && !updateCustomerRecordLoading)
    {
      if(categoryOptionTest){
        console.log('fhie')
        dispatch({
          type: UPDATE_CUSTOMER_DETAIL_SUCCESS,
          payload: [],
        })
        dispatch(postTestCustomerPaymentRecord(customer, testName, branch, attempts, payments, paymentStatus, discount, totalPayment))
  
      }else if(categoryOptionVisa)
      {
        dispatch({
          type: UPDATE_CUSTOMER_DETAIL_SUCCESS,
          payload: [],
        })
        dispatch(updateCustomerRecords(customer, categoryOptionTest))
        dispatch(postVisaCustomerPaymentRecord(customer, visaName, branch, payments,paymentStatus, discount, totalPayment))
      }
    }
  }, [updateCustomerRecord, updateCustomerRecordLoading])

  useEffect(()=>{
    if(!postVisaCustomerPaymentLoading && postVisaCustomerPayment?.success)
    {
      postCustomerPaymentSuccessMessage()

      dispatch({ type: POST_VISACUSTOMERPAYMENT_SUCCESS,
        payload: [] });
    }else if(!postVisaCustomerPaymentLoading && postVisaCustomerPaymentError)
    {
      dispatch(updateCustomerRecords(customer, previousStatus))
      postCustomerPaymentErrorMessage(postTestCustomerPaymentError?.message)
      console.log('record not added')
    }
  },[postVisaCustomerPayment, postVisaCustomerPaymentLoading, postVisaCustomerPaymentError])
  
const postCustomerPaymentSuccessMessage =() => {
  navigate('/customerRecordVisitors')
  return alert.success('Record Updated Successfully')
}

const postCustomerPaymentErrorMessage = (message) => {
  return alert.error(message)
}

  useEffect(()=>{
    if(!postTestCustomerPaymentLoading && postTestCustomerPayment?.success)
    {
      postCustomerPaymentSuccessMessage()
      dispatch({ type: POST_TESTCUSTOMERPAYMENT_SUCCESS,
        payload: [] });
    }else if(!postTestCustomerPaymentLoading && postTestCustomerPaymentError)
    {
      dispatch(updateCustomerRecords(customer, categoryOptionTest))
      postCustomerPaymentErrorMessage(postTestCustomerPaymentError?.message)
    }
  },[postTestCustomerPayment, postTestCustomerPaymentLoading])

  

  const back = ()=>{
    navigate("/customerRecordVisitors")
    }


    const categoryToggleDropdown = () => {
        setIsCategoryOptionOpen(!isCategoryOptionOpen)
      }

      const handleCategoryOptionSelection = (value) => {
        console.log(value)
        if(value.startsWith("V"))
        {
          setCategoryOptionVisa(value)
          setCategoryOptionTest('')
          setIsCategoryOptionOpen(!isCategoryOptionOpen)
        }else{
          setCategoryOptionTest(value)
          setCategoryOptionVisa('')
          setStudentStatus('Student')
          setIsCategoryOptionOpen(!isCategoryOptionOpen)
        }
      }

      const testToggleDropdown = () => {
        setIsTestOpen(!isTestOpen);
      };

      const visaToggleDropdown = () => {
        setIsVisaOpen(!isVisaOpen);
      }

      const handleTestValue = (value, price, name) => {
        setTestPrice(price)
        setSelectedTestName(name)
        setTestName(value)
        setTotalPayment(parseInt(attempts) * parseInt(price))
        setIsTestOpen(!isTestOpen);
      }
    
      const handleVisaValue = (value, price, name) => {
        setSelectedVisaName(name)
        setVisaName(value)
        setTotalPayment(price)
        setIsVisaOpen(!isVisaOpen);
      }

      const handleInitialAmount = (e) => {
        setPayments(prevState => ({
          ...prevState, // Spread the previous state object
          downPayment: e.target.value, // Update the value of the specified field
          paymentPayed: e.target.value
        }));
        if(e.target.value > totalPayment){
          return alert.error(`${e.target.value} should less than ${totalPayment}`)
        }
        setInitialPayment(e.target.value)
        setRemainingPayment(totalPayment - e.target.value)
      }

      const handleDiscountAmount = (e) => {
        console.log(payments)
        if(parseFloat(e.target.value) + parseFloat(initialPayment) === totalPayment)
        {
          setPaymentStatus("Paid")
        }else
        {
          setPaymentStatus("Unpaid")
        }
        // console.log(parseFloat(e.target.value)+parseFloat(initialPayment))
        if((parseFloat(e.target.value) + parseFloat(initialPayment)) > totalPayment)
        {
          return alert.error(`${parseFloat(e.target.value) + parseFloat(initialPayment)} should less than ${totalPayment}`)
        }
        setDiscount(e.target.value)
        setPayments(prevState => ({
          ...prevState, // Spread the previous state object
          remaining: totalPayment - (parseFloat(e.target.value) + parseFloat(initialPayment)), // Update the value of the specified field
       
        }));
        setRemainingPayment(totalPayment - (parseFloat(e.target.value) + parseFloat(initialPayment)))
      }


  
      useEffect(() => {
        const handleClickOutside = (event) => {
          if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
            console.log('fie')
            setIsCategoryOptionOpen(false);
          }
          console.log('clikced')
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
          window.removeEventListener('click', handleClickOutside);
        };
      }, []); 
    
  
    
      useEffect(()=>{
        const handleClickVisaOutside = (event) => {
             if (visaDropdownRef.current && !visaDropdownRef.current.contains(event.target)) {
               setIsVisaOpen(false);
             }
             console.log('clikced')
           };
           window.addEventListener('click', handleClickVisaOutside);
           return () => {
             window.removeEventListener('click', handleClickVisaOutside);
           };
         }, [])
    
         useEffect(()=>{
          const handleClickTestOutside = (event) => {
               if (testDropdownRef.current && !testDropdownRef.current.contains(event.target)) {
                 setIsTestOpen(false);
               }
               console.log('clikced')
             };
             window.addEventListener('click', handleClickTestOutside);
             return () => {
               window.removeEventListener('click', handleClickTestOutside);
             };
           }, [])
    

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`visitorMain ${colorTheme}`}>
        <div className={`visitor`}>
          <div className="visitorSecond">
            <div className="heading">
            <h3 >{"Update Visitor"}</h3> 
            </div>
            <div className="LastVisitorInputDiv">
             <div className="labeAndInputDiv">
             <label>Select Option</label>
              <div ref={statusDropdownRef} className="custom-dropdown">
                <div className="selected-option" onClick={categoryToggleDropdown}>
                  {categoryOptionTest || categoryOptionVisa || 'Select Category option'}
                </div>
                {isCategoryOptionOpen && (
                  <div className="options">
                    {categoryOptions?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleCategoryOptionSelection(option.value)}
                    >
                    {option?.text}
                    </div>
                    ))}
                </div>
                )}
              </div>
              </div>
              {
              categoryOptionTest && (<div className="labeAndInputDiv">
              <label>Attempts</label>
              <input
                label={"Attempts"}
                type="Number"
                className="visitorInputField"
                placeholder={"Enter Attempts"}
                name="productCode"
                autoComplete="off"
                maxLength="40"
                required
                value = {attempts}
                onChange={(e)=>{
                  if(testPrice)
                  {
                    setTotalPayment(parseInt(testPrice) * parseInt(e.target.value))
                    setAttempts(e.target.value)
                  }
                  else
                  setAttempts(e.target.value)}}
                  // (e)=> setInitialPayment(e.target.value)}
                />
                </div> )
            }
          
            </div>
             <div className="visitorInputDiv">
             {
               (categoryOptionTest) ? (
                <>
                 <div className="labeAndInputDiv">
                <label>Test Name</label>
                <div ref={testDropdownRef} className="custom-dropdown">
                <div className="selected-option" onClick={testToggleDropdown}>
                  {selectedTestName || 'Select Test option'}
                </div>
                {isTestOpen && (
                  <div className="options">
                    {testRecord?.test?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleTestValue(option._id, option.testPrice, option.testName)}
                    >
                    {option?.testName}
                    </div>
                    ))}
                </div>
                )}
              </div>
              </div>
              </>) : (
               <>
                {
                  categoryOptionVisa ? (
                    <>
                  <div className="labeAndInputDiv">
                  <label>Visa Name</label>
                  <div ref={visaDropdownRef} className="custom-dropdown">
                <div className="selected-option" onClick={visaToggleDropdown}>
                  {selectedVisaName || 'Select Visa option'}
                </div>
                {isVisaOpen && (
                  <div className="options">
                    {visaRecord?.visaData?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleVisaValue(option._id, option.visaPrice, option.visaName)}
                    >
                    {option?.visaName}
                    </div>
                    ))}
                </div>
                )}
              </div>
              </div>
                    </>
                  ) : (<>
                  <div className="labeAndInputDiv">
                  <label>Select Category First</label>
                  <div className="custom-dropdown">
                    <div className="selected-option" onClick={categoryToggleDropdown}>
                    {'Select Category option First'}
                    </div>
                  </div>
                  </div>
                  </>)
                }
             </>)}
             <div className="labeAndInputDiv">
            <label>Total Payment</label>
             <input
              label={"totalPayment"}
              type="Number"
              className="visitorInputField"
              placeholder={"Enter Total Payment"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
              required
              value = {totalPayment}
              onChange={(e)=>{ 
                setInitialPayment('')
                setDiscount('')
                setTotalPayment(e.target.value)}}
              // disabled
              />
               </div>
             <div className="labeAndInputDiv">
            <label>Initial Payment</label>
            <input
              label={"productCode"}
              type="Number"
              className="visitorInputField"
              placeholder={"Enter Initial Payment"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
              max={totalPayment}
              required
              value = {initialPayment}
              onChange={handleInitialAmount}
                // (e)=> setInitialPayment(e.target.value)}
              />
            </div>
        
            </div>
            <div className="LastVisitorInputDiv">
          
            <div className="labeAndInputDiv">
            <label>Discount</label>
            <input
              label={"Discount"}
              type="Number"
              className="visitorInputField"
              placeholder={"Enter Discount"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
              required
              value = {discount}
              onChange={handleDiscountAmount}
                // (e)=> setInitialPayment(e.target.value)}
              />
            </div>
              <div className="labeAndInputDiv">
              <label>Remaining</label>
              <input
              label={"productCode"}
              type="Number"
              className="visitorInputField"
              placeholder={"Enter Remaining Payment"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
             
              required
              value = {remainingPayment}
              onChange={(e)=> setRemainingPayment(e.target.value)}
              disabled
              />
              </div>
          
            </div>
            <div className="LastVisitorInputDiv">
         
            </div>
           
                <div className="buttons">
                <button onClick={back}>back</button>
                <button onClick={updateRecord}>Update Record</button>
                
                </div>
                </div>
          </div>
        </div>
    </>
  );
};

export default UpdateVisitor;
