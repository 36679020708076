import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { PostBranchRecord, getBranchRecord } from "../../../Actions/branchAction";
import { useNavigate } from "react-router-dom";
import "./branch.css"
const BranchForm = () => {
  const dispatch = useDispatch()  
  const [branchCode, setBranchCode] = useState()
  const [branchPhoneNo, setBranchPhoneNO] = useState()
  const [branchAddress, setBranchAddress] = useState()
  const [branchDescription, setBranchDescription] = useState()
  const {branchRecord} = useSelector((state)=> state.branchRecord)
  const [colorTheme, setColorTheme] = useState()
  const navigate = useNavigate()
  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  useEffect(()=>{
    dispatch(getBranchRecord())
  }, [])

  useEffect(() => {
    if (branchRecord?.branch?.length > 0) {
      const sCodes = [];
      branchRecord?.branch?.forEach((item) => {
        if (item.branchCode.startsWith("b_")) {
          sCodes.push(item.branchCode);
        }
      });
      const lastSCode = findLastNumericValue(sCodes);
      if (sCodes?.length === 0) {
        const nextSCode = "b_001";
        setBranchCode(nextSCode);
      } else {
        const nextSCode = generateNextStorageCode("b_", lastSCode);
        setBranchCode(nextSCode);
      }
    }
  }, [branchRecord]);

  const findLastNumericValue = (codes) => {
    let maxNumericValue = 0;
    codes?.forEach((code) => {
      const numericPart = code.substring(2);
      const numericValue = parseInt(numericPart);
      if (numericValue > maxNumericValue) {
        maxNumericValue = numericValue;
      }
    });
    return maxNumericValue;
  };
  const generateNextStorageCode = (prefix, lastNumericValue) => {
    const nextNumericValue = lastNumericValue + 1;
    // console.warn(nextNumericValue);
    const nextNumericPart = nextNumericValue.toString().padStart(3, "0");
    // console.warn(nextNumericPart);
    return prefix + nextNumericPart;
  };

    const addRecord = () =>{
        dispatch(PostBranchRecord(branchCode, branchPhoneNo, branchAddress, branchDescription))
    }

    const clearRecord = ()=>{
        setBranchCode('')
        dispatch(getBranchRecord())
        setBranchPhoneNO('')
        setBranchAddress('')
        setBranchDescription('')
    }

    const ShowTableRecord = async () => {
      navigate('/branchRecordTable')
  }


  return (
    <>
      <MetaData title="Al-Saif ~~Branch" />
      <div className={`branchMain ${colorTheme}`}>
        <div className={`branch`}>
          <div className="branchSecond">
          <div className="heading">
            <h3>{"Add Branch"}</h3> 
            <button onClick={ShowTableRecord}>
              View Records
            </button>
            </div>
         

                <div className="branchFirstInputDiv">
                  <div className="inputDiv">
                    <label className="labelDiv">Branch Code <span>*</span></label>
                    <input
                        label={"productCode"}
                        className="inputField"
                        type="text"
                        placeholder={"Enter Branch Code"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={branchCode}
                        onChange={(e)=> setBranchCode(e.target.value)}
                        disabled
                        />
                    </div>
                    <div className="inputDiv">
                    <label className="labelDiv">Phone No <span>*</span></label>
                    <input
                        label={"productCode"}
                        type="Number"
                        className="inputField"
                        placeholder={"Enter Phone No"}
                        name="productCode"
                        autoComplete="off"
                        required
                        value={branchPhoneNo}
                        onChange={(e)=> setBranchPhoneNO(e.target.value)}
                    />
                    </div>
                    </div>
                     <div className="branchFirstInputDiv">
                     <div className="inputDiv">
                    <label className="labelDiv">Address <span>*</span></label>
                    <input
                        className="inputField"
                        type="text"
                        placeholder={"Enter Address"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={branchAddress}
                        onChange={(e)=> setBranchAddress(e.target.value)}
                    />
                    </div>
                    <div className="inputDiv">
                    <label className="labelDiv">Branch Description <span>*</span></label>
                    <input
                        className="inputField"
                        type="text"
                        placeholder={"Enter Description"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={branchDescription}
                        onChange={(e)=> setBranchDescription(e.target.value)}
                    />
                     </div>
                </div>
                <div className="buttons">
                <button onClick={clearRecord}>Clear</button>
                <button onClick={addRecord}>Add Record</button>
           
                </div>
        </div>
      </div> 
    </div>
    </>
  );
};

export default BranchForm;
