export const GET_VISACUSTOMERPAYMENT_REQUEST = "GET_VISACUSTOMERPAYMENT_REQUEST"
export const GET_VISACUSTOMERPAYMENT_SUCCESS = "GET_VISACUSTOMERPAYMENT_SUCCESS"
export const GET_VISACUSTOMERPAYMENT_FAIL = "GET_VISACUSTOMERPAYMENT_FAIL"

export const GET_VISACUSTOMERPAYMENT_ONSHOP_REQUEST = "GET_VISACUSTOMERPAYMENT_ONSHOP_REQUEST"
export const GET_VISACUSTOMERPAYMENT_ONSHOP_SUCCESS = "GET_VISACUSTOMERPAYMENT_ONSHOP_SUCCESS"
export const GET_VISACUSTOMERPAYMENT_ONSHOP_FAIL = "GET_VISACUSTOMERPAYMENT_ONSHOP_FAIL"


export const GET_VISACUSTOMERPAYMENT_DETAIL_REQUEST = "GET_VISACUSTOMERPAYMENT_DETAIL_REQUEST"
export const GET_VISACUSTOMERPAYMENT_DETAIL_SUCCESS = "GET_VISACUSTOMERPAYMENT_DETAIL_SUCCESS"
export const GET_VISACUSTOMERPAYMENT_DETAIL_FAIL = "GET_VISACUSTOMERPAYMENT_DETAIL_FAIL"

export const GET_VISACUSTOMERPAYMENT_ONDATES_REQUEST = "GET_VISACUSTOMERPAYMENT_ONDATES_REQUEST"
export const GET_VISACUSTOMERPAYMENT_ONDATES_SUCCESS = "GET_VISACUSTOMERPAYMENT_ONDATES_SUCCESS"
export const GET_VISACUSTOMERPAYMENT_ONDATES_FAIL = "GET_VISACUSTOMERPAYMENT_ONDATES_FAIL"

export const GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_REQUEST = "GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_REQUEST"
export const GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_SUCCESS = "GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_SUCCESS"
export const GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_FAIL = "GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_FAIL"

export const UPDATE_VISACUSTOMERPAYMENT_DETAIL_REQUEST = "UPDATE_VISACUSTOMERPAYMENT_DETAIL_REQUEST"
export const UPDATE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS = "UPDATE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS"
export const UPDATE_VISACUSTOMERPAYMENT_DETAIL_FAIL = "UPDATE_VISACUSTOMERPAYMENT_DETAIL_FAIL"


export const DELETE_VISACUSTOMERPAYMENT_DETAIL_REQUEST = "DELETE_VISACUSTOMERPAYMENT_DETAIL_REQUEST"
export const DELETE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS = "DELETE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS"
export const DELETE_VISACUSTOMERPAYMENT_DETAIL_FAIL = "DELETE_VISACUSTOMERPAYMENT_DETAIL_FAIL"

export const GET_VISASTATISTICS_REQUEST = "GET_VISASTATISTICS_REQUEST"
export const GET_VISASTATISTICS_SUCCESS = "GET_VISASTATISTICS_SUCCESS"
export const GET_VISASTATISTICS_FAIL = "GET_VISASTATISTICS_FAIL"

export const GET_VISASTATISTICSONBRANCH_REQUEST = "GET_VISASTATISTICSONBRANCH_REQUEST"
export const GET_VISASTATISTICSONBRANCH_SUCCESS = "GET_VISASTATISTICSONBRANCH_SUCCESS"
export const GET_VISASTATISTICSONBRANCH_FAIL = "GET_VISASTATISTICSONBRANCH_FAIL"

export const POST_VISACUSTOMERPAYMENT_REQUEST = "POST_VISACUSTOMERPAYMENT_REQUEST"
export const POST_VISACUSTOMERPAYMENT_SUCCESS = "POST_VISACUSTOMERPAYMENT_SUCCESS"
export const POST_VISACUSTOMERPAYMENT_FAIL = "POST_VISACUSTOMERPAYMENT_FAIL"

export const GET_VISADASHBOARD_REQUEST = "GET_VISADASHBOARD_REQUEST"
export const GET_VISADASHBOARD_SUCCESS = "GET_VISADASHBOARD_SUCCESS"
export const GET_VISADASHBOARD_FAIL = "GET_VISADASHBOARD_FAIL"

export const GET_VISADASHBOARDONBRANCH_REQUEST = "GET_VISADASHBOARDONBRANCH_REQUEST"
export const GET_VISADASHBOARDONBRANCH_SUCCESS = "GET_VISADASHBOARDONBRANCH_SUCCESS"
export const GET_VISADASHBOARDONBRANCH_FAIL = "GET_VISADASHBOARDONBRANCH_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"