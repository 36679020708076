import axios from "axios"
import axiosInstance from "./baseURL";
import { GET_VISACUSTOMERPAYMENT_DETAIL_FAIL, GET_VISACUSTOMERPAYMENT_DETAIL_REQUEST, GET_VISACUSTOMERPAYMENT_DETAIL_SUCCESS, GET_VISACUSTOMERPAYMENT_FAIL, GET_VISACUSTOMERPAYMENT_ONDATES_FAIL, GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_FAIL, GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_REQUEST, GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_SUCCESS, GET_VISACUSTOMERPAYMENT_ONDATES_REQUEST, GET_VISACUSTOMERPAYMENT_ONDATES_SUCCESS, GET_VISACUSTOMERPAYMENT_ONSHOP_FAIL, GET_VISACUSTOMERPAYMENT_ONSHOP_REQUEST, GET_VISACUSTOMERPAYMENT_ONSHOP_SUCCESS, GET_VISACUSTOMERPAYMENT_REQUEST, GET_VISACUSTOMERPAYMENT_SUCCESS, GET_VISADASHBOARD_FAIL, GET_VISADASHBOARD_REQUEST, GET_VISADASHBOARD_SUCCESS, GET_VISASTATISTICSONBRANCH_FAIL, GET_VISASTATISTICSONBRANCH_REQUEST, GET_VISASTATISTICSONBRANCH_SUCCESS, GET_VISASTATISTICS_FAIL, GET_VISASTATISTICS_REQUEST, GET_VISASTATISTICS_SUCCESS, POST_VISACUSTOMERPAYMENT_FAIL, POST_VISACUSTOMERPAYMENT_REQUEST, POST_VISACUSTOMERPAYMENT_SUCCESS, UPDATE_VISACUSTOMERPAYMENT_DETAIL_FAIL, UPDATE_VISACUSTOMERPAYMENT_DETAIL_REQUEST, UPDATE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS } from "../Constants/visaCustomerPaymentConstants";

export const postVisaCustomerPaymentRecord =  (  
    cust_id,
    visa_id,
    branch_id,
    payments,
    paymentStatus,
    discount,
    totalPayment) => async (dispatch) =>  {
    try {
      dispatch({ type: POST_VISACUSTOMERPAYMENT_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axiosInstance.post(
        `/api/v1/createVisaCustomerPayment`,
        {
            cust_id,
            visa_id,
            branch_id,
            payments,
            paymentStatus,
            discount,
            totalPayment
        }, 
        config
      );
      dispatch({ type: POST_VISACUSTOMERPAYMENT_SUCCESS,
        payload: data });
    } catch (error) {
      dispatch({ type: POST_VISACUSTOMERPAYMENT_FAIL,
        payload: error.response, });
    }
  };

  export const getCustomerVisaRecord = () => async (dispatch) => {
    try {
      dispatch({ type: GET_VISACUSTOMERPAYMENT_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getAllVisaCustomerPayment`);
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_FAIL,
        payload: error.response,
      });
    }
  };

  export const getCustomerVisaRecordOnBranch = (branchCode) => async (dispatch) => {
    try {
      dispatch({ type: GET_VISACUSTOMERPAYMENT_ONSHOP_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getVisaPaymentRecordOnShop/${branchCode}`);
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_ONSHOP_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_ONSHOP_FAIL,
        payload: error.response,
      });
    }
  };

  export const getSpecificCustomerVisaRecord = (id) => async (dispatch) => {
    try {
      dispatch({ type: GET_VISACUSTOMERPAYMENT_DETAIL_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getVisaCustomerPayment/${id}`);
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };

  export const getVisaRecordPaymentOnDates = ( starting, ending) => async (dispatch) => {
    try {
      dispatch({ type: GET_VISACUSTOMERPAYMENT_ONDATES_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getTotalVisaOnDates?starting=${starting}&ending=${ending}`);
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_ONDATES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_ONDATES_FAIL,
        payload: error.response,
      });
    }
  };

  export const getVisaRecordPaymentOnDatesForBranch = (branch, starting, ending) => async (dispatch) => {
    try {
      dispatch({ type: GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getTotalVisaOnDatesForBranch/${branch}?starting=${starting}&ending=${ending}`);
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_FAIL,
        payload: error.response,
      });
    }
  };


  export const updateCustomerVisaRecord = (id,  payments, paymentStatus) => async (dispatch) => {
    try {
      console.log(payments)
      dispatch({ type: UPDATE_VISACUSTOMERPAYMENT_DETAIL_REQUEST });
      console.log(payments)
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } =await axiosInstance.put(`/api/v1/editVisaCustomerPayment/${id}`, {
        payments,
        paymentStatus
      },
      config);
      dispatch({
        type: UPDATE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_VISACUSTOMERPAYMENT_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };


  export const updateCustomerVisaStatus = (id,  Status, ) => async (dispatch) => {
    try {
      console.log(Status)
      dispatch({ type: UPDATE_VISACUSTOMERPAYMENT_DETAIL_REQUEST });
      console.log(Status)
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } =await axiosInstance.put(`/api/v1/editVisaCustomerPayment/${id}`, {
        Status
      },
      config);
      dispatch({
        type: UPDATE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_VISACUSTOMERPAYMENT_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };


  export const getVisaStatistics = (period) => async (dispatch) => {
    try {
      dispatch({ type: GET_VISASTATISTICS_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/visaStatistics?period=${period}`);
      dispatch({
        type: GET_VISASTATISTICS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VISASTATISTICS_FAIL,
        payload: error.response,
      });
    }
  };
  
  export const getVisaStatisticsOnBranch = (branchId, period, ) => async (dispatch) => {
    try {
      dispatch({ type: GET_VISASTATISTICS_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/visaStatisticsOnBranch/${branchId}?period=${period}`);
      dispatch({
        type: GET_VISASTATISTICS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VISASTATISTICS_FAIL,
        payload: error.response,
      });
    }
  };


  export const getVisaDashboard = (period) => async (dispatch) => {
    try {
      dispatch({ type: GET_VISADASHBOARD_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getVisaDashBoard?period=${period}`);
      
      dispatch({
        type: GET_VISADASHBOARD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VISADASHBOARD_FAIL,
        payload: error.response,
      });
    }
  };
  
  export const getVisaDashboardOnBranch = (branchId, period ) => async (dispatch) => {
    try {
      dispatch({ type: GET_VISADASHBOARD_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getVisaDashBoardOnBranch/${branchId}?period=${period}`);
      dispatch({
        type: GET_VISADASHBOARD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VISADASHBOARD_FAIL,
        payload: error.response,
      });
    }
  };