import React, { useEffect,useRef, useState } from "react";
import "./Cards.css";
import {
  UilClipboardAlt,
} from "@iconscout/react-unicons";
import PageLoader from "../../Loader/PageLoader"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import { useNavigate } from "react-router-dom";
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import BookIcon from '@mui/icons-material/Book';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import { useDispatch, useSelector } from "react-redux";
import Card from "../Card/Card";
import { getTestDashboard, getTestDashboardOnBranch, getTestStatistics, getTestStatisticsOnBranch } from "../../../Actions/testCustomerPaymentAction";
import { getVisaDashboard,getVisaDashboardOnBranch, getVisaStatistics, getVisaStatisticsOnBranch } from "../../../Actions/visaCustomerPaymentAction";
import { getBranchRecord } from "../../../Actions/branchAction";
import { getExpenseDashboardOnBranchRecord, getExpenseDashboardRecord } from "../../../Actions/expenseAction";
// import { getVisaDashboardOnBranch } from "../../../../../Backend/controllers/visaCustomerPaymentController";
// import { getTopFourForDashBoard, getTopFourForDashBoardOnShop, getTopFourForPurchasdDashBoardOnShop, getTopFourForPurchaseDashBoard } from "../../../actions/dashboardAction";
// import { getShop } from "../../../actions/shopAction";
let dataa;
let dataa2;
let dataa3;
let dataa4;
const Cards = () => {
  const selectInputRef = useRef();
  const [visaDataAdded, setVisaDataAdded] = useState(false)
  const [testDataAdded, setTestDataAdded] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const {branchRecord, branchLoading}= useSelector((state)=> state.branchRecord)
  const [selectedDuration, setSelectedDuration] = useState('');
  const [branchDatalist, setBranchDataList] = useState()
  const [selectedShop, setSelectedShop] = useState('');
  const navigate = useNavigate()
  const {user} = useSelector((state)=> state.user)
  const {testStatistics, testStatisticsLoading} = useSelector((state)=> state.testStatistics)
  const {testDashboard, testDashboardLoading} = useSelector((state)=> state.testDashboard)
  const {visaDashboard, visaDashboardLoading} = useSelector((state)=> state.visaDashboard)
  const {visaStatistics, visaStatisticsLoading} = useSelector((state)=> state.visaStatistics)
  const {expenseDashboardRecord, expenseDashboardRecordLoading} = useSelector((state)=> state.expenseDashboardRecord)
  const dispatch = useDispatch()
  const handleDropdownChange = ( value ) => {
    setVisaDataAdded(false)
    setTestDataAdded(false)
    console.log(value)
    if(value)
    {
      setSelectedDuration(value?.value);
    }else{
      setSelectedDuration('')
      // Call()
    }
  
    // Send the selected value back to the parent component
  };
  const handleShopDropdownChange = (event, { value }) => {
    setVisaDataAdded(true)
    setTestDataAdded(true)
    setSelectedShop(value);
    
    // Send the selected value back to the parent component
  };
  const categoryOptions = [
    { key: "1", label: "Today", value: "daily" },
    { key: "2", label: "This Week", value: "weekly" },
    { key: "3", label: "This Month", value: "monthly" },
    { key: "5", label: "This Year", value: "yearly" },
    { key: "6", label: "Overall", value: "overall" },
  ];

  useEffect(()=>{
    // dispatch(getShop())
    dispatch(getBranchRecord())
  }, [])
  useEffect(()=>{
    Call()
  }, [selectedDuration, selectedShop])

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCardSelect = (selectedValue) => {
    console.log('Selected value in Cards.js:', selectedValue);
    // Do something with the selected value in the parent component
  };

  useEffect(()=>{
    console.log(testDashboard?.testDashboardLoading)
    console.log(testDashboardLoading)
  }, [testDashboard?.testDashboardLoading])

  useEffect(()=>{
    console.log(testDashboard?.detailedCounts)
    console.log(testDashboardLoading)
    if(!testStatisticsLoading && !testDashboardLoading && !visaStatisticsLoading )
    {
      
      console.log(testStatistics)
      
      const totalStudentsData = testStatistics?.newStudentsByDate.map(entry =>entry.count);
      const totalStudentsDateData = testStatistics?.newStudentsByDate.map(entry =>entry._id);
     
     
      const totalStudentsDashboard = testDashboard?.detailedCounts?.map(entry =>entry.count);
      const totalStudentsDateDashboard = testDashboard?.detailedCounts?.map(entry =>entry.name);

      const totalStudentsBudget = testDashboard?.detailedCounts?.map(entry =>entry.totalPayments);
      const totalStudentsDateBudget = testDashboard?.detailedCounts?.map(entry =>entry.name);

      const testRemaningData = visaStatistics?.result?.lastPaymentsDetails?.map(entry =>entry.remaining);
      const testRemainingDateData = visaStatistics?.result?.lastPaymentsDetails?.map(entry =>entry.date);
        // totalStudentsDashboard = testDashboard?.map(detailedCount=> detailedCount?.detailedCounts?.map(entry =>entry.count));
        // totalStudentsDateDashboard = testDashboard?.map(detailedCount=> detailedCount?.detailedCounts?.map(entry =>entry.name));
      
      console.log(totalStudentsDashboard)
      console.log(totalStudentsDateDashboard)
      const totalTestData = testStatistics?.newTestsByDate.map(entry =>entry.count);
      const totalTestDateData = testStatistics?.newTestsByDate.map(entry =>entry.date);

      const totalRemainingStudentsData = testStatistics?.remaningStudentsByDate.map(entry =>entry.count);
      const totalRemainingStudentDateData = testStatistics?.remaningStudentsByDate.map(entry =>entry._id);

      const totalPassOutData = testStatistics?.passoutStudentsData.map(entry =>entry.count);
      const totalPassOutDateData = testStatistics?.passoutStudentsData.map(entry =>entry.date);
      dataa = [
      
      {
        title: "Total Tests",
        color: {
          label: "white",
          backGround: "#003B95",
          boxShadow: "rgb(139 139 227) 0px 10px 20px ",
        },
        barValue: 60,
        value:testDashboard?.totals?.totalCount,
        png: UilClipboardAlt,
        series: [
          {
            name: "Tests",
            data: totalStudentsDashboard
          },
        ],
        date: totalStudentsDateDashboard,
        duration:  selectedDuration ||  "Over All"
      },
     {
        title: "Test Budget",
        color: {
          backGround: "#003B95",
          boxShadow: "rgb(139 139 227) 0px 10px 20px ",
        },
        barValue: 60,
        value:testDashboard?.totals?.totalPayments,
        png: UilClipboardAlt,
        series: [
          {
            name: "Budget",
            data: totalStudentsBudget,
          },
        ],
        date: totalStudentsDateBudget,
        duration: selectedDuration ||  "Over All"
      },
      {
        title: "Tests Remaining Payment",
        color: {
          label: "white",
          backGround: "#003B95",
          boxShadow: "rgb(139 139 227) 0px 10px 20px ",
        },
        barValue: 60,
        value:visaStatistics?.result?.totalRemaining,
        png: UilClipboardAlt,
        series: [
          {
            name: "Tests Remainings",
            data: testRemaningData
          },
        ],
        date: testRemainingDateData,
        duration: selectedDuration ||  "Over All"
      },
    ]
     

      setTestDataAdded(true)
    }
   
  }, [testStatistics, testStatisticsLoading, testDashboard, testDashboardLoading, visaStatistics,visaStatisticsLoading])


  useEffect(()=>{
    if(!expenseDashboardRecordLoading && !testDashboardLoading && !testStatisticsLoading)
      {
        console.log(expenseDashboardRecord)
        let totalExpenseDashboard=[];
        let totalExpenseDateDashboard=[];
        if(expenseDashboardRecord?.expenses?.length > 0)
          {
            totalExpenseDashboard = expenseDashboardRecord?.expenses[0]?.expenses?.map(entry =>entry.totalExpenseAmount);
             totalExpenseDateDashboard = expenseDashboardRecord?.expenses[0]?.expenses?.map(entry =>entry._id);
          }
        

        const totalPassOutData = testStatistics?.passoutStudentsData?.map(entry =>entry.count);
        const totalPassOutDateData = testStatistics?.passoutStudentsData?.map(entry =>entry.date);

        const totalStudentsData = testStatistics?.newStudentsByDate?.map(entry =>entry.count);
        const totalStudentsDateData = testStatistics?.newStudentsByDate?.map(entry =>entry._id);
        console.log(totalExpenseDateDashboard)
        
      dataa4 = [
        {
          title: "Total Students",
          color: {
            backGround: "#003B95",
            boxShadow: "rgb(139 139 227) 0px 10px 20px ",
          },
          barValue: 60,
          value: testStatistics?.totalStudents,
          png: UilClipboardAlt,
          series: [
            {
              name: "Students",
              data: totalStudentsData,
            },
          ],
          date: totalStudentsDateData,
          duration: selectedDuration ||  "Over All"
        },
        
      {
        title: "Passout Student",
        color: {
          font: "#003B95",
          backGround: "#003B95",
          boxShadow: "rgb(139 139 227) 0px 10px 20px ",
        },
        barValue: 60,
        value: testStatistics?.passoutStudents,
        png: UilClipboardAlt,
        series: [
          {
            name: "Passout Students",
            data: totalPassOutData,
          },
        ],
        date: totalPassOutDateData,
        duration: selectedDuration ||  "Over All"
      },
      {
        title: "Total Expenses ",
        color: {
          backGround: "#003B95",
          boxShadow: "rgb(139 139 227) 0px 10px 20px ",
        },
        barValue: 60,
        value: expenseDashboardRecord?.expenses[0]?.totalPaymentsOverAll,
        png: UilClipboardAlt,
        series: [
          {
            name: "Expenses",
            data: totalExpenseDashboard,
          },
        ],
        date: totalExpenseDateDashboard,
        duration: selectedDuration ||  "Over All"
      },
      // {
      //   title: "Total Expenses ",
      //   color: {
      //     backGround: "#003B95",
      //     boxShadow: "rgb(139 139 227) 0px 10px 20px ",
      //   },
      //   barValue: 60,
      //   value: expenseDashboardRecord?.expenses[0]?.totalPaymentsOverAll,
      //   png: UilClipboardAlt,
      //   series: [
      //     {
      //       name: "Expenses",
      //       data: totalExpenseDashboard,
      //     },
      //   ],
      //   date: totalExpenseDateDashboard,
      //   duration: selectedDuration ||  "Over All"
      // },
      // {
      //   title: "Total Expenses ",
      //   color: {
      //     backGround: "#003B95",
      //     boxShadow: "rgb(139 139 227) 0px 10px 20px ",
      //   },
      //   barValue: 60,
      //   value: expenseDashboardRecord?.expenses[0]?.totalPaymentsOverAll,
      //   png: UilClipboardAlt,
      //   series: [
      //     {
      //       name: "Expenses",
      //       data: totalExpenseDashboard,
      //     },
      //   ],
      //   date: totalExpenseDateDashboard,
      //   duration: selectedDuration ||  "Over All"
      // },
      // {
      //   title: "Total Expenses ",
      //   color: {
      //     backGround: "#003B95",
      //     boxShadow: "rgb(139 139 227) 0px 10px 20px ",
      //   },
      //   barValue: 60,
      //   value: expenseDashboardRecord?.expenses[0]?.totalPaymentsOverAll,
      //   png: UilClipboardAlt,
      //   series: [
      //     {
      //       name: "Expenses",
      //       data: totalExpenseDashboard,
      //     },
      //   ],
      //   date: totalExpenseDateDashboard,
      //   duration: selectedDuration ||  "Over All"
      // },
     ]
      }

  }, [expenseDashboardRecord, expenseDashboardRecordLoading, testStatistics, testDashboard, testStatisticsLoading, testDashboardLoading])


  useEffect(()=>{
    console.log(testStatisticsLoading)
    if(!visaStatisticsLoading && !testStatisticsLoading && !visaDashboardLoading)
    {
      console.log('fie')
      const totalVisaCustomerData = visaStatistics?.newVisaCustomerByDate.map(entry =>entry.count);
      const totalVisaCustomerDateData = visaStatistics?.newVisaCustomerByDate.map(entry =>entry._id);

      const totalVisaDashboard = visaDashboard?.detailedCounts?.map(entry =>entry.count);
      const totalVisaDateDashboard = visaDashboard?.detailedCounts?.map(entry =>entry.name);

      const totalVisaBudget = visaDashboard?.detailedCounts?.map(entry =>entry.totalPayments);
      const totalVisaDateBudget = visaDashboard?.detailedCounts?.map(entry =>entry.name);

      const totalVisaData = visaStatistics?.newVisaByDate.map(entry =>entry.count);
      const totalVisatDateData = visaStatistics?.newVisaByDate.map(entry =>entry.date);
      console.log(totalVisaData)
      let visaRemaningData=[]
      let visaRemainingDateData=[]
      let testRemaningData=[]
      let testRemainingDateData=[];
      // if(visaStatistics?.visaResult)
      // {
        visaRemaningData = visaStatistics?.visaResult?.lastPaymentsDetails?.map(entry =>entry.remaining);
        visaRemainingDateData = visaStatistics?.visaResult?.lastPaymentsDetails?.map(entry =>entry.date);
      // }

      // if(visaStatistics?.result)
      // {
        testRemaningData = visaStatistics?.result?.lastPaymentsDetails?.map(entry =>entry.remaining);
        testRemainingDateData = visaStatistics?.result?.lastPaymentsDetails?.map(entry =>entry.date);
     
      dataa2 = [{
        title: "Total Visa ",
        color: {
          backGround: "#003B95",
          boxShadow: "rgb(139 139 227) 0px 10px 20px ",
        },
        barValue: 60,
        value: visaDashboard?.totals?.totalCount,
        png: UilClipboardAlt,
        series: [
          {
            name: "Visa Customers",
            data: totalVisaDashboard,
          },
        ],
        date: totalVisaDateDashboard,
        duration: selectedDuration ||  "Over All"
      },
      {
        title: "Total Visa Budget",
        color: {
          label: "white",
          backGround: "#003B95",
          boxShadow: "rgb(139 139 227) 0px 10px 20px ",
        },
        barValue: 60,
        value:visaDashboard?.totals?.totalPayments,
        png: UilClipboardAlt,
        series: [
          {
            name: "Visa Budget",
            data: totalVisaBudget
          },
        ],
        date: totalVisaDateBudget,
        duration: selectedDuration ||  "Over All"
      },
     
      {
        title: "Visa's Remaining Payments",
        color: {
          backGround: "#003B95",
          boxShadow: "rgb(139 139 227) 0px 10px 20px ",
        },
        barValue: 60,
        value: visaStatistics?.visaResult?.totalRemaining,
        png: UilClipboardAlt,
        series: [
          {
            name: "Visa Remainings",
            data: visaRemaningData,
          },
        ],
        date: visaRemainingDateData,
        duration: selectedDuration ||  "Over All"
      },
     ]
      console.log(testStatistics)
    }
    setVisaDataAdded(true)
    setVisaDataAdded(true)
  }, [visaStatistics, visaStatisticsLoading,testStatisticsLoading, visaDashboard, visaDashboardLoading])

  const Call=async() =>{
    const timePeriod = 'overall'
    console.log(selectedDuration)
    console.log(selectedShop)
    console.log(user)
    if(selectedDuration || selectedShop){
      if(user?.user?.role?.roleName === "superAdmin" || user?.user?.role?.roleName === "Administrator")
      {
        if(selectedDuration && selectedShop)
        {
          dispatch(getTestStatisticsOnBranch(selectedShop, selectedDuration ))
          dispatch(getVisaStatisticsOnBranch(selectedShop, selectedDuration, ))
          dispatch(getVisaDashboardOnBranch(selectedShop, selectedDuration, ))
          dispatch(getTestDashboardOnBranch(selectedShop, selectedDuration, ))
          dispatch(getExpenseDashboardOnBranchRecord(selectedShop, selectedDuration, ))
        }else
        if(selectedShop){
          console.log('ifie')
          dispatch(getTestStatisticsOnBranch(selectedShop, selectedDuration, ))
          dispatch(getTestDashboardOnBranch(selectedShop, selectedDuration, ))
          dispatch(getVisaDashboardOnBranch(selectedShop, selectedDuration, ))
          dispatch(getVisaStatisticsOnBranch(selectedShop, selectedDuration, ))
          dispatch(getExpenseDashboardOnBranchRecord(selectedShop, selectedDuration, ))
        }else if(selectedDuration){
            dispatch(getTestStatistics(selectedDuration))
            dispatch(getTestDashboard(selectedDuration))
            dispatch(getVisaDashboard(selectedDuration))
            dispatch(getVisaStatistics(selectedDuration))
            dispatch(getExpenseDashboardRecord(selectedDuration))
        }
      }else{
        if(selectedDuration)
        {
          dispatch(getTestStatisticsOnBranch(user?.user?.branch?._id, selectedDuration, ))
          dispatch(getTestDashboardOnBranch(user?.user?.branch?._id, selectedDuration, ))
          dispatch(getVisaDashboardOnBranch(user?.user?.branch?._id, selectedDuration, ))
          dispatch(getVisaStatisticsOnBranch(user?.user?.branch?._id, selectedDuration, ))
          dispatch(getExpenseDashboardOnBranchRecord(user?.user?.branch?._id, selectedDuration, ))
        }
      }
    }else{
      if(user?.user?.role?.roleName === "superAdmin" || user?.user?.role?.roleName === "Administrator")
      {
        console.log('hfei')
        dispatch(getTestStatistics("overall"))
        dispatch(getTestDashboard("overall"))
        dispatch(getVisaDashboard("overall"))
        dispatch(getVisaStatistics("overall"))
        dispatch(getExpenseDashboardRecord("overall"))
      }else{
        dispatch(getTestStatisticsOnBranch(user?.user?.branch?._id, 'overall' ))
        dispatch(getTestDashboardOnBranch(user?.user?.branch?._id, 'overall' ))
        dispatch(getVisaDashboardOnBranch(user?.user?.branch?._id, 'overall' ))
          dispatch(getVisaStatisticsOnBranch(user?.user?.branch?._id,'overall' ))
          dispatch(getExpenseDashboardOnBranchRecord(user?.user?.branch?._id, 'overall' ))
      }
    }
    // if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
    // {
    //   dispatch(getTestStatisticsOnBranch(timePeriod, user?.user?.branch?._id))
    //   dispatch(getVisaStatisticsOnBranch(timePeriod, user?.user?.branch?._id))
      
    // }
    // else{
    //   dispatch(getTestStatistics(timePeriod))
    //   dispatch(getVisaStatistics(timePeriod))
    // }

   dataa3 = [{
      png: SensorOccupiedIcon,
      text: "Add Student",
      path: "/customerAdd"
    },
    {
      png: BookIcon,
      text: "Test Bookings",
      path: "/testBookingTable"
    },
    {
      png: SensorOccupiedIcon,
      text: "Existing Students",
      path: "/customerRecordTest"
    }
    ,{
      png: AirplanemodeActiveIcon,
      text: "Visa's",
      path: "/customerRecordVisa"
    },{
      png: SensorOccupiedIcon,
      text: "Expense",
      path: "/expenseInvoice"
    }]
  
      // setVisaDataAdded(true)
      // setTestDataAdded(true)
    
  }

  const handleBranchChange=(selectedValue) => {
    setVisaDataAdded(false)
      setTestDataAdded(false)
      if(selectedValue)
      {
        console.log(selectedValue)
        setSelectedShop(selectedValue.value);
      }else{
        setSelectedShop('')
        // Call()
      }

    // setBatch(selectedValue?.value)
  }

  useEffect(()=>{
    console.log()
    if(branchRecord?.success && !branchLoading)
    {
      setBranchDataList(branchRecord?.branch.map(branchData =>({
        value: branchData._id,
        label: branchData?.branchCode + ' (' + branchData?.branchAddress + ')'
      })))
    }
  }, [branchRecord])

  const loadBatchOptions = (search, callBack) => {
    console.log('ajfe')
    console.log(branchDatalist)
  
    setTimeout(()=>{
      console.log(branchDatalist)
      const filterOptions = branchDatalist?.filter(option=> option?.value.toLowerCase().includes(search.toLowerCase()))
      console.log(filterOptions)
      callBack(filterOptions)
    }, 3000)
  }
  return (<>
    {/* {dataAdded && (<> */}
   
      <div className="dropdown-box ">
        {(user?.user?.role?.roleName === "superAdmin" || user?.user?.role?.roleName=== "Administrator") ? (
          <>
            <div className="dashboardDropdown1">
            <Select placeholder={'Select Time Period'} isClearable={true}   ref={selectInputRef} options={categoryOptions} defaultOptions={categoryOptions} onChange={handleDropdownChange} />
            </div>
            <div    ref={selectInputRef} className="dashboardDropdown1">
            <AsyncSelect placeholder={'Select Branch'} isClearable={true}
              loadOptions={branchDatalist?.length > 0 && loadBatchOptions}
               defaultOptions={branchDatalist} onChange={handleBranchChange} />
               </div>
          
           
          </>
        ): (<>
          <div className="dashboardDropdown1">
          <Select placeholder={'Select Time Period'} isClearable={true}   ref={selectInputRef} options={categoryOptions} onChange={handleDropdownChange} />
          </div>
        </>)
        }
        {/* <button className="buttons" onClick={()=>{setSelectedDuration('')
        // selectInputRef?.current?.clearValue();
        setSelectedShop('')
        setTestDataAdded(false)
        setVisaDataAdded(false)
        Call()}}>clear</button> */}
      
        {/* Your dropdown content goes here */}
       
      </div>
    {/* </>)} */}
    {(visaDataAdded && testDataAdded) ? (<>
    <div className="Cards">
    <div className="cardsLastContainer">
      {dataa3?.map((card, id) => {
        return (<>
          <div className="lastCont" onClick={()=> {navigate(`${card.path}`)
          console.log('fe')}}>
            <div>{<card.png onClick={()=> {navigate(`${card.path}`)
          console.log('fe')}}/>}</div>
            <span>{card.text}</span>
          </div>
            </>
        );
      })}
      </div>
      <div className="cardsContainer">
      {dataa4?.map((card, id) => {
        return (
          <div className="parentContainer" key={id}>
            <Card
              title={card.title}
              color={card.color}
              barValue={card.barValue}
              value={card.value}
              png={card.png}
              series={card.series}
              date={card.date}
              duration={card.duration}
              onSelectDropdown={handleCardSelect}
            />
          </div>
        );
      })}
      </div>
      <div className="cardsContainer">
        {dataa?.map((card, id) => {
        return (
          <div className="parentContainer" key={id}>
            <Card
              title={card.title}
              color={card.color}
              barValue={card.barValue}
              value={card.value}
              png={card.png}
              series={card.series}
              date={card?.date}
              duration={card.duration}
              onSelectDropdown={handleCardSelect}
            />
          </div>
        );
      })}
      </div>
      <div className="cardsContainer">
      {dataa2?.map((card, id) => {
        return (
          <div className="parentContainer" key={id}>
            <Card
              title={card.title}
              color={card.color}
              barValue={card.barValue}
              value={card.value}
              png={card.png}
              series={card.series}
              date={card.date}
              duration={card.duration}
              onSelectDropdown={handleCardSelect}
            />
          </div>
        );
      })}
      </div>
     
    
      </div></>): (<PageLoader />)}
    
      {/* </div> */}
       </>)
};

export default Cards;
