import {  CLEAR_ERRORS, DELETE_TESTCUSTOMERPAYMENT_DETAIL_FAIL, DELETE_TESTCUSTOMERPAYMENT_DETAIL_REQUEST, DELETE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS, GET_NOTIFICATION_FAIL, GET_NOTIFICATION_REQUEST, GET_NOTIFICATION_SUCCESS, GET_TESTCUSTOMERPAYMENT_DETAIL_FAIL, GET_TESTCUSTOMERPAYMENT_DETAIL_REQUEST, GET_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS, GET_TESTCUSTOMERPAYMENT_FAIL, GET_TESTCUSTOMERPAYMENT_ONDATES_FAIL, GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_FAIL, GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_REQUEST, GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_SUCCESS, GET_TESTCUSTOMERPAYMENT_ONDATES_REQUEST, GET_TESTCUSTOMERPAYMENT_ONDATES_SUCCESS, GET_TESTCUSTOMERPAYMENT_ONSHOP_FAIL, GET_TESTCUSTOMERPAYMENT_ONSHOP_REQUEST, GET_TESTCUSTOMERPAYMENT_ONSHOP_SUCCESS, GET_TESTCUSTOMERPAYMENT_REQUEST, GET_TESTCUSTOMERPAYMENT_SUCCESS, GET_TESTDASHBOARDONBRANCH_FAIL, GET_TESTDASHBOARDONBRANCH_REQUEST, GET_TESTDASHBOARDONBRANCH_SUCCESS, GET_TESTDASHBOARD_FAIL, GET_TESTDASHBOARD_REQUEST, GET_TESTDASHBOARD_SUCCESS, GET_TESTSTATISTICSONBRANCH_FAIL, GET_TESTSTATISTICSONBRANCH_REQUEST, GET_TESTSTATISTICSONBRANCH_SUCCESS, GET_TESTSTATISTICS_FAIL, GET_TESTSTATISTICS_REQUEST, GET_TESTSTATISTICS_SUCCESS, POST_TESTCUSTOMERPAYMENT_FAIL, POST_TESTCUSTOMERPAYMENT_REQUEST, POST_TESTCUSTOMERPAYMENT_SUCCESS, UPDATE_TESTCUSTOMERPAYMENT_DETAIL_FAIL, UPDATE_TESTCUSTOMERPAYMENT_DETAIL_REQUEST, UPDATE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS, UPDATE_TESTCUSTOMERPAYMENT_STATUS_FAIL, UPDATE_TESTCUSTOMERPAYMENT_STATUS_REQUEST, UPDATE_TESTCUSTOMERPAYMENT_STATUS_SUCCESS } from "../Constants/testCustomerPaymentConstants";


export const getTestCustomerPaymentReducer = (state = { testCustomerPaymentRecord: []}, action)=>{
    switch (action.type){
        case GET_TESTCUSTOMERPAYMENT_REQUEST:
            return{
                testCustomerPaymentRecordLoading: true,
                testCustomerPaymentRecord: [],
            };
        case GET_TESTCUSTOMERPAYMENT_SUCCESS: 
            return {
                testCustomerPaymentRecordLoading: false,
                testCustomerPaymentRecord: action.payload
            }
        case GET_TESTCUSTOMERPAYMENT_FAIL:
            return {
                testCustomerPaymentRecordLoading: false,
                testCustomerPaymentRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                testCustomerPaymentRecordError: null,
            }
            default:
            return state;
            
    }
}

export const getTestCustomerPaymentOnShopReducer = (state = { testCustomerPaymentRecordOnShop: []}, action)=>{
    switch (action.type){
        case GET_TESTCUSTOMERPAYMENT_ONSHOP_REQUEST:
            return{
                testCustomerPaymentRecordOnShopLoading: true,
                testCustomerPaymentRecordOnShop: [],
            };
        case GET_TESTCUSTOMERPAYMENT_ONSHOP_SUCCESS: 
            return {
                testCustomerPaymentRecordOnShopLoading: false,
                testCustomerPaymentRecordOnShop: action.payload
            }
        case GET_TESTCUSTOMERPAYMENT_ONSHOP_FAIL:
            return {
                testCustomerPaymentRecordOnShopLoading: false,
                testCustomerPaymentRecordOnShopError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                testCustomerPaymentRecordOnShopError: null,
            }
            default:
            return state;
            
    }
}

export const getTestCustomerPaymentDetailReducer = (state = { testCustomerPaymentDetailRecord: []}, action)=>{
    switch (action.type){
        case GET_TESTCUSTOMERPAYMENT_DETAIL_REQUEST:
            return{
                testCustomerPaymentDetailRecordLoading: true,
                testCustomerPaymentDetailRecord: [],
            };
        case GET_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS: 
            return {
                testCustomerPaymentDetailRecordLoading: false,
                testCustomerPaymentDetailRecord: action.payload
            }
        case GET_TESTCUSTOMERPAYMENT_DETAIL_FAIL:
            return {
                testCustomerPaymentDetailRecordLoading: false,
                testCustomerPaymentDetailRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                testCustomerPaymentDetailRecordError: null,
            }
            default:
            return state;
            
    }
}

export const getTestCustomerPaymentOnDatesReducer = (state = { testCustomerPaymentOnDates: []}, action)=>{
    switch (action.type){
        case GET_TESTCUSTOMERPAYMENT_ONDATES_REQUEST:
            return{
                testCustomerPaymentOnDatesLoading: true,
                testCustomerPaymentOnDates: [],
            };
        case GET_TESTCUSTOMERPAYMENT_ONDATES_SUCCESS: 
            return {
                testCustomerPaymentOnDatesLoading: false,
                testCustomerPaymentOnDates: action.payload
            }
        case GET_TESTCUSTOMERPAYMENT_ONDATES_FAIL:
            return {
                testCustomerPaymentOnDatesLoading: false,
                testCustomerPaymentOnDatesError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                testCustomerPaymentOnDatesError: null,
            }
            default:
            return state;
            
    }
}

export const getTestCustomerPaymentOnDatesForBranchReducer = (state = { testCustomerPaymentOnDatesForBranch: []}, action)=>{
    switch (action.type){
        case GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_REQUEST:
            return{
                testCustomerPaymentOnDatesForBranchLoading: true,
                testCustomerPaymentOnDatesForBranch: [],
            };
        case GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_SUCCESS: 
            return {
                testCustomerPaymentOnDatesForBranchLoading: false,
                testCustomerPaymentOnDatesForBranch: action.payload
            }
        case GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_FAIL:
            return {
                testCustomerPaymentOnDatesForBranchLoading: false,
                testCustomerPaymentOnDatesForBranchError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                testCustomerPaymentOnDatesForBranchError: null,
            }
            default:
            return state;
            
    }
}


export const getTestAndVisaNotifications = (state = { notificationRecord: []}, action)=>{
    switch (action.type){
        case GET_NOTIFICATION_REQUEST:
            return{
                notificationRecordLoading: true,
                notificationRecord: [],
            };
        case GET_NOTIFICATION_SUCCESS: 
            return {
                notificationRecordLoading: false,
                notificationRecord: action.payload
            }
        case GET_NOTIFICATION_FAIL:
            return {
                notificationRecordLoading: false,
                notificationRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                notificationRecordError: null,
            }
            default:
            return state;
            
    }
}

export const getTestStatisticsReducer = (state = { testStatistics: []}, action)=>{
    switch (action.type){
        case GET_TESTSTATISTICS_REQUEST:
            return{
                testStatisticsLoading: true,
                testStatistics: [],
            };
        case GET_TESTSTATISTICS_SUCCESS: 
            return {
                testStatisticsLoading: false,
                testStatistics: action.payload
            }
        case GET_TESTSTATISTICS_FAIL:
            return {
                testStatisticsLoading: false,
                testStatisticsError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                testStatisticsError: null,
            }
            default:
            return state;
            
    }
}

export const getTestStatisticsOnBranchReducer = (state = { testStatisticsOnBranch: []}, action)=>{
    switch (action.type){
        case GET_TESTSTATISTICSONBRANCH_REQUEST:
            return{
                testStatisticsOnBranchLoading: true,
                testStatisticsOnBranch: [],
            };
        case GET_TESTSTATISTICSONBRANCH_SUCCESS: 
            return {
                testStatisticsOnBranchLoading: false,
                testStatisticsOnBranch: action.payload
            }
        case GET_TESTSTATISTICSONBRANCH_FAIL:
            return {
                testStatisticsOnBranchLoading: false,
                testStatisticsOnBranchError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                testStatisticsOnBranchError: null,
            }
            default:
            return state;
            
    }
}


export const getTestDashboardReducer = (state = { testDashboard: []}, action)=>{
    switch (action.type){
        case GET_TESTDASHBOARD_REQUEST:
            return{
                testDashboardLoading: true,
                testDashboard: [],
            };
        case GET_TESTDASHBOARD_SUCCESS: 
            return {
                testDashboardLoading: false,
                testDashboard: action.payload
            }
        case GET_TESTDASHBOARD_FAIL:
            return {
                testDashboardLoading: false,
                testDashboardError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                testDashboardError: null,
            }
            default:
            return state;
            
    }
}

export const getTestDashboardOnBranchReducer = (state = { testDashboardOnBranch: []}, action)=>{
    switch (action.type){
        case GET_TESTDASHBOARDONBRANCH_REQUEST:
            return{
                testDashboardOnBranchLoading: true,
                testDashboardOnBranch: [],
            };
        case GET_TESTDASHBOARDONBRANCH_SUCCESS: 
            return {
                testDashboardOnBranchLoading: false,
                testDashboardOnBranch: action.payload
            }
        case GET_TESTDASHBOARDONBRANCH_FAIL:
            return {
                testDashboardOnBranchLoading: false,
                testDashboardOnBranchError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                testDashboardOnBranchError: null,
            }
            default:
            return state;
            
    }
}


export const updateTestCustomerPaymentReducer = (state = { updateTestCustomerPayment: []}, action)=>{
    switch (action.type){
        case UPDATE_TESTCUSTOMERPAYMENT_DETAIL_REQUEST:
            return{
                updateTestCustomerPaymentLoading: true,
                updateTestCustomerPayment: [],
            };
        case UPDATE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS: 
            return {
                updateTestCustomerPaymentLoading: false,
                updateTestCustomerPayment: action.payload
            }
        case UPDATE_TESTCUSTOMERPAYMENT_DETAIL_FAIL:
            return {
                updateTestCustomerPaymentLoading: false,
                updateTestCustomerPaymentError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                updateTestCustomerPaymentError: null,
            }
            default:
            return state;
            
    }
}

export const updateTestCustomerPaymentStatusReducer = (state = { updateTestCustomerPaymentStatus: []}, action)=>{
    switch (action.type){
        case UPDATE_TESTCUSTOMERPAYMENT_STATUS_REQUEST:
            return{
                updateTestCustomerPaymentStatusLoading: true,
                updateTestCustomerPaymentStatus: [],
            };
        case UPDATE_TESTCUSTOMERPAYMENT_STATUS_SUCCESS: 
            return {
                updateTestCustomerPaymentStatusLoading: false,
                updateTestCustomerPaymentStatus: action.payload
            }
        case UPDATE_TESTCUSTOMERPAYMENT_STATUS_FAIL:
            return {
                updateTestCustomerPaymentStatusLoading: false,
                updateTestCustomerPaymentStatusError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                updateTestCustomerPaymentStatusError: null,
            }
            default:
            return state;
            
    }
}

export const deleteTestCustomerPaymentReducer = (state = { deleteTestCustomerPayment: []}, action)=>{
    switch (action.type){
        case DELETE_TESTCUSTOMERPAYMENT_DETAIL_REQUEST:
            return{
                deleteTestCustomerPaymentLoading: true,
                deleteTestCustomerPayment: [],
            };
        case DELETE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS: 
            return {
                deleteTestCustomerPaymentLoading: false,
                deleteTestCustomerPayment: action.payload
            }
        case DELETE_TESTCUSTOMERPAYMENT_DETAIL_FAIL:
            return {
                deleteTestCustomerPaymentLoading: false,
                deleteTestCustomerPaymentError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                deleteTestCustomerPaymentError: null,
            }
            default:
            return state;
            
    }
}


export const postTestCustomerPaymentReducer = (state = { postTestCustomerPayment: []}, action)=>{
    switch (action.type){
        case POST_TESTCUSTOMERPAYMENT_REQUEST:
            return{
                postTestCustomerPaymentLoading: true,
                postTestCustomerPayment: [],
            };
        case POST_TESTCUSTOMERPAYMENT_SUCCESS: 
            return {
                postTestCustomerPaymentLoading: false,
                postTestCustomerPayment: action.payload
            }
        case POST_TESTCUSTOMERPAYMENT_FAIL:
            return {
                postTestCustomerPaymentLoading: false,
                postTestCustomerPaymentError: action.payload.data,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                postTestCustomerPaymentError: null,
            }
            default:
            return state;
            
    }
}