
export const POST_CUSTOMER_REQUEST = "POST_CUSTOMER_REQUEST"
export const POST_CUSTOMER_SUCCESS = "POST_CUSTOMER_SUCCESS"
export const POST_CUSTOMER_FAIL = "POST_CUSTOMER_FAIL"

export const GET_CUSTOMER_REQUEST = "GET_CUSTOMER_REQUEST"
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS"
export const GET_CUSTOMER_FAIL = "GET_CUSTOMER_FAIL"

export const GET_CUSTOMER_DETAIL_REQUEST = "GET_CUSTOMER_DETAIL_REQUEST"
export const GET_CUSTOMER_DETAIL_SUCCESS = "GET_CUSTOMER_DETAIL_SUCCESS"
export const GET_CUSTOMER_DETAIL_FAIL = "GET_CUSTOMER_DETAIL_FAIL"

export const UPDATE_CUSTOMER_DETAIL_REQUEST = "UPDATE_CUSTOMER_DETAIL_REQUEST"
export const UPDATE_CUSTOMER_DETAIL_SUCCESS = "UPDATE_CUSTOMER_DETAIL_SUCCESS"
export const UPDATE_CUSTOMER_DETAIL_FAIL = "UPDATE_CUSTOMER_DETAIL_FAIL"


export const DELETE_CUSTOMER_DETAIL_REQUEST = "DELETE_CUSTOMER_DETAIL_REQUEST"
export const DELETE_CUSTOMER_DETAIL_SUCCESS = "DELETE_CUSTOMER_DETAIL_SUCCESS"
export const DELETE_CUSTOMER_DETAIL_FAIL = "DELETE_CUSTOMER_DETAIL_FAIL"


export const CLEAR_ERRORS = "CLEAR_ERRORS"