import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import "./expense.css"
import { Statee } from "./expenseStateContext";
import { GetExpenseTypeRecord, PostExpenseTypeRecord } from "../../../Actions/expenseTypeAction";
import { getBranchRecord } from "../../../Actions/branchAction";
import { useNavigate } from "react-router-dom";
const ExpenseForm = () => {
  const dispatch = useDispatch()  
  const {user} = useSelector((state)=> state.user)
  const {
    expense,
    setExpense,
    expenseType,
    setExpenseType,
    expenseDescription,
    setExpenseDescription,
    expenseAmount,
    setExpenseAmount,
    expenseTotal,
    setExpenseTotal,
    expenseUser, setExpenseUser,
    handleSubmitt, expenseDate, setExpenseDate
  } = useContext(Statee);
  const navigate = useNavigate()
  const [colorTheme, setColorTheme] = useState()



  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  useEffect(()=>{
    
    dispatch(GetExpenseTypeRecord())
    dispatch(getBranchRecord())
  }, [])

  useEffect(()=>{
    setExpenseType('')
    setExpenseAmount('')
    setExpenseDescription('')

console.log(user)
  }, [user])



    const addRecord = () =>{
            if (!expenseType || !expenseAmount) {
            
            } else {
              handleSubmitt();
              navigate("/expense");
            }
        }

    const back = ()=>{
      navigate("/expense")
    }

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`expenseMain ${colorTheme}`}>
      <div className={`expense`}>
        <div className="expenseSecond">
        <div className="heading">
            <h3>{"Expense"}</h3> 
            </div>
            <div className="expenseInputDiv">
                <div className="labeAndInputDiv">
                <label>Item Name <span>*</span></label>
                    <input
                        label={"productCode"}
                        className="inputField"
                        type="text"
                        placeholder={"Enter Expense Name"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={expenseType}
                        onChange={(e)=> setExpenseType(e.target.value)}
                        />
                  </div>
                  <div className="labeAndInputDiv">
                    <label>Item Cost <span>*</span></label>
                    <input
                        className="inputField"
                        type="Number"
                        placeholder={"Enter Expense Price"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={expenseAmount}
                        onChange={(e)=> setExpenseAmount(e.target.value)}
                    />
                    </div>
                    <div className="labeAndInputDiv">
                    <label>Expese User <span>*</span></label>
                    <input
                        className="inputField"
                        type="text"
                        placeholder={"Enter Expense User"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={expenseUser}
                        onChange={(e)=> setExpenseUser(e.target.value)}
                    />
                    </div> 
                     <div className="labeAndInputDiv">
                    <label>Item Description <span>*</span></label>
                    <input
                        className="inputField"
                        type="text"
                        placeholder={"Enter Expense Description"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={expenseDescription}
                        onChange={(e)=> setExpenseDescription(e.target.value)}
                    />    
                    </div> 
                      <div className="labeAndInputDiv">
                    <label>Date <span>*</span></label>
                    <input
                        className="inputField"
                        type="date"
                        placeholder={"Enter Expense Description"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={setExpenseDate}
                        onChange={(e)=> setExpenseDate(e.target.value)}
                    /> 
                    </div> 
                
                  
                    {/* <div className="labeAndInputDiv">
                     <label>User</label>
                    <div className="custom-dropdown">
                        <div className="selected-option" onClick={toggleDropdown}>
                            {selectedBranch || 'Select an option'}
                        </div>
                        {isBranchOpen && (
                            <div className="options">
                                {user?.user?.map((option, index) => (
                                    <div
                                        key={index}
                                        className="option"
                                        onClick={() => handleBranchOptionClick(option.name)}
                                    >
                                        {option?.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div> */}
                    {/* </div> */}
                </div>
                  
                <div className="buttons">
                <button onClick={back}>Back</button>
                <button onClick={addRecord}>Add Record</button>
                </div>
                </div> 
      </div> 
    </div>
    </>
  );
};

export default ExpenseForm;
