import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import {useNavigate}  from "react-router-dom"
import "./expenseType.css"
import { useAlert } from "react-alert";
import swal from "sweetalert2";
import MainTableComponent from "../../TableComponent/MainTableComponent";
import { GetExpenseTypeRecord, deleteExpenseTypeRecords } from "../../../Actions/expenseTypeAction";
import { DELETE_EXPENSETYPE_DETAIL_FAIL, DELETE_EXPENSETYPE_DETAIL_SUCCESS } from "../../../Constants/expenseTypeConstants";
const ExpenseTypeTable = () => {
  const alert = useAlert()
  const dispatch = useDispatch()  
  const [colorTheme, setColorTheme] = useState()
  const {expenseTypeRecord, expenseTypeRecordLoading}= useSelector((state)=> state.expenseTypeRecord)
  const {deleteExpenseTypeRecord, deleteExpenseTypeRecordLoading, deleteExpenseTypeRecordError} = useSelector((state)=> state.deleteExpenseTypeRecord)
  const linkk = "updateExpenseTypeRecord";
  const actionUpdate = "Update";
  const action3 = "Delete";

  const navigate = useNavigate()

  const AddExpenseType = async () => {
      navigate('/addExpenseType')
  }

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  useEffect(()=>{
    dispatch(GetExpenseTypeRecord())
  }, [])

  const deleteProduct = async (id) => {
    swal
    .fire({
      icon: "warning",
      title: "Warning",
      text: "Are You Sure You Want to Delete",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        popup: "custom-swal-popup", // This is the custom class you're adding
      },
    })
    .then(async (result) => {
      if (result.value) {
        console.log('fei')
        dispatch(deleteExpenseTypeRecords(id))
      }})

  };

  useEffect(()=>{
    if(deleteExpenseTypeRecordError?.data && !deleteExpenseTypeRecordLoading)
    {
      deleteErrorMessage(deleteExpenseTypeRecordError?.data?.message)
    }
  }, [deleteExpenseTypeRecordError, deleteExpenseTypeRecordLoading])

  useEffect(()=>{
    if(deleteExpenseTypeRecord?.success && !deleteExpenseTypeRecordLoading)
    {
      deleteSuccessMessage()
    }
  }, [deleteExpenseTypeRecord, deleteExpenseTypeRecordLoading])

  const deleteSuccessMessage = () => {
    dispatch(GetExpenseTypeRecord())
    dispatch({
      type: DELETE_EXPENSETYPE_DETAIL_SUCCESS,
      payload: [],
    });
    return alert.success("Record Deleted Successfully")
  }

  const deleteErrorMessage = (message) =>{
    dispatch({
      type: DELETE_EXPENSETYPE_DETAIL_FAIL,
      payload: [],
    });
    return alert.error(message)
  }



    const columns = [
        { field: "expenseType", label: "Expense Type" },
        { field: "expenseTypeDescription", label: "Expense Type Description" },
      ];
    
      const actions = [
        {
          label: "Delete",
          color: "yellow",
          handler: (itemId) => deleteProduct(itemId),
        },
        {
          label: "Update",
          color: "green",
          url: (itemId) => `/updateExpenseTypeRecord/${itemId}`,
        },
      ];
    

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`expenseTypeMain ${colorTheme}`}>
      <div className={`expenseTypeTable`}>
      <div className="heading">
            <h3>{"Expense Type"}</h3> 
            <button onClick={AddExpenseType}>
              Add Expense Type
            </button>
            </div>
    <div className="expenseTypeSearch-withInputfields-box" >
    <div className="expenseTypeTableFirstInputDiv">
              <input
                label={"Enter Customer Name"}
                className="expenseTypTableInputField"
                type="text"
                placeholder={"Enter Customer Name"}
                name="customerName"
                autoComplete="off"
                maxLength="40"
                required
            
              />
              <input
              label={"Contact No"}
              type="Number"
              className="expenseTypTableInputField"
              placeholder={"Enter Contact No"}
              name="contactNo"
              autoComplete="off"
              maxLength="40"
              required
              />
              <input
              label={"Address"}
              className="expenseTypTableInputField"
              type="text"
              placeholder={"Enter Address"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              />
            </div>
    </div>

            <div className="expenseTypeTable-container">
            {!expenseTypeRecordLoading ? (
                <MainTableComponent
                    data={expenseTypeRecord?.expenseTypeData}
                    columns={columns}
                    actions={actions}
                    actionUpdate={actionUpdate}
                    linkk= {linkk}
                    action3={action3}
                />
             ) : (<></>
            //     // <Loader active>Loading</Loader>
             )} 
            </div>
        </div>
    </div>
    </>
  );
};

export default ExpenseTypeTable;
