export const GET_ROLE_REQUEST = "GET_ROLE_REQUEST"
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS"
export const GET_ROLE_FAIL = "GET_ROLE_FAIL"


export const GET_ROLE_DETAIL_REQUEST = "GET_ROLE_DETAIL_REQUEST"
export const GET_ROLE_DETAIL_SUCCESS = "GET_ROLE_DETAIL_SUCCESS"
export const GET_ROLE_DETAIL_FAIL = "GET_ROLE_DETAIL_FAIL"

export const UPDATE_ROLE_DETAIL_REQUEST = "UPDATE_ROLE_DETAIL_REQUEST"
export const UPDATE_ROLE_DETAIL_SUCCESS = "UPDATE_ROLE_DETAIL_SUCCESS"
export const UPDATE_ROLE_DETAIL_FAIL = "UPDATE_ROLE_DETAIL_FAIL"


export const DELETE_ROLE_DETAIL_REQUEST = "DELETE_ROLE_DETAIL_REQUEST"
export const DELETE_ROLE_DETAIL_SUCCESS = "DELETE_ROLE_DETAIL_SUCCESS"
export const DELETE_ROLE_DETAIL_FAIL = "DELETE_ROLE_DETAIL_FAIL"

export const POST_ROLE_REQUEST = "POST_ROLE_REQUEST"
export const POST_ROLE_SUCCESS = "POST_ROLE_SUCCESS"
export const POST_ROLE_FAIL = "POST_ROLE_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"