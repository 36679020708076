import {
    UilEstate,
    UilClipboardAlt,
    UilUsersAlt,
    UilPackage,
    UilChart,
    UilSignOutAlt,
  } from "@iconscout/react-unicons";
  
  
  // Sidebar Data
  export const SidebarData = [
    {
      icon: UilEstate,
      heading: "Language",
    },
    {
      icon: UilClipboardAlt,
      heading: "Themes",
    },
    {
      icon: UilUsersAlt,
      heading: "Permissions",
    },
    {
      icon: UilPackage,
      heading: 'Change Table Settings'
    },
    {
      icon: UilChart,
      heading: 'Change Password'
    },
    {
      icon: UilChart,
      heading: 'Printers'
    },
  ];