import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import { getPermissionForRoles } from "../../Pages/user/rolesAssigned/RolesPermissionValidation";
// import { useTranslation, initReactI18next } from "react-i18next";
// import { tableState } from "./tableContext";
import "./Table.css"
import { useSelector } from "react-redux";
import Tooltip from '@material-ui/core/Tooltip';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteIcon from "@mui/icons-material/Delete";
// import "./Table.css";
const MainTableComponent = ({
  data,
  columns,
  actions,
  linkk,
  actionUpdate,
  action3,
  actionPreview,
  actionBookingPreview,
  bookingLinkk
}) => {
  let lengthOfData = columns?.length;
  if (actions) {
    lengthOfData = lengthOfData;
  }
  const { user, loading, isAuthenticated } = useSelector((state) => state.user);
//   const { t, i18n } = useTranslation();
//   const { rowCount, setRowCount } = useContext(tableState);

  // State variables for sorting
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [rowsPerPage, setRowsPerPage] = React.useState(
    25
    // user?.user?.tableRows?.noOfRows
  );
  const navigate = useNavigate();

  //pagination
  const [page, setPage] = React.useState(0);

  const [permissionForUpdateProduct, setPermissionForUpdateProduct] =
    useState(false);
  const [permissionForDelete, setPermissionForDelete] = useState(false);

  // useEffect(() => {
  //   setRowsPerPage(user?.user?.tableRows?.noOfRows);
  // }, [user, loading, isAuthenticated]);

  const handleChangePage = (event, newPage) => {
    console.log(event)
    console.log(newPage);
    setPage(newPage);

  };

  const handleChangeRowsPerPage = (event) => {
    console.log('hfei')
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Function to handle sorting
  const handleSort = (field) => {
    if (field === sortedColumn) {
      // If the clicked column is already sorted, toggle the direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a different column is clicked, set it as the sorted column with ascending direction
      setSortedColumn(field);
      setSortDirection("asc");
    }
  };
  let currentIndex = 0;
  useEffect(() => {
    setPage(0)
    // setRowCount(data?.length);
  }, [data]);

  useEffect(() => {
    setPermissionForUpdateProduct(false);
    setPermissionForDelete(false);
    // getPermission();
  }, []);


  const renderCellValue = (item, field) => {
    const fieldKeys = field.split(".");

    return fieldKeys?.reduce((obj, key) => (obj ? obj[key] : ""), item);
  };
  console.log(data);
  // Sorting logic
  const sortedData = data?.slice().sort((a, b) => {
    if (!sortedColumn) return 0;

    const sortOrder = sortDirection === "asc" ? 1 : -1;
    const aField = renderCellValue(a, sortedColumn);
    const bField = renderCellValue(b, sortedColumn);

    if (aField < bField) return -1 * sortOrder;
    if (aField > bField) return 1 * sortOrder;
    return 0;
  });

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  

  const getColumnValue = (user, field, format, render) => {
    if (format === "date" || format === "time" || format === "bool") {
      return renderDateValue(user, field, format);
    } else 
    if (field.includes(".")) 
      {
      // Handle nested fields
      const nestedFields = field.split(".");
      let value = user;
      for (const nestedField of nestedFields) {
        if (value && value.hasOwnProperty(nestedField)) {
          value = value[nestedField];
        } else {
          value = ""; // Handle the case where a nested field is missing
          break;
        }
      }
      return (typeof value === 'number') ? formatNumber(value) : value;
      // return value;
    } else {
      // return user[field];
      return (typeof user[field] === 'number') ? formatNumber(user[field]) : user[field];
    }
  };

  // Function to render date values using moment.js
  const renderDateValue = (item, field, format) => {
    const value = renderCellValue(item, field);
    // console.log(value);

    //for converting bool value into string ..just for dispalying into table
    if (format === "bool") {
      return value && value.toLocaleString();
    }

    if (format === "time") {
      // console.log("time");
      return value ? new Date(value).toLocaleTimeString() : "";
    }
    if (format === "date") {
      // console.log("date");
      return value ? new Date(value).toLocaleDateString("en-GB") : "";
    }

    return value;
  };

  // const classes = useStyles();

  return (
    <>
      {/* <div className="tableComponent"> */}
      <TableContainer
        component={Paper}
        className="heightSetting"
        sx={{  zIndex: "2" }}
      >
        {sortedData && sortedData?.length > 0 ? (
          <Table
            sx={{ minWidth: 650, zIndex: "1" }}
            size="small"
            ariel-label="a dense table"
            // className={classes.cell}
            className={`customTable`}
            stickyHeader
          >
            <TableHead>
              <TableRow className="tableHead" align={"center"}>
                <TableCell
                  className="tableHead"
                  align={"center"}
                  // style={{ backgroundColor: "#ECECEC", zIndex: "1" }}
                >
                  {"SrNo"}
                </TableCell>
                {columns?.map((column) => (
                  <TableCell
                    className="tableHead"
                    align={"center"}
                    // style={{ backgroundColor: "#ECECEC", zIndex: "1" }}
                    onClick={() => handleSort(column.field)}
                  >
                    {column.label}
                    {sortedColumn === column.field && (
                      <span>{sortDirection === "asc" ? " ▲" : " ▼"}</span>
                    )}
                  </TableCell>
                ))}
                {actions && (
                  <TableCell
                    className="tableHead"
                    align={"center"}
                    // style={{ backgroundColor: "#ECECEC" }}
                  >
                    {"Actions"}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData
            //   ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow
                    key={index}
                    className={` ${index % 2 === 0 ? "evenRow" : "oddRow"} `}
                  >
                    <TableCell
                      align={"center"}
                      className={index % 2 === 0 ? "evenRow" : "oddRow"}
                    >
                      {++currentIndex}
                    </TableCell>
                    {columns?.map((column) => (
                      <TableCell
                        align="center"
                        className="border-Right"
                        key={column.field}
                      >
                        {column.render
                          ? column.render(item)
                          : getColumnValue(
                              item,
                              column.field,
                              column.format,
                              column.render
                            )}
                        {/* {columnn.render ? columnn.render(item) : item[columnn.field]} */}
                      </TableCell>
                    ))}
                    {actions && (
                      <TableCell
                        align={"center"}
                        className={` column-borde`}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {actions?.map((action) => {
                          if (action.label === action3 
                            // && permissionForDelete
                            ) {
                            return (
                              <Tooltip title="Delete Record" arrow>
                              <Button
                                key={action.label}
                                style={{
                                  backgroundColor: "#transparent",
                                  marginRight: "5px",
                                }}
                                onClick={() => actions[0].handler(item._id)}
                              >
                                <DeleteIcon />
                              </Button>
                              </Tooltip>
                            );
                          }
                          if (action.label === "existingCustomer"
                            // && permissionForDelete
                            ) {
                            return (
                              <Tooltip title="Customer Booking!" arrow>
                              <Button
                                key={action.label}
                                style={{
                                  backgroundColor: "#transparent",
                                  marginRight: "5px",
                                }}
                                onClick={() => actions[0].handler(item._id, item?.name)}
                              >
                                <UpdateIcon />
                              </Button>
                              </Tooltip>
                            );
                          }
                          
                          if (action.label === actionUpdate) 
                          {
                            return (
                              <Tooltip title={`${actionUpdate}`} arrow>
                              <Button
                                key={action.label}
                                style={{ backgroundColor: "#transparent" }}
                                onClick={() => {
                                  navigate(`/${linkk}/${item._id}`);
                                }}
                              >
                                <UpdateIcon />
                              </Button></Tooltip>
                            );
                          }
                          if (action.label === actionPreview) 
                          {
                            return (
                              <Tooltip title="Preview" arrow>
                              <Button
                                key={action.label}
                                style={{ backgroundColor: "#transparent" }}
                                onClick={() => {
                                  navigate(`/${linkk}/${item._id}`);
                                }}
                              >
                                <UpdateIcon />
                              </Button></Tooltip>
                            );
                          }
                          if (action.label === actionBookingPreview) 
                          {
                            return (
                              <Tooltip title="Preview" arrow>
                              <Button
                                key={action.label}
                                style={{ backgroundColor: "#transparent" }}
                                onClick={() => {
                                  navigate(`/${bookingLinkk}/${item._id}`);
                                }}
                              >
                                <UpdateIcon />
                              </Button></Tooltip>
                            );
                          }
                          if (action.label === "expenseListDelete") {
                            return (
                              <Button
                                key={action.label}
                                onClick={() => {
                                  // setShowModal(true);
                                  actions[0].handler(index);
                                }}
                              >
                                {/* <AiOutlineDelete className="text-red-500 font-bold text-xl" /> */}delete
                              </Button>
                            );
                          }
                          })}

                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <div>No record found.</div>
        )}
      </TableContainer>
      <TablePagination
        component="div"
        // rowsPerPageOptions={[10, 25, 100]}
        count={sortedData?.length}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        className="custom-table-pagination"
      />
      {/* </div> */}
    </>
  );
};

export default MainTableComponent;
