import { useState } from "react";
import React, { useContext, useEffect } from "react";
import companyLogo from "../../images/companyLogo.jpg"
import { useSelector } from "react-redux";
import "./testBooking.css"
export default function Header({ selectedPrinter }) {
    const {bookingDetailRecord, bookingDetailRecordLoading} = useSelector((state)=> state.bookingDetailRecord)

    const formattedDate = new Date(bookingDetailRecord?.booking?.createdAt).toLocaleDateString(
        "en-GB"
      )
      const formattedtime = new Date(bookingDetailRecord?.booking?.createdAt).toLocaleTimeString(
 
      )

    return (
    <>
        <div className="headerHeadings">
        <img src={companyLogo} alt="Logo" className="booking-logo"/>
        <div className="headings">
            <div>
            <p>Booking Date: </p> {formattedDate} {formattedtime}
            </div>
            <div>
            <p>Student ID: </p> {bookingDetailRecord?.booking?.cust_id?.invoiceNumber}
            </div>
            <h4>{bookingDetailRecord?.booking?.branch_id?.branchCode}</h4>
            <h4>{bookingDetailRecord?.booking?.branch_id?.branchAddress}</h4>
        </div>
        </div>
    </>
  );
}
