import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { PostTestRecord, deleteTestRecord, getTestRecord } from "../../../Actions/testAction";
import { getBranchRecord } from "../../../Actions/branchAction";
import {useNavigate}  from "react-router-dom"
import { useAlert } from "react-alert";
import swal from "sweetalert2";
import "./test.css"
import MainTableComponent from "../../TableComponent/MainTableComponent";
import { DELETE_SPECIFIC_TEST_FAIL, DELETE_SPECIFIC_TEST_SUCCESS } from "../../../Constants/testConstants";
const TestRecordTable = () => {
  const dispatch = useDispatch()  
  const [colorTheme, setColorTheme] = useState()
  const alert = useAlert()
  const {testRecord, testRecordloading}= useSelector((state)=> state.testRecord)
  const {deleteTestLoading, deleteTest, error} = useSelector((state)=> state.deleteTest)
  const {updateTestRecordloading, updateTestRecord} = useSelector((state)=> state.updateTestRecord)
  const linkk = "updateTestRecord";
  const actionUpdate = "Update";
  const action3 = "Delete";

  const navigate = useNavigate()

  const ShowTableRecord = async () => {
      navigate('/addTestRecord')
  }

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  useEffect(()=>{
    dispatch(getTestRecord())
  }, [])

  const deleteProduct = async (id) => {
    swal
    .fire({
      icon: "warning",
      title: "Warning",
      text: "Are You Sure You Want to Delete",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        popup: "custom-swal-popup", // This is the custom class you're adding
      },
    })
    .then(async (result) => {
      if (result.value) {
        console.log('fei')
        dispatch(deleteTestRecord(id))
      }})
   
  
  };


  useEffect(()=>{
    if(error?.data && !deleteTestLoading)
    {
      deleteErrorMessage(error?.data?.message)
    }
  }, [error, deleteTestLoading])

  useEffect(()=>{
    if(deleteTest?.success && !deleteTestLoading)
    {
      deleteSuccessMessage()
    }
  }, [deleteTest, deleteTestLoading])

  const deleteSuccessMessage = () => {
    dispatch(getTestRecord())
    dispatch({
      type: DELETE_SPECIFIC_TEST_SUCCESS,
      payload: [],
    });
    return alert.success("Record Deleted Successfully")
  }


  const deleteErrorMessage = (message) =>{
    dispatch({
      type: DELETE_SPECIFIC_TEST_FAIL,
      payload: [],
    });
    return alert.error(message)
  }


    const columns = [
        { field: "testName", label: "Test Name" },
        { field: "testPrice", label: "Test Price" },
        { field: "testDescription", label: "Test Description" },
      ];
    
      const actions = [
        {
          label: "Delete",
          color: "yellow",
          handler: (itemId) => deleteProduct(itemId),
        },
        {
          label: "Update",
          color: "green",
          url: (itemId) => `/updateTestRecord/${itemId}`,
        },
      ];
    

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`testMain ${colorTheme}`}>
      <div className={`testTable`}>
      <div className="heading">
            <h3 >{"Add Test"}</h3> 
            <button onClick={ShowTableRecord}>
              Add Test
            </button>
            </div>
    <div className="testSearch-withInputfields-box " >
    <div className="testTableFirstInputDiv">
              <input
                label={"Enter Customer Name"}
                className="testTableInputField"
                type="text"
                placeholder={"Enter Customer Name"}
                name="customerName"
                autoComplete="off"
                maxLength="40"
                required
            
              />
              <input
              label={"Contact No"}
              type="Number"
              className="testTableInputField"
              placeholder={"Enter Contact No"}
              name="contactNo"
              autoComplete="off"
              maxLength="40"
              required
              />
              <input
              label={"Address"}
              className="testTableInputField"
              type="text"
              placeholder={"Enter Address"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              />
            </div>
    </div>





            <div className="table-container">
            {!testRecordloading ? (
                <MainTableComponent
                    data={testRecord?.test}
                    columns={columns}
                    actions={actions}
                    actionUpdate={actionUpdate}
                    linkk= {linkk}
                    action3={action3}
                />
             ) : (<></>
            //     // <Loader active>Loading</Loader>
             )} 
            </div>
        </div>
    </div>
    </>
  );
};

export default TestRecordTable;
