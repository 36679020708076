import { DELETE_BRANCH_DETAIL_FAIL, DELETE_BRANCH_DETAIL_REQUEST, DELETE_BRANCH_DETAIL_SUCCESS, GET_BRANCH_DETAIL_FAIL, GET_BRANCH_DETAIL_REQUEST, GET_BRANCH_DETAIL_SUCCESS, GET_BRANCH_FAIL, GET_BRANCH_REQUEST, GET_BRANCH_SUCCESS, POST_BRANCH_FAIL, POST_BRANCH_REQUEST, POST_BRANCH_SUCCESS, UPDATE_BRANCH_DETAIL_FAIL, UPDATE_BRANCH_DETAIL_REQUEST, UPDATE_BRANCH_DETAIL_SUCCESS } from "../Constants/branchConstants";
import axiosInstance from "./baseURL";

export const getBranchRecord = () => async (dispatch) => {
    try {
      dispatch({ type: GET_BRANCH_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getBranches`);
      dispatch({
        type: GET_BRANCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BRANCH_FAIL,
        payload: error.response,
      });
    }
  };

  export const PostBranchRecord = (branchCode, branchPhoneNo, branchAddress, branchDescription) => async (dispatch) => {
    try {
      dispatch({ type: POST_BRANCH_REQUEST });
      const config = {headers: {"Content-Type": "application/json"}}
      const { data } = await axiosInstance.post(`/api/v1/createBranch`, {branchCode, branchAddress, branchPhoneNo, branchDescription}, config);
      dispatch({
        type: POST_BRANCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_BRANCH_FAIL,
        payload: error.response,
      });
    }
  };

  export const getSpecificBranchRecord = (id) => async (dispatch) => {
    try {
      dispatch({ type: GET_BRANCH_DETAIL_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getBranchDetails/${id}`);
      dispatch({
        type: GET_BRANCH_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BRANCH_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };

  export const updateBranchRecord = (id, branchCode, branchAddress, branchPhoneNo, branchDescription) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_BRANCH_DETAIL_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = axiosInstance.put(`/api/v1/editBranch/${id}`, {
        branchCode, branchAddress, branchPhoneNo, branchDescription
      },
      config);
      dispatch({
        type: UPDATE_BRANCH_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_BRANCH_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };

  export const deleteBranchRecord = (id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_BRANCH_DETAIL_REQUEST });
      const { data } = await axiosInstance.delete(`/api/v1/deleteBranch/${id}`);
      dispatch({
        type: DELETE_BRANCH_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_BRANCH_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };