export const GET_ASSINGEDTASK_REQUEST = "GET_ASSINGEDTASK_REQUEST"
export const GET_ASSINGEDTASK_SUCCESS = "GET_ASSINGEDTASK_SUCCESS"
export const GET_ASSINGEDTASK_FAIL = "GET_ASSINGEDTASK_FAIL"

export const GET_ASSINGEDTASK_ROLEANDTASKID_REQUEST = "GET_ASSINGEDTASK_ROLEANDTASKID_REQUEST"
export const GET_ASSINGEDTASK_ROLEANDTASKID_SUCCESS = "GET_ASSINGEDTASK_ROLEANDTASKID_SUCCESS"
export const GET_ASSINGEDTASK_ROLEANDTASKID_FAIL = "GET_ASSINGEDTASK_ROLEANDTASKID_FAIL"


export const GET_ASSINGEDTASK_ROLEID_REQUEST = "GET_ASSINGEDTASK_ROLEID_REQUEST"
export const GET_ASSINGEDTASK_ROLEID_SUCCESS = "GET_ASSINGEDTASK_ROLEID_SUCCESS"
export const GET_ASSINGEDTASK_ROLEID_FAIL = "GET_ASSINGEDTASK_ROLEID_FAIL"

export const GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_REQUEST = "GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_REQUEST"
export const GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_SUCCESS = "GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_SUCCESS"
export const GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_FAIL = "GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_FAIL"



export const POST_ASSIGNEDROLEBYID_REQUEST = "POST_ASSIGNEDROLEBYID_REQUEST"
export const POST_ASSIGNEDROLEBYID_SUCCESS = "POST_ASSIGNEDROLEBYID_SUCCESS"
export const POST_ASSIGNEDROLEBYID_FAIL = "POST_ASSIGNEDROLEBYID_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"