import { useContext, useEffect } from "react";
import { UseDispatch, useSelector } from "react-redux";
import "./customerStudent.css"
export default function Dates({ selectedPrinter }) {
    // const {visaCustomerPaymentDetailRecord, visaCustomerPaymentDetailRecordLoading} = useSelector((state)=> state.visaCustomerPaymentDetailRecord)
    const {testCustomerPaymentDetailRecord, testCustomerPaymentDetailRecordLoading} = useSelector((state)=> state.testCustomerPaymentDetailRecord)
    useEffect(()=>{
    console.log(testCustomerPaymentDetailRecord)
  }, [])
    return (
        <>
              <div className="InvoiceSecondCont">
                <div className="InvoiceThirdCont">
                  <div className="consolidateCustomerLaserdivs">
                    <h5>Customer Name:</h5>
                    <p>{testCustomerPaymentDetailRecord?.testCustomerPaymentData?.cust_id?.name}</p>
                  </div>
                  <div className="consolidateCustomerLaserdivs">
                    <h5>Cell No:</h5>
                    <p>{testCustomerPaymentDetailRecord?.testCustomerPaymentData?.cust_id?.contactNo}</p>
                  </div>
                  <div className="consolidateCustomerLaserdivs">
                    <h5>Status:</h5>
                    <p>{testCustomerPaymentDetailRecord?.testCustomerPaymentData?.Status}</p>
                  </div>
                  <div className="consolidateCustomerLaserdivs">
                    <h5>Discount:</h5>
                    <p>{testCustomerPaymentDetailRecord?.testCustomerPaymentData?.discount}</p>
                  </div>
                </div>
                <div className="InvoiceThirdCont">
                  <div className="consolidateCustomerSecondLaserdivs">
                    <h5>Invoicer number:</h5>
                    <p>{testCustomerPaymentDetailRecord?.testCustomerPaymentData?.cust_id?.invoiceNumber}</p>
                  </div>
                  <div className="consolidateCustomerSecondLaserdivs">
                    <h5>Invoice date:</h5>
                    <p>
                      {new Date(testCustomerPaymentDetailRecord?.testCustomerPaymentData?.createdAt).toLocaleDateString(
                        "en-GB"
                      )}
                    </p>
                  </div>
                    <div className="consolidateCustomerLaserdivs">
                    <h5>Payment Status:</h5>
                    <p>{testCustomerPaymentDetailRecord?.testCustomerPaymentData?.paymentStatus}</p>
                  </div>
                  <div className="consolidateCustomerLaserdivs">
                    <h5>Total Payment:</h5>
                    <p>{testCustomerPaymentDetailRecord?.testCustomerPaymentData?.totalPayment}</p>
                  </div>
                </div>
              </div>
    </>
  );
}
