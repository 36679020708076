import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import "./expense.css"
import { Statee } from "./expenseStateContext";
import { useNavigate } from "react-router-dom";
import { GetExpenseTypeRecord, PostExpenseTypeRecord } from "../../../Actions/expenseTypeAction";
import { getBranchRecord } from "../../../Actions/branchAction";
import Table from "./expenseTable";
import { postExpenseRecord } from "../../../Actions/expenseAction";
import { useAlert } from "react-alert"
import PageLoader from "../../Loader/PageLoader";
import { POST_EXPENSE_SUCCESS } from "../../../Constants/expenseConstants";
const App = () => {
  const dispatch = useDispatch()  
  const navigate = useNavigate()
  const expenseDropdownRef = useRef(null);
  const branchDropdownRef = useRef(null)
  const alert = useAlert()
  const [expenseTypeDescription, setExpenseTypeDescription] = useState()
  const {expenseTypeRecord} = useSelector((state)=> state.expenseTypeRecord)
  const {branchRecord} = useSelector((state)=> state.branchRecord)
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [isBranchOpen, setIsBranchOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState();
  const [colorTheme, setColorTheme] = useState()
  const {user} = useSelector((state) => state.user)
  const {expense,
    setExpenseType,
    expenseLocation, setExpenseLocation, 
    expenseEnteredBy, setExpenseEnteredBy, expenseCategory, setExpenseCategory, expenseTotal, setExpense
  } = useContext(Statee);
  const {postExpense, postExpenseLoading, postExpenseError} = useSelector((state)=> state.postExpense)
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])


  const toggleBranchDropdown = () => {
    setIsBranchOpen(!isBranchOpen);
  };


  const handleOptionClick = (name) => {
    console.log(name)
    setExpenseCategory(name)
    setSelectedOption(name);
    // onSelect(option);
    setIsOpen(false);
  };

  const handleBranchOptionClick = (name) => {
    console.log( name)
    setExpenseLocation(name)
    setSelectedBranch(name);
    // onSelect(option);
    setIsBranchOpen(false);
  };

  useEffect(()=>{
    setExpenseEnteredBy(user?.user?.name)
    dispatch(GetExpenseTypeRecord())
    dispatch(getBranchRecord())
  }, [user])



    const addRecord = () =>{
        // dispatch(PostExpenseTypeRecord(expenseType, expenseTypeDescription))
        navigate('/expenseForm')
    }

    const submitRecord = () => {
      dispatch(postExpenseRecord(expenseCategory, expenseLocation, expenseEnteredBy,  expense, expenseTotal))
    }


    useEffect(()=>{
      if(postExpense?.success && !postExpenseLoading)
      {
        postExpenseSuccessMessage()
      }
    }, [postExpense, postExpenseLoading])


    useEffect(()=>{
      if(postExpenseError && !postExpenseLoading)
      {
        postExpenseErrorMessage(postExpenseError?.data?.message)
      }
    }, [postExpenseError, postExpenseLoading])

    const postExpenseSuccessMessage = () => {
      setExpenseCategory('')
      setExpenseLocation('')
      setExpense([])
      dispatch({
        type: POST_EXPENSE_SUCCESS,
        payload: [],
      });
      return alert.success("Record Added Successfully")
    }

    const postExpenseErrorMessage = (message) => {
      return alert.error(message)
    }

    const clearRecord = ()=>{
        setExpenseType('')
        setExpenseTypeDescription('')
    }

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (expenseDropdownRef.current && !expenseDropdownRef.current.contains(event.target)) {
          console.log('fie')
          setIsOpen(false);
        }
        console.log('clikced')
      };
      window.addEventListener('click', handleClickOutside);
      return () => {
        window.removeEventListener('click', handleClickOutside);
      };
    }, []); 

    useEffect(()=>{
      const handleClickBranchOutside = (event) => {
           if (branchDropdownRef.current && !branchDropdownRef.current.contains(event.target)) {
             setIsBranchOpen(false);
           }
           console.log('clikced')
         };
         window.addEventListener('click', handleClickBranchOutside);
         return () => {
           window.removeEventListener('click', handleClickBranchOutside);
         };
       }, [])
  
  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`expenseMain ${colorTheme}`}>
      <div className={`expense`}>
        <div className="expenseSecond">
        <div className="heading">
            <h3>{"Expense"}</h3> 
            </div>
          <div className="expenseFirsSection">
                <div className="expenseInputDiv">
                <div className="labeAndInputDiv">
                <label className="labelDiv">Select Category <span>*</span></label>
                    <div ref={expenseDropdownRef} className="custom-dropdown">
                        <div className="selected-option" onClick={toggleDropdown}>
                            {expenseCategory || 'Select an option'}
                        </div>
                        {isOpen && (
                            <div className="options">
                                {expenseTypeRecord?.expenseTypeData?.map((option, index) => (
                                    <div
                                        key={index}
                                        className="option"
                                        onClick={() => handleOptionClick(option.expenseType)}
                                    >
                                        {option?.expenseType}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    </div>
                    <div className="labeAndInputDiv">
                    <label className="labelDiv">Branch Name <span>*</span></label>
                    <div ref={branchDropdownRef} className="custom-dropdown">
                        <div className="selected-option" onClick={toggleBranchDropdown}>
                            {expenseLocation || 'Select an option'}
                        </div>
                        {isBranchOpen && (
                            <div className="options">
                                {branchRecord?.branch?.map((option, index) => (
                                    <div
                                        key={index}
                                        className="option"
                                        onClick={() => handleBranchOptionClick(option.branchCode)}
                                    >
                                        {option?.branchCode}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    </div>
                </div>
                </div>
              {/* <div className="expensetableSection"> */}
              <div className="ExpenseButtons">
              {
                expenseCategory && expenseLocation && (<>
                 <button onClick={addRecord}>
                  Add Records
                </button>
                {
                  expense?.length > 0 && (<>
                    <button onClick={submitRecord}>Generate Record</button>
                  </>)
                }
                </>)
               
              }
                
                </div>
              {
                !postExpenseLoading  ? (
                  <>
                  <Table />
                  </>
                ) : (<>
                <PageLoader />
                </>)
              }
            {/* </div> */}
      </div> 
    </div>
    </div>
    </>
  );
};

export default App;
