import { CLEAR_ERRORS, DELETE_BRANCH_DETAIL_FAIL, DELETE_BRANCH_DETAIL_REQUEST, DELETE_BRANCH_DETAIL_SUCCESS, GET_BRANCH_DETAIL_FAIL, GET_BRANCH_DETAIL_REQUEST, GET_BRANCH_DETAIL_SUCCESS, GET_BRANCH_FAIL, GET_BRANCH_REQUEST, GET_BRANCH_SUCCESS, POST_BRANCH_FAIL, POST_BRANCH_REQUEST, POST_BRANCH_SUCCESS, UPDATE_BRANCH_DETAIL_FAIL, UPDATE_BRANCH_DETAIL_REQUEST, UPDATE_BRANCH_DETAIL_SUCCESS } from "../Constants/branchConstants";


export const getBranchReducer = (state = { branchRecord: []}, action)=>{
    switch (action.type){
        case GET_BRANCH_REQUEST:
            return{
                branchLoading: true,
                branchRecord: [],
            };
        case GET_BRANCH_SUCCESS: 
            return {
                branchLoading: false,
                branchRecord: action.payload
            }
        case GET_BRANCH_FAIL:
            return {
                branchLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const getBranchDetailReducer = (state = { branchDetailRecord: []}, action)=>{
    switch (action.type){
        case GET_BRANCH_DETAIL_REQUEST:
            return{
                branchDetailRecordLoading: true,
                branchDetailRecord: [],
            };
        case GET_BRANCH_DETAIL_SUCCESS: 
            return {
                branchDetailRecordLoading: false,
                branchDetailRecord: action.payload
            }
        case GET_BRANCH_DETAIL_FAIL:
            return {
                branchDetailRecordLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const updateBranchDetailReducer = (state = { updateBranchRecord: []}, action)=>{
    switch (action.type){
        case UPDATE_BRANCH_DETAIL_REQUEST:
            return{
                updateBranchRecordLoading: true,
                updateBranchRecord: [],
            };
        case UPDATE_BRANCH_DETAIL_SUCCESS: 
            return {
                updateBranchRecordLoading: false,
                updateBranchRecord: action.payload
            }
        case UPDATE_BRANCH_DETAIL_FAIL:
            return {
                updateBranchRecordLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const deleteBranchDetailReducer = (state = { deleteBranchRecord: []}, action)=>{
    switch (action.type){
        case DELETE_BRANCH_DETAIL_REQUEST:
            return{
                deleteBranchRecordLoading: true,
                deleteBranchRecord: [],
            };
        case DELETE_BRANCH_DETAIL_SUCCESS: 
            return {
                deleteBranchRecordLoading: false,
                deleteBranchRecord: action.payload
            }
        case DELETE_BRANCH_DETAIL_FAIL:
            return {
                deleteBranchRecordLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}


export const postBranchReducer = (state = { postBranch: []}, action)=>{
    switch (action.type){
        case POST_BRANCH_REQUEST:
            return{
                postBranchLoading: true,
                postBranch: [],
            };
        case POST_BRANCH_SUCCESS: 
            return {
                postBranchLoading: false,
                postBranch: action.payload
            }
        case POST_BRANCH_FAIL:
            return {
                postBranchLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}