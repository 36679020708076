import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { PostTestRecord, deleteTestRecord, getTestRecord } from "../../../Actions/testAction";
import { deleteBranchRecord, getBranchRecord } from "../../../Actions/branchAction";
import {useNavigate}  from "react-router-dom"
import "./roles.css"
import MainTableComponent from "../../TableComponent/MainTableComponent";
import { GetExpenseTypeRecord, deleteExpenseTypeRecord } from "../../../Actions/expenseTypeAction";
import { GetRoleRecord, deleteRoleRecord } from "../../../Actions/roleAction";
import { getAssignedTaskOnRoleIdAndTaskName } from "../../../Actions/assignedTaskAction";
const RolesTable = () => {
  const dispatch = useDispatch()  
  const [colorTheme, setColorTheme] = useState()
  const {roleRecord, roleRecordLoading}= useSelector((state)=> state.roleRecord)
  const {user} = useSelector((state)=> state.user)
  const {assignedTasksOnRoleIdAndTaskName, assignedTasksOnRoleIdAndTaskNameLoading} = useSelector((state)=> state.assignedTasksOnRoleIdAndTaskName)
  const linkk = "changePermissions";
  const actionUpdate = "Change Permission";
  const action3 = "Delete";

  const navigate = useNavigate()
 
  const ShowTableRecord = async () => {
      navigate('')
  }

  useEffect(()=>{
    console.log(user)
    dispatch(getAssignedTaskOnRoleIdAndTaskName(user?.user?.role?._id, "changePermissions"))
  }, [user])

  useEffect(()=>{
    if(assignedTasksOnRoleIdAndTaskName && assignedTasksOnRoleIdAndTaskNameLoading)
    {
      console.log(assignedTasksOnRoleIdAndTaskName)
    }
  }, [assignedTasksOnRoleIdAndTaskName, assignedTasksOnRoleIdAndTaskNameLoading])

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  useEffect(()=>{
   
    dispatch((GetRoleRecord()))
  }, [user])

  const deleteRole = async (id) => {
    dispatch(deleteRoleRecord(id))
  };


    const columns = [
        { field: "roleName", label: "Role Name" },
        { field: "roleDescription", label: "Role Description" },
      ];
    
      const actions = [
        {
          label: "Delete",
          color: "yellow",
          handler: (itemId) => deleteRole(itemId),
        },
        {
          label: "Change Permission",
          color: "green",
          url: (itemId) => `/changePermissions/${itemId}`,
        },
      ];
    

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`rolesMain ${colorTheme}`}>
        <div className="rolesTable">
            <div className="searchroles-withouInputfields-box"></div>
            <div className="rolesTable-container">
            {!roleRecordLoading ? (
              <>{
                
                  assignedTasksOnRoleIdAndTaskName?.status ? (<>
                    <MainTableComponent
                    data={roleRecord?.role}
                    columns={columns}
                    actions={actions}
                    actionUpdate={actionUpdate}
                    linkk= {linkk}
                    action3={action3}
                />
                </>  ): (<>
                You Are Not Allowed To change Permissions
                </>)
                
              }</>
              
             ) : (<></>
            //     // <Loader active>Loading</Loader>
             )} 
            </div>
        </div>
    </div>
    </>
  );
};

export default RolesTable;
