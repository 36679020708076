import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import {
  Message,
  Button,
  Dropdown,
  Form,
  Select,
  Modal,
  Dimmer,
  Loader,
  Image,
  Segment,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { getTestRecord } from "../../../Actions/testAction";
import { getBranchRecord } from "../../../Actions/branchAction";
import "./customer.css"
import { getVisaRecord } from "../../../Actions/visaAction";
import { deleteCustomerRecordOnForm, deleteCustomerRecords, postCustomer } from "../../../Actions/customerAction";
import {  deleteVisitorRecordOnForm, deleteVisitorRecords, postVisitorsRecord } from "../../../Actions/visitorsAction";
import { postTestCustomerPayment, postTestCustomerPaymentRecord } from "../../../Actions/testCustomerPaymentAction";
import { DELETE_CUSTOMER_DETAIL_SUCCESS, POST_CUSTOMER_SUCCESS } from "../../../Constants/customerConstants";
import { POST_TESTCUSTOMERPAYMENT_FAIL, POST_TESTCUSTOMERPAYMENT_SUCCESS } from "../../../Constants/testCustomerPaymentConstants";
import { DELETE_SPECIFIC_VISITORS_SUCCESS, POST_VISITORS_SUCCESS } from "../../../Constants/visitorsConstants";
import { postVisaCustomerPayment, postVisaCustomerPaymentRecord } from "../../../Actions/visaCustomerPaymentAction";
import PageLoader from "../../Loader/PageLoader";
import { useAlert } from "react-alert";
import { POST_VISACUSTOMERPAYMENT_SUCCESS } from "../../../Constants/visaCustomerPaymentConstants";

const RecordCustomer = () => {
  const dispatch = useDispatch()  
  const navigate = useNavigate()
  const statusDropdownRef = useRef(null);
  const branchDropdownRef = useRef(null)
  const visaDropdownRef = useRef(null)
  const testDropdownRef = useRef(null)
  const alert = useAlert()
  const [customerName, setCustomerName] = useState() 
  const [contactNo, setContactNo] = useState()
  const [address, setAddress] = useState()
  const [testName, setTestName] = useState()
  const [visaName, setVisaName] = useState()
  const [branch, setBranch] = useState()
  const [initialPayment, setInitialPayment] = useState()
  const [totalPayment, setTotalPayment] = useState()
  const [remainingPayment, setRemainingPayment] = useState()
  const [attempts, setAttempts] = useState()
  const [email, setEmail] = useState("Null")
  const [status, setStatus] = useState()
  const [description, setDescription] = useState()
  const [discount, setDiscount] = useState()
  const [categoryOptionTest, setCategoryOptionTest] = useState()
  const [categoryOptionVisa, setCategoryOptionVisa] = useState()
  const [errorOccurred, setErrorOccurred] = useState(false)
  const [isCategoryOptionOpen, setIsCategoryOptionOpen] = useState(false);
  const [isVisaOpen, setIsVisaOpen] = useState(false)
  const [isTestOpen, setIsTestOpen] = useState(false)
  const [testPrice, setTestPrice] = useState()
  const [branchCode, setBranchCode] = useState()
  const [isBranchOpen, setIsBranchOpen] = useState(false)
  const [isStatusOpen, setIsStatusOpen] = useState(false)
  const [selectedVisaName, setSelectedVisaName] = useState()
  const [selectedTestName, setSelectedTestName] = useState()
  const [paymentStatus, setPaymentStatus] = useState("Unpaid")
  const {loading, testRecord} = useSelector((state)=> state.testRecord)
  const {branchLoading, branchRecord} = useSelector((state)=> state.branchRecord)
  const {visaRecord, visaRecordLoading} = useSelector((state)=> state.visaRecord)
  const {customer, customerLoading, customerError} = useSelector((state) => state.customer)
  const {postVisitors, postVisitorsLoading, postVisitorsError} = useSelector((state)=> state.postVisitors)
  const {postTestCustomerPaymentLoading,postTestCustomerPayment, postTestCustomerPaymentError} = useSelector((state)=> state.postTestCustomerPayment)
  const {postVisaCustomerPaymentLoading, postVisaCustomerPayment, postVisaCustomerPaymentError} = useSelector((state)=> state.postVisaCustomerPayment)
  const {deleteCustomerRecord, deleteCustomerRecordLoading, deleteCustomerRecordError} = useSelector((state)=> state.deleteCustomerRecord)
  const {deleteVisitorsRecord, deleteVisitorsRecordLoading, deleteVisitorsRecordError} = useSelector((state)=> state.deleteVisitorsRecord)
  const [colorTheme, setColorTheme] = useState()
  const [branchName, setBranchName] = useState()
  const {user} = useSelector((state)=> state.user)
  const [customerStatus, setCustomerStatus] = useState([])
  const [payments, setPayments] = useState({
    downPayment: '',
    paymentPayed: '',
    remaining: '',
  });
  const [initialPaymentRangeExceeded, setInitialPaymentRangeExceeded] = useState(false)


  const categoryOptions = [
    { key: "1", text: "Visa", value: "Visa" },
    { key: "2", text: "Test", value: "Test" },
  ];

  const statusOptions = [
    { key: "1", text: "Visitor", value: "Visitor" },
    { key: "2", text: "Student", value: "Student" },
    { key: "2", text: "Visa", value: "Visa" },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const visaToggleDropdown = () => {
    setIsVisaOpen(!isVisaOpen);
  };

  const testToggleDropdown = () => {
    setIsTestOpen(!isTestOpen);
  };

  const branchToggleDropdown = () => {
    setIsBranchOpen(!isBranchOpen);
  };

  const statusToggleDropdown = () => {
    setIsStatusOpen(!isStatusOpen);
  };

  const handleTestValue = (value, price, name) => {
    if(categoryOptionTest)
    {
      setTestPrice(parseInt(price))
      setSelectedTestName(name)
      setTestName(value)
      setInitialPayment('')
      setTotalPayment('')
      setAttempts('')
      setRemainingPayment('')
      setDiscount('')
      setIsTestOpen(!isTestOpen);
    }else{
      setTestPrice(parseInt(price))
      setSelectedTestName(name)
      setTestName(value)
      setInitialPayment('')
      setTotalPayment('')
      setAttempts('')
      setRemainingPayment('')
      setDiscount('')
      setIsTestOpen(!isTestOpen);
    }
   
  }

  const handleVisaValue = (value, price, name) => {
    setSelectedVisaName(name)
    setVisaName(value)
    setTotalPayment(price)
    setIsVisaOpen(!isVisaOpen);
  }

  const handleInitialAmount = (e) => {
   
    if(e.target.value > totalPayment){
      return alert.error(`Initial Payment should less than Total Amount`)
    }

    setPayments(prevState => ({
      ...prevState, // Spread the previous state object
      downPayment: e.target.value, // Update the value of the specified field
      paymentPayed: e.target.value
    }));
   
    setInitialPayment(e.target.value)
    setRemainingPayment(totalPayment - e.target.value)
  }

 
  const handleDiscountAmount = (e) => {
    console.log(payments)
    if((parseFloat(e.target.value) + parseFloat(initialPayment)) > totalPayment)
    {
      return alert.error(`Discound & Initial Payment should less than Total Amount`)
    }


    if(parseFloat(e.target.value) + parseFloat(initialPayment) === totalPayment)
    {
      setPaymentStatus("Paid")
    }else
    {
      setPaymentStatus("Unpaid")
    }
    // console.log(parseFloat(e.target.value)+parseFloat(initialPayment))
  
    setDiscount(e.target.value)
    setPayments(prevState => ({
      ...prevState, // Spread the previous state object
      remaining: totalPayment - (parseFloat(e.target.value) + parseFloat(initialPayment)), // Update the value of the specified field
   
    }));
    setRemainingPayment(totalPayment - (parseFloat(e.target.value) + parseFloat(initialPayment)))
    let paymentRemining = totalPayment - (parseFloat(e.target.value) + parseFloat(initialPayment))
    

  }

  const handleBranchValue = (value, code) => {
    setBranchCode(code)
    setBranch(value)
    setIsBranchOpen(!isBranchOpen);
  }

  useEffect(()=>{
    if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
    {
    setBranchCode(user?.user?.branch?.branchCode)
    setBranch(user?.user?.branch?._id)
    }
  }, [])

  
  const handleStatusValue = (value) => {
 
    setStatus(value)
    setIsStatusOpen(!isStatusOpen);
    if(value.startsWith("Visa"))
    {
      // setCategoryOptionVisa(value)
      // setCategoryOptionTest('')
      setCategoryOptionVisa("Visa")
      setCategoryOptionTest('')
      setCustomerStatus(["Visa"]);
      // setIsCategoryOptionOpen(!isCategoryOptionOpen)
    }else if(value.startsWith("Student")){
      setCategoryOptionTest("Test")
      setCategoryOptionVisa('')
      setCustomerStatus(["Student"]);
      // setIsCategoryOptionOpen(!isCategoryOptionOpen)
    }else{
      setCategoryOptionVisa('')
      setCategoryOptionTest('')
      setCustomerStatus(["Visitor"]);
    }

  }

  const categoryToggleDropdown = () => {
    setIsCategoryOptionOpen(!isCategoryOptionOpen)
  }

  const handleCategoryOptionSelection = (value) => {
    console.log(value)
    if(value.startsWith("V"))
    {
      setCategoryOptionVisa(value)
      setCategoryOptionTest('')
      setIsCategoryOptionOpen(!isCategoryOptionOpen)
    }else{
      setCategoryOptionTest(value)
      setCategoryOptionVisa('')
      setIsCategoryOptionOpen(!isCategoryOptionOpen)
    }
  }

  const handleOptionClick = (id, name) => {
    console.log(id, name)
    setSelectedOption(name);
    // onSelect(option);
    setIsOpen(false);
  };

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])


  const ClearRecord = () => {
    if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
    {
    setBranchCode(user?.user?.branch?.branchCode)
    setBranch(user?.user?.branch?._id)
    }else{
      setBranchCode('')
      setBranch('')
    }
    setSelectedTestName('')
    setPayments('')
    setCustomerName('')
    setContactNo('')
    setSelectedTestName('')
    setSelectedVisaName('')
    setAddress('')
    setCategoryOptionTest('')
    setCategoryOptionVisa('')
    setTestName('')
    setVisaName('')
    setInitialPayment('')
    setTotalPayment('')
    setRemainingPayment('')
    setAttempts('')
    setEmail('')
    setStatus('')
    setDiscount('')
    setDescription('')
  };
  const AddCustomer = () => {
    dispatch(postCustomer(customerName, address, branch, contactNo, email, customerStatus))
    // navigate("/Record");
  };

  useEffect(()=>{
    if(!customerLoading && customer?.success){
      console.log(customer)
      dispatch(postVisitorsRecord(customer?.student?._id, branch, status, description))
      // dispatch(customer)
    }
  }, [customerLoading, customer])

  useEffect(()=>{
    if(!customerLoading && customer?.success){
      postVisitorsSuccessMessage()
    }
  }, [customerLoading, customer])
 
  const postVisitorsSuccessMessage =() => {
    if(status?.startsWith("Visitor"))
    {
      dispatch({ type: POST_CUSTOMER_SUCCESS,
        payload: [] });
      dispatch({ type: POST_VISITORS_SUCCESS,
        payload: [] });
        setPayments('')
        setCustomerName('')
        setSelectedTestName('')
      setContactNo('')
      setAddress('')
      setCategoryOptionTest('')
      setCategoryOptionVisa('')
      setTestName('')
      setVisaName('')
      setBranch('')
      setInitialPayment('')
      setTotalPayment('')
      setRemainingPayment('')
      setAttempts('')
      setEmail('')
      setStatus('')
      setDiscount('')
      setDescription('')
      setSelectedTestName('')
      setSelectedVisaName('')
      setBranchCode('')
        return alert.success('Record Added Successfully')
    }
  }



  useEffect(()=>{
    if(!postVisitorsLoading && postVisitors?.success && !status?.startsWith("Visitor"))
    {
      if(categoryOptionTest){
        console.log('fhie')
        dispatch(postTestCustomerPaymentRecord(customer?.student?._id, testName, branch, attempts, payments, paymentStatus, discount, totalPayment))
      }else if(categoryOptionVisa)
      {
        dispatch(postVisaCustomerPaymentRecord(customer?.student?._id, visaName, branch,  payments, paymentStatus, discount, totalPayment))
      }
    }else if(!postVisitorsLoading && postVisitorsError)
    {
      setSelectedTestName('')
      setPayments('')
      setCustomerName('')
      setContactNo('')
      setAddress('')
      setCategoryOptionTest('')
      setCategoryOptionVisa('')
      setTestName('')
      setVisaName('')
      setBranch('')
      setBranchCode('')
      setInitialPayment('')
      setTotalPayment('')
      setRemainingPayment('')
      setAttempts('')
      setEmail('')
      setStatus('')
      setDiscount('')
      setDescription('')
      console.log(customer?.student?._id)
      dispatch(deleteCustomerRecords(customer?.student?._id))
    }
  }, [postVisitors, postVisitorsLoading])

  useEffect(()=>{
    if(!postVisaCustomerPaymentLoading && postVisaCustomerPayment?.success)
    {
      console.log('record Added')
      setSelectedTestName('')
      setPayments('')
      setCustomerName('')
      setContactNo('')
      setAddress('')
      setCategoryOptionTest('')
      setCategoryOptionVisa('')
      setTestName('')
      setVisaName('')
      setBranch('')
      setInitialPayment('')
      setTotalPayment('')
      setRemainingPayment('')
      setAttempts('')
      setEmail('')
      setStatus('')
      setDiscount('')
      setDescription('')
      setSelectedTestName('')
      setSelectedVisaName('')
      setBranchCode('')
      dispatch({
        type: POST_CUSTOMER_SUCCESS,
        payload: [],
      })
      // dispatch({
      //   type: POST_VISACUSTOMERPAYMENT_SUCCESS,
      //   payload: [],
      // })
      dispatch({
        type: POST_VISITORS_SUCCESS,
        payload: [],
      })
    // return alert.success("Record Added Successfully")
    }else if(!postVisaCustomerPaymentLoading && postVisaCustomerPaymentError)
    {
      dispatch({
        type: POST_CUSTOMER_SUCCESS,
        payload: [],
      })
      dispatch(deleteCustomerRecordOnForm(customer?.student?._id))
      dispatch(deleteVisitorRecordOnForm(postVisitors?.visitorData?._id))
     
      setSelectedTestName('')
      setPayments('')
      setCustomerName('')
      setContactNo('')
      setAddress('')
      setCategoryOptionTest('')
      setCategoryOptionVisa('')
      setTestName('')
      setVisaName('')
      setBranch('')
      setInitialPayment('')
      setTotalPayment('')
      setRemainingPayment('')
      setAttempts('')
      setEmail('')
      setStatus('')
      setDiscount('')
      setDescription('')
      setSelectedTestName('')
      setSelectedVisaName('')
      setBranchCode('')
      console.log('record not added')
    }
  },[postVisaCustomerPayment, postVisaCustomerPaymentLoading])



  useEffect(()=>{
    if(!postTestCustomerPaymentLoading && postTestCustomerPayment?.success)
    {
      console.log('record Added')
      setCustomerName('')
      setContactNo('')
      setAddress('')
      setCategoryOptionTest('')
      setCategoryOptionVisa('')
      setTestName('')
      setVisaName('')
      setBranchCode('')
      setBranch('')
      setInitialPayment('')
      setTotalPayment('')
      setRemainingPayment('')
      setAttempts('')
      setEmail('')
      setStatus('')
      setDiscount('')
      setDescription('')
      dispatch({
        type: POST_CUSTOMER_SUCCESS,
        payload: [],
      })
      // dispatch({
      //   type: POST_TESTCUSTOMERPAYMENT_SUCCESS,
      //   payload: [],
      // })
      dispatch({
        type: POST_VISITORS_SUCCESS,
        payload: [],
      })
      // return alert.success("Record Added Successfully")
    }else if(!postTestCustomerPaymentLoading && postTestCustomerPaymentError)
    {
      setErrorOccurred(true)
      dispatch({
        type: POST_CUSTOMER_SUCCESS,
        payload: [],
      })
      dispatch(deleteCustomerRecordOnForm(customer?.student?._id))
      dispatch(deleteVisitorRecordOnForm(postVisitors?.visitorData?._id))

      dispatch({ type: POST_TESTCUSTOMERPAYMENT_FAIL,
        payload: [], });
      setCustomerName('')
      setContactNo('')
      setAddress('')
      setCategoryOptionTest('')
      setCategoryOptionVisa('')
      setTestName('')
      setVisaName('')
      setBranch('')
      setBranchCode('')
      setInitialPayment('')
      setTotalPayment('')
      setRemainingPayment('')
      setAttempts('')
      setEmail('')
      setStatus('')
      setDiscount('')
      setDescription('')
      console.log('record not added')
    
    }
  },[postTestCustomerPayment, postTestCustomerPaymentLoading])






  useEffect(()=>{
    if(postTestCustomerPaymentError)
    {
      console.log('hie')
      console.log(postTestCustomerPaymentError?.message)
      postCustomerPaymentErrorMessage(postTestCustomerPaymentError?.message)
      // return alert.error(postTestCustomerPaymentError?.message)
    }
  }, [postTestCustomerPaymentError])

  
  useEffect(()=>{
    if(postVisaCustomerPaymentError)
    {
      console.log('hie')
      console.log(postVisaCustomerPaymentError?.message)
      postCustomerPaymentErrorMessage(postVisaCustomerPaymentError?.message)
    }
  }, [postVisaCustomerPaymentError])

    useEffect(()=>{
      if(postVisaCustomerPayment?.success)
    {
      postCustomerPaymentSuccessMessage()
    }
  }, [postVisaCustomerPaymentLoading, postVisaCustomerPayment])

  useEffect(()=>{
    if(postTestCustomerPayment?.success)
  {
    postCustomerPaymentSuccessMessage()
  }
}, [postTestCustomerPayment])

const postCustomerPaymentSuccessMessage =() => {
  dispatch({
        type: POST_VISACUSTOMERPAYMENT_SUCCESS,
        payload: [],
      })
      dispatch({
        type: POST_TESTCUSTOMERPAYMENT_SUCCESS,
        payload: [],
      })
      setCustomerName('')
      setContactNo('')
      setAddress('')
      setCategoryOptionTest('')
      setCategoryOptionVisa('')
      setTestName('')
      setVisaName('')
      setBranch('')
      setBranchCode('')
      setInitialPayment('')
      setTotalPayment('')
      setRemainingPayment('')
      setAttempts('')
      setEmail('')
      setStatus('')
      setDiscount('')
      setDescription('')
  return alert.success('Record Added Successfully')
}

const postCustomerPaymentErrorMessage = (message) => {
  return alert.error(message)
}

useEffect(()=>{
  if(!deleteVisitorsRecordLoading && deleteVisitorsRecord?.success)
  {
    dispatch({
      type: DELETE_SPECIFIC_VISITORS_SUCCESS,
      payload: [],
    });
  }
}, [deleteVisitorsRecord, deleteVisitorsRecordLoading])



useEffect(()=>{
  if(deleteCustomerRecord?.success && !deleteCustomerRecordLoading)
  {
    dispatch({
      type: DELETE_CUSTOMER_DETAIL_SUCCESS,
      payload: [],
    });
  }
}, [deleteCustomerRecord, deleteCustomerRecordLoading])



useEffect(()=>{
  if(customerError && !customerLoading)
 {
  postCustomerPaymentErrorMessage(customerError?.data?.error)
 }
}, [customerError, customerLoading])

  useEffect(()=>{
    dispatch(getVisaRecord())
    dispatch(getTestRecord())
    dispatch(getBranchRecord())
  }, [])
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
        console.log('fie')
        setIsStatusOpen(false);
      }
      console.log('clikced')
    };
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []); 

  useEffect(()=>{
 const handleClickBranchOutside = (event) => {
      if (branchDropdownRef.current && !branchDropdownRef.current.contains(event.target)) {
        setIsBranchOpen(false);
      }
      console.log('clikced')
    };
    window.addEventListener('click', handleClickBranchOutside);
    return () => {
      window.removeEventListener('click', handleClickBranchOutside);
    };
  }, [])

  useEffect(()=>{
    const handleClickVisaOutside = (event) => {
         if (visaDropdownRef.current && !visaDropdownRef.current.contains(event.target)) {
           setIsVisaOpen(false);
         }
         console.log('clikced')
       };
       window.addEventListener('click', handleClickVisaOutside);
       return () => {
         window.removeEventListener('click', handleClickVisaOutside);
       };
     }, [])

     useEffect(()=>{
      const handleClickTestOutside = (event) => {
           if (testDropdownRef.current && !testDropdownRef.current.contains(event.target)) {
             setIsTestOpen(false);
           }
           console.log('clikced')
         };
         window.addEventListener('click', handleClickTestOutside);
         return () => {
           window.removeEventListener('click', handleClickTestOutside);
         };
       }, [])

  const ShowTableRecord = async () => {
    navigate('/customerTableRecord')
}

const handleCustomerChange = (event) => {
  const inputValueRegex = /^[A-Za-z\s]+$/ // Regular expression to match alphabetic characters
  const newValue = event.target.value;

  // Check if the input value matches the regular expression
  if (inputValueRegex.test(newValue) || newValue === '') {
    setCustomerName(newValue); // Update the input value if it matches the pattern
  }
};


const handleContactNoChange = (e) => {
  const inputValue = e.target.value;

  // Use regex to allow only digits and limit to 11 characters
  const sanitizedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 11);
  setContactNo(sanitizedValue)
};
  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
     <>
     {
     (customerLoading || postTestCustomerPaymentLoading || postVisitorsLoading || postVisaCustomerPaymentLoading ) ? (
      <PageLoader />
     ) : (
      <div className={`customerMain ${colorTheme}`}>
      <div className={`customer`}>
        <div className="customerSecond">
          <div className="heading">
            <h3>{"Add Customer"}</h3> 
            <button onClick={ShowTableRecord}>
              View Records
            </button>
            </div>
            <div className="customerFirstInputDiv">
            <div className="labeAndInputDiv">
              <label>Customer Name <span>*</span></label>
              <input
                label={"Enter Customer Name"}
                className="customerInputField"
                type="text"
                placeholder={"Enter Customer Name"}
                name="customerName"
                autoComplete="off"
                maxLength="40"
                required
                value={customerName}
                onChange={handleCustomerChange}
              />
            </div>
            <div className="labeAndInputDiv">
              <label>Email</label>
             <input
              label={"productCode"}
              type="email"
              className="customerInputField"
              placeholder={"Enter Email"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
              required
              value = {email}
              onChange={(e)=> setEmail(e.target.value)}
              
              />
              </div>
            <div className="labeAndInputDiv">
              <label>Contact No <span>*</span></label>
              <input
              label={"Contact No"}
              type="text"
              className="customerInputField"
              placeholder={"Enter Contact No"}
              name="contactNo"
              autoComplete="off"
              maxLength="40"
              required
              value = {contactNo}
              onChange={handleContactNoChange}
              />
            </div>
          
            </div>
            <div className="customerInputDiv">
            <div className="labeAndInputDiv">
              <label>Address <span>*</span></label>
              <input
              label={"Address"}
              className="customerInputField"
              type="text"
              placeholder={"Enter Address"}
              name="address"
              autoComplete="off"
              maxLength="200"
              required
              value = {address}
              onChange={(e)=> setAddress(e.target.value)}
              />
             </div>
            <div className="labeAndInputDiv">
              <label>Select Status <span>*</span></label>
               <div ref={statusDropdownRef}  className="custom-dropdown">
                <div className="selected-option" onClick={statusToggleDropdown}>
                  {status || 'Select Status option'}
                </div>
                {isStatusOpen && (
                  <div className="options">
                    {statusOptions?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleStatusValue(option.text)}
                    >
                    {option?.text}
                    </div>
                    ))}
                </div>
                )}
              </div>
            </div>
            <div className="labeAndInputDiv">
              <label>Description</label>
             <input
              label={"Description"}
              type="email"
              className="customerInputField"
              placeholder={"Enter Visit Description"}
              name="productCode"
              autoComplete="off"
              maxLength="150"
              required
              value = {description}
              onChange={(e)=> setDescription(e.target.value)}
              
              />
            </div>
            </div>
            <div className="LastCustomerInputDiv"> 
            <div className="labeAndInputDiv">
             <label>Branch Name <span>*</span></label>
             {
              user?.user?.role?.roleName === "superAdmin" || user?.user?.role?.roleName === "Administrator" ?
              (
                <div ref={branchDropdownRef} className="custom-dropdown">
                <div className="selected-option" onClick={branchToggleDropdown}>
                  {branchCode || 'Select an option'}
                </div>
                {isBranchOpen && (
                  <div className="options">
                    {branchRecord?.branch?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleBranchValue(option._id, option.branchCode)}
                    >
                    {option?.branchCode + ", " + option?.branchAddress}
                    </div>
                    ))}
                </div>
                )}
              </div>
              ) 
              : 
              (  <input
                label={"Description"}
                type="email"
                className="customerInputField"
                placeholder={"Enter Visit Description"}
                name="productCode"
                autoComplete="off"
                maxLength="40"
                required
                value = {branchCode}
                disabled
                />
              )
             }
             
            </div>         
            {
              categoryOptionTest && (

                  <div className="labeAndInputDiv">
                <label>Test Name <span>*</span></label>
                <div ref={testDropdownRef} className="custom-dropdown">
                <div className="selected-option" onClick={testToggleDropdown}>
                  {selectedTestName || 'Select Test option'}
                </div>
                {isTestOpen && (
                  <div className="options">
                    {testRecord?.test?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleTestValue(option._id, option.testPrice, option.testName)}
                    >
                    {option?.testName}
                    </div>
                    ))}
                </div>
                )}
              </div>
              </div>


              )
            }
            </div>
          
           {
            !status?.startsWith('Visitor') && 
            (
            <>
              <div className="customerSecondSection">
             <div className="customerInputDiv">
             {
               (categoryOptionTest) ? (
                <>
               
               <div className="labeAndInputDiv">
                <label>Attempts <span>*</span></label>
                <input
                  label={"Attempts"}
                  type="Number"
                  className="customerInputField"
                  placeholder={"Enter Attempts"}
                  name="productCode"
                  autoComplete="off"
                  maxLength="40"
                  required
                  value = {attempts}
                  onChange={(e)=> {
                    if(testPrice)
                    {
                      setAttempts(e.target.value)
                      setTotalPayment(parseInt(e.target.value) * testPrice)
                      setRemainingPayment(parseInt(e.target.value) * parseInt(testPrice) - (initialPayment + discount))
                      
                    }else{
                      setAttempts(e.target.value)
                    }
                  }
                }
                  />
                  </div> 
              </>) : (
               <>
                {
                  categoryOptionVisa ? (
                    <>
                  <div className="labeAndInputDiv">
                  <label>Visa Type <span>*</span></label>
                  <div ref={visaDropdownRef} className="custom-dropdown">
                <div className="selected-option" onClick={visaToggleDropdown}>
                  {selectedVisaName || 'Select Visa option'}
                </div>
                {isVisaOpen && (
                  <div className="options">
                    {visaRecord?.visaData?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleVisaValue(option._id, option.visaPrice, option.visaName)}
                    >
                    {option?.visaName}
                    </div>
                    ))}
                </div>
                )}
              </div>
              </div>
                    </>
                  ) : (<>
                  <div className="labeAndInputDiv">
                  <label>Select Category First <span>*</span></label>
                  <div className="custom-dropdown">
                    <div className="selected-option" onClick={categoryToggleDropdown}>
                    {'Select Category option First'}
                    </div>
                  </div>
                  </div>
                  </>)
                }
             </>)}
             <div className="labeAndInputDiv">
            <label>Total Payment <span>*</span></label>
             <input
              label={"totalPayment"}
              type="Number"
              className="customerInputField"
              placeholder={"Enter Total Payment"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
              required
              value = {totalPayment}
              onChange={
                (e)=>{
              setInitialPayment('')
              setDiscount('')  
              setRemainingPayment()
              setTotalPayment(e.target.value)}}
              // disabled
              />
               </div>
             <div className="labeAndInputDiv">
            <label>Initial Payment <span>*</span></label>
            <input
              label={"productCode"}
              type="Number"
              className="customerInputField"
              placeholder={"Enter Initial Payment"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
              max={totalPayment}
              required
              value = {initialPayment}
              onChange={handleInitialAmount}
                // (e)=> setInitialPayment(e.target.value)}
              />
            </div>
            </div>
            <div className="LastCustomerInputDiv">
            <div className="labeAndInputDiv">
            <label>Discount <span>*</span></label>
            <input
              label={"Discount"}
              type="Number"
              className="customerInputField"
              placeholder={"Enter Discount"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
              required
              value = {discount}
              onChange={handleDiscountAmount}
                // (e)=> setInitialPayment(e.target.value)}
              />
            </div>
           
              <div className="labeAndInputDiv">
              <label>Remaining <span>*</span></label>
              <input
              label={"productCode"}
              type="Number"
              className="customerInputField"
              placeholder={"Enter Remaining Payment"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
             
              required
              value = {remainingPayment}
              onChange={(e)=> setRemainingPayment(e.target.value)}
              disabled
              />
              </div>
          
        </div>
        </div>
              </>
            )
           }
          
            <div className="buttons">
            <button onClick={ClearRecord}>Clear</button>
              <button onClick={AddCustomer}>Add Record</button>
              
              </div>
          </div>
        <div>
      </div>
    </div>
  </div>
     )
     }
     </>
    </>
  );
};

export default RecordCustomer;
