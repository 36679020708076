// import { CLEAR_ERRORS, DELETE_SPECIFIC_TEST_FAIL, DELETE_SPECIFIC_TEST_REQUEST, DELETE_SPECIFIC_TEST_SUCCESS, GET_SPECIFIC_TEST_FAIL, GET_SPECIFIC_TEST_REQUEST, GET_SPECIFIC_TEST_SUCCESS, GET_TEST_FAIL, GET_TEST_REQUEST, GET_TEST_SUCCESS, POST_TEST_FAIL, POST_TEST_REQUEST, POST_TEST_SUCCESS, UPDATE_SPECIFIC_TEST_FAIL, UPDATE_SPECIFIC_TEST_REQUEST, UPDATE_SPECIFIC_TEST_SUCCESS } from "../Constants/testConstants";
import { CLEAR_ERRORS, DELETE_SPECIFIC_VISA_FAIL, DELETE_SPECIFIC_VISA_REQUEST, DELETE_SPECIFIC_VISA_SUCCESS, GET_SPECIFIC_VISA_FAIL, GET_SPECIFIC_VISA_REQUEST, GET_SPECIFIC_VISA_SUCCESS, GET_VISA_FAIL, GET_VISA_REQUEST, GET_VISA_SUCCESS, POST_VISA_FAIL, POST_VISA_REQUEST, POST_VISA_SUCCESS, UPDATE_SPECIFIC_VISA_FAIL, UPDATE_SPECIFIC_VISA_REQUEST, UPDATE_SPECIFIC_VISA_SUCCESS } from "../Constants/visaConstants";

export const getVisaReducer = (state = { visaRecord: []}, action)=>{
    switch (action.type){
        case GET_VISA_REQUEST:

            return{
                visaRecordLoading: true,
                visaRecord: [],
            };
        case GET_VISA_SUCCESS: 
            return {
                visaRecordLoading: false,
                visaRecord: action.payload
            }
        case GET_VISA_FAIL:
            return {
                visaRecordLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const getSpecificVisaReducer = (state = { visaSpecificRecord: []}, action)=>{
    switch (action.type){
        case GET_SPECIFIC_VISA_REQUEST:
            
            return{
                visaSpecificRecordLoading: true,
                visaSpecificRecord: [],
            };
        case GET_SPECIFIC_VISA_SUCCESS: 
            return {
                visaSpecificRecordLoading: false,
                visaSpecificRecord: action.payload
            }
        case GET_SPECIFIC_VISA_FAIL:
            return {
                visaSpecificRecordLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}


export const updateSpecificVisaReducer = (state = { updateVisaRecord: []}, action)=>{
    switch (action.type){
        case UPDATE_SPECIFIC_VISA_REQUEST:
            
            return{
                updateVisaRecordLoading: true,
                updateVisaRecord: [],
            };
        case UPDATE_SPECIFIC_VISA_SUCCESS: 
            return {
                updateVisaRecordLoading: false,
                updateVisaRecord: action.payload
            }
        case UPDATE_SPECIFIC_VISA_FAIL:
            return {
                updateVisaRecordLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const postVisaReducer = (state = { postVisa: []}, action)=>{
    switch (action.type){
        case POST_VISA_REQUEST:
            return{
                postVisaLoading: true,
                postVisa: [],
            };
        case POST_VISA_SUCCESS: 
            return {
                postVisaLoading: false,
                postVisa: action.payload
            }
        case POST_VISA_FAIL:
            return {
                postVisaLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const deleteVisaReducer = (state = { deleteVisa: []}, action)=>{
    switch (action.type){
        case DELETE_SPECIFIC_VISA_REQUEST:
            return{
                deleteVisaLoading: true,
                deleteVisa: [],
            };
        case DELETE_SPECIFIC_VISA_SUCCESS: 
            return {
                deleteVisaLoading: false,
                deleteVisa: action.payload
            }
        case DELETE_SPECIFIC_VISA_FAIL:
            return {
                deleteVisaLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}