import { GET_TASK_FAIL, GET_TASK_REQUEST, GET_TASK_SUCCESS, POST_TASK_FAIL, POST_TASK_REQUEST, POST_TASK_SUCCESS } from "../Constants/TaskConstants";
import axiosInstance from "./baseURL";

export const PostTask = (taskName, taskDescription) => async (dispatch) => {
    try {
      dispatch({ type: POST_TASK_REQUEST });
      const config = {headers: {"Content-Type": "application/json"}}
      const { data } = await axiosInstance.post(`/api/v1/createTask`, {taskName, taskDescription}, config);
      dispatch({
        type: POST_TASK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_TASK_FAIL,
        payload: error.response,
      });
    }
  };

  export const getTask = () => async (dispatch) => {
    try {
      dispatch({ type: GET_TASK_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getTask`);
      dispatch({
        type: GET_TASK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TASK_FAIL,
        payload: error.response,
      });
    }
  };