import { POST_EXPENSE_FAIL, POST_EXPENSE_REQUEST, POST_EXPENSE_SUCCESS, CLEAR_ERRORS, GET_EXPENSE_REQUEST, GET_EXPENSE_SUCCESS, GET_EXPENSE_FAIL, GET_EXPENSE_ONBRANCH_REQUEST, GET_EXPENSE_ONBRANCH_SUCCESS, GET_EXPENSE_ONBRANCH_FAIL, GET_EXPENSE_DETAIL_REQUEST, GET_EXPENSE_DETAIL_SUCCESS, GET_EXPENSE_DETAIL_FAIL, GET_EXPENSE_ONDATES_REQUEST, GET_EXPENSE_ONDATES_SUCCESS, GET_EXPENSE_ONDATES_FAIL, GET_EXPENSE_ONDATES_FORBRANCH_REQUEST, GET_EXPENSE_ONDATES_FORBRANCH_SUCCESS, GET_EXPENSE_ONDATES_FORBRANCH_FAIL, GET_EXPENSE_DASHBOARD_FORBRANCH_REQUEST, GET_EXPENSE_DASHBOARD_FORBRANCH_SUCCESS, GET_EXPENSE_DASHBOARD_FORBRANCH_FAIL } from "../Constants/expenseConstants";

export const postExpenseReducer = (state = { postExpense: []}, action)=>{
    switch (action.type){
        case POST_EXPENSE_REQUEST:
            return{
                postExpenseLoading: true,
                postExpense: [],
            };
        case POST_EXPENSE_SUCCESS: 
            return {
                postExpenseLoading: false,
                postExpense: action.payload
            }
        case POST_EXPENSE_FAIL:
            return {
                postExpenseLoading: false,
                postExpenseError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                postExpenseError: null,
            }
            default:
            return state;
            
    }
}

export const getExpenseReducer = (state = { expenseRecord: []}, action)=>{
    switch (action.type){
        case GET_EXPENSE_REQUEST:
            return{
                expenseRecordLoading: true,
                expenseRecord: [],
            };
        case GET_EXPENSE_SUCCESS: 
            return {
                expenseRecordLoading: false,
                expenseRecord: action.payload
            }
        case GET_EXPENSE_FAIL:
            return {
                expenseRecordLoading: false,
                expenseRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                expenseRecordError: null,
            }
            default:
            return state;
            
    }
}


export const getExpenseOnBranchReducer = (state = { expenseRecordOnBranch: []}, action)=>{
    switch (action.type){
        case GET_EXPENSE_ONBRANCH_REQUEST:
            return{
                expenseRecordOnBranchLoading: true,
                expenseRecordOnBranch: [],
            };
        case GET_EXPENSE_ONBRANCH_SUCCESS: 
            return {
                expenseRecordOnBranchLoading: false,
                expenseRecordOnBranch: action.payload
            }
        case GET_EXPENSE_ONBRANCH_FAIL:
            return {
                expenseRecordOnBranchLoading: false,
                expenseRecordOnBranchError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                expenseRecordOnBranchError: null,
            }
            default:
            return state;
            
    }
}

export const getExpenseDetailReducer = (state = { expenseRecordDetail: []}, action)=>{
    switch (action.type){
        case GET_EXPENSE_DETAIL_REQUEST:
            return{
                expenseRecordDetailLoading: true,
                expenseRecordDetail: [],
            };
        case GET_EXPENSE_DETAIL_SUCCESS: 
            return {
                expenseRecordDetailLoading: false,
                expenseRecordDetail: action.payload
            }
        case GET_EXPENSE_DETAIL_FAIL:
            return {
                expenseRecordDetailLoading: false,
                expenseRecordDetailError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                expenseRecordDetailError: null,
            }
            default:
            return state;
            
    }
}

export const getExpenseOnDatesReducer = (state = { expenseOnDates: []}, action)=>{
    switch (action.type){
        case GET_EXPENSE_ONDATES_REQUEST:
            return{
                expenseOnDatesLoading: true,
                expenseOnDates: [],
            };
        case GET_EXPENSE_ONDATES_SUCCESS: 
            return {
                expenseOnDatesLoading: false,
                expenseOnDates: action.payload
            }
        case GET_EXPENSE_ONDATES_FAIL:
            return {
                expenseOnDatesLoading: false,
                expenseOnDatesError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                expenseOnDatesError: null,
            }
            default:
            return state;
            
    }
}

export const getExpenseOnDatesForBranchReducer = (state = { expenseOnDatesForBranch: []}, action)=>{
    switch (action.type){
        case GET_EXPENSE_ONDATES_FORBRANCH_REQUEST:
            return{
                expenseOnDatesForBranchLoading: true,
                expenseOnDatesForBranch: [],
            };
        case GET_EXPENSE_ONDATES_FORBRANCH_SUCCESS: 
            return {
                expenseOnDatesForBranchLoading: false,
                expenseOnDatesForBranch: action.payload
            }
        case GET_EXPENSE_ONDATES_FORBRANCH_FAIL:
            return {
                expenseOnDatesForBranchLoading: false,
                expenseOnDatesForBranchError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                expenseOnDatesForBranchError: null,
            }
            default:
            return state;
            
    }
}


export const getExpenseDasboardReducer = (state = { expenseDashboardRecord: []}, action)=>{
    switch (action.type){
        case GET_EXPENSE_DASHBOARD_FORBRANCH_REQUEST:
            return{
                expenseDashboardRecordLoading: true,
                expenseDashboardRecord: [],
            };
        case GET_EXPENSE_DASHBOARD_FORBRANCH_SUCCESS: 
            return {
                expenseDashboardRecordLoading: false,
                expenseDashboardRecord: action.payload
            }
        case GET_EXPENSE_DASHBOARD_FORBRANCH_FAIL:
            return {
                expenseDashboardRecordLoading: false,
                expenseDashboardRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                expenseDashboardRecordError: null,
            }
            default:
            return state;
            
    }
}
