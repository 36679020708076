import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import {useNavigate}  from "react-router-dom"
import "./customer.css"
import MainTableComponent from "../../TableComponent/MainTableComponent";
import { deleteCustomerRecord, deleteCustomerRecords, getCustomerRecords } from "../../../Actions/customerAction";
import PageLoader from "../../Loader/PageLoader";
import { useAlert } from "react-alert";
import swal from "sweetalert2";
import { DELETE_CUSTOMER_DETAIL_FAIL, DELETE_CUSTOMER_DETAIL_SUCCESS, POST_CUSTOMER_FAIL } from "../../../Constants/customerConstants";
import { CustomerInvoiceDataSearch } from "../../SearchComponent/customerInvoiceSearch";
const CustomerTable = () => {
  const dispatch = useDispatch()  
  const [colorTheme, setColorTheme] = useState()
  const alert = useAlert()
  const [customerName, setCustomerName] = useState()
  const [customerEmail, setCustomerEmail] = useState()
  const [tableData, setTableData] = useState()
  const [tableDataLoading, setTableDataLoading] = useState(true)
  const {customerRecord, customerRecordLoading}= useSelector((state)=> state.customerRecord)
  const {deleteCustomerRecord, deleteCustomerRecordLoading, deleteCustomerRecordError} = useSelector((state)=> state.deleteCustomerRecord)
  const linkk = "updateCustomerRecord";
  const actionUpdate = "Update";
  const action3 = "Delete";

  const navigate = useNavigate()

  const AddCustomerRecord = async () => {
      navigate('/customerAdd')
  }

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  useEffect(()=>{
    dispatch(getCustomerRecords())
  }, [])

  const deleteProduct = async (id) => {
    swal
    .fire({
      icon: "warning",
      title: "Warning",
      text: "Are You Sure You Want to Delete",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        popup: "custom-swal-popup", // This is the custom class you're adding
      },
    })
    .then(async (result) => {
      if (result.value) {
        console.log('fei')
         dispatch(deleteCustomerRecords(id))
      }})
   
  };

  useEffect(()=>{
    if(deleteCustomerRecordError?.data && !deleteCustomerRecordLoading)
    {
      deleteErrorMessage(deleteCustomerRecordError?.data?.message)
    }
  }, [deleteCustomerRecordError, deleteCustomerRecordLoading])

  useEffect(()=>{
    if(deleteCustomerRecord?.success && !deleteCustomerRecordLoading)
    {
      deleteSuccessMessage()
    }
  }, [deleteCustomerRecord, deleteCustomerRecordLoading])

  const deleteSuccessMessage = () => {
    dispatch(getCustomerRecords())
    dispatch({
      type: DELETE_CUSTOMER_DETAIL_SUCCESS,
      payload: [],
    });
    return alert.success("Record Deleted Successfully")
  }


  useEffect(()=>{
    if(!customerRecordLoading && customerRecord?.success)
    {
      setTableData(customerRecord?.student)
      setTableDataLoading(false)
    }
  }, [customerRecordLoading, customerRecord])
  const deleteErrorMessage = (message) =>{
    dispatch({
      type: DELETE_CUSTOMER_DETAIL_FAIL,
      payload: [],
    });
    return alert.error(message)
  }

  const handleSearch=async(name, email)=>{
    const data = await CustomerInvoiceDataSearch(customerRecord?.student, name, email)
    setTableData(data)
  }

    const columns = [
        { field: "name", label: "Customer Name" },
        { field: "email", label: "Email" },
        { field: "contactNo", label: "Phone No" },
        { field: "branch.branchCode", label: "Branch" },
        { field: "address", label: "Address" },
        { field: "status", label: "Status" },
      ];
    
      const actions = [
        {
          label: "Delete",
          color: "yellow",
          handler: (itemId) => deleteProduct(itemId),
        },
        {
          label: "Update",
          color: "green",
          url: (itemId) => `/updateCustomerRecord/${itemId}`,
        },
      ];
    

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`customerMain ${colorTheme}`}>
      <div className={`customerTable`}>
      <div className="heading">
            <h3>{"Customers"}</h3> 
            <button onClick={AddCustomerRecord}>
              Add Customer
            </button>
            </div>
    <div className="customerSearch-withInputfields-box " >
    <div className="customerTableFirstInputDiv">
              <input
                label={"Enter Customer Name"}
                className="customerTableInputField"
                type="text"
                placeholder={"Enter Customer Name"}
                name="customerName"
                autoComplete="off"
                maxLength="40"
                required
                value={customerName}
                onChange={(e)=>{let name = e.target.value
                  setCustomerName(e.target.value)
                  handleSearch(name, customerEmail)
                }}
              />
              <input
              label={"Contact No"}
              type="email"
              className="customerTableInputField"
              placeholder={"Enter Email"}
              name="contactNo"
              autoComplete="off"
              maxLength="40"
              required
              value={customerEmail}
                onChange={(e)=>{ let email = e.target.value
                  setCustomerEmail(e.target.value)
                  handleSearch(customerName, email)
                }}
              />
              <input
              label={"Address"}
              className="customerTableInputField"
              type="text"
              placeholder={"Enter Address"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              />
            </div>
    </div>
            <div className="customer-table-container">
            {!tableDataLoading  ? (
                <MainTableComponent
                    data={tableData}
                    columns={columns}
                    actions={actions}
                    actionUpdate={actionUpdate}
                    linkk= {linkk}
                    action3={action3}
                />
             ) : (<>
                  <div className="customer-table-container">
             <PageLoader />
             </div>
             </>
            //     // <Loader active>Loading</Loader>
             )} 
            </div>
        </div>
    </div>
    </>
  );
};

export default CustomerTable;
