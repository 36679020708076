import { CLEAR_ERRORS, DELETE_SPECIFIC_TEST_FAIL, DELETE_SPECIFIC_TEST_REQUEST, DELETE_SPECIFIC_TEST_SUCCESS, GET_SPECIFIC_TEST_FAIL, GET_SPECIFIC_TEST_REQUEST, GET_SPECIFIC_TEST_SUCCESS, GET_TEST_FAIL, GET_TEST_REQUEST, GET_TEST_SUCCESS, POST_TEST_FAIL, POST_TEST_REQUEST, POST_TEST_SUCCESS, UPDATE_SPECIFIC_TEST_FAIL, UPDATE_SPECIFIC_TEST_REQUEST, UPDATE_SPECIFIC_TEST_SUCCESS } from "../Constants/testConstants";

export const getTestReducer = (state = { testRecord: []}, action)=>{
    switch (action.type){
        case GET_TEST_REQUEST:

            return{
                testRecordloading: true,
                testRecord: [],
            };
        case GET_TEST_SUCCESS: 
            return {
                testRecordloading: false,
                testRecord: action.payload
            }
        case GET_TEST_FAIL:
            return {
                testRecordloading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const getSpecificTestReducer = (state = { testSpecificRecord: []}, action)=>{
    switch (action.type){
        case GET_SPECIFIC_TEST_REQUEST:
            
            return{
                testSpecificRecordloading: true,
                testSpecificRecord: [],
            };
        case GET_SPECIFIC_TEST_SUCCESS: 
            return {
                testSpecificRecordloading: false,
                testSpecificRecord: action.payload
            }
        case GET_SPECIFIC_TEST_FAIL:
            return {
                testSpecificRecordloading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}


export const updateSpecificTestReducer = (state = { updateTestRecord: []}, action)=>{
    switch (action.type){
        case UPDATE_SPECIFIC_TEST_REQUEST:
            
            return{
                updateTestRecordloading: true,
                updateTestRecord: [],
            };
        case UPDATE_SPECIFIC_TEST_SUCCESS: 
            return {
                updateTestRecordloading: false,
                updateTestRecord: action.payload
            }
        case UPDATE_SPECIFIC_TEST_FAIL:
            return {
                updateTestRecordloading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const postTestReducer = (state = { postTest: []}, action)=>{
    switch (action.type){
        case POST_TEST_REQUEST:
            return{
                postTestLoading: true,
                postTest: [],
            };
        case POST_TEST_SUCCESS: 
            return {
                postTestLoading: false,
                postTest: action.payload
            }
        case POST_TEST_FAIL:
            return {
                postTestLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const deleteTestReducer = (state = { deleteTest: []}, action)=>{
    switch (action.type){
        case DELETE_SPECIFIC_TEST_REQUEST:
            return{
                deleteTestLoading: true,
                deleteTest: [],
            };
        case DELETE_SPECIFIC_TEST_SUCCESS: 
            return {
                deleteTestLoading: false,
                deleteTest: action.payload
            }
        case DELETE_SPECIFIC_TEST_FAIL:
            return {
                deleteTestLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}