import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert2"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import CatchingPokemonIcon from "@mui/icons-material/CatchingPokemon";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import { LOAD_USER_FAIL } from "../../../constants/userConstants";
import SettingsIcon from '@mui/icons-material/Settings';
// import { logout } from "../../../actions/userAction";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { logedOut, logout } from "../../../../Actions/userAction";
import { useEffect } from "react";
import companylogo from "./companyLogo.jpg"
import { getTestAndVisaPaymentNotifications } from "../../../../Actions/testCustomerPaymentAction";

export const Navbar = ({ setIsOpen }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, user, isAuthenticated } = useSelector((state) => state.user);
  const {logoutLoading, isUserAuthenticated, loggedOut} = useSelector((state)=> state.loggedOut)
  const {notificationRecord, notificationRecordLoading, notificationRecordError} = useSelector((state)=> state.notificationRecord)
  const [notificationOpen, setNotificationOpen] = useState(false)
const HandleLogoClick =()=>{
  navigate('/dashboard')
}

const Logout = async()=>{
  console.log('called')
 
   swal
   .fire({
     icon: "warning",
     title: "Are You Sure, You want to logout",
     showCancelButton: true,
     confirmButtonText: "Yes",
     cancelButtonText: "No",
     customClass: {
       popup: "custom-swal-popup", // This is the custom class you're adding
     },
   })
   .then(async (result) => {
     if (result.value) {
      dispatch(logout());
      let data = await logedOut()
      console.log(data)
      if(data?.success){
       localStorage.clear()
       navigate('/login')
      }

     }})
  
 }

 useEffect(()=>{
  dispatch(getTestAndVisaPaymentNotifications())
 }, [])

 const handleNotification = () => {
  setNotificationOpen(!notificationOpen)
 }
 
 const settings = async() =>{
   navigate("/settings")
 }


return(
  <>
  <nav className="navbar">
  <button
    onClick={() => setIsOpen(true)}
    className="burger material-symbols-outlined"
  >
        <MenuIcon className="svgClass"/>
  </button>
  <div className="logo" onClick={HandleLogoClick}>
    <img src={companylogo} alt="Logo" className="navbar-logo"/>
    {/* <CatchingPokemonIcon className='svgClass'/> */}
      <h4>Saif Consulting Firm</h4>
  </div>
  <div className="center">
  </div>
  <nav className="secondNav">
  
  {/* <button className="material-symbols-outlined"><LiaFileInvoiceDollarSolid className='svgClass'/></button> */}
  <button className="material-symbols-outlined"><LiaFileInvoiceDollarSolid className='svgClass'/></button>
  <Tooltip title={`${user?.user?.role?.roleName}`} arrow>
    <p className='svgClass'>{user?.user?.name}</p>  
  </Tooltip>
  {/* <button className="material-symbols-outlined"><LiaFileInvoiceDollarSolid /></button> */}
  <button className="material-symbols-outlined">
    {notificationRecord?.length > 0 && (<> <span className="badge">{`${notificationRecord?.responseMessages?.length}`}+</span></>)}
     <NotificationsNoneIcon className='svgClass' onClick={handleNotification}/>
  </button>
  <Tooltip title="Profile" arrow>
  <button className="material-symbols-outlined">
  {/* <img src={joe} alt="Joe" /> */}
  <AccountCircleIcon  className="svgClass"/>
  </button>
  </Tooltip>
  {
    user?.user?.role?.roleName === "superAdmin" && (<Tooltip title="Settings" arrow><button className="material-symbols-outlined" onClick={settings}><SettingsIcon className='svgClass'/></button></Tooltip>)
  }
  {/* <Tooltip title="Settings" arrow><button className="material-symbols-outlined" onClick={settings}><SettingsIcon className='svgClass'/></button></Tooltip> */}
  <Tooltip title="Logout" arrow><button className="material-symbols-outlined" onClick={Logout}><LogoutIcon className='svgClass'/></button></Tooltip>
</nav>
</nav>
{
  notificationOpen && (
  // <div className="notificationDiv">
      <div className="navOptions">
      {notificationRecord?.responseMessages?.map((option, index) => (
                    <div
                      key={index}
                      className="navOption"
                    >
                    <h1></h1>
                    <p>{option}</p>
                    </div>
                    ))}
      </div>
  // </div>
  ) 
}

</>
)};
