import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { PostTestRecord, getSpecificTestRecord, getTestRecord, updateTestRecord, updateTestRecordd } from "../../../Actions/testAction";
import { getBranchRecord } from "../../../Actions/branchAction";
import {useNavigate, useParams}  from "react-router-dom"
import "./test.css"
import { useAlert } from "react-alert";
import { UPDATE_SPECIFIC_TEST_SUCCESS } from "../../../Constants/testConstants";
const UpdateTestForm = () => {
  const dispatch = useDispatch()  
  const [testName, setTestName] = useState()
  const [testPrice, setTestPrice] = useState()
  const alert = useAlert()
  const [testDescription, setTestDescription] = useState()
  const params = useParams()
  const [colorTheme, setColorTheme] = useState()
  const {testSpecificRecordloading, testSpecificRecord } = useSelector((state)=> state.testSpecificRecord)
  const{updateTestRecord, updateTestRecordLoading} = useSelector((state)=> state.updateTestRecord)
  const navigate = useNavigate()

  const ShowTableRecord = async () => {
      navigate('/testRecordTable')
  }

  useEffect(()=>{
    if(!testSpecificRecordloading && testSpecificRecord)
    {
      setTestName(testSpecificRecord?.test?.testName)
      setTestPrice(testSpecificRecord?.test?.testPrice)
      setTestDescription(testSpecificRecord?.test?.testDescription)
    }
  }, [testSpecificRecordloading, testSpecificRecord])

  useEffect(()=>{
    dispatch(getSpecificTestRecord(params.id))
  }, [])

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  const updateRecord = () =>{
        dispatch(updateTestRecordd(params.id, testName, testPrice, testDescription))
        // 
    }

  useEffect(()=>{
    if(updateTestRecord?.success && !updateTestRecordLoading){
      updateTestSuccessMessage()
    }
console.log(updateTestRecord)
  }, [updateTestRecord, updateTestRecordLoading])


  const updateTestSuccessMessage =() => {
    dispatch({
      type: UPDATE_SPECIFIC_TEST_SUCCESS,
      payload: [],
    });
    navigate("/testRecordTable")
    return alert.success('Record Updated Successfully')
  }


  const clearRecord = ()=>{
        setTestName('')
        setTestPrice('')
        setTestDescription('')
    }

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`testMain ${colorTheme}`}>
        <div className={`test`}>
          <div className="testSecond">
            <div className="heading">
            <h3 >{"Update Test"}</h3> 
            </div>
                <div className="testInputDiv">
                  {/* <div className="inputDiv"> */}
                  <div className="labeAndInputDiv">
                    <label className="labelDiv">Test Name <span>*</span></label>
                    <input
                        label={"productCode"}
                        className="inputField"
                        type="text"
                        placeholder={"Enter Test Name"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={testName}
                        onChange={(e)=> setTestName(e.target.value)}
                        />
                  </div>
                  <div className="labeAndInputDiv">
                    <label className="labelDiv">Test Price <span>*</span></label>
                    <input
                        label={"productCode"}
                        type="Number"
                        className="inputField"
                        placeholder={"Enter Test Price"}
                        name="productCode"
                        autoComplete="off"
                        required
                        value={testPrice}
                        onChange={(e)=> setTestPrice(e.target.value)}
                    />
                  </div>
                  <div className="labeAndInputDiv">
                    <label className="labelDiv">Test Description <span>*</span></label>
                    <input
                        className="inputField"
                        type="text"
                        placeholder={"Enter Test Description"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="150"
                        required
                        value={testDescription}
                        onChange={(e)=> setTestDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="buttons">
                <button onClick={updateRecord}>Update Record</button>
                <button onClick={clearRecord}>Clear</button>
                </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateTestForm;
