import React, { useEffect, useState } from "react"
import {useDispatch, useSelector} from "react-redux"
import Box from "@mui/material/Box";
import { getAssignedTaskOnRoleId, getAssignedTaskOnRoleIdAndTaskId, postAssignedTaskOnRoleAndTask } from "../../../Actions/assignedTaskAction"
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import {useParams} from "react-router-dom"
import { getTask } from "../../../Actions/taskAction";
import PageLoader from "../../Loader/PageLoader";
const ChangePermissions = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const [tasks, setTasks] = useState(false)
    const [taskPermissions, setTaskPermissions] = useState()
    const {taskRecord, taskRecordLoading} = useSelector((state)=> state.taskRecord)
     const {assignedTasksOnRoleId, assignedTasksOnRoleIdLoading} = useSelector((state)=> state.assignedTasksOnRoleId)
    useEffect(()=>{
        dispatch(getTask())
        dispatch(getAssignedTaskOnRoleId(params.id))
    }, [])

    const handleCheckBoxChange = async (status, taskId) => {
        console.log(status)
        console.log(taskId)
        console.log(params.id)
        dispatch(postAssignedTaskOnRoleAndTask(params.id, taskId, status))
      };

    useEffect(()=>{
      if(!taskRecordLoading && taskRecord?.success)
      {
        const permissionPromises = taskRecord?.task?.map((task) =>
        checkrolePermission(task._id)
        );
        PromistResolve(permissionPromises)

        
      }
    }, [taskRecord, taskRecordLoading])

    const checkrolePermission = async (taskId) => {
      try {
        const result =await getAssignedTaskOnRoleIdAndTaskId(params.id, taskId)
        return result?.assingedTask[0]?.status
      } catch (error) {
        console.error("Error fetching permissions: ", error);
        return false;
      }
    };
    
    const PromistResolve = async (permissionPromises) => {
      try {
        const permissions = await Promise.all(permissionPromises);
        const taskPermissionMap = {};
        taskRecord?.task?.forEach((task, index) => {
          taskPermissionMap[task._id] = permissions[index];
        });
      setTaskPermissions(taskPermissionMap)
      setTasks(true)
      } catch (error) {
        console.error("Error fetching permissions: ", error);
        return false;
      }
    };
    
    
    return (
        <>
          <Box sx={{ width: "85%", margin: "0 auto" }}>
      {!assignedTasksOnRoleIdLoading ? (
        <Box>
          <Typography variant="h4" sx={{ textAlign: "left", marginTop: "100px" }}>
            Assign Permissions (
            {!assignedTasksOnRoleIdLoading && assignedTasksOnRoleId?.assignedTask[0]?.role?.roleName.toUpperCase()}
            )
          </Typography>
          <Box
            sx={{
              width: "30%",
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "space-between",
            }}
          >
            {tasks ? (<>
              { 
              taskRecord?.task?.map((data, index) => {
                return (
                  <FormGroup key={index}>
                    <FormControlLabel
                      label={data?.taskName}
                      labelPlacement="start"
                      control={
                        <Checkbox
                          defaultChecked={
                            taskPermissions[data?._id] === true ? true : false
                          }
                          onClick={(e) =>
                            handleCheckBoxChange(e.target.checked, data?._id)
                          }
                        />
                      }
                      sx={{ display: "flex", justifyContent: "space-between" , color:"black" }}
                    />
                  </FormGroup>
                );
              })}</>) : (<><Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PageLoader />
                {/* <Loader active>Loading</Loader> */}
              </Box></>)}
             
          </Box>
        </Box>
     ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PageLoader />
          {/* <Loader active>Loading</Loader> */}
        </Box>
      )}
    </Box> 
         {/* {!assignedTasksOnRoleIdLoading > 0 &&
              assignedTasksOnRoleId?.assignedTask.map((data, index) => {
                return (<>
                  <FormGroup key={index}>
                    <FormControlLabel
                      label={data?.task?.taskName}
                      labelPlacement="start"
                      control={
                        <Checkbox
                          defaultChecked={
                            data.status === true ? true : false
                            // taskPermissions[data._id] === true ? true : false
                          }
                          onClick={(e) =>
                            handleCheckBoxChange(e.target.checked, data._id)
                          }
                        />
                      }
                      sx={{ display: "flex", justifyContent: "space-between" , color:"black" }}
                    />
                  </FormGroup>
               </> );
              })} */}
              </>
    )
}
export default ChangePermissions