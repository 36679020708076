import React, { useContext, useEffect } from "react";
// import { State } from "./context/stateContext";
import { Statee } from "./expenseStateContext";
import MainTableComponent from "../../TableComponent/MainTableComponent";
export default function Table({ selectedPrinter }) {
  const { expense, setExpense } = useContext(Statee);
  const action2 = "expenseListDelete";

  const handleDelete = (id) => {
    // console.warn('hiiii')
    //setShowModal(true)
    // swal
    //   .fire({
    //     icon: "warning",
    //     title: t("titleAreYouSure"),
    //     text: t("textDeleteItem"),
    //     showCancelButton: true,
    //     confirmButtonColor: "#d33",
    //     cancelButtonColor: "#3085d6",
    //     confirmButtonText: "Yes, delete it!",
    //     cancelButtonText: "Cancel",
    //   })
    //   .then((result) => {
        // if (result.isConfirmed) {
          const updatedItems = [...expense];
          updatedItems.splice(id, 1);
          setExpense(updatedItems);
        // }
    //   });
  };




  useEffect(()=>{
    console.log(expense)
  }, [expense])

  
  const columns=[
    {field: 'expenseType', label: 'Expense Type'},
    {field: 'expenseAmount', label: 'Amount'},
    {field: "expenseUser", label: 'Expense User'},
    {field: 'expenseDescription', label: 'Expense Description'}
   
  ]

  const actions = [
    {
      label: "expenseListDelete",
      color: "green",
      handler: (itemId) => handleDelete(itemId),
      url: null,
    },
  ];
  return (
    <>
      
        <div className="expenseTableMain">
      {
        expense?.length > 0 && (<>
         <MainTableComponent data={expense} columns={columns}   actions={actions}
            action2={action2}/>
        </>)
      }
       
     
    </div>
    {/* {
      expenses  && expenses?.length > 0 ? (
      <div>
        <h2
          className="flex items-end justify-end text-gray-800 font-bold"
          style={{ fontSize: "1.7rem" }}
        >
          Grand Total. {expenseTotal.toLocaleString()}
        </h2>
      </div>
      ):(
        <h1></h1>
      )
      } */}
    </>
  );
}
