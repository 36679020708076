export const GET_EXPENSETYPE_REQUEST = "GET_EXPENSETYPE_REQUEST"
export const GET_EXPENSETYPE_SUCCESS = "GET_EXPENSETYPE_SUCCESS"
export const GET_EXPENSETYPE_FAIL = "GET_EXPENSETYPE_FAIL"


export const GET_EXPENSETYPE_DETAIL_REQUEST = "GET_EXPENSETYPE_DETAIL_REQUEST"
export const GET_EXPENSETYPE_DETAIL_SUCCESS = "GET_EXPENSETYPE_DETAIL_SUCCESS"
export const GET_EXPENSETYPE_DETAIL_FAIL = "GET_EXPENSETYPE_DETAIL_FAIL"

export const UPDATE_EXPENSETYPE_DETAIL_REQUEST = "UPDATE_EXPENSETYPE_DETAIL_REQUEST"
export const UPDATE_EXPENSETYPE_DETAIL_SUCCESS = "UPDATE_EXPENSETYPE_DETAIL_SUCCESS"
export const UPDATE_EXPENSETYPE_DETAIL_FAIL = "UPDATE_EXPENSETYPE_DETAIL_FAIL"


export const DELETE_EXPENSETYPE_DETAIL_REQUEST = "DELETE_EXPENSETYPE_DETAIL_REQUEST"
export const DELETE_EXPENSETYPE_DETAIL_SUCCESS = "DELETE_EXPENSETYPE_DETAIL_SUCCESS"
export const DELETE_EXPENSETYPE_DETAIL_FAIL = "DELETE_EXPENSETYPE_DETAIL_FAIL"

export const POST_EXPENSETYPE_REQUEST = "POST_EXPENSETYPE_REQUEST"
export const POST_EXPENSETYPE_SUCCESS = "POST_EXPENSETYPE_SUCCESS"
export const POST_EXPENSETYPE_FAIL = "POST_EXPENSETYPE_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"