import React, { useEffect, useState, useRef } from "react";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { getCustomerVisaRecord } from "../../../Actions/visaCustomerPaymentAction";
import MainTableComponent from "../../TableComponent/MainTableComponent";
import {  deleteVisitorRecords, getVisitorsOnDates, getVisitorsOnDatesForBranch, getVisitorsRecord, getVisitorsRecordOnBranch } from "../../../Actions/visitorsAction";
import "./visitorRecord.css"
import PageLoader from "../../Loader/PageLoader"
import swal from "sweetalert2"
import LaserPrintTable from "../../TableComponent/LaserPrintTable"
import { DELETE_SPECIFIC_VISITORS_FAIL, DELETE_SPECIFIC_VISITORS_SUCCESS, GET_VISITORS_ONSHOP_SUCCESS, GET_VISITORS_SUCCESS } from "../../../Constants/visitorsConstants";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { VisitorInvoiceDataSearch } from "../../SearchComponent/VisitorInvoiceSearch";
let name;
const VisitorsTableRecord = () => {
  const visitorRef = useRef();
    const dispatch = useDispatch()
    const [customerName, setCustomerName] = useState()
    const [colorTheme, setColorTheme] = useState()
    const navigate = useNavigate()
    const [tableData, setTableData] = useState()
    const [dataLoading, setDataLoading] = useState(true)
    const [tableRecord, setTableRecord] = useState()
    const alert = useAlert()
    const [startingDate, setStartingDate] = useState()
    const [endingDate, setEndingDate]= useState()
    const linkk = "updateVisitor"
    const actionUpdate = "Update";
    const action3 = "Delete";
    const {user} = useSelector((state)=> state.user)
    const {visitorsRecordOnBranch, visitorsRecordOnBranchLoading, visitorsRecordOnBranchError} = useSelector((state)=> state.visitorsRecordOnBranch) 
    const {visitorsRecord, visitorsRecordLoading, visitorsRecordError} = useSelector((state)=> state.visitorsRecord)
    const {visitorsOnDates, visitorsOnDatesLoading} = useSelector((state)=> state.visitorsOnDates)
    const {visitorsOnDatesForBranch, visitorsOnDatesForBranchLoading} = useSelector((state)=> state.visitorsOnDatesForBranch)
    const {deleteVisitorsRecord, deleteVisitorsRecordLoading, deleteVisitorsRecordError} = useSelector((state)=> state.deleteVisitorsRecord)
    useEffect(()=>{
      if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
      {
        console.log('jfi')
        dispatch(getVisitorsRecordOnBranch(user?.user?.branch?.branchCode))
      }else{
        console.log('ajfei')
        dispatch(getVisitorsRecord())
      }
    
    }, [user])


    useEffect(()=>{
      if(visitorsRecordOnBranch?.success && !visitorsRecordOnBranchLoading)
      {
        setTableData(visitorsRecordOnBranch?.visitorsRecord)
        setTableRecord(visitorsRecordOnBranch?.visitorsRecord)
        setDataLoading(false)
      }

    }, [visitorsRecordOnBranch, visitorsRecordOnBranch])

    useEffect(()=>{
      if(visitorsRecord?.success && !visitorsRecordLoading)
      {
        setTableData(visitorsRecord?.visitorData)
        setTableRecord(visitorsRecord?.visitorData)
        setDataLoading(false)
      }
    
    }, [visitorsRecord, visitorsRecordLoading])

    useEffect(()=>{
      setColorTheme(localStorage.getItem('theme-color'))
    }, [localStorage.getItem('theme-color')])


    const deleteVisitor = (id) => {
      swal
      .fire({
        icon: "warning",
        title: "Warning",
        text: "Are You Sure You Want to Delete",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          popup: "custom-swal-popup", // This is the custom class you're adding
        },
      })
      .then(async (result) => {
        if (result.value) {
          console.log('fei')
          dispatch(deleteVisitorRecords(id))
        }})
    
    }

    useEffect(()=>{
      if(!deleteVisitorsRecordLoading && deleteVisitorsRecord?.success)
      {
        deleteSuccessMessage()
      }
    }, [deleteVisitorsRecord, deleteVisitorsRecordLoading])

    useEffect(()=>{
      if(!deleteVisitorsRecordLoading && deleteVisitorsRecordError?.data)
      {
        deleteErrorMessage(deleteVisitorsRecordError?.data?.message)
      }
    }, [deleteVisitorsRecordError, deleteVisitorsRecordLoading])

    const deleteSuccessMessage =() =>{
      dispatch(getVisitorsRecord())
      dispatch({
        type: DELETE_SPECIFIC_VISITORS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: DELETE_SPECIFIC_VISITORS_FAIL,
        payload: null,
      });
      return alert.success("Record Deleted Successfully")
    }

    const deleteErrorMessage =(message) =>{
      dispatch({
        type: DELETE_SPECIFIC_VISITORS_SUCCESS,
        payload: [],
      });
      return alert.error(message)
    }

    
  const AddCustomerRecord = async () => {
    navigate('/customerAdd')
} 



const handleSearch = async(customerName) =>{
  const data = await VisitorInvoiceDataSearch(tableRecord, customerName)
  console.log(data)
  setTableData(data)
}


const handleStartingDate =(e) =>{
  setStartingDate(e.target.value)
  if(endingDate)
  {
    setDataLoading(true)
    if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
    {
      dispatch({
        type: GET_VISITORS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: GET_VISITORS_ONSHOP_SUCCESS,
        payload: [],
      });
 
      dispatch(getVisitorsOnDatesForBranch(user?.user?.branch?._id, e.target.value, endingDate))
    } 
    else 
    {
      dispatch({
        type: GET_VISITORS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: GET_VISITORS_ONSHOP_SUCCESS,
        payload: [],
      });
 
      dispatch(getVisitorsOnDates(e.target.value, endingDate))
    }
  }else{
    setStartingDate(e.target.value)
  }
  console.log(e.target.value)
}

const handleEndingDate = (e) => {
  setEndingDate(e.target.value)
  if(!startingDate)
  {
    return alert.error('Please Select Starting Date')
  }
  setDataLoading(true)
  if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
  {
    dispatch({
      type: GET_VISITORS_SUCCESS,
      payload: [],
    });
    dispatch({
      type: GET_VISITORS_ONSHOP_SUCCESS,
      payload: [],
    });

    dispatch(getVisitorsOnDatesForBranch(user?.user?.branch?._id, startingDate, e.target.value))
  } 
  else 
  {
    dispatch({
      type: GET_VISITORS_SUCCESS,
      payload: [],
    });
    dispatch({
      type: GET_VISITORS_ONSHOP_SUCCESS,
      payload: [],
    });

    dispatch(getVisitorsOnDates(startingDate, e.target.value))
  }
}


useEffect(()=>{
  if(!visitorsOnDatesLoading && visitorsOnDates?.success)
  {
    setTableData(visitorsOnDates?.studentData)
    setTableRecord(visitorsOnDates?.studentData)
    setDataLoading(false)
  }
}, [visitorsOnDates, visitorsOnDatesLoading])

useEffect(()=>{
  if(!visitorsOnDatesForBranchLoading && visitorsOnDatesForBranch?.success)
  {
    setTableData(visitorsOnDatesForBranch?.studentData)
    setTableRecord(visitorsOnDatesForBranch?.studentData)
    setDataLoading(false)
  }
}, [visitorsOnDatesForBranch, visitorsOnDatesForBranchLoading])

const clear = () =>{
  setDataLoading(true)
  if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
      {
        console.log('jfi')
        dispatch(getVisitorsRecordOnBranch(user?.user?.branch?.branchCode))
      }else{
        console.log('ajfei')
        dispatch(getVisitorsRecord())
      }
  setCustomerName('')
  setStartingDate('')
  setEndingDate('')
}


    const columns = [
        { field: "cust_id.name", label: "Name" },
        { field: "cust_id.contactNo", label: "Contact No" },
        { field: "branch_id.branchCode", label: "Branch"},
        { field: "cust_id.email", label: "Email" },
        { field: "cust_id.address", label: "Address" },
        { field: "visitStatus", label: "Status" },
        {field: "createdAt", label: "Date", format: "date"},
        {field: "createdAt", label: "Time", format: "time"},
        { field: "visitDescription", label: "Description" }
      ];
    
      const actions = [
        {
          label: "Delete",
          color: "yellow",
          handler: (itemId) => deleteVisitor(itemId),
        },
        {
          label: "Update",
          color: "green",
          url: (itemId) => `/updateVisitor/${itemId}`,
        },
      ];

    return(<>
     <div className={`customerVisitorRecordTable ${colorTheme}`}>
    <div className={`customerRecordVisitor`}>
    <div className="heading">
            <h3>{"Visitors History"}</h3> 
            <button onClick={AddCustomerRecord}>
              Add Customer
            </button>
            </div>
    <div className="searchCustomerRecordVisitor-withouInputfields-box" >
    <div className="customerRecordVisitorTableFirstInputDiv">
              <input
                label={"Enter Customer Name"}
                className="customerRecordVisitorTableInputField"
                type="text"
                placeholder={"Enter Customer Name"}
                name="customerName"
                autoComplete="off"
                maxLength="40"
                required
                value={customerName}
                onChange={(e)=>{
                  name = e.target.value
                  setCustomerName(e.target.value)
                  handleSearch(name)
                }}
              />
               <input
              label={"Address"}
              className="customerRecordVisitorTableInputField"
              type="date"
              placeholder={"Enter Starting Date"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              value = {startingDate}
              onChange={handleStartingDate}
              />
                <input
              label={"Address"}
              className="customerRecordVisitorTableInputField"
              type="date"
              placeholder={"Enter Ending Date"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              value = {endingDate}
              onChange={handleEndingDate}
              />
               <div className="searchButton">
                <button onClick={clear}>
                    Clear
                </button>
                <ReactToPrint
            trigger={() => (
              <button >
                Print / Download
              </button>
            )}
            content={() => visitorRef.current}
          />
            </div>
            </div>
    </div>
    <div className="customerVisitorTable-container">
    {
      !dataLoading ? (<>
       <MainTableComponent
                    data={tableData}
                    columns={columns}
                    actions={actions}
                    actionUpdate={actionUpdate}
                    linkk= {linkk}
                    action3={action3}
                />
      </>) : (<>
      <PageLoader />
      </>)
    }
     <div className="printSection">
      <div ref={visitorRef} style={{overflow: "auto",}} className="p-5">
        <div  className="printPagePrev" style={{margin: "10px", border: "2px solid black", padding: "10px",}}>
        {
                !dataLoading && (<LaserPrintTable
                     data={tableData}
                     columns={columns}
                />)
            }
      </div>
      </div>
      </div>
    </div>
    </div>
        </div>
    </>)
}
export default VisitorsTableRecord;