import { useState } from "react";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
export default function Header({ selectedPrinter }) {
    const {visaCustomerPaymentDetailRecord, visaCustomerPaymentDetailRecordLoading} = useSelector((state)=> state.visaCustomerPaymentDetailRecord)
    // const {testCustomerPaymentDetailRecord, testCustomerPaymentDetailRecordLoading} = useSelector((state)=> state.testCustomerPaymentDetailRecord)
    return (
    <>
      {/* {selectedPrinter === "Laser" ? ( */}
        <div className="headerHeading">
          <h2 className="invoiceTitle">Saif Consultancy Firm</h2>
          <p className="invoiceAddress"> {visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.cust_id?.branch?.branchAddress}</p>
          <p className="invoicePhoneNo">
            <p>
              Phone No:
              {visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.cust_id?.branch?.branchPhoneNo}
            </p>
          </p>
        </div>
    </>
  );
}
