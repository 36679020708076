import { CLEAR_ERRORS, DELETE_BOOKING_DETAIL_FAIL, DELETE_BOOKING_DETAIL_REQUEST, DELETE_BOOKING_DETAIL_SUCCESS, GET_BOOKING_DETAIL_FAIL, GET_BOOKING_DETAIL_REQUEST, GET_BOOKING_DETAIL_SUCCESS, GET_BOOKING_FAIL, GET_BOOKING_ONDATES_FAIL, GET_BOOKING_ONDATES_FORBRANCH_FAIL, GET_BOOKING_ONDATES_FORBRANCH_REQUEST, GET_BOOKING_ONDATES_FORBRANCH_SUCCESS, GET_BOOKING_ONDATES_REQUEST, GET_BOOKING_ONDATES_SUCCESS, GET_BOOKING_ONSHOP_FAIL, GET_BOOKING_ONSHOP_REQUEST, GET_BOOKING_ONSHOP_SUCCESS, GET_BOOKING_REQUEST, GET_BOOKING_SUCCESS, POST_BOOKING_FAIL, POST_BOOKING_REQUEST, POST_BOOKING_SUCCESS, UPDATE_BOOKING_DETAIL_FAIL, UPDATE_BOOKING_DETAIL_REQUEST, UPDATE_BOOKING_DETAIL_SUCCESS } from "../Constants/bookingConstants";
import {  DELETE_BRANCH_DETAIL_FAIL, DELETE_BRANCH_DETAIL_REQUEST, DELETE_BRANCH_DETAIL_SUCCESS, GET_BRANCH_DETAIL_FAIL, GET_BRANCH_DETAIL_REQUEST, GET_BRANCH_DETAIL_SUCCESS, GET_BRANCH_FAIL, GET_BRANCH_REQUEST, GET_BRANCH_SUCCESS, POST_BRANCH_FAIL, POST_BRANCH_REQUEST, POST_BRANCH_SUCCESS, UPDATE_BRANCH_DETAIL_FAIL, UPDATE_BRANCH_DETAIL_REQUEST, UPDATE_BRANCH_DETAIL_SUCCESS } from "../Constants/branchConstants";


export const getBookingReducer = (state = { bookingRecord: []}, action)=>{
    switch (action.type){
        case GET_BOOKING_REQUEST:
            return{
                bookingRecordLoading: true,
                bookingRecord: [],
            };
        case GET_BOOKING_SUCCESS: 
            return {
                bookingRecordLoading: false,
                bookingRecord: action.payload
            }
        case GET_BOOKING_FAIL:
            return {
                bookingRecordLoading: false,
                bookingRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                bookingRecordError: null,
            }
            default:
            return state;
            
    }
}

export const getBookingDetailReducer = (state = { bookingDetailRecord: []}, action)=>{
    switch (action.type){
        case GET_BOOKING_DETAIL_REQUEST:
            return{
                bookingDetailRecordLoading: true,
                bookingDetailRecord: [],
            };
        case GET_BOOKING_DETAIL_SUCCESS: 
            return {
                bookingDetailRecordLoading: false,
                bookingDetailRecord: action.payload
            }
        case GET_BOOKING_DETAIL_FAIL:
            return {
                bookingDetailRecordLoading: false,
                bookingDetailRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                bookingDetailRecordError: null,
            }
            default:
            return state;
            
    }
}

export const updateBookingDetailReducer = (state = { updateBookingRecord: []}, action)=>{
    switch (action.type){
        case UPDATE_BOOKING_DETAIL_REQUEST:
            return{
                updateBookingRecordLoading: true,
                updateBookingRecord: [],
            };
        case UPDATE_BOOKING_DETAIL_SUCCESS: 
            return {
                updateBookingRecordLoading: false,
                updateBookingRecord: action.payload
            }
        case UPDATE_BOOKING_DETAIL_FAIL:
            return {
                updateBookingRecordLoading: false,
                updateBookingRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                updateBookingRecordError: null,
            }
            default:
            return state;
            
    }
}

export const deleteBookingDetailReducer = (state = { deleteBookingRecord: []}, action)=>{
    switch (action.type){
        case DELETE_BOOKING_DETAIL_REQUEST:
            return{
                deleteBookingRecordLoading: true,
                deleteBookingRecord: [],
            };
        case DELETE_BOOKING_DETAIL_SUCCESS: 
            return {
                deleteBookingRecordLoading: false,
                deleteBookingRecord: action.payload
            }
        case DELETE_BOOKING_DETAIL_FAIL:
            return {
                deleteBookingRecordLoading: false,
                deleteBookingRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                deleteBookingRecordError: null,
            }
            default:
            return state;
            
    }
}


export const postBookingReducer = (state = { postBooking: []}, action)=>{
    switch (action.type){
        case POST_BOOKING_REQUEST:
            return{
                postBookingLoading: true,
                postBooking: [],
            };
        case POST_BOOKING_SUCCESS: 
            return {
                postBookingLoading: false,
                postBooking: action.payload
            }
        case POST_BOOKING_FAIL:
            return {
                postBookingLoading: false,
                postBookingError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                postBookingError: null,
            }
            default:
            return state;
            
    }
}

export const getBookingOnDatesReducer = (state = { bookingOnDates: []}, action)=>{
    switch (action.type){
        case GET_BOOKING_ONDATES_REQUEST:
            return{
                bookingOnDatesLoading: true,
                bookingOnDates: [],
            };
        case GET_BOOKING_ONDATES_SUCCESS: 
            return {
                bookingOnDatesLoading: false,
                bookingOnDates: action.payload
            }
        case GET_BOOKING_ONDATES_FAIL:
            return {
                bookingOnDatesLoading: false,
                bookingOnDatesError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                bookingOnDatesError: null,
            }
            default:
            return state;
            
    }
}


export const getBookingOnBranchReducer = (state = { bookingOnBranch: []}, action)=>{
    switch (action.type){
        case GET_BOOKING_ONSHOP_REQUEST:
            return{
                bookingOnBranchLoading: true,
                bookingOnBranch: [],
            };
        case GET_BOOKING_ONSHOP_SUCCESS: 
            return {
                bookingOnBranchLoading: false,
                bookingOnBranch: action.payload
            }
        case GET_BOOKING_ONSHOP_FAIL:
            return {
                bookingOnBranchLoading: false,
                bookingOnBranchError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                bookingOnBranchError: null,
            }
            default:
            return state;
            
    }
}



export const getBookingsOnDatesForBranchReducer = (state = { bookingsOnDatesForBranch: []}, action)=>{
    switch (action.type){
        case GET_BOOKING_ONDATES_FORBRANCH_REQUEST:
            return{
                bookingsOnDatesForBranchLoading: true,
                bookingsOnDatesForBranch: [],
            };
        case GET_BOOKING_ONDATES_FORBRANCH_SUCCESS: 
            return {
                bookingsOnDatesForBranchLoading: false,
                bookingsOnDatesForBranch: action.payload
            }
        case GET_BOOKING_ONDATES_FORBRANCH_FAIL:
            return {
                bookingsOnDatesForBranchLoading: false,
                bookingsOnDatesForBranchError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                bookingsOnDatesForBranchError: null,
            }
            default:
            return state;
            
    }
}

