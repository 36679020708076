export const POST_BOOKING_REQUEST = "POST_BOOKING_REQUEST"
export const POST_BOOKING_SUCCESS = "POST_BOOKING_SUCCESS"
export const POST_BOOKING_FAIL = "POST_BOOKING_FAIL"

export const GET_BOOKING_ONSHOP_REQUEST = "GET_BOOKING_ONSHOP_REQUEST"
export const GET_BOOKING_ONSHOP_SUCCESS = "GET_BOOKING_ONSHOP_SUCCESS"
export const GET_BOOKING_ONSHOP_FAIL = "GET_BOOKING_ONSHOP_FAIL"

export const GET_BOOKING_REQUEST = "GET_BOOKING_REQUEST"
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS"
export const GET_BOOKING_FAIL = "GET_BRANCH_FAIL"

export const GET_BOOKING_ONDATES_REQUEST = "GET_BOOKING_ONDATES_REQUEST"
export const GET_BOOKING_ONDATES_SUCCESS = "GET_BOOKING_ONDATES_SUCCESS"
export const GET_BOOKING_ONDATES_FAIL = "GET_BOOKING_ONDATES_FAIL"

export const GET_BOOKING_ONDATES_FORBRANCH_REQUEST = "GET_BOOKING_ONDATES_FORBRANCH_REQUEST"
export const GET_BOOKING_ONDATES_FORBRANCH_SUCCESS = "GET_BOOKING_ONDATES_FORBRANCH_SUCCESS"
export const GET_BOOKING_ONDATES_FORBRANCH_FAIL = "GET_BOOKING_ONDATES_FORBRANCH_FAIL"

export const GET_BOOKING_DETAIL_REQUEST = "GET_BOOKING_DETAIL_REQUEST"
export const GET_BOOKING_DETAIL_SUCCESS = "GET_BOOKING_DETAIL_SUCCESS"
export const GET_BOOKING_DETAIL_FAIL = "GET_BOOKING_DETAIL_FAIL"

export const UPDATE_BOOKING_DETAIL_REQUEST = "UPDATE_BOOKING_DETAIL_REQUEST"
export const UPDATE_BOOKING_DETAIL_SUCCESS = "UPDATE_BOOKING_DETAIL_SUCCESS"
export const UPDATE_BOOKING_DETAIL_FAIL = "UPDATE_BOOKING_DETAIL_FAIL"


export const DELETE_BOOKING_DETAIL_REQUEST = "DELETE_BOOKING_DETAIL_REQUEST"
export const DELETE_BOOKING_DETAIL_SUCCESS = "DELETE_BOOKING_DETAIL_SUCCESS"
export const DELETE_BOOKING_DETAIL_FAIL = "DELETE_BOOKING_DETAIL_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"