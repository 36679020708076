import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Redirect, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import swal from "sweetalert2";
const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const navigate = useNavigate();
  const { isAuthenticated, loading, user, error } = useSelector((state) => state.user);
  const userRoles = user?.user?.role?.roleName;
  const isLogin = localStorage.getItem('loggedIn')
  const isAuthorized = isAuthenticated && allowedRoles?.includes(userRoles);

  useEffect(()=>{
    console.log(Component)
    console.log(allowedRoles)
    console.log(isAuthorized)
    console.log(!loading)
  },[Component])
  const SessionTimeOut = () =>{
    // console.log(JSON.parse(localStorage.getItem("loggedIn")))
    // swal
    // .fire({
    //   icon: "Alert",
    //   title: t("titleOops"),
    //   text: t("sessionMessage"),
     
    //   confirmButtonText: t("confirmokButton"),
    //   customClass: {
    //     popup: "custom-swal-popup", // This is the custom class you're adding
    //   },
    // })
    // .then(async (result) => {
    //   if(result.value){
    //     navigate("/login")
    //   }
    // })
  }

  return (
    <Fragment>
      {
              isAuthorized && !loading ? (
                  <>
                 
                    <Routes>
                      <Route
                        {...rest}
                        render={(props) => {
                            console.log(props)
                        //   return <Component {...props} />;
                        }}
                      />
                    </Routes>
                  </>)
                 :(<>{(error?.data === "Please login to acces this resource" && JSON.parse(localStorage.getItem("loggedIn"))) && SessionTimeOut()}</>)}

    </Fragment>
  );
};

export default ProtectedRoute;
