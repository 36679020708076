import { GET_EXPENSE_DASHBOARD_FORBRANCH_FAIL, GET_EXPENSE_DASHBOARD_FORBRANCH_REQUEST, GET_EXPENSE_DASHBOARD_FORBRANCH_SUCCESS, GET_EXPENSE_DETAIL_FAIL, GET_EXPENSE_DETAIL_REQUEST, GET_EXPENSE_DETAIL_SUCCESS, GET_EXPENSE_FAIL, GET_EXPENSE_ONBRANCH_FAIL, GET_EXPENSE_ONBRANCH_REQUEST, GET_EXPENSE_ONBRANCH_SUCCESS, GET_EXPENSE_ONDATES_FAIL, GET_EXPENSE_ONDATES_FORBRANCH_FAIL, GET_EXPENSE_ONDATES_FORBRANCH_REQUEST, GET_EXPENSE_ONDATES_FORBRANCH_SUCCESS, GET_EXPENSE_ONDATES_REQUEST, GET_EXPENSE_ONDATES_SUCCESS, GET_EXPENSE_REQUEST, GET_EXPENSE_SUCCESS, POST_EXPENSE_FAIL, POST_EXPENSE_REQUEST, POST_EXPENSE_SUCCESS } from "../Constants/expenseConstants";
import axiosInstance from "./baseURL";

export const postExpenseRecord = (expenseCategory, expenseLocation, expenseEnteredBy,  expense, expenseTotal) => async (dispatch) => {
    try {
      dispatch({ type: POST_EXPENSE_REQUEST });
      console.log(expense)
      const config = {headers: {"Content-Type": "application/json"}}
      const { data } = await axiosInstance.post(`/api/v1/createExpense`, {expenseCategory, expenseLocation, expenseEnteredBy,  expense, expenseTotal}, config);
      dispatch({
        type: POST_EXPENSE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_EXPENSE_FAIL,
        payload: error.response,
      });
    }
  };

  export const getExpenseRecord = () => async (dispatch) => {
    try {
      dispatch({ type: GET_EXPENSE_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getExpense`);
      dispatch({
        type: GET_EXPENSE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EXPENSE_FAIL,
        payload: error.response,
      });
    }
  };

  export const getExpenseRecordOnBranch = (branchCode) => async (dispatch) => {
    try {
      dispatch({ type: GET_EXPENSE_ONBRANCH_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getExpenseRecordOnBranch/${branchCode}`);
      dispatch({
        type: GET_EXPENSE_ONBRANCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EXPENSE_ONBRANCH_FAIL,
        payload: error.response,
      });
    }
  };

  export const getExpenseDetail = (id) => async (dispatch) => {
    try {
      dispatch({ type: GET_EXPENSE_DETAIL_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getExpenseDetails/${id}`);
      dispatch({
        type: GET_EXPENSE_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EXPENSE_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };
  export const getExpenseRecordOnDates = (starting, ending) => async (dispatch) => {
    try {
      dispatch({ type: GET_EXPENSE_ONDATES_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getTotalExpenseOnDates?starting=${starting}&ending=${ending}`);
      dispatch({
        type: GET_EXPENSE_ONDATES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EXPENSE_ONDATES_FAIL,
        payload: error.response,
      });
    }
  };

  
  export const getExpenseOnDatesForBranch = (branch, starting, ending) => async (dispatch) => {
    try {
      dispatch({ type: GET_EXPENSE_ONDATES_FORBRANCH_REQUEST });
      console.log(branch, starting, ending)
      const { data } = await axiosInstance.get(`/api/v1/getTotalExpenseOnDatesForBranch/${branch}?starting=${starting}&ending=${ending}`);
      console.log(data)
      dispatch({
        type: GET_EXPENSE_ONDATES_FORBRANCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EXPENSE_ONDATES_FORBRANCH_FAIL,
        payload: error.response,
      });
    }
  };

  export const getExpenseDashboardRecord = (period) => async (dispatch) => {
    try {
      dispatch({ type: GET_EXPENSE_DASHBOARD_FORBRANCH_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getExpenseRecordForDashBoard?period=${period}`);
      dispatch({
        type: GET_EXPENSE_DASHBOARD_FORBRANCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EXPENSE_DASHBOARD_FORBRANCH_FAIL,
        payload: error.response,
      });
    }
  };

  export const getExpenseDashboardOnBranchRecord = (branchId, period) => async (dispatch) => {
    try {
      dispatch({ type: GET_EXPENSE_DASHBOARD_FORBRANCH_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getExpenseRecordForDashBoardOnBranch/${branchId}?period=${period}`);
      dispatch({
        type: GET_EXPENSE_DASHBOARD_FORBRANCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EXPENSE_DASHBOARD_FORBRANCH_FAIL,
        payload: error.response,
      });
    }
  };



