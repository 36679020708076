import axios from "axios"
import { POST_CUSTOMER_REQUEST, POST_CUSTOMER_SUCCESS, POST_CUSTOMER_FAIL, DELETE_CUSTOMER_DETAIL_REQUEST, DELETE_CUSTOMER_DETAIL_SUCCESS, DELETE_CUSTOMER_DETAIL_FAIL, UPDATE_CUSTOMER_DETAIL_REQUEST, UPDATE_CUSTOMER_DETAIL_SUCCESS, UPDATE_CUSTOMER_DETAIL_FAIL, GET_CUSTOMER_REQUEST, GET_CUSTOMER_FAIL, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_DETAIL_REQUEST, GET_CUSTOMER_DETAIL_SUCCESS, GET_CUSTOMER_DETAIL_FAIL } from "../Constants/customerConstants"
import axiosInstance from "./baseURL";

export const postCustomer =  (name, 
  address, 
  branch, 
  contactNo,
  email, 
  status) => async (dispatch) =>  {
    try {
      dispatch({ type: POST_CUSTOMER_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axiosInstance.post(
        `/api/v1/createStudent`,
        {
          name, 
          address,  
          branch, 
          contactNo, 
          email, 
          status
        }, 
        config
      );
      dispatch({ type: POST_CUSTOMER_SUCCESS,
        payload: data });
    } catch (error) {
      dispatch({ type: POST_CUSTOMER_FAIL,
        payload: error.response, });
    }
  };

  export const getCustomerRecords = () => async (dispatch) => {
    try {
      dispatch({ type:  GET_CUSTOMER_REQUEST});
      const { data } = await axiosInstance.get(`/api/v1/getStudents`);
      dispatch({
        type: GET_CUSTOMER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOMER_FAIL,
        payload: error.response,
      });
    }
  };

  export const getCustomerRecordDetails = (id) => async (dispatch) => {
    try {
      dispatch({ type:  GET_CUSTOMER_DETAIL_REQUEST});
      const { data } = await axiosInstance.get(`/api/v1/getStudentDetail/${id}`);
      dispatch({
        type: GET_CUSTOMER_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOMER_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };


  export const deleteCustomerRecords = (id) => async (dispatch) => {
    try {
      dispatch({ type:  DELETE_CUSTOMER_DETAIL_REQUEST});
      console.log(id)
      const { data } = await axiosInstance.delete(`/api/v1/deleteStudent/${id}`);
      dispatch({
        type: DELETE_CUSTOMER_DETAIL_SUCCESS,
        payload: data,
      });
      dispatch({
        type: POST_CUSTOMER_SUCCESS,
        payload: [],
      })
    } catch (error) {
      dispatch({
        type: DELETE_CUSTOMER_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };

  export const deleteCustomerRecordOnForm = (id) => async (dispatch) => {
    try {
      dispatch({ type:  DELETE_CUSTOMER_DETAIL_REQUEST});
      console.log(id)
      const { data } = await axiosInstance.delete(`/api/v1/deleteStudentOnForm/${id}`);
      dispatch({
        type: DELETE_CUSTOMER_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_CUSTOMER_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };

  export const updateCustomerRecords = (id, status) => async (dispatch) => {
    try {
      dispatch({ type:  UPDATE_CUSTOMER_DETAIL_REQUEST});
      console.log(id)
      console.log(status)
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } =await axiosInstance.put(`/api/v1/editStudent/${id}`, {
        status
      },
      config);

      dispatch({
        type: UPDATE_CUSTOMER_DETAIL_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_CUSTOMER_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };


  export const updateCustomerRecordStatus = (id, status) => async (dispatch) => {
    try {
      dispatch({ type:  UPDATE_CUSTOMER_DETAIL_REQUEST});
      console.log(id)
      console.log(status)
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } =await axiosInstance.put(`/api/v1/editCustomerStatus/${id}`, {
        status
      },
      config);

      dispatch({
        type: UPDATE_CUSTOMER_DETAIL_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_CUSTOMER_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };

  export const updateCustomerRecordFull = (id, name, email, contactNo, address, branchValue) => async (dispatch) => {
    try {
      dispatch({ type:  UPDATE_CUSTOMER_DETAIL_REQUEST});
      console.log(id)
      console.log(name)
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } =await axiosInstance.put(`/api/v1/editStudent/${id}`, {
        name, email, contactNo, address, branchValue
      },
      config);

      dispatch({
        type: UPDATE_CUSTOMER_DETAIL_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_CUSTOMER_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };