import { useState } from "react";
import React, { useContext, useEffect } from "react";
import companyLogo from "../../images/companyLogo.jpg"
import { useSelector } from "react-redux";
import "./testBooking.css"
export default function Dates({ selectedPrinter }) {
    const {bookingDetailRecord, bookingDetailRecordLoading} = useSelector((state)=> state.bookingDetailRecord)

    const formattedDate = new Date(bookingDetailRecord?.booking?.createdAt).toLocaleDateString(
        "en-GB"
      )

    return (
    <>
        <div className="invoiceRecord">
            <div className="invoiceRecordFirstSection">
                <div>
                <h4>Student Name</h4>
                <h2>{bookingDetailRecord?.booking?.cust_id?.name}</h2>
                <h4>Test Name</h4>
                <h2>{bookingDetailRecord?.booking?.test_id?.testName}</h2>
                <h4>Test Module</h4>
                <h2>{bookingDetailRecord?.booking?.testType}</h2>
                </div>
            </div>
            <div className="invoiceRecordSecondSection">
            <div>
                <h4>Country</h4>
                <h2>{bookingDetailRecord?.booking?.testCountryName}</h2>
                <h4>Pakistan Date & Time</h4>
                <h2>{new Date(bookingDetailRecord?.booking?.pakistanDate).toLocaleDateString("en-GB")} {bookingDetailRecord?.booking?.pakistanTime}</h2>
                <h4>Test Country Date & Time</h4>
                <h2>{new Date(bookingDetailRecord?.booking?.otherCountryDate).toLocaleDateString("en-GB")} {bookingDetailRecord?.booking?.otherCountryTime}</h2>
                </div>
            </div>
        </div>
        <div className="invoiceNote">
            <h4>Note:&nbsp;</h4>
            <div> {bookingDetailRecord?.booking?.notes} </div>
        </div>
        <div className="footer">
            
        </div>
    </>
  );
}
