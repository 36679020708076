import { CLEAR_ERRORS, FORGOTPASSWORD_FAIL, FORGOTPASSWORD_REQUEST, FORGOTPASSWORD_SUCCESS, LOAD_USER_FAIL, LOAD_USER_REQUEST, LOAD_USER_SUCCESS, LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_FAIL, LOGOUT_REQUEST, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL, TOKEN_REFRESH_REQUEST, TOKEN_REFRESH_SUCCESS } from "../Constants/userConstants"

export const userReducer =(state = { user: {}}, action)=>{
    switch (action.type) {
        case LOGIN_REQUEST:
        case LOAD_USER_REQUEST:
        case TOKEN_REFRESH_REQUEST:
        return {
            loading: true,
            isAuthenticated: false
        }
        case LOGIN_SUCCESS: 
        case LOAD_USER_SUCCESS:
        return {
            ...state, 
            loading: false,
            isAuthenticated: true,
            user: action.payload,
        }
        case LOGOUT_SUCCESS:
        case LOAD_USER_FAIL:
            return {
                loading: false,
                isAuthenticated: false,
                user: null,
            }
        case TOKEN_REFRESH_SUCCESS: 
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
            }
        case TOKEN_REFRESH_FAIL: 
            return {
                loading: false,
                isAuthenticated: false,
                error: action.payload,
            }
        case LOGIN_FAIL: 
        return {
            ...state,
            loading: false,
            error: action.payload
        }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
           return state;
    }
}

export const logoutReducer =(state = { loggedOut: {}}, action)=>{
    switch (action.type) {
        case LOGOUT_REQUEST:
        return {
            logoutLoading: false,
            isUserAuthenticated: true,
            loggedOut: null
        }
        case LOGOUT_SUCCESS:
        return {
            logoutLoading: false,
            isUserAuthenticated: false,
            loggedOut: action.payload,
        }
         case LOGOUT_FAIL:
            return {
                ...state,
                logoutLoading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
           return state;
    }
}


export const forgotPasswordReducer =(state = { forgotPassword: {}}, action)=>{
    switch (action.type) {
        case FORGOTPASSWORD_REQUEST:
            return {
                loading: true,
                forgotPassword: []
            }
        case FORGOTPASSWORD_SUCCESS: 
        return {
            ...state, 
            loading: false,
            forgotPassword: action.payload,
        }
        case FORGOTPASSWORD_FAIL: 
        return {
            loading: false,
            error: action.payload,
        }
        
       
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
           return state;
    }
}