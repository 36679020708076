import React, { useEffect, useState, useRef } from "react";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import LaserPrintTable from "../../TableComponent/LaserPrintTable"
import { getCustomerVisaRecord, getCustomerVisaRecordOnBranch } from "../../../Actions/visaCustomerPaymentAction";
import MainTableComponent from "../../TableComponent/MainTableComponent";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../Loader/PageLoader";
import { VisaInvoiceDataSearch } from "../../SearchComponent/VisaInvoiceSearch";
import { getExpenseOnDatesForBranch, getExpenseRecord, getExpenseRecordOnBranch, getExpenseRecordOnDates } from "../../../Actions/expenseAction";
import "./expenseTable.css"
let name;
const ExpenseTableRecord = () => {
  const expenseRef = useRef();
    const dispatch = useDispatch()
    const [customerName, setCustomerName] = useState()
    const [colorTheme, setColorTheme] = useState()
    const navigate = useNavigate()
    const linkk = "PreviewExpenseRecord"
    const actionPreview = "Preview"
    const [startingDate, setStartingDate] = useState()
    const [endingDate, setEndingDate]= useState()
    const [tableData, setTableData] = useState()
    const [tableRecord, setTableRecord] = useState()
    const [loadingData, setLoadingData] = useState(true)
    const {user} = useSelector((state)=> state.user)
    const {expenseRecord, expenseRecordLoading, expenseRecordError} = useSelector((state)=> state.expenseRecord)
    const {expenseRecordOnBranch, expenseRecordOnBranchLoading} = useSelector((state)=> state.expenseRecordOnBranch)
    const {expenseOnDates, expenseOnDatesLoading} = useSelector((state)=> state.expenseOnDates)
    const {expenseOnDatesForBranch, expenseOnDatesForBranchLoading} = useSelector((state)=> state.expenseOnDatesForBranch)
    useEffect(()=>{
      if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
      {
        console.log('jfi')
        dispatch(getExpenseRecordOnBranch(user?.user?.branch?.branchCode))
      }else{
        console.log('ajfei')
        dispatch(getExpenseRecord())
      }
    }, [user])

    useEffect(()=>{
      setColorTheme(localStorage.getItem('theme-color'))
    }, [localStorage.getItem('theme-color')])


    const AddExpenseRecord = async () => {
      navigate('/expense')
  }

  useEffect(()=>{
    if(expenseRecord?.success && !expenseRecordLoading)
    {
      setTableData(expenseRecord?.expenseData)
      setTableRecord(expenseRecord?.expenseData)
      setLoadingData(false)
    }

  }, [expenseRecord, expenseRecordLoading])

  
  useEffect(()=>{
    if(expenseRecordOnBranch?.success && !expenseRecordOnBranchLoading)
    {
      setTableData(expenseRecordOnBranch?.expenseData)
      setTableRecord(expenseRecordOnBranch?.expenseData)
      setLoadingData(false)
    }

  }, [expenseRecordOnBranch, expenseRecordOnBranchLoading])


  
  const handleSearch = async(customerName) =>{
    const data = await VisaInvoiceDataSearch(tableRecord, customerName)
    console.log(data)
    setTableData(data)
  }

  const handleStartingDate =(e) =>{
    setStartingDate(e.target.value)
    if(endingDate)
    {
      setLoadingData(true)
      if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
      {
        dispatch(getExpenseOnDatesForBranch(user?.user?.branch?.branchCode, e.target.value, endingDate))
      } 
      else 
      {
        dispatch(getExpenseRecordOnDates(e.target.value, endingDate))
      }
    }else{
      setStartingDate(e.target.value)
    }
    console.log(e.target.value)
  }

  const handleEndingDate = (e) => {
    setEndingDate(e.target.value)
    if(!startingDate)
    {
      return alert.error('Please Select Starting Date')
    }
    setLoadingData(true)
    if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
    {
      dispatch(getExpenseOnDatesForBranch(user?.user?.branch?.branchCode, startingDate, e.target.value))
    } 
    else 
    {
      dispatch(getExpenseRecordOnDates(startingDate, e.target.value))
    }
  }

  useEffect(()=>{
    if(!expenseOnDatesLoading && expenseOnDates?.success)
    {
      setTableData(expenseOnDates?.studentData)
      setTableRecord(expenseOnDates?.studentData)
      setLoadingData(false)
    }
  }, [expenseOnDates, expenseOnDatesLoading])

  useEffect(()=>{
    if(!expenseOnDatesForBranchLoading && expenseOnDatesForBranch?.success)
    {
      setTableData(expenseOnDatesForBranch?.studentData)
      setTableRecord(expenseOnDatesForBranch?.studentData)
      setLoadingData(false)
    }
  }, [expenseOnDatesForBranch, expenseOnDatesForBranchLoading])


  const clear = () =>{
    setLoadingData(true)
    if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
    {
      console.log('jfi')
      dispatch(getExpenseRecordOnBranch(user?.user?.branch?.branchCode))
    }else{
      console.log('ajfei')
      dispatch(getExpenseRecord())
    }
   
    setCustomerName('')
    setStartingDate('')
    setEndingDate('')
  }

    const columns = [
        { field: "invoiceNumber", label: "Invoice Number" },
        { field: "expenseLocation", label: "Expense Location" },
        { field: "expenseCategory", label: "Category" },
        { field: "expenseEnteredBy", label: "Entered By" },
        { field: "createdAt", label: "Date", format: "date" },
        { field: "createdAt", label: "Time", format: "time" }

      ];
    
      const actions = [
        // {
        //   label: "Preview",
        //   color: "yellow",
        //   handler: (itemId) => p(itemId),
        // },
        {
          label: "Preview",
          color: "green",
          url: (itemId) => `/PreviewExpenseRecord/${itemId}`,
        },
      ];

    return(<>
     <div className={`expenseRecordTable ${colorTheme}`}>
        <div className={`expenseRecord`}>
        <div className="heading">
            <h3>{"Expense Record"}</h3> 
            <button onClick={AddExpenseRecord}>
              Add Expense
            </button>
            </div>
    <div className="searchExpenseRecord-withouInputfields-box" >
    <div className="expenseRecordTableFirstInputDiv">
              <input
                label={"Enter Customer Name"}
                className="expenseRecordTableInputField"
                type="text"
                placeholder={"Enter Customer Name"}
                name="customerName"
                autoComplete="off"
                maxLength="40"
                required
                value={customerName}
                onChange={(e)=>{ 
                  name = e.target.value 
                  setCustomerName(e.target.value)
                  handleSearch(name)}}
              />
              <input
              label={"Address"}
              className="expenseRecordTableInputField"
              type="date"
              placeholder={"Enter Starting Date"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              value = {startingDate}
              onChange={handleStartingDate}
              />
                <input
              label={"Address"}
              className="expenseRecordTableInputField"
              type="date"
              placeholder={"Enter Ending Date"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              value = {endingDate}
              onChange={handleEndingDate}
              />
              <div className="searchButton">
                <button onClick={clear}>
                    Clear
                </button>
                <ReactToPrint
            trigger={() => (
              <button >
                Print / Download
              </button>
            )}
            content={() => expenseRef.current}
          />
            </div>
            </div>
    </div>
    <div className="expenseTable-container">
      {
        !loadingData ? (<>
         <MainTableComponent
                    data={tableData}
                    columns={columns}
                    actions={actions}
                    actionPreview={actionPreview}
                    linkk= {linkk}
                    // action3={action3}
                />
        </>) : (<>
        <PageLoader />
        </>)
      }
    <div className="printSection">
      <div ref={expenseRef} style={{overflow: "auto",}} className="p-5">
        <div  className="printPagePrev" style={{margin: "10px", border: "2px solid black", padding: "10px",}}>
        {
                !loadingData && (<LaserPrintTable
                     data={tableData}
                     columns={columns}
                />)
            }
      </div>
      </div>
      </div>
    </div>
    </div>
        </div>
    </>)
}
export default ExpenseTableRecord;