import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { PostTestRecord, getSpecificTestRecord, getTestRecord, updateTestRecord } from "../../../Actions/testAction";
import { getBranchRecord } from "../../../Actions/branchAction";
import {useNavigate, useParams}  from "react-router-dom"
import "./visas.css"
import { useAlert } from "react-alert";
import { getSpecificVisaRecord, updateVisaRecord, updateVisaRecordd } from "../../../Actions/visaAction";
import { UPDATE_SPECIFIC_VISA_SUCCESS } from "../../../Constants/visaConstants";
const UpdateVisaForm = () => {
  const dispatch = useDispatch()  
  const alert = useAlert()
  const [visaName, setVisaName] = useState()
  const [visaPrice, setVisaPrice] = useState()
  const [visaDescription, setVisaDescription] = useState()
  const params = useParams()
  const [colorTheme, setColorTheme] = useState()
  const {visaSpecificRecordloading, visaSpecificRecord } = useSelector((state)=> state.visaSpecificRecord)
  const {updateVisaRecord, updateVisaRecordLoading} = useSelector((state)=> state.updateVisaRecord)

  const navigate = useNavigate()

  const ShowTableRecord = async () => {
      navigate('/testRecordTable')
  }

  useEffect(()=>{
    if(!visaSpecificRecordloading && visaSpecificRecord)
    {
      setVisaName(visaSpecificRecord?.visaData?.visaName)
      setVisaPrice(visaSpecificRecord?.visaData?.visaPrice)
      setVisaDescription(visaSpecificRecord?.visaData?.visaDescription)
    }
  }, [visaSpecificRecordloading, visaSpecificRecord])

  useEffect(()=>{
    dispatch(getSpecificVisaRecord(params.id))
  }, [])

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  const updateRecord = () =>{
        dispatch(updateVisaRecordd(params.id, visaName, visaPrice, visaDescription))
        
    }


    useEffect(()=>{
      if(updateVisaRecord?.success && !updateVisaRecordLoading){
        updateTestSuccessMessage()
      }
  console.log(updateVisaRecord)
    }, [updateVisaRecord, updateVisaRecordLoading])
  
  
    const updateTestSuccessMessage =() => {
      dispatch({
        type: UPDATE_SPECIFIC_VISA_SUCCESS,
        payload: [],
      });
      navigate("/visaRecordTable")
      return alert.success('Record Updated Successfully')
    }
  const clearRecord = ()=>{
        setVisaName('')
        setVisaPrice('')
        setVisaDescription('')
    }

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`visaMain ${colorTheme}`}>
        <div className={`visa`}>
          <div className="visaSecond">
            <div className="heading">
            <h3 >{"Update Visa"}</h3> 
            </div>
            <div className="visaInputDiv">
            <div className="labeAndInputDiv">
                  <label className="labelDiv">visa Name <span>*</span></label>
                  <input
                      label={"productCode"}
                      className="inputField"
                      type="text"
                      placeholder={"Enter Test Name"}
                      name="productCode"
                      autoComplete="off"
                      maxLength="40"
                      required
                      value={visaName}
                      onChange={(e)=> setVisaName(e.target.value)}
                      />
                      </div>
                  <div className="labeAndInputDiv">
                    <label className="labelDiv">visa Price <span>*</span></label>
                  <input
                      label={"productCode"}
                      className="inputField"
                      type="Number"
                      placeholder={"Enter Test Name"}
                      name="productCode"
                      autoComplete="off"
                      maxLength="40"
                      required
                      value={visaPrice}
                      onChange={(e)=> setVisaPrice(e.target.value)}
                      />
                       </div>
                  <div className="labeAndInputDiv">
                  <label className="labelDiv">Type Description <span>*</span></label>
                  <input
                      className="inputField"
                      type="text"
                      placeholder={"Enter Test Description"}
                      name="productCode"
                      autoComplete="off"
                      maxLength="150"
                      required
                      value={visaDescription}
                      onChange={(e)=> setVisaDescription(e.target.value)}
                  />
                  </div>
              </div>
                <div className="buttons">
                <button onClick={updateRecord}>Update Record</button>
                <button onClick={clearRecord}>Clear</button>
                </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateVisaForm;
