import { Sidebar } from "./sidebar/Sidebar";
import { Navbar } from "./navbar/Navbar";
import { useEffect, useState } from "react";
import { UseDispatch, useDispatch, useSelector } from "react-redux";
import { getAssignedTaskOnRole } from "../../../Actions/assignedTaskAction";
export const Concept = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch()
  const {user, isAuthenticated, loading} = useSelector((state)=> state.user)
  const {assignedTask}= useSelector((state)=> state.assignedTask)
  useEffect(()=>{
    dispatch(getAssignedTaskOnRole(JSON.parse(localStorage.getItem("userRoleId"))))
  }, [isAuthenticated, JSON.parse(localStorage.getItem("userRoleId"))])

  return (
    <>
      <div
        onClick={() => setIsOpen(false)}
        className={`overlay ${isOpen ? "open" : ""}`}
      />
      {
        (isAuthenticated && assignedTask) && (<>
          <Navbar setIsOpen={setIsOpen} />
          <Sidebar setIsOpen={setIsOpen} isOpen={isOpen} props={{
        name: user?.user?.name,
        permissions: assignedTask?.taskNamesArray,
      }}/></>) 
      }
    
    </>
  );
};
