import { POST_BOOKING_SUCCESS, POST_BOOKING_REQUEST, POST_BOOKING_FAIL, GET_BOOKING_SUCCESS, GET_BOOKING_REQUEST, GET_BOOKING_FAIL, UPDATE_BOOKING_DETAIL_REQUEST, UPDATE_BOOKING_DETAIL_SUCCESS, UPDATE_BOOKING_DETAIL_FAIL, DELETE_BOOKING_DETAIL_REQUEST, DELETE_BOOKING_DETAIL_SUCCESS, DELETE_BOOKING_DETAIL_FAIL, GET_BOOKING_DETAIL_REQUEST, GET_BOOKING_DETAIL_SUCCESS, GET_BOOKING_DETAIL_FAIL, GET_BOOKING_ONDATES_REQUEST, GET_BOOKING_ONDATES_SUCCESS, GET_BOOKING_ONDATES_FAIL, GET_BOOKING_ONDATES_FORBRANCH_REQUEST, GET_BOOKING_ONDATES_FORBRANCH_SUCCESS, GET_BOOKING_ONDATES_FORBRANCH_FAIL, GET_BOOKING_ONSHOP_REQUEST, GET_BOOKING_ONSHOP_SUCCESS, GET_BOOKING_ONSHOP_FAIL,  } from "../Constants/bookingConstants"
import axiosInstance from "./baseURL"
export const PostTestBookingRecord = (cust_id, branch_id, test_id, testType, pakistanDate, pakistanTime, testCountryName, otherCountryDate, otherCountryTime, notes) => async (dispatch) => {
    try {
      dispatch({ type: POST_BOOKING_REQUEST });
      const config = {headers: {"Content-Type": "application/json"}}
      const { data } = await axiosInstance.post(`/api/v1/createBooking`, 
      {cust_id, branch_id, test_id, testType, pakistanDate, pakistanTime, testCountryName, otherCountryDate, otherCountryTime, notes},
       config);
      dispatch({
        type: POST_BOOKING_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_BOOKING_FAIL,
        payload: error.response,
      });
    }
  };

  export const getBookingRecord = () => async (dispatch) => {
    try {
      dispatch({ type: GET_BOOKING_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getBookings`);
      dispatch({
        type: GET_BOOKING_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BOOKING_FAIL,
        payload: error.response,
      });
    }
  };

  export const getBookingRecordDetail = (id) => async (dispatch) => {
    try {
      dispatch({ type: GET_BOOKING_DETAIL_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getBookingDetails/${id}`);
      dispatch({
        type: GET_BOOKING_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BOOKING_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };

  export const getBookingOnBranch = (branchCode) => async (dispatch) => {
    try {
      dispatch({ type: GET_BOOKING_ONSHOP_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getBookingRecordOnBranchCode/${branchCode}`);
      dispatch({
        type: GET_BOOKING_ONSHOP_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BOOKING_ONSHOP_FAIL,
        payload: error.response,
      });
    }
  };


  export const updateBookingRecordd = (id, cust_id, branch_id, test_id, testType, pakistanDate, pakistanTime, testCountryName, otherCountryDate, otherCountryTime) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_BOOKING_DETAIL_REQUEST });
      console.log(cust_id)
      console.log(pakistanTime, otherCountryTime)
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } =await axiosInstance.put(`/api/v1/editBooking/${id}`, {
        cust_id, branch_id, test_id, testType, pakistanDate, pakistanTime, testCountryName, otherCountryDate, otherCountryTime
      },
      config);
      console.log(data)
      dispatch({
        type: UPDATE_BOOKING_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_BOOKING_DETAIL_FAIL,
        payload: error.response.data,
      });
    }
  };


  export const getBookingsRecordOnDates = (starting, ending) => async (dispatch) => {
    try {
      dispatch({ type: GET_BOOKING_ONDATES_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getTotalTestBookingsOnDates?starting=${starting}&ending=${ending}`);
      dispatch({
        type: GET_BOOKING_ONDATES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BOOKING_ONDATES_FAIL,
        payload: error.response,
      });
    }
  };

  export const getBookingsRecordOnDatesForBranch = (branch, starting, ending) => async (dispatch) => {
    try {
      dispatch({ type: GET_BOOKING_ONDATES_FORBRANCH_REQUEST });
      console.log(branch, starting, ending)
      const { data } = await axiosInstance.get(`/api/v1/getTotalTestBookingsOnDatesForBranch/${branch}?starting=${starting}&ending=${ending}`);
      console.log(data)
      dispatch({
        type: GET_BOOKING_ONDATES_FORBRANCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BOOKING_ONDATES_FORBRANCH_FAIL,
        payload: error.response,
      });
    }
  };



  export const deleteBookingRecords = (id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_BOOKING_DETAIL_REQUEST });
      const { data } = await axiosInstance.delete(`/api/v1/deleteBooking/${id}`);
      dispatch({
        type: DELETE_BOOKING_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_BOOKING_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };