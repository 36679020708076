import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { PostTestRecord, deleteVisaRecord, getVisaRecord } from "../../../Actions/visaAction";
import { getBranchRecord } from "../../../Actions/branchAction";
import {useNavigate}  from "react-router-dom"
import "./visas.css"
import MainTableComponent from "../../TableComponent/MainTableComponent";
import swal from "sweetalert2";
import { useAlert } from "react-alert";
import { DELETE_SPECIFIC_VISA_FAIL, DELETE_SPECIFIC_VISA_SUCCESS } from "../../../Constants/visaConstants";
const VisaRecordTable = () => {
  const dispatch = useDispatch()  
  const [colorTheme, setColorTheme] = useState()
  const alert = useAlert()
  const {visaRecord, visaRecordloading}= useSelector((state)=> state.visaRecord)
  const {deleteVisaLoading, deleteVisa, error} = useSelector((state)=> state.deleteVisa)
  const {updateTestRecordloading, updateTestRecord} = useSelector((state)=> state.updateTestRecord)
  const linkk = "updateVisaRecord";
  const actionUpdate = "Update";
  const action3 = "Delete";

  const navigate = useNavigate()

  const ShowTableRecord = async () => {
      navigate('/visaForm')
  }

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  useEffect(()=>{
    dispatch(getVisaRecord())
  }, [])

  useEffect(()=>{
    if(!deleteVisaLoading && deleteVisa?.success)
    {
      visaDeleteSuccessMessage()
    }

  }, [deleteVisa, deleteVisaLoading])

  useEffect(()=>{
    if(!deleteVisaLoading && error?.data)
    {
      visaDeleteErrorMessage(error?.data?.message)
    }
  }, [error, deleteVisaLoading])


  const visaDeleteSuccessMessage = () =>{
    dispatch({
      type: DELETE_SPECIFIC_VISA_SUCCESS,
      payload: error.response,
    });
    return alert.success("Record Deleted Successfully")
  }

  const visaDeleteErrorMessage = (message) =>{
    dispatch({
      type: DELETE_SPECIFIC_VISA_FAIL,
      payload: [],
    });
    return alert.error(message)
  }
  const deleteProduct = async (id) => {
    swal
    .fire({
      icon: "warning",
      title: "Warning",
      text: "Are You Sure You Want to Delete",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        popup: "custom-swal-popup", // This is the custom class you're adding
      },
    })
    .then(async (result) => {
      if (result.value) {
        console.log('fei')
        dispatch(deleteVisaRecord(id))
      }})
  };


    const columns = [
        { field: "visaName", label: "Visa Name" },
        { field: "visaPrice", label: "Visa Price" },
        { field: "visaDescription", label: "Visa Description" },
      ];
    
      const actions = [
        {
          label: "Delete",
          color: "yellow",
          handler: (itemId) => deleteProduct(itemId),
        },
        {
          label: "Update",
          color: "green",
          url: (itemId) => `/updateVisaRecord/${itemId}`,
        },
      ];
    

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`visaMain ${colorTheme}`}>
        <div className="visaTable">
        <div className="heading">
          <h3>{"Add Visa Type"}</h3> 
          <button onClick={ShowTableRecord}>
            Add Visa
          </button>
          </div>
        <div className="visaSearch-withInputfields-box " >
    <div className="visaTableFirstInputDiv">
              <input
                label={"Enter Customer Name"}
                className="visaTableInputField"
                type="text"
                placeholder={"Enter Customer Name"}
                name="customerName"
                autoComplete="off"
                maxLength="40"
                required
            
              />
              <input
              label={"Contact No"}
              type="Number"
              className="visaTableInputField"
              placeholder={"Enter Contact No"}
              name="contactNo"
              autoComplete="off"
              maxLength="40"
              required
              />
              <input
              label={"Address"}
              className="visaTableInputField"
              type="text"
              placeholder={"Enter Address"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              />
            </div>
          </div>
            <div className="visaTable-container">
            {!visaRecordloading ? (
                <MainTableComponent
                    data={visaRecord?.visaData}
                    columns={columns}
                    actions={actions}
                    actionUpdate={actionUpdate}
                    linkk= {linkk}
                    action3={action3}
                />
             ) : (<></>
            //     // <Loader active>Loading</Loader>
             )} 
            </div>
        </div>
    </div>
    </>
  );
};

export default VisaRecordTable;
