import React, { useEffect, useState, useRef } from "react";
import { UseDispatch, useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getSpecificCustomerVisaRecord, updateCustomerVisaRecord, updateCustomerVisaStatus } from "../../../Actions/visaCustomerPaymentAction";
import MainTableComponent from "../../TableComponent/MainTableComponent";
import "./customerStudent.css"
import ReactToPrint from "react-to-print";
import { useAlert } from "react-alert";
import Header from "./Header"
import LaserPrintTable from "../../TableComponent/LaserPrintTable"
import Dates from "./Dates"
import { getSpecificCustomerTestRecord, updateCustomerTestRecord, updateCustomerTestStatus } from "../../../Actions/testCustomerPaymentAction";
import { UPDATE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS, UPDATE_TESTCUSTOMERPAYMENT_STATUS_SUCCESS } from "../../../Constants/testCustomerPaymentConstants";
let legnthOfPrevData;
let payedAmount;
let remains;
let initialPayments;
const PreviewStudentRecord = () => {
    const dispatch = useDispatch()
    const statusDropdownRef = useRef(null);
    const params = useParams()
    const alert = useAlert()
    const testRef = useRef();
    const [colorTheme, setColorTheme] = useState()
    const {testCustomerPaymentDetailRecord, testCustomerPaymentDetailRecordLoading} = useSelector((state)=> state.testCustomerPaymentDetailRecord)
    const {updateTestCustomerPaymentStatus, updateTestCustomerPaymentStatusLoading, updateTestCustomerPaymentStatusError} = useSelector((state)=> state.updateTestCustomerPaymentStatus)
    const {updateTestCustomerPayment, updateTestCustomerPaymentLoading, updateTestCustomerPaymentError} = useSelector((state)=> state.updateTestCustomerPayment)
    const [payments, setPayments] = useState([]);
    const [isOptionSelectedOpen, setIsOptionSelectedOpen] = useState(false)
    const [isStatusSelectedOpen, setIsStatusSelectedOpen] = useState(false)
    const [recordUpdate, setRecordUpdate] = useState('paymentUpdate')
    const [selectedValue, setSelectedValue] = useState('Payments Update')
    const [paymentStatus, setPaymentStatus] = useState("Unpaid")
    const [statusUpdate, setStatusUpdate] = useState()
    const [selectedStatus, setSelectedStatus] = useState()
    const navigate = useNavigate()
    const statusOptions = [
        { key: "1", text: "Student", value: "Student" },
        { key: "2", text: "PassOut", value: "PassOut" },
      ];

      const Options = [
        { key: "1", text: "Payments Update", value: "paymentUpdate" },
        { key: "2", text: "Status Update", value: "statusUpdate" },
       
      ];

    const handleDownPaymentValue = (e) => {
        console.log(e)
        initialPayments = e.target.value
        console.log(testCustomerPaymentDetailRecord?.testCustomerPaymentData?.payments[legnthOfPrevData])
        const prevValue = testCustomerPaymentDetailRecord?.testCustomerPaymentData?.payments[legnthOfPrevData]
        payedAmount = parseFloat(prevValue?.paymentPayed) + parseFloat(e.target.value)
        const discount = testCustomerPaymentDetailRecord?.testCustomerPaymentData?.discount
        remains = (testCustomerPaymentDetailRecord?.testCustomerPaymentData?.totalPayment - discount) - payedAmount
        if(payedAmount > testCustomerPaymentDetailRecord?.testCustomerPaymentData?.totalPayment){
            return alert.error(`Payed Amount should less than Total Amount`)
          }

          if(remains < 0){
            initialPayments=0
            payedAmount=0
            remains=0
            return alert.error(`Payment Completed`)
          }
        console.log(payedAmount)
        console.log(remains)
        if(remains==0){
          console.log('fe')
          setPaymentStatus("Paid")
        }else{
          setPaymentStatus("Unpaid")
        }
        const newPayments = [
            ...testCustomerPaymentDetailRecord?.testCustomerPaymentData?.payments, // Copy previous array
            {
              downPayment: e.target.value, // Default value for down payment
              paymentPayed: payedAmount, // Default value for payment payed
              remaining: remains, // Default value for remaining payment
            }
          ];
          console.log(newPayments)
          setPayments(newPayments); // Update stat
        // setPayments(prevState => ({
        //     ...prevState, // Spread the previous state object
        //     downPayment: e.target.value, // Update the value of the specified field
        //     paymentPayed: payedAmount,
        //     remaining: remains
        //   }));
    }

    const updateCustomerTest= () => {
        dispatch(updateCustomerTestRecord(params.id, payments, paymentStatus))
    }
 
    
    const updateCustomerVisStatus= () => {
      dispatch(updateCustomerTestStatus(params.id, selectedStatus))
  }

  useEffect(()=> {
    if(!updateTestCustomerPaymentStatusLoading && updateTestCustomerPaymentStatus?.success)
    {
      console.log(updateTestCustomerPaymentStatus)
      updateSuccessMessage()
    }
  }, [updateTestCustomerPaymentStatus,updateTestCustomerPaymentStatusLoading])

  useEffect(()=> {
    if(!updateTestCustomerPaymentLoading && updateTestCustomerPayment?.success)
    {
      console.log(updateTestCustomerPayment)
      updateSuccessMessage()
    }
  }, [updateTestCustomerPaymentLoading,updateTestCustomerPayment])

  useEffect(()=> {
    if(!updateTestCustomerPaymentLoading && updateTestCustomerPaymentError)
    {
      updateErrorMessage(updateTestCustomerPaymentError)
    }
  }, [updateTestCustomerPaymentLoading,updateTestCustomerPaymentError])

  useEffect(()=> {
    if(!updateTestCustomerPaymentStatusLoading && updateTestCustomerPaymentStatusError)
    {
      updateErrorMessage(updateTestCustomerPaymentStatusError)
    }
  }, [updateTestCustomerPaymentStatusError,updateTestCustomerPaymentStatusLoading])

  const updateSuccessMessage =() => {
    initialPayments = ''
    payedAmount = ''
    remains = ''
    dispatch(getSpecificCustomerTestRecord(params.id))
    setSelectedStatus('')
    dispatch({
      type: UPDATE_TESTCUSTOMERPAYMENT_STATUS_SUCCESS,
      payload: [],
    });
    dispatch({
      type: UPDATE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS,
      payload: [],
    });
    return alert.success('Record Updated Successfully')
  }
  
  const updateErrorMessage = (message) => {
    return alert.error(message)
  }
  

      useEffect(()=>{
        setColorTheme(localStorage.getItem('theme-color'))
      }, [localStorage.getItem('theme-color')])

    useEffect(()=>{
        dispatch(getSpecificCustomerTestRecord(params.id))
    }, [])

    useEffect(()=>{
        if(!testCustomerPaymentDetailRecordLoading && testCustomerPaymentDetailRecord)
        {
        legnthOfPrevData = testCustomerPaymentDetailRecord?.testCustomerPaymentData?.payments.length - 1
        const initialPayments =testCustomerPaymentDetailRecord?.testCustomerPaymentData?.payments
        setPayments(initialPayments)
        console.log(legnthOfPrevData)
        }
    }, [testCustomerPaymentDetailRecordLoading, testCustomerPaymentDetailRecord])

    const optionToggleDropdown = () => {
      setIsOptionSelectedOpen(!isOptionSelectedOpen)
    }

    const statusOptionToggleDropdown = () => {
      setIsStatusSelectedOpen(!isStatusSelectedOpen)
    }

    const handleOptionSelection = (value, text) => {
      console.log(value)
      if(value.startsWith("p"))
      {
        console.log('p')
        setRecordUpdate(value)
        setStatusUpdate('')
        setSelectedValue(text)
        setIsOptionSelectedOpen(!isOptionSelectedOpen)
      }else{
        console.log('s')
        setStatusUpdate(value)
        setRecordUpdate('')
        setSelectedValue(text)
        setIsOptionSelectedOpen(!isOptionSelectedOpen)
      }
    }


    
    const handleStatusOptionSelection = (value) => {
      console.log(value)
      setIsStatusSelectedOpen(!isStatusSelectedOpen)
      setSelectedStatus(value)
    }

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
          console.log('fie')
          setIsOptionSelectedOpen(false);
        }
        console.log('clikced')
      };
      window.addEventListener('click', handleClickOutside);
      return () => {
        window.removeEventListener('click', handleClickOutside);
      };
    }, []); 
  
    const columns = [
        { field: "downPayment", label: "Amount Payed" },
         { field: "paymentPayed", label: "Total Payed Amount" },
        { field: "remaining", label: "Remaining" },
        {field: "createdAt", label: "Date", format: "date"},
        {field: "createdAt", label: "Time", format: "time"}
      ];
    return(
        <>
           <div className={`customerTestRecordTable ${colorTheme}`}>
        <div className={`customerRecordTest`}>
        <div className="previewDropdownDiv">
        <button onClick={()=> navigate('/customerRecordTest')}>
          Back
        </button>
        <div ref={statusDropdownRef} className="custom-dropdown">
                <div className="selected-option" onClick={optionToggleDropdown}>
                  { selectedValue || 'Select Category option'}
                </div>
                {isOptionSelectedOpen && (
                  <div className="options">
                    {Options?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleOptionSelection(option.value, option.text)}
                    >
                    {option?.text}
                    {/* {console.log(option)} */}
                    </div>
                    ))}
                </div>
                )}
              </div>
        </div>
        {
          statusUpdate ? (<>
          <div className="previewCustomerRecordTest-Inputfields-box">
           <div className="previewCustomerTestInputDiv">
           <div className="custom-dropdown">
                <div className="selected-option" onClick={statusOptionToggleDropdown}>
                  { selectedStatus || 'Select Category option'}
                </div>
                {isStatusSelectedOpen && (
                  <div className="options">
                    {statusOptions?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleStatusOptionSelection(option.value)}
                    >
                    {option?.text}
                    {/* {console.log(option)} */}
                    </div>
                    ))}
                </div>
                )}
              </div>
              <button className="customerStudentUpdateButton" onClick={updateCustomerVisStatus}>Update Status</button>
           </div>
          </div>
          </>) : (<>
          {
            recordUpdate && (<>
            <div className="previewCustomerRecordTest-Inputfields-box">
            <div className="previewCustomerTestInputDiv">
            <input
              label={"productCode"}
              type="Number"
              className="customerStudentInputField"
              placeholder={"Enter Initial Payment"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
            //   max={totalPayment}
              required
              value={initialPayments}
            //   value = {downPayment}
              onChange={handleDownPaymentValue}
              
                // (e)=> setInitialPayment(e.target.value)}
              />
             <input
              label={"totalPayment"}
              type="Number"
              className="customerStudentInputField"
              placeholder={"Enter Total Payment"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
              required
              value = {payedAmount}
              disabled
              />
                <input
              label={"productCode"}
              type="Number"
              className="customerStudentInputField"
              placeholder={"Enter Remaining Payment"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
             
              required
              value = {remains}
              disabled
              />
              <button className="customerStudentUpdateButton" onClick={updateCustomerTest}>Update Record</button>
            </div>
            </div>
            </>)
          }
         
          </>)
        }
      
        <div className="previewTable">
        <ReactToPrint
            trigger={() => (
              <button style={{padding: "10px", marginTop: "5px"}} >
                Print / Download
              </button>
            )}
            content={() => testRef.current}
          />
            <div ref={testRef} style={{overflow: "auto", height: "90%"}} className="p-5">
            <div  className="printPagePrev" style={{margin: "10px", border: "2px solid black", padding: "10px"}}>
              <Header />
              <Dates />
            {
                !testCustomerPaymentDetailRecordLoading && (<LaserPrintTable 
                     data={testCustomerPaymentDetailRecord?.testCustomerPaymentData?.payments}
                     columns={columns}
                />)
            }
            </div>
            </div>
             {/* {<p>Status: {testCustomerPaymentDetailRecord?.testCustomerPaymentData?.Status}</p>}
             {<p>Discount: {testCustomerPaymentDetailRecord?.testCustomerPaymentData?.discount}</p>}
            {<p>Total Payment: {testCustomerPaymentDetailRecord?.testCustomerPaymentData?.totalPayment}</p>}
                 */}
            </div>
        </div>
        </div>
      
        </>
    )
}
export default PreviewStudentRecord