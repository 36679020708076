import React, { useEffect,useRef, useState } from "react";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { getCustomerVisaRecord } from "../../../Actions/visaCustomerPaymentAction";
import MainTableComponent from "../../TableComponent/MainTableComponent";
import { getCustomerStudentRecord, getCustomerStudentRecordOnShop, getTestPaymentRecordOnDates, getTestPaymentRecordOnDatesForBranch } from "../../../Actions/testCustomerPaymentAction";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../customerRecord/customerTable.css"
import {useAlert} from "react-alert"
import PageLoader from "../../Loader/PageLoader"
import { TestInvoiceDataSearch } from "../../SearchComponent/TestInvoiceSearch";
import LaserPrintTable from "../../TableComponent/LaserPrintTable"
import { GET_TESTCUSTOMERPAYMENT_ONSHOP_SUCCESS, GET_TESTCUSTOMERPAYMENT_SUCCESS } from "../../../Constants/testCustomerPaymentConstants";
let name;
let test;
const CustomerStudentTable = () => {
  const testRef = useRef();
    const dispatch = useDispatch()
    const [customerName, setCustomerName] = useState()
    const [testName, setTestName] = useState()
    const [colorTheme, setColorTheme] = useState()
    const [tableData, setTableData] = useState()
    const [tableRecord, setTableRecord] = useState()
    const [startingDate, setStartingDate] = useState()
    const [endingDate, setEndingDate]= useState()
    const [loadingData, setLoadingData] = useState(true)
    const linkk = "PreviewTestRecord"
    const actionPreview = "Preview"
    const params = useParams()
    const alert = useAlert()
    const navigate = useNavigate()
    const {user} = useSelector((state)=> state.user)
    const {testCustomerPaymentRecord, testCustomerPaymentRecordLoading, testCustomerPaymentRecordError} = useSelector((state)=> state.testCustomerPaymentRecord)
    const {testCustomerPaymentRecordOnShop, testCustomerPaymentRecordOnShopLoading, testCustomerPaymentRecordOnShopError} = useSelector((state)=> state.testCustomerPaymentRecordOnShop)
    const {testCustomerPaymentOnDates, testCustomerPaymentOnDatesLoading}= useSelector((state)=>state.testCustomerPaymentOnDates)
    const {testCustomerPaymentOnDatesForBranch, testCustomerPaymentOnDatesForBranchLoading} = useSelector((state)=>state.testCustomerPaymentOnDatesForBranch)
    useEffect(()=>{
      if(user?.user?.role?.roleName === "superAdmin" || user?.user?.role?.roleName === "Administrator")
      {
        console.log('jfi')
        dispatch(getCustomerStudentRecord())
      }else{
        console.log('ajfei')
        dispatch(getCustomerStudentRecordOnShop(user?.user?.branch?.branchCode))
      }
       }, [user])

    useEffect(()=>{
      setColorTheme(localStorage.getItem('theme-color'))
    }, [localStorage.getItem('theme-color')])

    const AddCustomerRecord = async () => {
      navigate('/customerAdd')
  }

  useEffect(()=>{
    if(!testCustomerPaymentRecordLoading && testCustomerPaymentRecord?.success)
    {
      setTableData(testCustomerPaymentRecord?.testCustomerPaymentData)
      setTableRecord(testCustomerPaymentRecord?.testCustomerPaymentData)
      setLoadingData(false)
    }
  }, [testCustomerPaymentRecord, testCustomerPaymentRecordLoading])

  useEffect(()=>{
    if(!testCustomerPaymentRecordOnShopLoading && testCustomerPaymentRecordOnShop?.success)
    {
      setTableData(testCustomerPaymentRecordOnShop?.testPayments)
      setTableRecord(testCustomerPaymentRecordOnShop?.testPayments)
      setLoadingData(false)
    }
  }, [testCustomerPaymentRecordOnShop, testCustomerPaymentRecordOnShopLoading])

  useEffect(()=>{
    if(!testCustomerPaymentOnDatesForBranchLoading && testCustomerPaymentOnDatesForBranch?.success)
    {
      setTableData(testCustomerPaymentOnDatesForBranch?.studentData)
      setTableRecord(testCustomerPaymentOnDatesForBranch?.studentData)
      setLoadingData(false)
    }
  }, [testCustomerPaymentOnDatesForBranch, testCustomerPaymentOnDatesForBranchLoading])

  useEffect(()=>{
    if(!testCustomerPaymentOnDatesLoading && testCustomerPaymentOnDates?.success)
    {
      setTableData(testCustomerPaymentOnDates?.studentData)
      setTableRecord(testCustomerPaymentOnDates?.studentData)
      setLoadingData(false)
    }
  }, [testCustomerPaymentOnDates, testCustomerPaymentOnDatesLoading])

  const handleSearch = async(customer, test) =>{
    const data = await TestInvoiceDataSearch(tableRecord, customer, test)
    setTableData(data)
  }

  const handleStartingDate =(e) =>{
    setStartingDate(e.target.value)
    if(endingDate)
    {
      setLoadingData(true)
      if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
      {
        dispatch(getTestPaymentRecordOnDatesForBranch(user?.user?.branch?._id, e.target.value, endingDate))
      } 
      else 
      {
        dispatch(getTestPaymentRecordOnDates(e.target.value, endingDate))
      }
    }else{
      setStartingDate(e.target.value)
    }
    console.log(e.target.value)
  }

  const handleEndingDate = (e) => {
    setEndingDate(e.target.value)
    if(!startingDate)
    {
      return alert.error('Please Select Starting Date')
    }
    setLoadingData(true)
    if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
    {
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_SUCCESS,
        payload: [],
      });
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_ONSHOP_SUCCESS,
        payload: [],
      });
      dispatch(getTestPaymentRecordOnDatesForBranch(user?.user?.branch?._id, startingDate, e.target.value))
    } 
    else 
    {
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_SUCCESS,
        payload: [],
      });
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_ONSHOP_SUCCESS,
        payload: [],
      });
      dispatch(getTestPaymentRecordOnDates(startingDate, e.target.value))
    }
  }

  const clear = () =>{
    setLoadingData(true)
    if(user?.user?.role?.roleName === "superAdmin" || user?.user?.role?.roleName === "Administrator")
    {
      console.log('jfi')
      dispatch(getCustomerStudentRecord())
    }else{
      console.log('ajfei')
      dispatch(getCustomerStudentRecordOnShop(user?.user?.branch?.branchCode))
    }
    test = ''
    name = ''
    setTestName('')
    setCustomerName('')
    setStartingDate('')
    setEndingDate('')
  }



    const columns = [
        { field: "cust_id.name", label: "Customer Name" },
        { field: "branch_id.branchCode", label: "Branch" },
        { field: "test_id.testName", label: "Test" },
        { field: "cust_id.contactNo", label: "Contact NO" },
        { field: "cust_id.email", label: "Email" },
        { field: "totalPayment", label: "Total Payment" },
        { field: "Status", label: "Status" },
        { field: "paymentStatus", label: "Status" },
        { field: "createdAt", label: "Date", format: "date" },
        { field: "createdAt", label: "Time", format: "time" },

      ];
    
      const actions = [
        // {
        //   label: "Preview",
        //   color: "yellow",
        //   handler: (itemId) => p(itemId),
        // },
        {
          label: "Preview",
          color: "green",
          url: (itemId) => `/PreviewTestRecord/${itemId}`,
        },
      ];

    return(<>
      <div className={`customerTestRecordTable ${colorTheme}`}>
        <div className={`customerRecordTest`}>
    <div className="heading">
            <h3>{"Test Record"}</h3> 
            <button onClick={AddCustomerRecord}>
              Add Customer
            </button>
            </div>
    <div className="searchCustomerRecordTest-withouInputfields-box" >
    <div className="customerRecordTestTableFirstInputDiv">
              <input
                label={"Enter Customer Name"}
                className="customerRecordTestTableInputField"
                type="text"
                placeholder={"Enter Customer Name"}
                name="customerName"
                autoComplete="off"
                maxLength="40"
                required
                value={customerName}
                onChange={(e)=>{ name = e.target.value
                  setCustomerName(e.target.value)
                  handleSearch(name, test)
                }}
              />
              <input
              type="text"
              className="customerRecordTestTableInputField"
              placeholder={"Enter Test Name"}
              name="contactNo"
              autoComplete="off"
              maxLength="40"
              required
              value = {testName}
              onChange={(e)=>{ test = e.target.value
                setTestName(e.target.value)
                handleSearch(name, test)
              }}
              />
              <input
              label={"Address"}
              className="customerRecordTestTableInputField"
              type="date"
              placeholder={"Enter Starting Date"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              value = {startingDate}
              onChange={handleStartingDate}
              />
                <input
              label={"Address"}
              className="customerRecordTestTableInputField"
              type="date"
              placeholder={"Enter Ending Date"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              value = {endingDate}
              onChange={handleEndingDate}
              />
                <div className="searchButton">
                <button onClick={clear}>
                    Clear
                </button>
                <ReactToPrint
            trigger={() => (
              <button >
                Print / Download
              </button>
            )}
            content={() => testRef.current}
          />
            </div>
            </div>
          
    </div>
    <div className="customerTestTable-container">
      {
        !loadingData ? (<>
        <MainTableComponent
                    data={tableData}
                    columns={columns}
                    actions={actions}
                    actionPreview={actionPreview}
                    linkk= {linkk}
                    // action3={action3}
                />
        </>) : (<>
        <PageLoader />
        </>)
      }
      <div className="printSection">
      <div ref={testRef} style={{overflow: "auto",}} className="p-5">
        <div  className="printPagePrev" style={{margin: "10px", border: "2px solid black", padding: "10px",}}>
        {
                !loadingData && (<LaserPrintTable
                     data={tableData}
                     columns={columns}
                />)
            }
      </div>
      </div>
      </div>
    </div>
    </div>
        </div>
 
    </>)
}
export default CustomerStudentTable;