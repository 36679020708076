import React, { useContext, useState, useEffect } from "react";
export const TestInvoiceDataSearch = async (pr, customerName, testName) => {
  console.log(pr)
//   console.log(colorName)

  let Filtered= []

    Filtered = pr?.filter((data) => {
      //Filter by Products

      if (
        customerName &&
        !data?.cust_id?.name?.toString().toLowerCase()?.includes(customerName?.toString()?.toLowerCase())
      ) {
        return false;
      }

      if (
        testName &&
        !data?.test_id?.testName?.toString().toLowerCase()?.includes(testName?.toString()?.toLowerCase())
      ) {
        return false;
      }
      return true;
    });
  
  
  console.log(Filtered)
  return Filtered;
};
