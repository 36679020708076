// import { DELETE_EXPENSETYPE_DETAIL_FAIL, DELETE_EXPENSETYPE_DETAIL_REQUEST, DELETE_EXPENSETYPE_DETAIL_SUCCESS, GET_EXPENSETYPE_DETAIL_FAIL, GET_EXPENSETYPE_DETAIL_REQUEST, GET_EXPENSETYPE_DETAIL_SUCCESS, GET_EXPENSETYPE_FAIL, GET_EXPENSETYPE_REQUEST, GET_EXPENSETYPE_SUCCESS, POST_EXPENSETYPE_FAIL, POST_EXPENSETYPE_REQUEST, POST_EXPENSETYPE_SUCCESS, UPDATE_EXPENSETYPE_DETAIL_FAIL, UPDATE_EXPENSETYPE_DETAIL_REQUEST, UPDATE_EXPENSETYPE_DETAIL_SUCCESS } from "../Constants/expenseTypeConstants";
import axiosInstance from "./baseURL";
import {DELETE_ROLE_DETAIL_FAIL, DELETE_ROLE_DETAIL_REQUEST, DELETE_ROLE_DETAIL_SUCCESS, GET_ROLE_DETAIL_FAIL, GET_ROLE_DETAIL_REQUEST, GET_ROLE_DETAIL_SUCCESS, GET_ROLE_FAIL, GET_ROLE_REQUEST, GET_ROLE_SUCCESS, POST_ROLE_FAIL, POST_ROLE_REQUEST, POST_ROLE_SUCCESS, UPDATE_ROLE_DETAIL_FAIL, UPDATE_ROLE_DETAIL_REQUEST, UPDATE_ROLE_DETAIL_SUCCESS} from "../Constants/roleConstants"
export const PostRole = (roleName, roleDescription) => async (dispatch) => {
    try {
      dispatch({ type: POST_ROLE_REQUEST});
      const config = {headers: {"Content-Type": "application/json"}}
      const { data } = await axiosInstance.post(`/api/v1/createRole`, {roleName, roleDescription}, config);
      dispatch({
        type: POST_ROLE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_ROLE_FAIL,
        payload: error.response,
      });
    }
  };


  export const GetRoleRecord = () => async (dispatch) => {
    try {
      dispatch({ type: GET_ROLE_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getRole`);
      dispatch({
        type: GET_ROLE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ROLE_FAIL,
        payload: error.response,
      });
    }
  };

  export const getSpecificRoleRecord = (id) => async (dispatch) => {
    try {
      dispatch({ type: GET_ROLE_DETAIL_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getRoleDetails/${id}`);
      dispatch({
        type: GET_ROLE_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ROLE_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };

  export const updateRoleRecord = (id,  expenseType, expenseTypeDescription) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ROLE_DETAIL_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = axiosInstance.put(`/api/v1/editRole/${id}`, {
        expenseType, expenseTypeDescription
      },
      config);
      dispatch({
        type: UPDATE_ROLE_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ROLE_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };

  export const deleteRoleRecord = (id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_ROLE_DETAIL_REQUEST });
      const { data } = await axiosInstance.delete(`/api/v1/deleteRole/${id}`);
      dispatch({
        type: DELETE_ROLE_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_ROLE_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };