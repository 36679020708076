import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { Table, Button, Tab } from "semantic-ui-react";

import "./printTable.css"

const TableComponentId = ({
  data,
  columns,
  actions,

}) => {
  let lengthOfData = columns?.length;
  if (actions) {
    // let lengthOfAction = actions.length;
    // lengthOfData = lengthOfData + lengthOfAction;
    lengthOfData = lengthOfData;
  }

  // State variables for sorting
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const navigate = useNavigate();

  // Function to handle sorting
  const handleSort = (field) => {
    if (field === sortedColumn) {
      // If the clicked column is already sorted, toggle the direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a different column is clicked, set it as the sorted column with ascending direction
      setSortedColumn(field);
      setSortDirection("asc");
    }
  };

  let currentIndex = 0;
//   useEffect(() => {
//     setRowCount(data?.length);
//   }, [data]);

  const renderDateValue = (item, field, format) => {
    const value = renderCellValue(item, field);
    // console.log(value);

    //for converting bool value into string ..just for dispalying into table
    if (format === "bool") {
      return value && value.toLocaleString();
    }

    if (format === "time") {
      // console.log("time");
      return value ? new Date(value).toLocaleTimeString() : "";
    }
    if (format === "date") {
      // console.log("date");
      return value ? new Date(value).toLocaleDateString() : "";
    }

    return value;
  };
  const renderCellValue = (item, field) => {
    const fieldKeys = field.split(".");

    return fieldKeys?.reduce((obj, key) => (obj ? obj[key] : ""), item);
  };

  // Sorting logic
  const sortedData = data?.slice().sort((a, b) => {
    if (!sortedColumn) return 0;

    const sortOrder = sortDirection === "asc" ? 1 : -1;
    const aField = renderCellValue(a, sortedColumn);
    const bField = renderCellValue(b, sortedColumn);

    if (aField < bField) return -1 * sortOrder;
    if (aField > bField) return 1 * sortOrder;
    return 0;
  });

  return (
    <div
      style={{
        width: "100%",
        // overflowX: "auto",
        zIndex: "2",
        // height: "40vh",
      }}
    >
      {sortedData && sortedData?.length > 0 ? (
        <Table
          celled
          columns={lengthOfData}
          striped
          sortable
          selectable
          stickyHeader
          // className="bg-gray-100"
          style={{ margin: "0", zIndex: "2",borderSpacing: "0", width: "100%" }}
        >
          <Table.Header id="table-header"
            className="HeaderStyle"
           style={{ position: "sticky", top: 0, backgroundColor: "lightgray" , border: "1px solid black" }}
          >
            <Table.Row
              // className="font-bold text-center HeaderStyle"
              style={{
                backgroundColor: "lightgray",
                border: "1px solid black",
                textAlign: "center",
                padding: "0px",
                textSize: "14px",
              }}
              // style={styles.tableHeader}
            >
              <Table.HeaderCell
                className="Hello"
                style={{
                  backgroundColor: "lightgray",
                  padding: "10px",
                  width: "15px",
                  fontSize: "12px",
                  border: "1px solid black",
                }}
              >
                {"sNo"}
              </Table.HeaderCell>
              {columns?.map((column) => (
                <Table.HeaderCell
                  key={column.field}
                  className="HeaderStyle"
                  style={{
                    backgroundColor: "lightgray",
                    whiteSpace: "nowrap",
                    padding: "10px",
                    fontSize: "12px",
                    border: "1px solid black",
                  }}
                  onClick={() => handleSort(column.field)} // Attach the click event
                >
                  {/* Display sorting indicator based on the current sorting state */}
                  {column.label}
                  {sortedColumn === column.field && (
                    <span>{sortDirection === "asc" ? " ▲" : " ▼"}</span>
                  )}
                </Table.HeaderCell>
              ))}
              {actions && (
                <Table.HeaderCell className="HeaderStyle">
                  {"actions"}
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body id="table-body" className="text-center">
            {sortedData?.map((item, index) => (
              <Table.Row key={index} className="h-10">
                <Table.Cell
                  className="Hello"
                  style={{
                    width: "15px",
                    fontSize: "12px",
                    padding: "10px",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  {++currentIndex}
                </Table.Cell>
                {columns?.map((column) => (
                  <Table.Cell
                    key={column.Code}
                    className="Hello"
                    // style={{ textAlign: "left" }}
                    style={{
                      width: "15px",
                      padding: "10px",
                      fontSize: "12px",
                      textAlign: "center",
                      border: "1px solid black",
                    }}
                  >
                    {column.format === "date" ||
                    column.format === "time" ||
                    column.format === "bool"
                      ? renderDateValue(item, column.field, column.format)
                      : renderCellValue(item, column.field, column.format)}
                  </Table.Cell>
                ))}

              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <div>No record found.</div>
      )}
    </div>
  );
};

export default TableComponentId;
const styles = {
  printTable: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#f0f0f0",
    border: "1px solid black",
    textAlign: "center",
  },
  tableCell: {
    border: "1px solid #000",
    padding: "8px",
    textAlign: "center",
  },
};
