import { CLEAR_ERRORS, GET_TASK_FAIL, GET_TASK_REQUEST, GET_TASK_SUCCESS, POST_TASK_FAIL, POST_TASK_REQUEST, POST_TASK_SUCCESS } from "../Constants/TaskConstants";


export const postTaskReducer = (state = { postTask: []}, action)=>{
    switch (action.type){
        case POST_TASK_REQUEST:
            return{
                postTaskLoading: true,
                postTask: [],
            };
        case POST_TASK_SUCCESS: 
            return {
                postTaskLoading: false,
                postTask: action.payload
            }
        case POST_TASK_FAIL:
            return {
                postTaskLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const GetTaskReducer = (state = { taskRecord: []}, action)=>{
    switch (action.type){
        case GET_TASK_REQUEST:
            return{
                taskRecordLoading: true,
                taskRecordRecord: [],
            };
        case GET_TASK_SUCCESS: 
            return {
                taskRecordLoading: false,
                taskRecord: action.payload
            }
        case GET_TASK_FAIL:
            return {
                taskRecordLoading: false,
                taskRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                taskRecordError: null,
            }
            default:
            return state;
            
    }
}