
import React, { useContext,useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { useNavigate } from "react-router-dom";
import { getTestRecord } from "../../../Actions/testAction";
import { getBranchRecord } from "../../../Actions/branchAction";
import "./testBooking.css"
import {  getCustomerRecords,  } from "../../../Actions/customerAction";

import PageLoader from "../../Loader/PageLoader";
import { useAlert } from "react-alert";
import { PostTestBookingRecord, getBookingRecordDetail, updateBookingRecord, updateBookingRecordd } from "../../../Actions/testBookingsAction";
import { UPDATE_BOOKING_DETAIL_SUCCESS } from "../../../Constants/bookingConstants";

const UpdateTestBookingForm = () => {
const dispatch = useDispatch()  
const navigate = useNavigate()
const alert = useAlert()
const customerDropdownRef = useRef(null);
const branchDropdownRef = useRef(null)
const testTypeDropdownRef = useRef(null)
const testDropdownRef = useRef(null)
const params = useParams() 
// Test Booking Data
const [isCustomerOpen, setIsCustomerOpen] = useState(false)
const [customerName, setCustomerName] = useState()
const [customerValue, setCustomerValue] = useState()
const [selectedTestName, setSelectedTestName] = useState()
const [testValue, setTestValue]= useState()
const [isTestOpen, setIsTestOpen] = useState(false)
const [isBranchOpen, setIsBranchOpen] = useState(false)
const [branchCode, setBranchCode] = useState()
const [branch, setBranch] = useState()
const [isTestTypeOpen, setIsTestTypeOpen] = useState(false)
const [testType, setTestType] = useState() 
const [colorTheme, setColorTheme] = useState()
const [pakistanDate, setPakistanDate] = useState(null);
const [pakistanTime, setPakistanTime] = useState()
const [pakTimeValue, setPakTimeValue] = useState()
const [otherCountryTimeValue, setOtherCountryTimeValue] = useState()
const [testCountryName, setTestCountryName] = useState()
const [otherCountryDate, setOtherCountryDate] = useState()
const [otherCountryTime, setOtherCountryTime] = useState()
const {loading, testRecord} = useSelector((state)=> state.testRecord)
const {branchLoading, branchRecord} = useSelector((state)=> state.branchRecord)
const {customerRecord, customerRecordLoading} = useSelector((state)=> state.customerRecord)
const {updateBookingRecord, updateBookingRecordLoading, updateBookingRecordError} = useSelector((state)=> state.updateBookingRecord)
const {bookingDetailRecord, bookingDetailRecordLoading} = useSelector((state)=> state.bookingDetailRecord)
const {user} = useSelector((state)=> state.user)
const testTypeOptions = [
    { key: "1", text: "Reading", value: "Reading" },
    { key: "2", text: "Listening", value: "Listening" },
    { key: "3", text: "Writing", value: "Writing" },
    { key: "3", text: "Speaking", value: "Speaking" },
  ];

useEffect(()=>{
  dispatch(getBookingRecordDetail(params.id))
}, [])

useEffect(()=>{
  console.log(bookingDetailRecord)
  if(bookingDetailRecord?.success && !bookingDetailRecordLoading)
  {
    console.log(bookingDetailRecord)
    setCustomerValue(bookingDetailRecord?.booking?.cust_id?._id)
    setCustomerName(bookingDetailRecord?.booking?.cust_id?.name)
    setBranch(bookingDetailRecord?.booking?.branch_id?._id)
    setBranchCode(bookingDetailRecord?.booking?.branch_id?.branchCode)
    setTestValue(bookingDetailRecord?.booking?.test_id?._id)
    setSelectedTestName(bookingDetailRecord?.booking?.test_id?.testName)
    setTestType(bookingDetailRecord?.booking?.testType)
    setPakistanDate(bookingDetailRecord?.booking?.pakistanDate)
    setPakistanTime(bookingDetailRecord?.booking?.pakistanTime)
    setTestCountryName(bookingDetailRecord?.booking?.testCountryName)
  
  
    setOtherCountryDate(bookingDetailRecord?.booking?.otherCountryDate)
    setOtherCountryTime(bookingDetailRecord?.booking?.otherCountryTime)
    // customerValue, branch, testValue, testType, pakistanDate, pakistanTime, testCountryName, otherCountryDate, otherCountryTime
  }

}, [bookingDetailRecord,bookingDetailRecordLoading])

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])



useEffect(()=>{
    dispatch(getCustomerRecords())
    dispatch(getTestRecord())
    dispatch(getBranchRecord())
}, [])

const customerNameToggleDropdown  = () => {
    setIsCustomerOpen(!isCustomerOpen);
  };

  const testToggleDropdown = () => {
    setIsTestOpen(!isTestOpen);
  };


  const handleCustomerValue = (value, name) => {
    setCustomerValue(value)
    setCustomerName(name)
    setIsCustomerOpen(!isCustomerOpen);
  }

  const handleTestValue = (value, name) => {
    setTestValue(value)
    setSelectedTestName(name)
    setIsTestOpen(!isTestOpen)
  } 

  
  const branchToggleDropdown = () => {
    setIsBranchOpen(!isBranchOpen);
  };

  const handleBranchValue = (value, code) => {
    setBranchCode(code)
    setBranch(value)
    setIsBranchOpen(!isBranchOpen);
  }

  useEffect(()=>{
    if(user?.user?.role?.roleName !== "superAdmin" || user?.user?.role?.roleName !== "Administrator")
    {
    setBranchCode(user?.user?.branch?.branchCode)
    setBranch(user?.user?.branch?._id)
    }
  }, [])

    const testTypeToggleDropdown = () => {
        setIsTestTypeOpen(!isTestTypeOpen);
      };
      
    const handleTestTypeValue = (value) =>{
        setTestType(value)
        setIsTestTypeOpen(!isTestTypeOpen);
    }


      const ShowTableRecord = async () => {
        navigate('/testBookingTable')
    }

 

  const handlePakistanDateChange = (event) => {
    console.log(event)
    console.log(event.target.value)
    setPakistanDate(event.target.value);
    setOtherCountryDate(event.target.value)
  };
    
  const handlePakistanTimeChange = (event) => {
    setPakTimeValue(event.target.value)
    console.log(event)
    const inputTime = event.target.value;
    const [hour, minute] = inputTime.split(':');
    let formattedHour = parseInt(hour, 10);

    // Adjust hour to 12-hour format
    if (formattedHour >= 12) {
      formattedHour -= 12;
    }
    if (formattedHour === 0) {
      formattedHour = 12;
    }
    const period = hour >= 12 ? 'PM' : 'AM';

    const formattedTime = `${formattedHour}:${minute} ${period}`;
    console.log(formattedTime)
    setPakistanTime(formattedTime);
  };

  const handleOtherCountryDateChange = (event) => {
    setOtherCountryDate(event.target.value);
  };
    
  const handleOtherCountryTimeChange = (event) => {
    setOtherCountryTimeValue(event.target.value)
    console.log(event)
    const inputTime = event.target.value;
    const [hour, minute] = inputTime.split(':');
    let formattedHour = parseInt(hour, 10);

    // Adjust hour to 12-hour format
    if (formattedHour >= 12) {
      formattedHour -= 12;
    }
    if (formattedHour === 0) {
      formattedHour = 12;
    }
    const period = hour >= 12 ? 'PM' : 'AM';
    
    const formattedTime = `${formattedHour}:${minute} ${period}`;
    console.log(formattedTime)
    // setPakistanTime(formattedTime);
    setOtherCountryTime(formattedTime);
  };
    

    const ClearRecord = () => {
      
      };
      const AddBooking = () => {
        dispatch(updateBookingRecordd(params.id, customerValue, branch, testValue, testType, pakistanDate, pakistanTime, testCountryName, otherCountryDate, otherCountryTime))
      };

      useEffect(()=>{
        console.log(updateBookingRecord)
        if(updateBookingRecord?.success && !updateBookingRecordLoading)
        {
          updateBookingSuccessMessage()
        }
      }, [updateBookingRecord, updateBookingRecordLoading])

      useEffect(()=>{
        if(updateBookingRecordError?.data && !updateBookingRecordLoading)
        {
          updateBookingErrorMessage(updateBookingRecordError?.data?.message)
        }
      }, [updateBookingRecord, updateBookingRecordError])
      
      const updateBookingSuccessMessage =()=>{
        dispatch({
          type: UPDATE_BOOKING_DETAIL_SUCCESS,
          payload: [],
        });
        navigate("/testBookingTable")
        return alert.success("Record Added Successfully")
      }

      const updateBookingErrorMessage =(message)=>{
        return alert.error(message)
      }

      useEffect(()=>{
        const handleClickCustomerOutside = (event) => {
             if (customerDropdownRef.current && !customerDropdownRef.current.contains(event.target)) {
               setIsCustomerOpen(false);
             }
             console.log('clikced')
           };
           window.addEventListener('click', handleClickCustomerOutside);
           return () => {
             window.removeEventListener('click', handleClickCustomerOutside);
           };
         }, [])

      useEffect(()=>{
        const handleClickTestOutside = (event) => {
             if (testDropdownRef.current && !testDropdownRef.current.contains(event.target)) {
               setIsTestOpen(false);
             }
             console.log('clikced')
           };
           window.addEventListener('click', handleClickTestOutside);
           return () => {
             window.removeEventListener('click', handleClickTestOutside);
           };
         }, [])

         useEffect(()=>{
          const handleClickBranchOutside = (event) => {
               if (branchDropdownRef.current && !branchDropdownRef.current.contains(event.target)) {
                 setIsBranchOpen(false);
               }
               console.log('clikced')
             };
             window.addEventListener('click', handleClickBranchOutside);
             return () => {
               window.removeEventListener('click', handleClickBranchOutside);
             };
           }, [])

           useEffect(() => {
            const handleClickOutside = (event) => {
              if (testTypeDropdownRef.current && !testTypeDropdownRef.current.contains(event.target)) {
                console.log('fie')
                setIsTestTypeOpen(false);
              }
              console.log('clikced')
            };
            window.addEventListener('click', handleClickOutside);
            return () => {
              window.removeEventListener('click', handleClickOutside);
            };
          }, []); 

          const back = () => {
            navigate("/testBookingTable")
          }
        

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`testBookingMain ${colorTheme}`}>
      <div className={`testBooking`}>
        <div className="testBookingSecond">
          <div className="heading">
            <h3>{"Update Bookings"}</h3> 
          
            </div>
            {
              !updateBookingRecordLoading ? (<>
                      <div className="testBookingFirstInputDiv">
            <div className="labeAndInputDiv">
              <label>Customer Name <span>*</span></label>
              <div ref={customerDropdownRef} className="custom-dropdown">
                <div className="selected-option" 
                // onClick={customerNameToggleDropdown}
                >
                  {customerName || 'Select Status option'}
                </div>
                {isCustomerOpen && (
                  <div className="options">
                    {customerRecord?.student?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleCustomerValue(option?._id, option?.name)}
                    >
                    {option?.name}
                    </div>
                    ))}
                </div>
                )}
              </div>
            </div>
              <div className="labeAndInputDiv">
                <label>Test Name <span>*</span></label>
                <div ref={testDropdownRef} className="custom-dropdown">
                <div className="selected-option" onClick={testToggleDropdown}>
                  {selectedTestName || 'Select Test option'}
                </div>
                {isTestOpen && (
                  <div className="options">
                    {testRecord?.test?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleTestValue(option._id, option.testName)}
                    >
                    {option?.testName}
                    </div>
                    ))}
                </div>
                )}
              </div>
            </div>
            <div className="labeAndInputDiv">
             <label>Branch Name <span>*</span></label>
             {
                 user?.user?.role?.roleName === "superAdmin" || user?.user?.role?.roleName === "Administrator" ? (
                  <div ref={branchDropdownRef} className="custom-dropdown">
                  <div className="selected-option" onClick={branchToggleDropdown}>
                    {branchCode || 'Select an option'}
                  </div>
                  {isBranchOpen && (
                    <div className="options">
                      {branchRecord?.branch?.map((option, index) => (
                      <div
                        key={index}
                        className="option"
                        onClick={() => handleBranchValue(option._id, option.branchCode)}
                      >
                      {option?.branchCode + ", " + option?.branchAddress}
                      </div>
                      ))}
                  </div>
                  )}
                </div>
                 ) : (
                  <input
                type="text"
                className="testBookingInputField"
                placeholder={"Enter Branch"}
                name="productCode"
                autoComplete="off"
                maxLength="40"
                required
                value = {branchCode}
                disabled
                />
                 )
             }
            
            </div>      
            </div>
            <div className="testBookingInputDiv">
            <div className="labeAndInputDiv">
              <label>Select Test Type <span>*</span></label>
               <div ref={testTypeDropdownRef} className="custom-dropdown">
                <div className="selected-option" onClick={testTypeToggleDropdown}>
                  {testType || 'Select Status option'}
                </div>
                {isTestTypeOpen && (
                  <div className="options">
                    {testTypeOptions?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleTestTypeValue(option.text)}
                    >
                    {option?.text}
                    </div>
                    ))}
                </div>
                )}
              </div>
            </div>
            <div className="labeAndInputDiv">
            <label>Select Pakistan Date <span>*</span></label>
            <input
               className="testBookingInputField"
              type="date"
              value={pakistanDate}
              onChange={handlePakistanDateChange}
            />
            </div>
            <div className="labeAndInputDiv">
            <label>Select Pakistan Time <span>*</span></label>
            <input
              className="testBookingInputField"
              type="time"
              value={pakTimeValue}
              onChange={handlePakistanTimeChange}
            />
            </div>
            </div>
            <div className="testBookingInputDiv">
            <div className="labeAndInputDiv">
            <label>Select Test Country <span>*</span></label>
            <input
              placeholder="Select Country"
              className="testBookingInputField"
              type="text"
              value={testCountryName}
              onChange={(e)=> setTestCountryName(e.target.value)}
            />
            </div>
            <div className="labeAndInputDiv">
            <label>Select Test Country Date <span>*</span></label>
            <input
               className="testBookingInputField"
              type="date"
              value={otherCountryDate}
              onChange={handleOtherCountryDateChange}
            />
            </div>
            <div className="labeAndInputDiv">
            <label>Select Test Country Time <span>*</span></label>
            <input
              className="testBookingInputField"
              type="time"
              value={otherCountryTimeValue}
              onChange={handleOtherCountryTimeChange}
            />
            </div>
            </div>
          
            <div className="buttons">
            <button onClick={back}>Back</button>
              <button onClick={AddBooking}>Update Record</button>
              
              </div>
              </>) : (
                <PageLoader />
              )
            }
        
          </div>
        <div>
          
      </div>
    </div>
  </div>
    </>
  );
};

export default UpdateTestBookingForm;
