export const GET_EXPENSE_REQUEST = "GET_EXPENSE_REQUEST"
export const GET_EXPENSE_SUCCESS = "GET_EXPENSE_SUCCESS"
export const GET_EXPENSE_FAIL = "GET_EXPENSE_FAIL"

export const GET_EXPENSE_ONBRANCH_REQUEST = "GET_EXPENSE_ONBRANCH_REQUEST"
export const GET_EXPENSE_ONBRANCH_SUCCESS = "GET_EXPENSE_ONBRANCH_SUCCESS"
export const GET_EXPENSE_ONBRANCH_FAIL = "GET_EXPENSE_ONBRANCH_FAIL"

export const GET_EXPENSE_DETAIL_REQUEST = "GET_EXPENSE_DETAIL_REQUEST"
export const GET_EXPENSE_DETAIL_SUCCESS = "GET_EXPENSE_DETAIL_SUCCESS"
export const GET_EXPENSE_DETAIL_FAIL = "GET_EXPENSE_DETAIL_FAIL"

export const GET_EXPENSE_ONDATES_REQUEST = "GET_EXPENSE_ONDATES_REQUEST"
export const GET_EXPENSE_ONDATES_SUCCESS = "GET_EXPENSE_ONDATES_SUCCESS"
export const GET_EXPENSE_ONDATES_FAIL = "GET_EXPENSE_ONDATES_FAIL"

export const GET_EXPENSE_ONDATES_FORBRANCH_REQUEST = "GET_EXPENSE_ONDATES_FORBRANCH_REQUEST"
export const GET_EXPENSE_ONDATES_FORBRANCH_SUCCESS = "GET_EXPENSE_ONDATES_FORBRANCH_SUCCESS"
export const GET_EXPENSE_ONDATES_FORBRANCH_FAIL = "GET_EXPENSE_ONDATES_FORBRANCH_FAIL"

export const UPDATE_EXPENSE_DETAIL_REQUEST = "UPDATE_EXPENSE_DETAIL_REQUEST"
export const UPDATE_EXPENSE_DETAIL_SUCCESS = "UPDATE_EXPENSE_DETAIL_SUCCESS"
export const UPDATE_EXPENSE_DETAIL_FAIL = "UPDATE_EXPENSE_DETAIL_FAIL"


export const DELETE_EXPENSE_DETAIL_REQUEST = "DELETE_EXPENSE_DETAIL_REQUEST"
export const DELETE_EXPENSE_DETAIL_SUCCESS = "DELETE_EXPENSE_DETAIL_SUCCESS"
export const DELETE_EXPENSE_DETAIL_FAIL = "DELETE_EXPENSE_DETAIL_FAIL"

export const POST_EXPENSE_REQUEST = "POST_EXPENSE_REQUEST"
export const POST_EXPENSE_SUCCESS = "POST_EXPENSE_SUCCESS"
export const POST_EXPENSE_FAIL = "POST_EXPENSE_FAIL"

export const GET_EXPENSE_DASHBOARD_FORBRANCH_REQUEST = "GET_EXPENSE_DASHBOARD_FORBRANCH_REQUEST"
export const GET_EXPENSE_DASHBOARD_FORBRANCH_SUCCESS = "GET_EXPENSE_DASHBOARD_FORBRANCH_SUCCESS"
export const GET_EXPENSE_DASHBOARD_FORBRANCH_FAIL = "GET_EXPENSE_DASHBOARD_FORBRANCH_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"