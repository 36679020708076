import { useContext, useEffect } from "react";
import { UseDispatch, useSelector } from "react-redux";
import "./expenseTable.css"
export default function Dates({ selectedPrinter }) {
    // const {visaCustomerPaymentDetailRecord, visaCustomerPaymentDetailRecordLoading} = useSelector((state)=> state.visaCustomerPaymentDetailRecord)
    const {expenseRecordDetail, expenseRecordDetailLoading} = useSelector((state)=> state.expenseRecordDetail)
    useEffect(()=>{
    console.log(expenseRecordDetail)
  }, [])
    return (
        <>
              <div className="InvoiceSecondCont">
                <div className="InvoiceThirdCont">
                  <div className="consolidateCustomerLaserdivs">
                    <h5>Entered By:</h5>&nbsp;
                    <p>{expenseRecordDetail?.expenseData?.expenseEnteredBy}</p>
                  </div>
                  <div className="consolidateCustomerLaserdivs">
                  <h5>Invoice date:&nbsp;</h5>
                    <p>
                      {new Date(expenseRecordDetail?.expenseData?.createdAt).toLocaleDateString(
                        "en-GB"
                      )}
                    </p>
                  </div>
                 
                </div>
                <div className="InvoiceThirdCont">
                  <div className="consolidateCustomerSecondLaserdivs">
                    <h5>Invoicer number:&nbsp;</h5>
                    <p>{expenseRecordDetail?.expenseData?.invoiceNumber}</p>
                  </div>
                  <div className="consolidateCustomerSecondLaserdivs">
                  <h5>Total Payment:&nbsp;</h5>
                    <p>{expenseRecordDetail?.expenseData?.expenseTotal}</p>
                  </div>
                  
                
                </div>
              </div>
    </>
  );
}
