import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from "react-redux";
import ExpenseStateContext from "./Components/pages/Expense/expenseStateContext"
import store from "./Store/store";
import {positions, transitions, Provider as AlertProvider} from "react-alert"
import AlertTemplate from "react-alert-template-basic"
const root = ReactDOM.createRoot(document.getElementById('root'));
const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
  transition: transitions.SCALE
}
root.render(
  <React.StrictMode>
       <Provider store={store}>
       
        <ExpenseStateContext>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
        </ExpenseStateContext>
   
    </Provider>
  </React.StrictMode>
);


