import { CLEAR_ERRORS, DELETE_CUSTOMER_DETAIL_FAIL, DELETE_CUSTOMER_DETAIL_REQUEST, DELETE_CUSTOMER_DETAIL_SUCCESS, GET_CUSTOMER_DETAIL_FAIL, GET_CUSTOMER_DETAIL_REQUEST, GET_CUSTOMER_DETAIL_SUCCESS, GET_CUSTOMER_FAIL, GET_CUSTOMER_REQUEST, GET_CUSTOMER_SUCCESS, POST_CUSTOMER_FAIL, POST_CUSTOMER_REQUEST, POST_CUSTOMER_SUCCESS, UPDATE_CUSTOMER_DETAIL_FAIL, UPDATE_CUSTOMER_DETAIL_REQUEST, UPDATE_CUSTOMER_DETAIL_SUCCESS } from "../Constants/customerConstants";


export const customerReducer = (state = { customer: []}, action)=>{
    switch (action.type){
        case POST_CUSTOMER_REQUEST:
            return{
                customerLoading: true,
                customer: [],
            };
        case POST_CUSTOMER_SUCCESS: 
            return {
                customerLoading: false,
                customer: action.payload
            }
        case POST_CUSTOMER_FAIL:
            return {
                customerLoading: false,
                customerError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                customerError: null,
            }
            default:
            return state;
            
    }
}


export const getCustomerReducer = (state = { customerRecord: []}, action)=>{
    switch (action.type){
        case GET_CUSTOMER_REQUEST:
            return{
                customerRecordLoading: true,
                customerRecord: [],
            };
        case GET_CUSTOMER_SUCCESS: 
            return {
                customerRecordLoading: false,
                customerRecord: action.payload
            }
        case GET_CUSTOMER_FAIL:
            return {
                customerRecordLoading: false,
                customerRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                customerRecordError: null,
            }
            default:
            return state;
            
    }
}

export const getCustomerDetailReducer = (state = { customerDetailRecord: []}, action)=>{
    switch (action.type){
        case GET_CUSTOMER_DETAIL_REQUEST:
            return{
                customerDetailRecordLoading: true,
                customerDetailRecord: [],
            };
        case GET_CUSTOMER_DETAIL_SUCCESS: 
            return {
                customerDetailRecordLoading: false,
                customerDetailRecord: action.payload
            }
        case GET_CUSTOMER_DETAIL_FAIL:
            return {
                customerDetailRecordLoading: false,
                customerDetailRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                customerDetailRecordError: null,
            }
            default:
            return state;
            
    }
}

export const updateCustomerRecordReducer = (state = { updateCustomerRecord: []}, action)=>{
    switch (action.type){
        case UPDATE_CUSTOMER_DETAIL_REQUEST:
            return{
                updateCustomerRecordLoading: true,
                updateCustomerRecord: [],
            };
        case UPDATE_CUSTOMER_DETAIL_SUCCESS: 
            return {
                updateCustomerRecordLoading: false,
                updateCustomerRecord: action.payload
            }
        case UPDATE_CUSTOMER_DETAIL_FAIL:
            return {
                updateCustomerRecordLoading: false,
                updateCustomerRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                updateCustomerRecordError: null,
            }
            default:
            return state;
            
    }
}

export const deleteCustomerRecordReducer = (state = { deleteCustomerRecord: []}, action)=>{
    switch (action.type){
        case DELETE_CUSTOMER_DETAIL_REQUEST:
            return{
                deleteCustomerRecordLoading: true,
                deleteCustomerRecord: [],
            };
        case DELETE_CUSTOMER_DETAIL_SUCCESS: 
            return {
                deleteCustomerRecordLoading: false,
                deleteCustomerRecord: action.payload
            }
        case DELETE_CUSTOMER_DETAIL_FAIL:
            return {
                deleteCustomerRecordLoading: false,
                deleteCustomerRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                deleteCustomerRecordError: null,
            }
            default:
            return state;
            
    }
}
