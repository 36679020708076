import { REGISTER_USER_FAIL, REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS } from "../Constants/registerConstants";
import axiosInstance from "./baseURL";

export const registerUserRecord = (name,email,password, avatar, branch, role) => async (dispatch) => {
    try {
        console.log(avatar)
        const formData = new FormData();
        formData.set("avatar",avatar);
        console.log(avatar)
      dispatch({ type: REGISTER_USER_REQUEST });
      const config = {headers: {
        "Content-Type": "multipart/form-data", // Important for sending FormData
      }};
    // const config = { headers: { "Content-Type": "application/json" } };
    //   console.log(name, email, password,file, branch, rolef)
    console.log(avatar)
      const { data } = await axiosInstance.post(`/api/v1/register`, {
        name,email,password, avatar, branch, role
      },
      config);
      dispatch({
        type: REGISTER_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: error.response,
      });
    }
  };
