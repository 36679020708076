import axios from "axios"
import axiosInstance from "./baseURL";
import { DELETE_SPECIFIC_VISITORS_FAIL, DELETE_SPECIFIC_VISITORS_REQUEST, DELETE_SPECIFIC_VISITORS_SUCCESS, GET_SPECIFIC_VISITORS_FAIL, GET_SPECIFIC_VISITORS_REQUEST, GET_SPECIFIC_VISITORS_SUCCESS, GET_VISITORS_FAIL, GET_VISITORS_ONDATES_FAIL, GET_VISITORS_ONDATES_FORBRANCH_FAIL, GET_VISITORS_ONDATES_FORBRANCH_REQUEST, GET_VISITORS_ONDATES_FORBRANCH_SUCCESS, GET_VISITORS_ONDATES_REQUEST, GET_VISITORS_ONDATES_SUCCESS, GET_VISITORS_ONSHOP_FAIL, GET_VISITORS_ONSHOP_REQUEST, GET_VISITORS_ONSHOP_SUCCESS, GET_VISITORS_REQUEST, GET_VISITORS_SUCCESS, POST_VISITORS_FAIL, POST_VISITORS_REQUEST, POST_VISITORS_SUCCESS } from "../Constants/visitorsConstants";

export const postVisitorsRecord =  (  
    cust_id,
    branch_id,
    visitStatus,
    visitDescription) => async (dispatch) =>  {
    try {
      dispatch({ type: POST_VISITORS_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axiosInstance.post(
        `/api/v1/createVisitor`,
        {
            cust_id,
            branch_id,
            visitStatus,
            visitDescription
        }, 
        config
      );
      dispatch({ type: POST_VISITORS_SUCCESS,
        payload: data });
    } catch (error) {
      dispatch({ type: POST_VISITORS_FAIL,
        payload: error.response, });
    }
  };

  

  export const deleteVisitorRecords = (id) => async (dispatch) => {
    try {
      dispatch({ type:  DELETE_SPECIFIC_VISITORS_REQUEST});
      const { data } = await axiosInstance.delete(`/api/v1/deleteVisitor/${id}`);
      dispatch({
        type: DELETE_SPECIFIC_VISITORS_SUCCESS,
        payload: data,
      });
 
      dispatch({
        type: POST_VISITORS_SUCCESS,
        payload: [],
      })
    } catch (error) {
      dispatch({
        type: DELETE_SPECIFIC_VISITORS_FAIL,
        payload: error.response,
      });
    }
  };

  
  export const deleteVisitorRecordOnForm = (id) => async (dispatch) => {
    try {
      dispatch({ type:  DELETE_SPECIFIC_VISITORS_REQUEST});
      const { data } = await axiosInstance.delete(`/api/v1/deleteVisitorOnForm/${id}`);
      dispatch({
        type: DELETE_SPECIFIC_VISITORS_SUCCESS,
        payload: data,
      });
 
      dispatch({
        type: POST_VISITORS_SUCCESS,
        payload: [],
      })
    } catch (error) {
      dispatch({
        type: DELETE_SPECIFIC_VISITORS_FAIL,
        payload: error.response,
      });
    }
  };

  export const getVisitorsRecord = () => async (dispatch) => {
    try {
      dispatch({ type:  GET_VISITORS_REQUEST});
      const { data } = await axiosInstance.get(`/api/v1/getVisitor`);
      dispatch({
        type: GET_VISITORS_SUCCESS,
        payload: data,
      });
 
    
    } catch (error) {
      dispatch({
        type: GET_VISITORS_FAIL,
        payload: error.response,
      });
    }
  };

  export const getVisitorsRecordOnBranch = (branchCode) => async (dispatch) => {
    try {
      dispatch({ type:  GET_VISITORS_ONSHOP_REQUEST});
      const { data } = await axiosInstance.get(`/api/v1/getVisitorsRecordOnBranch/${branchCode}`);
      dispatch({
        type: GET_VISITORS_ONSHOP_SUCCESS,
        payload: data,
      });
 
    
    } catch (error) {
      dispatch({
        type: GET_VISITORS_ONSHOP_FAIL,
        payload: error.response,
      });
    }
  };

  export const getSpecificVisitorRecord = (id) => async (dispatch) => {
    try {
      dispatch({ type:  GET_SPECIFIC_VISITORS_REQUEST});
      const { data } = await axiosInstance.get(`/api/v1/getVisitorDetails/${id}`);
      dispatch({
        type: GET_SPECIFIC_VISITORS_SUCCESS,
        payload: data,
      });
 
    
    } catch (error) {
      dispatch({
        type: GET_SPECIFIC_VISITORS_FAIL,
        payload: error.response,
      });
    }
  };


  export const getVisitorsOnDates = (starting, ending) => async (dispatch) => {
    try {
      dispatch({ type: GET_VISITORS_ONDATES_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getTotalVisitorsOnDates?starting=${starting}&ending=${ending}`);
      dispatch({
        type: GET_VISITORS_ONDATES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VISITORS_ONDATES_FAIL,
        payload: error.response,
      });
    }
  };

  export const getVisitorsOnDatesForBranch = (branch, starting, ending) => async (dispatch) => {
    try {
      dispatch({ type: GET_VISITORS_ONDATES_FORBRANCH_REQUEST });
      console.log(branch, starting, ending)
      const { data } = await axiosInstance.get(`/api/v1/getTotalVisitorOnDatesForBranch/${branch}?starting=${starting}&ending=${ending}`);
      console.log(data)
      dispatch({
        type: GET_VISITORS_ONDATES_FORBRANCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VISITORS_ONDATES_FORBRANCH_FAIL,
        payload: error.response,
      });
    }
  };
