import { createContext, useEffect, useRef, useState } from "react";
// import { getStorage } from "../../../Api";
export const Statee = createContext();

export default function StateContext({ children }) {
    const [expenseLocation, setExpenseLocation] = useState("")
    const [expenseCategory, setExpenseCategory] = useState("")
    const [expenseEnteredBy, setExpenseEnteredBy] = useState('')
    const [expenseType, setExpenseType] = useState("");
    const [expenseUser, setExpenseUser] = useState()
    const [expenseAmount, setExpenseAmount] = useState("");
    const [expenseDescription, setExpenseDescription] = useState("");
    const [expenseTotal, setExpenseTotal] = useState("");
    const [expense, setExpense] = useState([]);
    const [amount, setAmount] = useState("");
    const [expenseDate, setExpenseDate] = useState('')

    const handleSubmitt = () => {
        console.warn("hiiiiii");
        const newItems = {
          expenseType,
          expenseUser,
          expenseAmount,
          expenseDate,
          expenseDescription,
        };
        setExpense([...expense, newItems]);
    
      };

  useEffect(()=>{
    let expenseTotal = 0;
    expense?.map((exp)=>{
     expenseTotal = parseFloat(expenseTotal) + parseFloat(exp?.expenseAmount)
    })

    setExpenseTotal(expenseTotal)
  }, [expense])
    
const context = {
    expense,
    setExpense,
    expenseType,
    setExpenseType,
    amount,
    setAmount,
    expenseDescription,
    setExpenseDescription,
    handleSubmitt,
    expenseCategory,
    setExpenseCategory,
    expenseAmount,
    setExpenseAmount,
    expenseTotal,
    setExpenseTotal,expenseUser, setExpenseUser, expenseLocation, setExpenseLocation,
    expenseEnteredBy, setExpenseEnteredBy, expenseCategory, setExpenseCategory, expenseDate, setExpenseDate
  };

  return <Statee.Provider value={context}>{children}</Statee.Provider>;
}
