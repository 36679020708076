import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { PostTestRecord, deleteTestRecord, getTestRecord } from "../../../Actions/testAction";
import { deleteBranchRecord, getBranchRecord } from "../../../Actions/branchAction";
import {useNavigate}  from "react-router-dom"
import "./branch.css"
import MainTableComponent from "../../TableComponent/MainTableComponent";
const BranchRecordTable = () => {
  const dispatch = useDispatch()  
  const [colorTheme, setColorTheme] = useState()
  const {branchRecord, branchLoading}= useSelector((state)=> state.branchRecord)
  const {deleteTestLoading, deleteTest} = useSelector((state)=> state.deleteTest)
  const {updateTestRecordloading, updateTestRecord} = useSelector((state)=> state.updateTestRecord)
  const linkk = "updateBranchRecord";
  const actionUpdate = "Update";
  const action3 = "Delete";

  const navigate = useNavigate()

  const ShowTableRecord = async () => {
      navigate('')
  }

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  useEffect(()=>{
    dispatch(getBranchRecord())
  }, [])

  const deleteProduct = async (id) => {
    dispatch(deleteBranchRecord(id))
    // swal
    //   .fire({
    //     icon: "warning",
    //     title: "titleMessage",
    //     text: "textRevertWarning",
    //     showCancelButton: true,
    //     confirmButtonText: "confirmButtonText",
    //     cancelButtonText: "cancelButtonText",
    //     customClass: {
    //       popup: "custom-swal-popup", 
    //     },
    //   })
    //   .then(async (result) => {
    //     if (result.value) {
    //       console.log("called");
    //       const response = await deleteColor(id);
    //       console.log(response);
    //     }
    //   });
  };

  const AddBranch = async () => {
    navigate('/addBranchRecord')
}


    const columns = [
        { field: "branchCode", label: "Branch Code" },
        { field: "branchAddress", label: "Branch Address" },
        { field: "branchPhoneNo", label: "Branch Phone No" },
        { field: "branchDescription", label: "Branch Description" },
      ];
    
      const actions = [
        {
          label: "Delete",
          color: "yellow",
          handler: (itemId) => deleteProduct(itemId),
        },
        {
          label: "Update",
          color: "green",
          url: (itemId) => `/updateBranchRecord/${itemId}`,
        },
      ];
    

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`branchMain ${colorTheme}`}>
        <div className="branchTable">
        <div className="heading">
            <h3>{"Branches"}</h3> 
            <button onClick={AddBranch}>
              Add Branch
            </button>
            </div>
            <div className="searchBranch-withouInputfields-box"></div>
            <div className="branchTable-container">
            {!branchLoading ? (
                <MainTableComponent
                    data={branchRecord?.branch}
                    columns={columns}
                    actions={actions}
                    actionUpdate={actionUpdate}
                    linkk= {linkk}
                    action3={action3}
                />
             ) : (<></>
            //     // <Loader active>Loading</Loader>
             )} 
            </div>
        </div>
    </div>
    </>
  );
};

export default BranchRecordTable;
