export const GET_TESTCUSTOMERPAYMENT_REQUEST = "GET_TESTCUSTOMERPAYMENT_REQUEST"
export const GET_TESTCUSTOMERPAYMENT_SUCCESS = "GET_TESTCUSTOMERPAYMENT_SUCCESS"
export const GET_TESTCUSTOMERPAYMENT_FAIL = "GET_TESTCUSTOMERPAYMENT_FAIL"

export const GET_TESTCUSTOMERPAYMENT_ONSHOP_REQUEST = "GET_TESTCUSTOMERPAYMENT_ONSHOP_REQUEST"
export const GET_TESTCUSTOMERPAYMENT_ONSHOP_SUCCESS = "GET_TESTCUSTOMERPAYMENT_ONSHOP_SUCCESS"
export const GET_TESTCUSTOMERPAYMENT_ONSHOP_FAIL = "GET_TESTCUSTOMERPAYMENT_ONSHOP_FAIL"


export const GET_TESTCUSTOMERPAYMENT_DETAIL_REQUEST = "GET_TESTCUSTOMERPAYMENT_DETAIL_REQUEST"
export const GET_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS = "GET_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS"
export const GET_TESTCUSTOMERPAYMENT_DETAIL_FAIL = "GET_TESTCUSTOMERPAYMENT_DETAIL_FAIL"

export const GET_TESTCUSTOMERPAYMENT_ONDATES_REQUEST = "GET_TESTCUSTOMERPAYMENT_ONDATES_REQUEST"
export const GET_TESTCUSTOMERPAYMENT_ONDATES_SUCCESS = "GET_TESTCUSTOMERPAYMENT_ONDATES_SUCCESS"
export const GET_TESTCUSTOMERPAYMENT_ONDATES_FAIL = "GET_TESTCUSTOMERPAYMENT_ONDATES_FAIL"

export const GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_REQUEST = "GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_REQUEST"
export const GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_SUCCESS = "GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_SUCCESS"
export const GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_FAIL = "GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_FAIL"

export const UPDATE_TESTCUSTOMERPAYMENT_DETAIL_REQUEST = "UPDATE_TESTCUSTOMERPAYMENT_DETAIL_REQUEST"
export const UPDATE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS = "UPDATE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS"
export const UPDATE_TESTCUSTOMERPAYMENT_DETAIL_FAIL = "UPDATE_TESTCUSTOMERPAYMENT_DETAIL_FAIL"


export const UPDATE_TESTCUSTOMERPAYMENT_STATUS_REQUEST = "UPDATE_TESTCUSTOMERPAYMENT_STATUS_REQUEST"
export const UPDATE_TESTCUSTOMERPAYMENT_STATUS_SUCCESS = "UPDATE_TESTCUSTOMERPAYMENT_STATUS_SUCCESS"
export const UPDATE_TESTCUSTOMERPAYMENT_STATUS_FAIL = "UPDATE_TESTCUSTOMERPAYMENT_STATUS_FAIL"


export const DELETE_TESTCUSTOMERPAYMENT_DETAIL_REQUEST = "DELETE_TESTCUSTOMERPAYMENT_DETAIL_REQUEST"
export const DELETE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS = "DELETE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS"
export const DELETE_TESTCUSTOMERPAYMENT_DETAIL_FAIL = "DELETE_TESTCUSTOMERPAYMENT_DETAIL_FAIL"

export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST"
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS"
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL"

export const POST_TESTCUSTOMERPAYMENT_REQUEST = "POST_TESTCUSTOMERPAYMENT_REQUEST"
export const POST_TESTCUSTOMERPAYMENT_SUCCESS = "POST_TESTCUSTOMERPAYMENT_SUCCESS"
export const POST_TESTCUSTOMERPAYMENT_FAIL = "POST_TESTCUSTOMERPAYMENT_FAIL"


export const GET_TESTSTATISTICS_REQUEST = "GET_TESTSTATISTICS_REQUEST"
export const GET_TESTSTATISTICS_SUCCESS = "GET_TESTSTATISTICS_SUCCESS"
export const GET_TESTSTATISTICS_FAIL = "GET_TESTSTATISTICS_FAIL"

export const GET_TESTSTATISTICSONBRANCH_REQUEST = "GET_TESTSTATISTICSONBRANCH_REQUEST"
export const GET_TESTSTATISTICSONBRANCH_SUCCESS = "GET_TESTSTATISTICSONBRANCH_SUCCESS"
export const GET_TESTSTATISTICSONBRANCH_FAIL = "GET_TESTSTATISTICSONBRANCH_FAIL"


export const GET_TESTDASHBOARD_REQUEST = "GET_TESTDASHBOARD_REQUEST"
export const GET_TESTDASHBOARD_SUCCESS = "GET_TESTDASHBOARD_SUCCESS"
export const GET_TESTDASHBOARD_FAIL = "GET_TESTDASHBOARD_FAIL"

export const GET_TESTDASHBOARDONBRANCH_REQUEST = "GET_TESTDASHBOARDONBRANCH_REQUEST"
export const GET_TESTDASHBOARDONBRANCH_SUCCESS = "GET_TESTDASHBOARDONBRANCH_SUCCESS"
export const GET_TESTDASHBOARDONBRANCH_FAIL = "GET_TESTDASHBOARDONBRANCH_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"