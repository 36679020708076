import React from "react";
import Cards from "../Cards/Cards";
// import Table from "../Table/Table";
import "./MainDash.css";
import TransferCard from "../TransferChart/TransferChart";
const MainDash = () => {


 


  return (
    <div className="MainDash">
      <Cards />
   
      {/* <TransferCard /> */}
    </div>
  );
};

export default MainDash;
