export const GET_TEST_REQUEST = "GET_TEST_REQUEST"
export const GET_TEST_SUCCESS = "GET_TEST_SUCCESS"
export const GET_TEST_FAIL = "GET_TEST_FAIL"

export const GET_SPECIFIC_TEST_REQUEST = "GET_SPECIFIC_TEST_REQUEST"
export const GET_SPECIFIC_TEST_SUCCESS = "GET_SPECIFIC_TEST_SUCCESS"
export const GET_SPECIFIC_TEST_FAIL = "GET_SPECIFIC_TEST_FAIL"

export const UPDATE_SPECIFIC_TEST_REQUEST = "UPDATE_SPECIFIC_TEST_REQUEST"
export const UPDATE_SPECIFIC_TEST_SUCCESS = "UPDATE_SPECIFIC_TEST_SUCCESS"
export const UPDATE_SPECIFIC_TEST_FAIL = "UPDATE_SPECIFIC_TEST_FAIL"

export const DELETE_SPECIFIC_TEST_REQUEST = "DELETE_SPECIFIC_TEST_REQUEST"
export const DELETE_SPECIFIC_TEST_SUCCESS = "DELETE_SPECIFIC_TEST_SUCCESS"
export const DELETE_SPECIFIC_TEST_FAIL = "DELETE_SPECIFIC_TEST_FAIL"


export const POST_TEST_REQUEST = "POST_TEST_REQUEST"
export const POST_TEST_SUCCESS = "POST_TEST_SUCCESS"
export const POST_TEST_FAIL = "POST_TEST_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"