import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { PostTestRecord, getTestRecord } from "../../../Actions/testAction";
import { getBranchRecord } from "../../../Actions/branchAction";
import {useNavigate}  from "react-router-dom"
import { useAlert } from "react-alert";
import "./test.css"
import { POST_TEST_SUCCESS } from "../../../Constants/testConstants";
const TestForm = () => {
  const dispatch = useDispatch()  
  const alert = useAlert()
  const [testName, setTestName] = useState()
  const [testPrice, setTestPrice] = useState()
  const [testDescription, setTestDescription] = useState()
  const [colorTheme, setColorTheme] = useState()
  const {postTest, postTestLoading, error} = useSelector((state)=> state.postTest)
  const navigate = useNavigate()

  const ShowTableRecord = async () => {
      navigate('/testRecordTable')
  }

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  useEffect(()=>{
    if(!postTestLoading && postTest?.success)
    {
      postTestSuccessMessage()
    }
  }, [postTest, postTestLoading])

  useEffect(()=>{
    if(!postTestLoading && error)
    {
      postTestFailMessage(error?.data?.message)
    }
  }, [error, postTestLoading])

  const postTestSuccessMessage =() => {
    setTestName('')
        setTestPrice('')
        setTestDescription('')
    dispatch({
      type: POST_TEST_SUCCESS,
      payload: [],
    });
    return alert.success('Record Added Successfully')
  }

  const postTestFailMessage =(message) => {
    return alert.error(message)
  }


  const addRecord = () =>{
        dispatch(PostTestRecord(testName, testPrice, testDescription))
    }

  const clearRecord = ()=>{
        setTestName('')
        setTestPrice('')
        setTestDescription('')
    }

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`testMain ${colorTheme}`}>
        <div className={`test`}>
          <div className="testSecond">
            <div className="heading">
            <h3 >{"Add Test"}</h3> 
            <button onClick={ShowTableRecord}>
              View Records
            </button>
            </div>
            <div className="testInputDiv">
                  {/* <div className="inputDiv"> */}
                  <div className="labeAndInputDiv">
                    <label className="labelDiv">
                      Test Name <span>*</span></label>
                    <input
                        label={"productCode"}
                        className="inputField"
                        type="text"
                        placeholder={"Enter Test Name"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={testName}
                        onChange={(e)=> setTestName(e.target.value)}
                        />
                  </div>
                  <div className="labeAndInputDiv">
                  {/* </div> */}
                    <label className="labelDiv">Test Price <span>*</span></label>
                    <input
                        label={"productCode"}
                        type="Number"
                        className="inputField"
                        placeholder={"Enter Test Price"}
                        name="productCode"
                        autoComplete="off"
                        required
                        value={testPrice}
                        onChange={(e)=> setTestPrice(e.target.value)}
                    />
                    </div>
                  <div className="labeAndInputDiv">
                    <label className="labelDiv">Test Description <span>*</span></label>
                    <input
                        className="inputField"
                        type="text"
                        placeholder={"Enter Test Description"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="150"
                        required
                        value={testDescription}
                        onChange={(e)=> setTestDescription(e.target.value)}
                    />
                      </div>
                
                </div>
                <div className="buttons">
                <button onClick={clearRecord}>Clear</button>
                <button onClick={addRecord}>Add Record</button>
               
                </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestForm;
