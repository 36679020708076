// import { CLEAR_ERRORS, DELETE_EXPENSETYPE_DETAIL_FAIL, DELETE_EXPENSETYPE_DETAIL_REQUEST, DELETE_EXPENSETYPE_DETAIL_SUCCESS, GET_EXPENSETYPE_DETAIL_FAIL, GET_EXPENSETYPE_DETAIL_REQUEST, GET_EXPENSETYPE_DETAIL_SUCCESS, GET_EXPENSETYPE_FAIL, GET_EXPENSETYPE_REQUEST, GET_EXPENSETYPE_SUCCESS, POST_EXPENSETYPE_FAIL, POST_EXPENSETYPE_REQUEST, POST_EXPENSETYPE_SUCCESS, UPDATE_EXPENSETYPE_DETAIL_FAIL, UPDATE_EXPENSETYPE_DETAIL_REQUEST, UPDATE_EXPENSETYPE_DETAIL_SUCCESS } from "../Constants/expenseTypeConstants";
import { CLEAR_ERRORS, DELETE_ROLE_DETAIL_FAIL, DELETE_ROLE_DETAIL_REQUEST, DELETE_ROLE_DETAIL_SUCCESS, GET_ROLE_DETAIL_FAIL, GET_ROLE_DETAIL_REQUEST, GET_ROLE_DETAIL_SUCCESS, GET_ROLE_FAIL, GET_ROLE_REQUEST, GET_ROLE_SUCCESS, POST_ROLE_FAIL, POST_ROLE_REQUEST, POST_ROLE_SUCCESS, UPDATE_ROLE_DETAIL_FAIL, UPDATE_ROLE_DETAIL_REQUEST, UPDATE_ROLE_DETAIL_SUCCESS } from "../Constants/roleConstants";

export const postRoleReducer = (state = { postRole: []}, action)=>{
    switch (action.type){
        case POST_ROLE_REQUEST:
            return{
                postRoleLoading: true,
                postRole: [],
            };
        case POST_ROLE_SUCCESS: 
            return {
                postRoleLoading: false,
                postRole: action.payload
            }
        case POST_ROLE_FAIL:
            return {
                postRoleLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}

export const GetRoleReducer = (state = { roleRecord: []}, action)=>{
    switch (action.type){
        case GET_ROLE_REQUEST:
            return{
                roleRecordLoading: true,
                roleRecord: [],
            };
        case GET_ROLE_SUCCESS: 
            return {
                roleRecordLoading: false,
                roleRecord: action.payload
            }
        case GET_ROLE_FAIL:
            return {
                roleRecordLoading: false,
                roleRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                roleRecordError: null,
            }
            default:
            return state;
            
    }
}

export const getRoleDetailReducer = (state = { roleRecordDetail: []}, action)=>{
    switch (action.type){
        case GET_ROLE_DETAIL_REQUEST:
            return{
                roleRecordDetailLoading: true,
                roleRecordDetail: [],
            };
        case GET_ROLE_DETAIL_SUCCESS: 
            return {
                roleRecordDetailLoading: false,
                roleRecordDetail: action.payload
            }
        case GET_ROLE_DETAIL_FAIL:
            return {
                roleRecordDetailLoading: false,
                roleRecordDetailError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                roleRecordDetailError: null,
            }
            default:
            return state;
            
    }
}

export const updateRoleReducer = (state = { updateRoleRecord: []}, action)=>{
    switch (action.type){
        case UPDATE_ROLE_DETAIL_REQUEST:
            return{
                updateRoleRecordLoading: true,
                updateRoleRecord: [],
            };
        case UPDATE_ROLE_DETAIL_SUCCESS: 
            return {
                updateRoleRecordLoading: false,
                updateRoleRecord: action.payload
            }
        case UPDATE_ROLE_DETAIL_FAIL:
            return {
                updateRoleRecordLoading: false,
                updateRoleRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                updateRoleRecordError: null,
            }
            default:
            return state;
            
    }
}

export const deleteRoleDetailReducer = (state = { deleteRoleRecord: []}, action)=>{
    switch (action.type){
        case DELETE_ROLE_DETAIL_REQUEST:
            return{
                deleteRoleRecordLoading: true,
                deleteRoleRecord: [],
            };
        case DELETE_ROLE_DETAIL_SUCCESS: 
            return {
                deleteRoleRecordLoading: false,
                deleteRoleRecord: action.payload
            }
        case DELETE_ROLE_DETAIL_FAIL:
            return {
                deleteRoleRecordLoading: false,
                deleteRoleRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                deleteRoleRecordError: null,
            }
            default:
            return state;
            
    }
}