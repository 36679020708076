
import { GET_ASSINGEDTASK_FAIL, GET_ASSINGEDTASK_REQUEST, GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_FAIL, GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_REQUEST, GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_SUCCESS, GET_ASSINGEDTASK_ROLEID_FAIL, GET_ASSINGEDTASK_ROLEID_REQUEST, GET_ASSINGEDTASK_ROLEID_SUCCESS, GET_ASSINGEDTASK_SUCCESS, POST_ASSIGNEDROLEBYID_FAIL, POST_ASSIGNEDROLEBYID_REQUEST, POST_ASSIGNEDROLEBYID_SUCCESS } from "../Constants/assignedTaskConstants";
import axiosInstance from "./baseURL";


export const postAssignedTaskOnRoleAndTask = (role, task, status) => async (dispatch) => {
  try {
    dispatch({ type: POST_ASSIGNEDROLEBYID_REQUEST });
    console.log(role)
    const config = {headers: {"Content-Type": "application/json"}}
    const { data } = await axiosInstance.post(`/api/v1/createAssignedTask`, {role, task, status}, config);
    dispatch({
      type: POST_ASSIGNEDROLEBYID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: POST_ASSIGNEDROLEBYID_FAIL,
      payload: error.response,
    });
  }
};

export const getAssignedTaskOnRole = (role) => async (dispatch) => {
    try {
      dispatch({ type: GET_ASSINGEDTASK_REQUEST });
      console.log(role)
      const { data } = await axiosInstance.get(`/api/v1/getTaskByRole/${role}`);
      dispatch({
        type: GET_ASSINGEDTASK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ASSINGEDTASK_FAIL,
        payload: error.response,
      });
    }
  };

  export const getAssignedTaskOnRoleId = (role) => async (dispatch) => {
    try {
      dispatch({ type: GET_ASSINGEDTASK_ROLEID_REQUEST });
      console.log(role)
      const { data } = await axiosInstance.get(`/api/v1/getAssignedTaskByRoleId/${role}`);
      dispatch({
        type: GET_ASSINGEDTASK_ROLEID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ASSINGEDTASK_ROLEID_FAIL,
        payload: error.response,
      });
    }
  };

  export const getAssignedTaskOnRoleIdAndTaskId = async(role, task) =>  {
    try {
      const { data } = await axiosInstance.get(`/api/v1/getAssignedTaskBy/${role}/${task}`);
      return data;
    } catch (error) {
  
    }
  };

  export const getAssignedTaskOnRoleIdAndTaskName =(role, taskName)=> async(dispatch) =>  {
    try {
      dispatch({ type: GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_REQUEST });
      console.log(role)
      const { data } = await axiosInstance.get(`/api/v1/getByIdandName/${role}?taskName=${taskName}`);
      dispatch({
        type: GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ASSINGEDTASK_ROLEID_AND_TASKNAME_FAIL,
        payload: error.response,
      });
    }
  };