export const GET_VISITORS_REQUEST = "GET_VISITORS_REQUEST"
export const GET_VISITORS_SUCCESS = "GET_VISITORS_SUCCESS"
export const GET_VISITORS_FAIL = "GET_VISITORS_FAIL"

export const GET_VISITORS_ONSHOP_REQUEST = "GET_VISITORS_ONSHOP_REQUEST"
export const GET_VISITORS_ONSHOP_SUCCESS = "GET_VISITORS_ONSHOP_SUCCESS"
export const GET_VISITORS_ONSHOP_FAIL = "GET_VISITORS_ONSHOP_FAIL"

export const GET_SPECIFIC_VISITORS_REQUEST = "GET_SPECIFIC_VISITORS_REQUEST"
export const GET_SPECIFIC_VISITORS_SUCCESS = "GET_SPECIFIC_VISITORS_SUCCESS"
export const GET_SPECIFIC_VISITORS_FAIL = "GET_SPECIFIC_VISITORS_FAIL"

export const UPDATE_SPECIFIC_VISITORS_REQUEST = "UPDATE_SPECIFIC_VISITORS_REQUEST"
export const UPDATE_SPECIFIC_VISITORS_SUCCESS = "UPDATE_SPECIFIC_VISITORS_SUCCESS"
export const UPDATE_SPECIFIC_VISITORS_FAIL = "UPDATE_SPECIFIC_VISITORS_FAIL"

export const GET_VISITORS_ONDATES_REQUEST = "GET_VISITORS_ONDATES_REQUEST"
export const GET_VISITORS_ONDATES_SUCCESS = "GET_VISITORS_ONDATES_SUCCESS"
export const GET_VISITORS_ONDATES_FAIL = "GET_VISITORS_ONDATES_FAIL"

export const GET_VISITORS_ONDATES_FORBRANCH_REQUEST = "GET_VISITORS_ONDATES_FORBRANCH_REQUEST"
export const GET_VISITORS_ONDATES_FORBRANCH_SUCCESS = "GET_VISITORS_ONDATES_FORBRANCH_SUCCESS"
export const GET_VISITORS_ONDATES_FORBRANCH_FAIL = "GET_VISITORS_ONDATES_FORBRANCH_FAIL"


export const DELETE_SPECIFIC_VISITORS_REQUEST = "DELETE_SPECIFIC_VISITORS_REQUEST"
export const DELETE_SPECIFIC_VISITORS_SUCCESS = "DELETE_SPECIFIC_VISITORS_SUCCESS"
export const DELETE_SPECIFIC_VISITORS_FAIL = "DELETE_SPECIFIC_VISITORS_FAIL"


export const POST_VISITORS_REQUEST = "POST_VISITORS_REQUEST"
export const POST_VISITORS_SUCCESS = "POST_VISITORS_SUCCESS"
export const POST_VISITORS_FAIL = "POST_VISITORS_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"