import { useContext, useEffect } from "react";
import { UseDispatch, useSelector } from "react-redux";
import "./customerVisa.css"
export default function Dates({ selectedPrinter }) {
    const {visaCustomerPaymentDetailRecord, visaCustomerPaymentDetailRecordLoading} = useSelector((state)=> state.visaCustomerPaymentDetailRecord)
  useEffect(()=>{
    console.log(visaCustomerPaymentDetailRecord)
  }, [])
    return (
        <>
              <div className="InvoiceSecondCont">
                <div className="InvoiceThirdCont">
                  <div className="consolidateCustomerLaserdivs">
                    <h5>Customer Name:</h5>
                    <p>{visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.cust_id?.name}</p>
                  </div>
                  <div className="consolidateCustomerLaserdivs">
                    <h5>Cell No:</h5>
                    <p>{visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.cust_id?.contactNo}</p>
                  </div>
                  <div className="consolidateCustomerLaserdivs">
                    <h5>Status:</h5>
                    <p>{visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.Status}</p>
                  </div>
                  <div className="consolidateCustomerLaserdivs">
                    <h5>Discount:</h5>
                    <p>{visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.discount}</p>
                  </div>
                </div>
                <div className="InvoiceThirdCont">
                  <div className="consolidateCustomerSecondLaserdivs">
                    <h5>Invoicer number:</h5>
                    <p>{visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.cust_id?.invoiceNumber}</p>
                  </div>
                  <div className="consolidateCustomerSecondLaserdivs">
                    <h5>Invoice date:</h5>
                    <p>
                      {new Date(visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.createdAt).toLocaleDateString(
                        "en-GB"
                      )}
                    </p>
                  </div>
                    <div className="consolidateCustomerLaserdivs">
                    <h5>Payment Status:</h5>
                    <p>{visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.paymentStatus}</p>
                  </div>
                  <div className="consolidateCustomerLaserdivs">
                    <h5>Total Payment:</h5>
                    <p>{visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.totalPayment}</p>
                  </div>
                </div>
              </div>
    </>
  );
}
