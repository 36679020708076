import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import "./task.css"
import { useNavigate } from "react-router-dom";
import { PostTask } from "../../../Actions/taskAction";
const TaskForm = () => {
  const dispatch = useDispatch()  
  const [taskName, setTaskName] = useState()
  const [taskDescription, setTaskDescription] = useState()
  const [colorTheme, setColorTheme] = useState()
  const navigate = useNavigate()
  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

    const addRecord = () =>{
        dispatch(PostTask(taskName, taskDescription))
    }

    const clearRecord = ()=>{
        setTaskName('')
        setTaskDescription('')
    }

    const ShowTableRecord = async () => {
      navigate('/expenseTypeTable')
  }


  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`taskMain ${colorTheme}`}>
      <div className={`task`}>
        <div className="taskSecond">
        <div className="heading">
          <h3>{"Add Task"}</h3> 
          <button onClick={ShowTableRecord}>
            View Records
          </button>
          </div>
                <div className="taskInputDiv">
                    <label>Task Name</label>
                    <input
                        label={"productCode"}
                        className="inputField"
                        type="text"
                        placeholder={"Enter Task Name"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={taskName}
                        onChange={(e)=> setTaskName(e.target.value)}
                        />
                    <label>Task Description</label>
                    <input
                        className="inputField"
                        type="text"
                        placeholder={"Enter Test Description"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={taskDescription}
                        onChange={(e)=> setTaskDescription(e.target.value)}
                    />
                </div>
                <div>
                <button onClick={addRecord}>Add Record</button>
                <button onClick={clearRecord}>Clear</button>
                </div>
                </div> 
    </div> 
  </div>
    </>
  );
};

export default TaskForm;
