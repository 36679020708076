import { useRef, useState, useEffect } from "react";
import { useNavigate, Link, json } from "react-router-dom";
import "./logIn.css";
import swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { formToJSON } from "axios";
import { login, loginUser } from "../../../Actions/userAction";
import PageLoader from "../../Loader/PageLoader"
export const Login = () => {
  const userRef = useRef();
  const errRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loginAgain, setLoginAgain] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [unAllowedRoles, setUnAllowedRoles] = useState(false)
  const [rolesMessage, setRolesMessage] = useState()

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, user, isAuthenticated, error } = useSelector((state) => state.user);

  // useEffect(() => {
  //   userRef.current.focus();
  // }, []);

  
  // useEffect(() => {
  //   getToken();
  // }, []);

  // const getToken = async () => {
  //   const token = await refreshTokken();
  //   console.log(token);
  //   if (token?.data === "Please login to acces this resource") {
  //    return content
  //   } else {
  //     navigate("/dashboard");
  //   }
  // };


  useEffect(()=>{
    if(isAuthenticated){
      let isAdministrator = user?.user?.role?.roleName === "Administrator";
      let isSuperAdmin = user?.user?.role?.roleName === "superAdmin";
      let isAdmin = user?.user?.role?.roleName === "Admin";
      let isSalesman = user?.user?.role?.roleName === "Salesman";
      let isLoggedIn = true;
      localStorage.setItem("isAdministrator",JSON?.stringify(isAdministrator));
      localStorage.setItem("loggedIn", JSON?.stringify(isLoggedIn))
      localStorage.setItem("isSuperAdmin", JSON?.stringify(isSuperAdmin));
      localStorage.setItem("isAdmin", JSON?.stringify(isAdmin));
      localStorage.setItem("isSalesman", JSON?.stringify(isSalesman));
      localStorage.setItem("userRoleId", JSON.stringify(user?.user?.role?._id));
      localStorage.setItem("theme-color", "theme-gradient");
      console.log('chik')
      navigate('/dashboard')
      }
     
  }, [isAuthenticated, loading, user])

  useEffect(()=>{
    console.log(isAuthenticated)
    console.log(error)
    console.log(user)
    if(!isAuthenticated && error?.data?.message === "Your Subscription Has Been Ended Please Contact With Softwise Sol Team")
    {
      swal.fire({
        icon: "error",
        // title: t("titleError"),
        text: error?.data?.message,
        showConfirmButton: false,
        timer: 5000,
        customClass: {
          popup: "custom-swal-popup", // This is the custom class you're adding
        },
      });
    }
  }, [error, isAuthenticated])
  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  const handleForgotPassword = async (e) => {
    navigate("/passwordForgot");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let loadingAlert = null;
    try {
      dispatch(loginUser(username, password));
      const data = await login(username, password);
      console.log(isAuthenticated)
      if (data?.success) {
      
      }

    console.log(data)
     
    
    } catch (err) {
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const handleUserInput = (e) => {
    setUnAllowedRoles(false)
    setUnauthorized(false);
    setUsername(e.target.value);
  };

  const handlePwdInput = (e) => setPassword(e.target.value);
//   const handleToggle = () => setPersist((prev) => !prev);

  const errClass = errMsg ? "errmsg" : "offscreen";
  const content = (<>
    <div className="LoginSignUpContainer">
      <div className="LoginSignUpBox">
        <div>
          <div className="LoginSignUp-Toggle">
            <p>LOGIN</p>
          </div>
        </div>
        <form className="loginForm" ref={userRef} onSubmit={handleSubmit}>
        {unauthorized && "Invalid Username & password"}
        {loginAgain && "Please Check Your Internet Connection && Login Again"}
        {unAllowedRoles && rolesMessage}
          <div className="loginEmail">
            <MailOutlineIcon />
            <input
              type="username"
              placeholder="Enter Email"
              ref={userRef}
              value={username}
              onChange={handleUserInput}
              autoComplete="off"
              required
            />
          </div>
         
          <div className="loginPassword">
            <LockOpenIcon />
            <input
              // type="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              id="password"
              onChange={handlePwdInput}
              value={password}
              required
            />
          
            {showPassword ? <VisibilityOffIcon className="visibilitySvg" onClick={togglePasswordVisibility}/> : <VisibilityIcon className="visibilitySvg" onClick={togglePasswordVisibility}/>}
          </div>
          <Link to="/passwordForgot">Forget Password? </Link>
          <input
            type="submit"
            name="Login"
            value="Login"
            className="loginBtn"
            id=""
          />
        </form>
      </div>
    </div>
    </>
  );

  return !JSON.parse(localStorage.getItem("username"))
    ? (<>{
      !loading ? (content) : (<PageLoader />)
    }</>
    )
    :(() => {
        localStorage.clear();
        window.location.reload();
      })();
};
// exp Login;
