export const GET_VISA_REQUEST = "GET_VISA_REQUEST"
export const GET_VISA_SUCCESS = "GET_VISA_SUCCESS"
export const GET_VISA_FAIL = "GET_VISA_FAIL"

export const GET_SPECIFIC_VISA_REQUEST = "GET_SPECIFIC_VISA_REQUEST"
export const GET_SPECIFIC_VISA_SUCCESS = "GET_SPECIFIC_VISA_SUCCESS"
export const GET_SPECIFIC_VISA_FAIL = "GET_SPECIFIC_VISA_FAIL"

export const UPDATE_SPECIFIC_VISA_REQUEST = "UPDATE_SPECIFIC_VISA_REQUEST"
export const UPDATE_SPECIFIC_VISA_SUCCESS = "UPDATE_SPECIFIC_VISA_SUCCESS"
export const UPDATE_SPECIFIC_VISA_FAIL = "UPDATE_SPECIFIC_VISA_FAIL"

export const DELETE_SPECIFIC_VISA_REQUEST = "DELETE_SPECIFIC_VISA_REQUEST"
export const DELETE_SPECIFIC_VISA_SUCCESS = "DELETE_SPECIFIC_VISA_SUCCESS"
export const DELETE_SPECIFIC_VISA_FAIL = "DELETE_SPECIFIC_VISA_FAIL"


export const POST_VISA_REQUEST = "POST_VISA_REQUEST"
export const POST_VISA_SUCCESS = "POST_VISA_SUCCESS"
export const POST_VISA_FAIL = "POST_VISA_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"