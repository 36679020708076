import React, { useEffect, useState, useRef } from "react";
import { UseDispatch, useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";
import { getSpecificCustomerVisaRecord, updateCustomerVisaRecord, updateCustomerVisaStatus } from "../../../Actions/visaCustomerPaymentAction";
import MainTableComponent from "../../TableComponent/MainTableComponent";
import "./expenseTable.css"
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { UPDATE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS } from "../../../Constants/visaCustomerPaymentConstants";
import { getExpenseDetail } from "../../../Actions/expenseAction";
import LaserPrintTable from "../../TableComponent/LaserPrintTable"
import Dates from "./Dates";
import Header from "./Header";
let legnthOfPrevData;

const PreviewExpenseRecord = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const expenseRef = useRef();
    const alert = useAlert()
    const [colorTheme, setColorTheme] = useState()
    const {expenseRecordDetail, expenseRecordDetailLoading} = useSelector((state)=> state.expenseRecordDetail)

    const [selectedStatus, setSelectedStatus] = useState()


      useEffect(()=>{
        setColorTheme(localStorage.getItem('theme-color'))
      }, [localStorage.getItem('theme-color')])

    useEffect(()=>{
        console.log(params.id)
        dispatch(getExpenseDetail(params.id))
    }, [])

    useEffect(()=>{
        if(!expenseRecordDetailLoading && expenseRecordDetail)
        {
        const initialPayments =expenseRecordDetail?.expenseData?.expense
        }
    

    }, [expenseRecordDetailLoading, expenseRecordDetail])

    const back = () =>{
      navigate("/expenseInvoice")
    }

    const columns = [
        { field: "expenseType", label: "Expense Type" },
         { field: "expenseAmount", label: "Expense Amount" },
         {field: "expenseUser", label: "Expense User"},
        { field: "expenseDescription", label: "Expense Description" },
    {field: 'expenseDate', label: 'Expense Date', format: 'date'},
      ];
    return(
        <>
           <div className={`expenseRecordTable ${colorTheme}`}>
        <div className={`expenseRecord`}>
        <div className="previewDropdownDiv">
          <button onClick={back}>Back</button>
          <ReactToPrint
            trigger={() => (
              <button  >
                Print / Download
              </button>
            )}
            content={() => expenseRef.current}
          />
        </div>
        <div className="previewTable">
       
              <div ref={expenseRef} style={{overflow: "auto", height: "90%"}} className="p-5">
            <div  className="printPagePrev" style={{margin: "10px", border: "2px solid black", padding: "10px"}}>
              <Header />
              <Dates />
            {
                !expenseRecordDetailLoading && (<LaserPrintTable 
                     data={expenseRecordDetail?.expenseData?.expense}
                     columns={columns}
                />)
            }
            </div>
            </div>                
            </div>
        </div>
        </div> 
        </>
    )
}
export default PreviewExpenseRecord