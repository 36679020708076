import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
// import { PostTestRecord, getSpecificTestRecord, getTestRecord, updateTestRecord, updateTestRecordd } from "../../../Actions/testAction";
import { getBranchRecord } from "../../../Actions/branchAction";
import {useNavigate, useParams}  from "react-router-dom"
import "./customer.css"
import { useAlert } from "react-alert";
import { UPDATE_SPECIFIC_TEST_SUCCESS } from "../../../Constants/testConstants";
import { getCustomerRecordDetails, updateCustomerRecordFull } from "../../../Actions/customerAction";
import { UPDATE_CUSTOMER_DETAIL_SUCCESS } from "../../../Constants/customerConstants";
const UpdateCustomerRecord = () => {
  const dispatch = useDispatch()  
  const [customerName, setCustomerName] = useState() 
  const [contactNo, setContactNo] = useState()
  const [address, setAddress] = useState()
  const [email, setEmail] = useState()
  const [status, setStatus] = useState()
  const [description, setDescription] = useState()
  const [branchCode, setBranchCode] = useState()
  const [branchValue, setBranchValue] = useState()
  const [isBranchOpen, setIsBranchOpen] = useState(false)
  const alert = useAlert()
  const [testDescription, setTestDescription] = useState()
  const params = useParams()
  const [colorTheme, setColorTheme] = useState()
  const {customerDetailRecord, customerDetailRecordLoading, customerDetailRecordError} = useSelector((state)=> state.customerDetailRecord)
  const {branchLoading, branchRecord} = useSelector((state)=> state.branchRecord)
  const {testSpecificRecordloading, testSpecificRecord } = useSelector((state)=> state.testSpecificRecord)
  const{updateCustomerRecord, updateCustomerRecordLoading} = useSelector((state)=> state.updateCustomerRecord)
  const navigate = useNavigate()

  const ShowTableRecord = async () => {
      navigate('/testRecordTable')
  }
useEffect(()=>{
    dispatch(getBranchRecord())
}, [])
  

  useEffect(()=>{
    if(!customerDetailRecordLoading && customerDetailRecord)
    {
      setCustomerName(customerDetailRecord?.student?.name)
      setEmail(customerDetailRecord?.student?.email)
      setContactNo(customerDetailRecord?.student?.contactNo)
      setAddress(customerDetailRecord?.student?.address)
      setDescription(customerDetailRecord?.student?.description)
      setBranchCode(customerDetailRecord?.student?.branch?.branchCode)
      setBranchValue(customerDetailRecord?.student?.branch?._id)
      console.log(customerDetailRecord)
    }
  }, [customerDetailRecordLoading, customerDetailRecord])

  useEffect(()=>{
    dispatch(getCustomerRecordDetails(params.id))
  }, [])

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  const updateRecord = () =>{
    console.log(customerName)
        dispatch(updateCustomerRecordFull(params.id, customerName, email, contactNo, address, branchValue))
        // 
    }

  useEffect(()=>{
    if(updateCustomerRecord?.success && !updateCustomerRecordLoading){
      updateTestSuccessMessage()
    }
console.log(updateCustomerRecord)
  }, [updateCustomerRecord, updateCustomerRecordLoading])


  const updateTestSuccessMessage =() => {
    dispatch({
      type: UPDATE_CUSTOMER_DETAIL_SUCCESS,
      payload: [],
    });
    navigate("/customerTableRecord")
    return alert.success('Record Updated Successfully')
  }

  const handleBranchValue = (value, code) => {
    setBranchCode(code)
    setBranchValue(value)
    setIsBranchOpen(!isBranchOpen);
  }

  const branchToggleDropdown = () => {
    setIsBranchOpen(!isBranchOpen);
  };


  const clearRecord = ()=>{
       navigate("/customerTableRecord")
    }

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`customerMain ${colorTheme}`}>
      <div className={`customer`}>
        <div className="customerSecond">
          <div className="heading">
            <h3>{"Update Customer"}</h3> 
            {/* <button onClick={ShowTableRecord}>
              View Records
            </button> */}
            </div>
            <div className="customerFirstInputDiv">
            <div className="labeAndInputDiv">
              <label>Customer Name</label>
              <input
                label={"Enter Customer Name"}
                className="customerInputField"
                type="text"
                placeholder={"Enter Customer Name"}
                name="customerName"
                autoComplete="off"
                maxLength="40"
                required
                value={customerName}
                onChange={(e)=> setCustomerName(e.target.value)}
              />
            </div>
            <div className="labeAndInputDiv">
              <label>Email</label>
             <input
              label={"productCode"}
              type="email"
              className="customerInputField"
              placeholder={"Enter Email"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
              required
              value = {email}
              onChange={(e)=> setEmail(e.target.value)}
              
              />
              </div>
            <div className="labeAndInputDiv">
              <label>Contact No</label>
              <input
              label={"Contact No"}
              type="text"
              className="customerInputField"
              placeholder={"Enter Contact No"}
              name="contactNo"
              autoComplete="off"
              maxLength="150"
              required
              value = {contactNo}
              onChange={(e)=> setContactNo(e.target.value)}
              />
            </div>
          
            </div>
            <div className="LastCustomerInputDiv">
            <div className="labeAndInputDiv">
              <label>Address</label>
              <input
              label={"Address"}
              className="customerInputField"
              type="text"
              placeholder={"Enter Address"}
              name="address"
              autoComplete="off"
              maxLength="200"
              required
              value = {address}
              onChange={(e)=> setAddress(e.target.value)}
              />
             </div>
          
            {/* </div> */}
{/*             
            <div className="LastCustomerInputDiv">  */}
            <div className="labeAndInputDiv">
             <label>Branch Name</label>
              <div className="custom-dropdown">
                <div className="selected-option" onClick={branchToggleDropdown}>
                  {branchCode || 'Select an option'}
                </div>
                {isBranchOpen && (
                  <div className="options">
                    {branchRecord?.branch?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleBranchValue(option._id, option.branchCode)}
                    >
                    {option?.branchCode}
                    </div>
                    ))}
                </div>
                )}
              </div>
            </div>         

            </div>
                <div className="buttons">
                <button onClick={clearRecord}>back</button>
                <button onClick={updateRecord}>Update Record</button>
               
                </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateCustomerRecord;
