import { DELETE_SPECIFIC_TEST_FAIL, DELETE_SPECIFIC_TEST_REQUEST, DELETE_SPECIFIC_TEST_SUCCESS, GET_SPECIFIC_TEST_FAIL, GET_SPECIFIC_TEST_REQUEST, GET_SPECIFIC_TEST_SUCCESS, GET_TEST_FAIL, GET_TEST_REQUEST, GET_TEST_SUCCESS, POST_TEST_FAIL, POST_TEST_REQUEST, POST_TEST_SUCCESS, UPDATE_SPECIFIC_TEST_FAIL, UPDATE_SPECIFIC_TEST_REQUEST, UPDATE_SPECIFIC_TEST_SUCCESS } from "../Constants/testConstants";
import axiosInstance from "./baseURL";
import {DELETE_SPECIFIC_VISA_FAIL, DELETE_SPECIFIC_VISA_REQUEST, DELETE_SPECIFIC_VISA_SUCCESS, GET_SPECIFIC_VISA_FAIL, GET_SPECIFIC_VISA_REQUEST, GET_SPECIFIC_VISA_SUCCESS, GET_VISA_FAIL, GET_VISA_REQUEST, GET_VISA_SUCCESS, POST_VISA_FAIL, POST_VISA_REQUEST, POST_VISA_SUCCESS, UPDATE_SPECIFIC_VISA_FAIL, UPDATE_SPECIFIC_VISA_REQUEST, UPDATE_SPECIFIC_VISA_SUCCESS} from "../Constants/visaConstants"

export const getVisaRecord = () => async (dispatch) => {
    try {
      dispatch({ type: GET_VISA_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getVisa`);
      dispatch({
        type: GET_VISA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VISA_FAIL,
        payload: error.response,
      });
    }
  };

  export const getSpecificVisaRecord = (id) => async (dispatch) => {
    try {
      dispatch({ type: GET_SPECIFIC_VISA_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getVisaDetails/${id}`);
      dispatch({
        type: GET_SPECIFIC_VISA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SPECIFIC_VISA_FAIL,
        payload: error.response,
      });
    }
  };

  export const updateVisaRecordd = (id, visaName, visaPrice, visaDescription) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SPECIFIC_VISA_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } =await axiosInstance.put(`/api/v1/editVisa/${id}`, {
        visaName, visaPrice, visaDescription
      },
      config);
      dispatch({
        type: UPDATE_SPECIFIC_VISA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SPECIFIC_VISA_FAIL,
        payload: error.response,
      });
    }
  };

  export const PostVisaRecord = (visaName, visaPrice, visaDescription) => async (dispatch) => {
    try {
      dispatch({ type: POST_VISA_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axiosInstance.post(`/api/v1/createVisa`, {
        visaName, visaPrice, visaDescription
      },
      config);
      dispatch({
        type: POST_VISA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_VISA_FAIL,
        payload: error.response,
      });
    }
  };

  
  export const deleteVisaRecord = (id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_SPECIFIC_VISA_REQUEST });
      const { data } = await axiosInstance.delete(`/api/v1/deleteVisa/${id}`);
      dispatch({
        type: DELETE_SPECIFIC_VISA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_SPECIFIC_VISA_FAIL,
        payload: error.response,
      });
    }
  };