import axios from "axios"
import axiosInstance from "./baseURL";
import {GET_NOTIFICATION_FAIL, GET_NOTIFICATION_REQUEST, GET_NOTIFICATION_SUCCESS, GET_TESTCUSTOMERPAYMENT_DETAIL_FAIL, GET_TESTCUSTOMERPAYMENT_DETAIL_REQUEST, GET_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS, GET_TESTCUSTOMERPAYMENT_FAIL, GET_TESTCUSTOMERPAYMENT_ONDATES_FAIL, GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_FAIL, GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_REQUEST, GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_SUCCESS, GET_TESTCUSTOMERPAYMENT_ONDATES_REQUEST, GET_TESTCUSTOMERPAYMENT_ONDATES_SUCCESS, GET_TESTCUSTOMERPAYMENT_ONSHOP_FAIL, GET_TESTCUSTOMERPAYMENT_ONSHOP_REQUEST, GET_TESTCUSTOMERPAYMENT_ONSHOP_SUCCESS, GET_TESTCUSTOMERPAYMENT_REQUEST, GET_TESTCUSTOMERPAYMENT_SUCCESS, GET_TESTDASHBOARDONBRANCH_FAIL, GET_TESTDASHBOARDONBRANCH_REQUEST, GET_TESTDASHBOARDONBRANCH_SUCCESS, GET_TESTDASHBOARD_FAIL, GET_TESTDASHBOARD_REQUEST, GET_TESTDASHBOARD_SUCCESS, GET_TESTSTATISTICSONBRANCH_FAIL, GET_TESTSTATISTICSONBRANCH_REQUEST, GET_TESTSTATISTICSONBRANCH_SUCCESS, GET_TESTSTATISTICS_FAIL, GET_TESTSTATISTICS_REQUEST, GET_TESTSTATISTICS_SUCCESS, POST_TESTCUSTOMERPAYMENT_FAIL, POST_TESTCUSTOMERPAYMENT_REQUEST, POST_TESTCUSTOMERPAYMENT_SUCCESS, UPDATE_TESTCUSTOMERPAYMENT_DETAIL_FAIL, UPDATE_TESTCUSTOMERPAYMENT_DETAIL_REQUEST, UPDATE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS, UPDATE_TESTCUSTOMERPAYMENT_STATUS_FAIL, UPDATE_TESTCUSTOMERPAYMENT_STATUS_REQUEST, UPDATE_TESTCUSTOMERPAYMENT_STATUS_SUCCESS} from "../Constants/testCustomerPaymentConstants"

export const postTestCustomerPaymentRecord =  (  
    cust_id,
    test_id,
    branch_id,
    attempts,
    payments,
    paymentStatus,
    discount,
    totalPayment) => async (dispatch) =>  {
    try {
      dispatch({ type: POST_TESTCUSTOMERPAYMENT_REQUEST });
      console.log(payments)
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axiosInstance.post(
        `/api/v1/createTestCustomerPayment`,
        {
            cust_id,
            test_id,
            branch_id,
            attempts,
            payments,
            paymentStatus,
            discount,
            totalPayment
        }, 
        config
      );
      dispatch({ type: POST_TESTCUSTOMERPAYMENT_SUCCESS,
        payload: data });
    } catch (error) {
      dispatch({ type: POST_TESTCUSTOMERPAYMENT_FAIL,
        payload: error.response, });
    }
  };

  export const getCustomerStudentRecord = () => async (dispatch) => {
    try {
      dispatch({ type: GET_TESTCUSTOMERPAYMENT_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getAllTestCustomerPayment`);
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_FAIL,
        payload: error.response,
      });
    }
  };

  export const getCustomerStudentRecordOnShop = (branchCode) => async (dispatch) => {
    try {
      dispatch({ type: GET_TESTCUSTOMERPAYMENT_ONSHOP_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getTestPaymentRecordOnShop/${branchCode}`);
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_ONSHOP_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_ONSHOP_FAIL,
        payload: error.response,
      });
    }
  };


  export const getSpecificCustomerTestRecord = (id) => async (dispatch) => {
    try {
      dispatch({ type: GET_TESTCUSTOMERPAYMENT_DETAIL_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getTestCustomerPayment/${id}`);
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };


  
  export const getTestPaymentRecordOnDates = (starting, ending) => async (dispatch) => {
    try {
      dispatch({ type: GET_TESTCUSTOMERPAYMENT_ONDATES_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getTotalStudents?starting=${starting}&ending=${ending}`);
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_ONDATES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_ONDATES_FAIL,
        payload: error.response,
      });
    }
  };

  export const getTestPaymentRecordOnDatesForBranch = (branch, starting, ending) => async (dispatch) => {
    try {
      dispatch({ type: GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_REQUEST });
      console.log(branch, starting, ending)
      const { data } = await axiosInstance.get(`/api/v1/getTotalStudentsForBranch/${branch}?starting=${starting}&ending=${ending}`);
      console.log(data)
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TESTCUSTOMERPAYMENT_ONDATES_FORBRANCH_FAIL,
        payload: error.response,
      });
    }
  };


  export const getTestAndVisaPaymentNotifications = () => async (dispatch) => {
    try {
      dispatch({ type: GET_NOTIFICATION_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getMessageNotificationRecord`);
      dispatch({
        type: GET_NOTIFICATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_NOTIFICATION_FAIL,
        payload: error.response,
      });
    }
  };



  export const updateCustomerTestRecord = (id,  payments, paymentStatus) => async (dispatch) => {
    try {
      console.log(payments)
      dispatch({ type: UPDATE_TESTCUSTOMERPAYMENT_DETAIL_REQUEST });
      console.log(payments)
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } =await axiosInstance.put(`/api/v1/editTestCustomerPayment/${id}`, {
        payments,
        paymentStatus
      },
      config);
      dispatch({
        type: UPDATE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TESTCUSTOMERPAYMENT_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };

  export const updateCustomerTestStatus = (id,  Status ) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_TESTCUSTOMERPAYMENT_STATUS_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } =await axiosInstance.put(`/api/v1/editTestCustomerPayment/${id}`, {
        Status
      },
      config);
      console.log(data)
      dispatch({
        type: UPDATE_TESTCUSTOMERPAYMENT_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TESTCUSTOMERPAYMENT_STATUS_FAIL,
        payload: error.response,
      });
    }
  };


  export const getTestStatistics = (period) => async (dispatch) => {
    try {
      dispatch({ type: GET_TESTSTATISTICS_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/statistics?period=${period}`);
      dispatch({
        type: GET_TESTSTATISTICS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TESTSTATISTICS_FAIL,
        payload: error.response,
      });
    }
  };
  
  export const getTestStatisticsOnBranch = (branchId, period ) => async (dispatch) => {
    try {
      dispatch({ type: GET_TESTSTATISTICS_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/statisticsOnBranch/${branchId}?period=${period}`);
      dispatch({
        type: GET_TESTSTATISTICS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TESTSTATISTICS_FAIL,
        payload: error.response,
      });
    }
  };

  // export const getTestDashboard =async ( period ) => {
  //   try {
  //     // dispatch({ type: GET_TESTSTATISTICS_REQUEST });
  //     const { data } = await axiosInstance.get(`/api/v1/getTestsDashBoard?period=${period}`);
  //     // dispatch({
  //     //   type: GET_TESTSTATISTICS_SUCCESS,
  //     //   payload: data,
  //     // });
  //     return data
  //   } catch (error) {
  //     // dispatch({
  //     //   type: GET_TESTSTATISTICS_FAIL,
  //     //   payload: error.response,
  //     // });
  //   }
  // };

  export const getTestDashboard = (period) => async (dispatch) => {
    try {
      dispatch({ type: GET_TESTDASHBOARD_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getTestsDashBoard?period=${period}`);
      
      dispatch({
        type: GET_TESTDASHBOARD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TESTDASHBOARD_FAIL,
        payload: error.response,
      });
    }
  };
  
  export const getTestDashboardOnBranch = (branchId, period ) => async (dispatch) => {
    try {
      dispatch({ type: GET_TESTDASHBOARD_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getTestsDashBoardOnBranch/${branchId}?period=${period}`);
      dispatch({
        type: GET_TESTDASHBOARD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TESTDASHBOARD_FAIL,
        payload: error.response,
      });
    }
  };