import React, { useEffect, useState, useRef } from "react";
import { UseDispatch, useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "./testBooking.css"
import backImage from "../../images/pageBackground.jpg"
import ReactToPrint from "react-to-print";
// import Header from "./Header"
import LaserPrintTable from "../../TableComponent/LaserPrintTable"
import { getBookingRecordDetail } from "../../../Actions/testBookingsAction";
import Header from "./Header";
import Dates from "./Dates";
// import Dates from "./Dates"

const TestBookingInvoice = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const testRef = useRef();
    const [colorTheme, setColorTheme] = useState()
    const {testCustomerPaymentDetailRecord, testCustomerPaymentDetailRecordLoading} = useSelector((state)=> state.testCustomerPaymentDetailRecord)
    const {bookingDetailRecord, bookingDetailRecordLoading} = useSelector((state)=> state.bookingDetailRecord)
    const navigate = useNavigate()
    useEffect(()=>{
        setColorTheme(localStorage.getItem('theme-color'))
      }, [localStorage.getItem('theme-color')])
    
    useEffect(()=>{
        if(bookingDetailRecord?.success && !bookingDetailRecordLoading)
        {
            console.log(bookingDetailRecord)
        }

    }, [bookingDetailRecord,bookingDetailRecordLoading])
    useEffect(()=> {
        dispatch(getBookingRecordDetail(params.id))
    }, [])
  
    const columns = [
        { field: "downPayment", label: "Amount Payed" },
         { field: "paymentPayed", label: "Total Payed Amount" },
        { field: "remaining", label: "Balance" },
        {field: "createdAt", label: "Date", format: "date"},
        {field: "createdAt", label: "Time", format: "time"}
      ];
    return(
        <>
           <div className={`testBookingMain ${colorTheme}`}>
        <div className={`testBooking`}>
    

        <div className="previewTable">
        <div className="previewButtons">
        <button onClick={()=>navigate('/testBookingTable')}>Back</button>
        <ReactToPrint
            trigger={() => (
              <button style={{padding: "10px"}} >
                Print / Download
              </button>
            )}
            content={() => testRef.current}
          />
      </div>
            <div ref={testRef} style={{overflow: "auto", height: "95%", display: "hidden", backgroundImage: `${backImage}`}} className="p-5">
            <div className="printcontainer"> </div> 
            <div  className="testPrintPagePrev" style={{margin: "10px", border: "2px solid black", }}>
              <Header />
              <Dates />
              {/* </div> */}
            {/* {
                !testCustomerPaymentDetailRecordLoading && (
                  <LaserPrintTable 
                     data={testCustomerPaymentDetailRecord?.testCustomerPaymentData?.payments}
                     columns={columns}
                />)
            } */}
            </div>
            </div>
            </div>
        </div>
        </div>
      
        </>
    )
}
export default TestBookingInvoice