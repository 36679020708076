import React, { useEffect, useState } from "react";
import CustomerVisaTable from "../CustomerVisa/visaTableRecord";
import CustomerStudentTable from "../CustomerStudents/studentTableRecord";
import "./customerTable.css"
const CustomerRecordTable = () =>{
    const [selectedOption, setSelectedOption] = useState()
    const [colorTheme, setColorTheme] = useState()
    const [visitorSelected, setVisitorSelected] = useState()
    const [visaSelected, setVisaSelected] = useState()
    const [studentSelected, setStudentSelected] = useState()
    const [isOpen, setIsOpen] = useState(false)

    const categoryOptions = [
        { key: "1", text: "Visitors", value: "Visitors" },
        { key: "2", text: "Students", value: "Students" },
        { key: "3", text: "Visa Record", value: "Visa Record" },
      ];
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
      };

    const handleCategoryOptionSelection = (value) => {
      if(value === "Visitors")
      {
        console.log('visit')
        setVisitorSelected(value)
        setVisaSelected('')
        setStudentSelected('')
      }else if(value === "Students")
      {
        console.log('stud')
        setStudentSelected(value)
        setVisaSelected('')
        setVisitorSelected('')
      }else if(value === "Visa Record")
      {
        console.log('visa')
        setVisitorSelected('')
        setStudentSelected('')
        setVisaSelected(value)
      }
        setSelectedOption(value)
        setIsOpen(!isOpen)
      }
    
      
  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])
    return(
        <>
        <div className={`customerRecordTable ${colorTheme}`}>
        <div className={`customerRecord`}>
        <div className="customerRecordFirstInputDiv">
            <div className="custom-dropdown">
                <div className="selected-option" onClick={toggleDropdown}>
                  {selectedOption || 'Select an option'}
                </div>
                {isOpen && (
                  <div className="options">
                    {categoryOptions?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleCategoryOptionSelection(option.value)}
                    >
                    {option?.text}
                    </div>
                    ))}
                </div>
                )}
              </div>
            </div>
        <div className="customerRecordTable">
                {
                  visaSelected ? ( <CustomerVisaTable />) : (<>
                  {
                    studentSelected ? (<CustomerStudentTable />) : (<></>)
                  }</>)
                }
                 
              </div>
        </div>
        </div>
        </>
    )
}

export default CustomerRecordTable;