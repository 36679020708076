
import React, { useContext,useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { useNavigate } from "react-router-dom";
import { getTestRecord } from "../../../Actions/testAction";
import { getBranchRecord } from "../../../Actions/branchAction";
import "./testBooking.css"
import {  getCustomerRecords,  } from "../../../Actions/customerAction";

import PageLoader from "../../Loader/PageLoader";
import { useAlert } from "react-alert";
import { PostTestBookingRecord } from "../../../Actions/testBookingsAction";
import MainTableComponent from "../../TableComponent/MainTableComponent";
import { POST_BOOKING_FAIL, POST_BOOKING_SUCCESS } from "../../../Constants/bookingConstants";
import { CustomerInvoiceDataSearch } from "../../SearchComponent/customerInvoiceSearch";

const TestBookingForm = () => {
  const dispatch = useDispatch()  
  const navigate = useNavigate()
  const alert = useAlert()
  const customerDropdownRef = useRef(null);
  const branchDropdownRef = useRef(null)
  const testTypeDropdownRef = useRef(null)
  const testDropdownRef = useRef(null)
  const [custName, setCustName] = useState()
  const [customerEmail, setCustomerEmail] = useState()
  const [tableData, setTableData] = useState()
  const [tableDataLoading, setTableDataLoading] = useState(true)
// Test Booking Data
const [showModal, setShowModal] = useState(false)
const [isCustomerOpen, setIsCustomerOpen] = useState(false)
const [customerName, setCustomerName] = useState()
const [customerValue, setCustomerValue] = useState()
const [selectedTestName, setSelectedTestName] = useState()
const [testValue, setTestValue]= useState()
const [isTestOpen, setIsTestOpen] = useState(false)
const [isBranchOpen, setIsBranchOpen] = useState(false)
const [branchCode, setBranchCode] = useState()
const [branch, setBranch] = useState()
const [isTestTypeOpen, setIsTestTypeOpen] = useState(false)
const [testType, setTestType] = useState() 
const [colorTheme, setColorTheme] = useState()
const [pakistanDate, setPakistanDate] = useState(null);
const [pakistanTime, setPakistanTime] = useState()
const [pakTimeValue, setPakTimeValue] = useState()
const [otherCountryTimeValue, setOtherCountryTimeValue] = useState()
const [testCountryName, setTestCountryName] = useState()
const [otherCountryDate, setOtherCountryDate] = useState()
const [otherCountryTime, setOtherCountryTime] = useState()
const [note, setNote] = useState()
const {loading, testRecord} = useSelector((state)=> state.testRecord)
const {branchLoading, branchRecord} = useSelector((state)=> state.branchRecord)
const {customerRecord, customerRecordLoading} = useSelector((state)=> state.customerRecord)
const {postBooking, postBookingLoading, postBookingError} = useSelector((state)=> state.postBooking)
const {user} = useSelector((state)=> state.user)

const linkk = "updateCustomerRecord";
const actionUpdate = "Update";
const action3 = "existingCustomer";

const testTypeOptions = [
    { key: "1", text: "Reading", value: "Reading" },
    { key: "2", text: "Listening", value: "Listening" },
    { key: "3", text: "Writing", value: "Writing" },
    { key: "3", text: "Speaking", value: "Speaking" },
    { key: "4", text: "LRW", value: "LRW" },
  ];

  const studentOptions = [
    { key: "1", text: "Existing Students", value: "existing" },
    { key: "2", text: "New Student", value: "newStudent" },
  ];

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])



useEffect(()=>{
    dispatch(getCustomerRecords())
    dispatch(getTestRecord())
    dispatch(getBranchRecord())
}, [])

const customerNameToggleDropdown  = () => {
    setIsCustomerOpen(!isCustomerOpen);
  };

  const testToggleDropdown = () => {
    setIsTestOpen(!isTestOpen);
  };


  const handleCustomerValue = (value, name) => {
    if(value === "existing")
    {
      setShowModal(!showModal)
    }else{
      navigate("/customerAdd")
    }
  
    setIsCustomerOpen(!isCustomerOpen);
  }

  const handleTestValue = (value, name) => {
    setTestValue(value)
    setSelectedTestName(name)
    setIsTestOpen(!isTestOpen)
  } 

  
  const branchToggleDropdown = () => {
    setIsBranchOpen(!isBranchOpen);
  };

  const handleBranchValue = (value, code) => {
    setBranchCode(code)
    setBranch(value)
    setIsBranchOpen(!isBranchOpen);
  }

  useEffect(()=>{
    if(user?.user?.role?.roleName !== "superAdmin" || user?.user?.role?.roleName !== "Administrator")
    {
    setBranchCode(user?.user?.branch?.branchCode)
    setBranch(user?.user?.branch?._id)
    }
  }, [])

    const testTypeToggleDropdown = () => {
        setIsTestTypeOpen(!isTestTypeOpen);
      };
      
    const handleTestTypeValue = (value) =>{
        setTestType(value)
        setIsTestTypeOpen(!isTestTypeOpen);
    }


      const ShowTableRecord = async () => {
        navigate('/testBookingTable')
    }

 

  const handlePakistanDateChange = (event) => {
    setPakistanDate(event.target.value);
    setOtherCountryDate(event.target.value)
  };
    
  const handlePakistanTimeChange = (event) => {
    setPakTimeValue(event.target.value)
    console.log(event)
    const inputTime = event.target.value;
    const [hour, minute] = inputTime.split(':');
    let formattedHour = parseInt(hour, 10);

    // Adjust hour to 12-hour format
    if (formattedHour >= 12) {
      formattedHour -= 12;
    }
    if (formattedHour === 0) {
      formattedHour = 12;
    }
    const period = hour >= 12 ? 'PM' : 'AM';

    const formattedTime = `${formattedHour}:${minute} ${period}`;
    console.log(formattedTime)
    setPakistanTime(formattedTime);
  };

  const handleOtherCountryDateChange = (event) => {
    setOtherCountryDate(event.target.value);
  };
    
  const handleOtherCountryTimeChange = (event) => {
    setOtherCountryTimeValue(event.target.value)
    console.log(event)
    const inputTime = event.target.value;
    const [hour, minute] = inputTime.split(':');
    let formattedHour = parseInt(hour, 10);

    // Adjust hour to 12-hour format
    if (formattedHour >= 12) {
      formattedHour -= 12;
    }
    if (formattedHour === 0) {
      formattedHour = 12;
    }
    const period = hour >= 12 ? 'PM' : 'AM';
    
    const formattedTime = `${formattedHour}:${minute} ${period}`;
    console.log(formattedTime)
    // setPakistanTime(formattedTime);
    setOtherCountryTime(formattedTime);
  };
    

    const ClearRecord = () => {
      setCustomerName('')
      setCustomerValue('')
      setTestType('')
      setTestValue('')
      setSelectedTestName('')
      setTestType('')
      setPakistanDate('')
      setPakistanTime('')
      setPakTimeValue('')
      setOtherCountryDate('')
      setOtherCountryTime('')
      setOtherCountryTimeValue('')
      setTestCountryName('')
      setNote('')
      };
    
      const AddBooking = () => {
        dispatch(PostTestBookingRecord(customerValue, branch, testValue, testType, pakistanDate, pakistanTime, testCountryName, otherCountryDate, otherCountryTime, note))
      };

      useEffect(()=>{
        if(postBooking?.success && !postBookingLoading)
        {
          postBookingSuccessMessage()
        }
      }, [postBooking, postBookingLoading])

      useEffect(()=>{
        if(postBookingError?.data && !postBookingLoading)
        {
          postBookingErrorMessage(postBookingError?.data?.message)
        }
      }, [postBooking, postBookingError])
      
      const postBookingSuccessMessage =()=>{
        dispatch({
          type: POST_BOOKING_SUCCESS,
          payload: [],
        });
        return alert.success("Record Added Successfully")
      }

      const postBookingErrorMessage =(message)=>{
        dispatch({
          type: POST_BOOKING_FAIL,
          payload: [],
        });
        return alert.error(message)
      }

      useEffect(()=>{
        const handleClickCustomerOutside = (event) => {
             if (customerDropdownRef.current && !customerDropdownRef.current.contains(event.target)) {
               setIsCustomerOpen(false);
             }
             console.log('clikced')
           };
           window.addEventListener('click', handleClickCustomerOutside);
           return () => {
             window.removeEventListener('click', handleClickCustomerOutside);
           };
         }, [])

      useEffect(()=>{
        const handleClickTestOutside = (event) => {
             if (testDropdownRef.current && !testDropdownRef.current.contains(event.target)) {
               setIsTestOpen(false);
             }
             console.log('clikced')
           };
           window.addEventListener('click', handleClickTestOutside);
           return () => {
             window.removeEventListener('click', handleClickTestOutside);
           };
         }, [])

         useEffect(()=>{
          const handleClickBranchOutside = (event) => {
               if (branchDropdownRef.current && !branchDropdownRef.current.contains(event.target)) {
                 setIsBranchOpen(false);
               }
               console.log('clikced')
             };
             window.addEventListener('click', handleClickBranchOutside);
             return () => {
               window.removeEventListener('click', handleClickBranchOutside);
             };
           }, [])

           useEffect(() => {
            const handleClickOutside = (event) => {
              if (testTypeDropdownRef.current && !testTypeDropdownRef.current.contains(event.target)) {
                console.log('fie')
                setIsTestTypeOpen(false);
              }
              console.log('clikced')
            };
            window.addEventListener('click', handleClickOutside);
            return () => {
              window.removeEventListener('click', handleClickOutside);
            };
          }, []); 

          
  useEffect(()=>{
    if(!customerRecordLoading && customerRecord?.success)
    {
      setTableData(customerRecord?.student)
      setTableDataLoading(false)
    }
  }, [customerRecordLoading, customerRecord])

    const handleSearch=async(name, email)=>{
      const data = await CustomerInvoiceDataSearch(customerRecord?.student, name, email)
            setTableData(data)
    }



  const customerValueChange = (id, itemName) =>{
    console.log(id, itemName)
    setCustomerValue(id)
    setCustomerName(itemName)
    setShowModal(!showModal)
  }

          const columns = [
            { field: "name", label: "Customer Name" },
            { field: "email", label: "Email" },
            { field: "contactNo", label: "Phone No" },
            // { field: "branch.branchCode", label: "Branch" },
            { field: "address", label: "Address" },
            { field: "status", label: "Status" },
          ];
        
          const actions = [
            {
              label: "existingCustomer",
              color: "yellow",
              handler: (itemId, name) => customerValueChange(itemId, name),
            },
          ];

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`testBookingMain ${colorTheme}`}>
      <div className={`testBooking`}>
        <div className="testBookingSecond">
        {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <h2>Existing Students</h2>
            <div className="searchTestBooking-withouInputfields-box" >
            <div className="testBookingTableFirstInputDiv">
              <input
                label={"Enter Customer Name"}
                className="testBookingTableInputField"
                type="text"
                placeholder={"Enter Customer Name"}
                name="customerName"
                autoComplete="off"
                maxLength="40"
                required
                value={custName}
                onChange={(e)=>{let name = e.target.value
                  setCustName(e.target.value)
                  handleSearch(name, customerEmail)
                }}
              />
              <input
              label={"Contact No"}
              type="email"
              className="testBookingTableInputField"
              placeholder={"Enter Email"}
              name="contactNo"
              autoComplete="off"
              maxLength="40"
              required
              value={customerEmail}
                onChange={(e)=>{ let email = e.target.value
                  setCustomerEmail(e.target.value)
                  handleSearch(custName, email)
                }}
              />
              <input
              label={"Address"}
              className="testBookingTableInputField"
              type="text"
              placeholder={"Enter Address"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              />
            </div>
    </div>
            {!tableDataLoading ? (
               <div className="booking-table-container">
                <MainTableComponent
                    data={tableData}
                    columns={columns}
                    actions={actions}
                    actionUpdate={actionUpdate}
                    linkk= {linkk}
                    // action3={action3}
                />
               </div>) : (<>
                  <div className="booking-table-container">
             <PageLoader />
             </div>
             </>
            //     // <Loader active>Loading</Loader>
             )} 
          </div>
        </div>)}
          <div className="heading">
            <h3>{"Add Bookings"}</h3> 
            <button onClick={ShowTableRecord}>
              View Records
            </button>
            </div>
            {
              !postBookingLoading ? (<>
                      <div className="testBookingFirstInputDiv">
            <div className="labeAndInputDiv">
              <label>Customer Name <span>*</span></label>
              <div ref={customerDropdownRef} className="custom-dropdown">
                <div className="selected-option" onClick={customerNameToggleDropdown}>
                  {customerName || 'Select Name option'}
                </div>
                {isCustomerOpen && (
                  <div className="options">
                    {studentOptions?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleCustomerValue(option?.value)}
                    >
                    {option?.text}
                    </div>
                    ))}
                </div>
                )}
              </div>
            </div>
              <div className="labeAndInputDiv">
                <label>Test Name <span>*</span></label>
                <div ref={testDropdownRef} className="custom-dropdown">
                <div className="selected-option" onClick={testToggleDropdown}>
                  {selectedTestName || 'Select Test option'}
                </div>
                {isTestOpen && (
                  <div className="options">
                    {testRecord?.test?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleTestValue(option._id, option.testName)}
                    >
                    {option?.testName}
                    </div>
                    ))}
                </div>
                )}
              </div>
            </div>
            <div className="labeAndInputDiv">
             <label>Branch Name <span>*</span></label>
             {
                 user?.user?.role?.roleName === "superAdmin" || user?.user?.role?.roleName === "Administrator" ? (
                  <div ref={branchDropdownRef} className="custom-dropdown">
                  <div className="selected-option" onClick={branchToggleDropdown}>
                    {branchCode || 'Select an option'}
                  </div>
                  {isBranchOpen && (
                    <div className="options">
                      {branchRecord?.branch?.map((option, index) => (
                      <div
                        key={index}
                        className="option"
                        onClick={() => handleBranchValue(option._id, option.branchCode)}
                      >
                      {option?.branchCode + ", " + option?.branchAddress}
                      </div>
                      ))}
                  </div>
                  )}
                </div>
                 ) : (
                  <input
                type="text"
                className="testBookingInputField"
                placeholder={"Enter Branch"}
                name="productCode"
                autoComplete="off"
                maxLength="40"
                required
                value = {branchCode}
                disabled
                />
                 )
             }
            
            </div>      
            </div>
            <div className="testBookingInputDiv">
            <div className="labeAndInputDiv">
              <label>Select Test Type <span>*</span></label>
               <div ref={testTypeDropdownRef} className="custom-dropdown">
                <div className="selected-option" onClick={testTypeToggleDropdown}>
                  {testType || 'Select Status option'}
                </div>
                {isTestTypeOpen && (
                  <div className="options">
                    {testTypeOptions?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleTestTypeValue(option.text)}
                    >
                    {option?.text}
                    </div>
                    ))}
                </div>
                )}
              </div>
            </div>
            <div className="labeAndInputDiv">
            <label>Select Pakistan Date <span>*</span></label>
            <input
               className="testBookingInputField"
              type="date"
              value={pakistanDate}
              onChange={handlePakistanDateChange}
            />
            </div>
            <div className="labeAndInputDiv">
            <label>Select Pakistan Time <span>*</span></label>
            <input
              className="testBookingInputField"
              type="time"
              value={pakTimeValue}
              onChange={handlePakistanTimeChange}
            />
            </div>
            </div>
            <div className="testBookingInputDiv">
            <div className="labeAndInputDiv">
            <label>Select Test Country <span>*</span></label>
            <input
              placeholder="Select Country"
              className="testBookingInputField"
              type="text"
              value={testCountryName}
              onChange={(e)=> setTestCountryName(e.target.value)}
            />
            </div>
            <div className="labeAndInputDiv">
            <label>Select Test Country Date <span>*</span></label>
            <input
               className="testBookingInputField"
              type="date"
              value={otherCountryDate}
              onChange={handleOtherCountryDateChange}
            />
            </div>
            <div className="labeAndInputDiv">
            <label>Select Test Country Time <span>*</span></label>
            <input
              className="testBookingInputField"
              type="time"
              value={otherCountryTimeValue}
              onChange={handleOtherCountryTimeChange}
            />
            </div>
            </div>
            <div className="LastTestBookingInputDiv">
            <div className="labeAndInputDiv">
            <label>Enter Note <span>*</span></label>
            <textarea
              className="testBookingInputField"
              type="text"
              value={note}
              onChange={(e)=> setNote(e.target.value)}
            />
               </div>
            </div>
          
            <div className="buttons">
            <button onClick={ClearRecord}>Clear</button>
              <button onClick={AddBooking}>Add Record</button>
              
              </div>
              </>) : (
                <PageLoader />
              )
            }
        
          </div>
        <div>
          
      </div>
    </div>
  </div>
    </>
  );
};

export default TestBookingForm;
