import { DELETE_EXPENSETYPE_DETAIL_FAIL, DELETE_EXPENSETYPE_DETAIL_REQUEST, DELETE_EXPENSETYPE_DETAIL_SUCCESS, GET_EXPENSETYPE_DETAIL_FAIL, GET_EXPENSETYPE_DETAIL_REQUEST, GET_EXPENSETYPE_DETAIL_SUCCESS, GET_EXPENSETYPE_FAIL, GET_EXPENSETYPE_REQUEST, GET_EXPENSETYPE_SUCCESS, POST_EXPENSETYPE_FAIL, POST_EXPENSETYPE_REQUEST, POST_EXPENSETYPE_SUCCESS, UPDATE_EXPENSETYPE_DETAIL_FAIL, UPDATE_EXPENSETYPE_DETAIL_REQUEST, UPDATE_EXPENSETYPE_DETAIL_SUCCESS } from "../Constants/expenseTypeConstants";
import axiosInstance from "./baseURL";

export const PostExpenseTypeRecord = (expenseType, expenseTypeDescription) => async (dispatch) => {
    try {
      dispatch({ type: POST_EXPENSETYPE_REQUEST });
      const config = {headers: {"Content-Type": "application/json"}}
      const { data } = await axiosInstance.post(`/api/v1/createExpenseType`, {expenseType, expenseTypeDescription}, config);
      dispatch({
        type: POST_EXPENSETYPE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_EXPENSETYPE_FAIL,
        payload: error.response,
      });
    }
  };


  export const GetExpenseTypeRecord = () => async (dispatch) => {
    try {
      dispatch({ type: GET_EXPENSETYPE_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getExpenseType`);
      dispatch({
        type: GET_EXPENSETYPE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EXPENSETYPE_FAIL,
        payload: error.response,
      });
    }
  };

  export const getSpecificExpenseTypeRecord = (id) => async (dispatch) => {
    try {
      dispatch({ type: GET_EXPENSETYPE_DETAIL_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getExpenseTypeDetails/${id}`);
      dispatch({
        type: GET_EXPENSETYPE_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EXPENSETYPE_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };

  export const updateExpenseTypeRecord = (id,  expenseType, expenseTypeDescription) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_EXPENSETYPE_DETAIL_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = axiosInstance.put(`/api/v1/editExpenseType/${id}`, {
        expenseType, expenseTypeDescription
      },
      config);
      dispatch({
        type: UPDATE_EXPENSETYPE_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_EXPENSETYPE_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };

  export const deleteExpenseTypeRecords = (id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_EXPENSETYPE_DETAIL_REQUEST });
      const { data } = await axiosInstance.delete(`/api/v1/deleteExpenseType/${id}`);
      dispatch({
        type: DELETE_EXPENSETYPE_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_EXPENSETYPE_DETAIL_FAIL,
        payload: error.response,
      });
    }
  };