import {  CLEAR_ERRORS, DELETE_TESTCUSTOMERPAYMENT_DETAIL_FAIL, DELETE_TESTCUSTOMERPAYMENT_DETAIL_REQUEST, DELETE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS, GET_TESTCUSTOMERPAYMENT_DETAIL_FAIL, GET_TESTCUSTOMERPAYMENT_DETAIL_REQUEST, GET_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS, GET_TESTCUSTOMERPAYMENT_FAIL, GET_TESTCUSTOMERPAYMENT_REQUEST, GET_TESTCUSTOMERPAYMENT_SUCCESS, POST_TESTCUSTOMERPAYMENT_FAIL, POST_TESTCUSTOMERPAYMENT_REQUEST, POST_TESTCUSTOMERPAYMENT_SUCCESS, UPDATE_TESTCUSTOMERPAYMENT_DETAIL_FAIL, UPDATE_TESTCUSTOMERPAYMENT_DETAIL_REQUEST, UPDATE_TESTCUSTOMERPAYMENT_DETAIL_SUCCESS } from "../Constants/testCustomerPaymentConstants";
import { DELETE_VISACUSTOMERPAYMENT_DETAIL_FAIL, DELETE_VISACUSTOMERPAYMENT_DETAIL_REQUEST, DELETE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS, GET_VISACUSTOMERPAYMENT_DETAIL_FAIL, GET_VISACUSTOMERPAYMENT_DETAIL_REQUEST, GET_VISACUSTOMERPAYMENT_DETAIL_SUCCESS, GET_VISACUSTOMERPAYMENT_FAIL, GET_VISACUSTOMERPAYMENT_ONDATES_FAIL, GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_FAIL, GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_REQUEST, GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_SUCCESS, GET_VISACUSTOMERPAYMENT_ONDATES_REQUEST, GET_VISACUSTOMERPAYMENT_ONDATES_SUCCESS, GET_VISACUSTOMERPAYMENT_ONSHOP_FAIL, GET_VISACUSTOMERPAYMENT_ONSHOP_REQUEST, GET_VISACUSTOMERPAYMENT_ONSHOP_SUCCESS, GET_VISACUSTOMERPAYMENT_REQUEST, GET_VISACUSTOMERPAYMENT_SUCCESS, GET_VISADASHBOARD_FAIL, GET_VISADASHBOARD_REQUEST, GET_VISADASHBOARD_SUCCESS, GET_VISASTATISTICSONBRANCH_FAIL, GET_VISASTATISTICSONBRANCH_REQUEST, GET_VISASTATISTICSONBRANCH_SUCCESS, GET_VISASTATISTICS_FAIL, GET_VISASTATISTICS_REQUEST, GET_VISASTATISTICS_SUCCESS, POST_VISACUSTOMERPAYMENT_FAIL, POST_VISACUSTOMERPAYMENT_REQUEST, POST_VISACUSTOMERPAYMENT_SUCCESS, UPDATE_VISACUSTOMERPAYMENT_DETAIL_FAIL, UPDATE_VISACUSTOMERPAYMENT_DETAIL_REQUEST, UPDATE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS } from "../Constants/visaCustomerPaymentConstants";


export const getVisaCustomerPaymentReducer = (state = { visaCustomerPaymentRecord: []}, action)=>{
    switch (action.type){
        case GET_VISACUSTOMERPAYMENT_REQUEST:
            return{
                visaCustomerPaymentRecordLoading: true,
                visaCustomerPaymentRecord: [],
            };
        case GET_VISACUSTOMERPAYMENT_SUCCESS: 
            return {
                visaCustomerPaymentRecordLoading: false,
                visaCustomerPaymentRecord: action.payload
            }
        case GET_VISACUSTOMERPAYMENT_FAIL:
            return {
                visaCustomerPaymentRecordLoading: false,
                visaCustomerPaymentRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                visaCustomerPaymentRecordError: null,
            }
            default:
            return state;
            
    }
}

export const getVisaDashboardReducer = (state = { visaDashboard: []}, action)=>{
    switch (action.type){
        case GET_VISADASHBOARD_REQUEST:
            return{
                visaDashboardLoading: true,
                visaDashboard: [],
            };
        case GET_VISADASHBOARD_SUCCESS: 
            return {
                visaDashboardLoading: false,
                visaDashboard: action.payload
            }
        case GET_VISADASHBOARD_FAIL:
            return {
                visaDashboardLoading: false,
                visaDashboardError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                visaDashboardError: null,
            }
            default:
            return state;
            
    }
}


export const getVisaCustomerPaymentOnShopReducer = (state = { visaCustomerPaymentRecordOnShop: []}, action)=>{
    switch (action.type){
        case GET_VISACUSTOMERPAYMENT_ONSHOP_REQUEST:
            return{
                visaCustomerPaymentRecordOnShopLoading: true,
                visaCustomerPaymentRecordOnShop: [],
            };
        case GET_VISACUSTOMERPAYMENT_ONSHOP_SUCCESS: 
            return {
                visaCustomerPaymentRecordOnShopLoading: false,
                visaCustomerPaymentRecordOnShop: action.payload
            }
        case GET_VISACUSTOMERPAYMENT_ONSHOP_FAIL:
            return {
                visaCustomerPaymentRecordOnShopLoading: false,
                visaCustomerPaymentRecordOnShopError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                visaCustomerPaymentRecordOnShopError: null,
            }
            default:
            return state;
            
    }
}

export const getVisaCustomerPaymentDetailReducer = (state = { visaCustomerPaymentDetailRecord: []}, action)=>{
    switch (action.type){
        case GET_VISACUSTOMERPAYMENT_DETAIL_REQUEST:
            return{
                visaCustomerPaymentDetailRecordLoading: true,
                visaCustomerPaymentDetailRecord: [],
            };
        case GET_VISACUSTOMERPAYMENT_DETAIL_SUCCESS: 
            return {
                visaCustomerPaymentDetailRecordLoading: false,
                visaCustomerPaymentDetailRecord: action.payload
            }
        case GET_VISACUSTOMERPAYMENT_DETAIL_FAIL:
            return {
                visaCustomerPaymentDetailRecordLoading: false,
                visaCustomerPaymentDetailRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                visaCustomerPaymentDetailRecordError: null,
            }
            default:
            return state;
            
    }
}

export const getVisaCustomerPaymentOnDatesReducer = (state = { visaCustomerPaymentOnDates: []}, action)=>{
    switch (action.type){
        case GET_VISACUSTOMERPAYMENT_ONDATES_REQUEST:
            return{
                visaCustomerPaymentOnDatesLoading: true,
                visaCustomerPaymentOnDates: [],
            };
        case GET_VISACUSTOMERPAYMENT_ONDATES_SUCCESS: 
            return {
                visaCustomerPaymentOnDatesLoading: false,
                visaCustomerPaymentOnDates: action.payload
            }
        case GET_VISACUSTOMERPAYMENT_ONDATES_FAIL:
            return {
                visaCustomerPaymentOnDatesLoading: false,
                visaCustomerPaymentOnDatesError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                visaCustomerPaymentOnDatesError: null,
            }
            default:
            return state;
            
    }
}


export const getVisaCustomerPaymentOnDatesForBranchReducer = (state = { visaCustomerPaymentOnDatesForBranch: []}, action)=>{
    switch (action.type){
        case GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_REQUEST:
            return{
                visaCustomerPaymentOnDatesForBranchLoading: true,
                visaCustomerPaymentOnDatesForBranch: [],
            };
        case GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_SUCCESS: 
            return {
                visaCustomerPaymentOnDatesForBranchLoading: false,
                visaCustomerPaymentOnDatesForBranch: action.payload
            }
        case GET_VISACUSTOMERPAYMENT_ONDATES_FORBRANCH_FAIL:
            return {
                visaCustomerPaymentOnDatesForBranchLoading: false,
                visaCustomerPaymentOnDatesForBranchError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                visaCustomerPaymentOnDatesForBranchError: null,
            }
            default:
            return state;
            
    }
}


export const getVisaStatisticsReducer = (state = { visaStatistics: []}, action)=>{
    switch (action.type){
        case GET_VISASTATISTICS_REQUEST:
            return{
                visaStatisticsLoading: true,
                visaStatistics: [],
            };
        case GET_VISASTATISTICS_SUCCESS: 
            return {
                visaStatisticsLoading: false,
                visaStatistics: action.payload
            }
        case GET_VISASTATISTICS_FAIL:
            return {
                visaStatisticsLoading: false,
                visaStatisticsError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                visaStatisticsError: null,
            }
            default:
            return state;
            
    }
}

export const getVisaStatisticsOnBranchReducer = (state = { visaStatisticsOnBranch: []}, action)=>{
    switch (action.type){
        case GET_VISASTATISTICSONBRANCH_REQUEST:
            return{
                visaStatisticsOnBranchLoading: true,
                visaStatisticsOnBranch: [],
            };
        case GET_VISASTATISTICSONBRANCH_SUCCESS: 
            return {
                visaStatisticsOnBranchLoading: false,
                visaStatisticsOnBranch: action.payload
            }
        case GET_VISASTATISTICSONBRANCH_FAIL:
            return {
                visaStatisticsOnBranchLoading: false,
                visaStatisticsOnBranchError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                visaStatisticsOnBranchError: null,
            }
            default:
            return state;
            
    }
}


export const updateVisaCustomerPaymentReducer = (state = { updateVisaCustomerPayment: []}, action)=>{
    switch (action.type){
        case UPDATE_VISACUSTOMERPAYMENT_DETAIL_REQUEST:
            return{
                updateVisaCustomerPaymentLoading: true,
                updateVisaCustomerPayment: [],
            };
        case UPDATE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS: 
            return {
                updateVisaCustomerPaymentLoading: false,
                updateVisaCustomerPayment: action.payload
            }
        case UPDATE_VISACUSTOMERPAYMENT_DETAIL_FAIL:
            return {
                updateVisaCustomerPaymentLoading: false,
                updateVisaCustomerPaymentError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                updateVisaCustomerPaymentError: null,
            }
            default:
            return state;
            
    }
}

export const deleteVisaCustomerPaymentReducer = (state = { deleteVisaCustomerPayment: []}, action)=>{
    switch (action.type){
        case DELETE_VISACUSTOMERPAYMENT_DETAIL_REQUEST:
            return{
                deleteVisaCustomerPaymentLoading: true,
                deleteVisaCustomerPayment: [],
            };
        case DELETE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS: 
            return {
                deleteVisaCustomerPaymentLoading: false,
                deleteVisaCustomerPayment: action.payload
            }
        case DELETE_VISACUSTOMERPAYMENT_DETAIL_FAIL:
            return {
                deleteVisaCustomerPaymentLoading: false,
                deleteVisaCustomerPaymentError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                deleteVisaCustomerPaymentError: null,
            }
            default:
            return state;
            
    }
}


export const postVisaCustomerPaymentReducer = (state = { postVisaCustomerPayment: []}, action)=>{
    switch (action.type){
        case POST_VISACUSTOMERPAYMENT_REQUEST:
            return{
                postVisaCustomerPaymentLoading: true,
                postVisaCustomerPayment: [],
            };
        case POST_VISACUSTOMERPAYMENT_SUCCESS: 
            return {
                postVisaCustomerPaymentLoading: false,
                postVisaCustomerPayment: action.payload
            }
        case POST_VISACUSTOMERPAYMENT_FAIL:
            return {
                postVisaCustomerPaymentLoading: false,
                postVisaCustomerPaymentError: action.payload.data,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                postVisaCustomerPaymentError: null,
            }
            default:
            return state;
            
    }
}