import React, { useState, useEffect } from "react";
import MetaData from "../../MetaData";
// import "./setting.css";
import "./mainSetting.css"
import { useNavigate } from "react-router-dom";
// import { getPermissionForRoles } from "../Pages/user/rolesAssigned/RolesPermissionValidation";
import Sidebar from "./SettingSidebar/SettingsSideBar";
import RolesTable from "../pages/Roles/rolesTable";
// import LanguageSettingPage from "./Language Setting/LanguageSettingPage";
// import RolesTable from "../Pages/user/roles/RolesTable";
// import DarkMode from "./ThemeSetting/DarkMode";
// import TableSettingPage from "./TableSetting/tablePageSetting";
// import UpdateProfileUser from "../features/users/updateProfileUser";
// import PrinterSettingPage from "./Printer Setting/printerPageSetting";
// import { useTranslation } from "react-i18next";
const SettingMainPage = () => {
  const navigate = useNavigate();
  const [colorTheme, setColorTheme] = useState("theme-white");
  const [rolePermission, setRolePermission] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState(null);
//   const { t, i18n } = useTranslation();

//   useEffect(() => {
//     setRolePermission(false);
//     getPermission();
//   }, []);

//   async function getPermission() {
//     try {
//       const permissionForAdd = await getPermissionForRoles("View Roles");
//       setRolePermission(permissionForAdd);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   }
  useEffect(() => {
    const currentThemeColor = localStorage.getItem("theme-color");
    console.log(localStorage.getItem("theme-color"));
    if (currentThemeColor) {
      setColorTheme(currentThemeColor);
      document.body.className = currentThemeColor;
    }
  }, [colorTheme]);


  const handleSidebarItemClick = (settingName) => {
    console.log(settingName)
    setSelectedSetting(settingName);
  };

  const renderSelectedSettingComponent = () => {
    switch (selectedSetting) {
      case 'Language':
        // return <LanguageSettingPage />;
      case 'Themes':
        // return <DarkMode />;
      case 'Permissions':
        return <RolesTable />;
      case 'Change Table Settings':
        // return <TableSettingPage />;
      case 'Change Password':
        // return <UpdateProfileUser />;
      case 'Printers':
        // return <PrinterSettingPage />;
      // Add other cases for different settings
      default:
        // return <LanguageSettingPage />;
    }
  };


  return (
    <>
      <MetaData title="QE ~~Settings" />
      <div className={`settings ${colorTheme}`}>
        <div className="settingsGlass">
          <Sidebar onSidebarItemClick={handleSidebarItemClick}/>
          {renderSelectedSettingComponent()}
        </div>
      </div>
      </>
  );
};

export default SettingMainPage;
