import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import LaserPrintTable from "../../TableComponent/LaserPrintTable"
import MetaData from "../../../MetaData"
import swal from "sweetalert2";
import { PostTestRecord, deleteTestRecord, getTestRecord } from "../../../Actions/testAction";
import { deleteBranchRecord, getBranchRecord } from "../../../Actions/branchAction";
import {useNavigate}  from "react-router-dom"
import "./testBooking.css"
import MainTableComponent from "../../TableComponent/MainTableComponent";
import { deleteBookingRecord, deleteBookingRecords, getBookingOnBranch, getBookingRecord, getBookingsRecordOnDates, getBookingsRecordOnDatesForBranch } from "../../../Actions/testBookingsAction";
import PageLoader from "../../Loader/PageLoader";
import { DELETE_BOOKING_DETAIL_FAIL, DELETE_BOOKING_DETAIL_SUCCESS, GET_BOOKING_ONSHOP_SUCCESS, GET_BOOKING_SUCCESS } from "../../../Constants/bookingConstants";
import { getCustomerRecords } from "../../../Actions/customerAction";
import { useAlert } from "react-alert";
import { TestInvoiceDataSearch } from "../../SearchComponent/TestInvoiceSearch";
let name;

const TestBookingRecord = () => {
  const testBookingRef = useRef();
  const dispatch = useDispatch()  
  const [colorTheme, setColorTheme] = useState()
  const [customerName, setCustomerName] = useState()
  const alert = useAlert()
  const [tableData, setTableData] = useState()
  const [tableRecord, setTableRecord] = useState()  
  const [loadingData, setLoadingData] = useState(true)
  const [startingDate, setStartingDate] = useState()
  const [endingDate, setEndingDate]= useState()
  const {user} = useSelector((state)=> state.user)
  const {bookingRecord, bookingRecordLoading}= useSelector((state)=> state.bookingRecord)
  const {bookingOnBranch, bookingOnBranchLoading} = useSelector((state)=> state.bookingOnBranch)
  const {deleteBookingRecord, deleteBookingRecordLoading, deleteBookingRecordError} = useSelector((state)=> state.deleteBookingRecord)
  const {updateTestRecordloading, updateTestRecord} = useSelector((state)=> state.updateTestRecord)
  const {bookingOnDates, bookingOnDatesLoading} = useSelector((state)=> state.bookingOnDates)
  const {bookingsOnDatesForBranch, bookingsOnDatesForBranchLoading} = useSelector((state)=> state.bookingsOnDatesForBranch)
  const linkk = "updateTestBooking";

  const actionBookingPreview = "Preview"
  const bookingLinkk = "previewTestBooking"
  const actionUpdate = "Update";
  const action3 = "Delete";

  const navigate = useNavigate()

  const ShowTableRecord = async () => {
      navigate('')
  }


  useEffect(()=>{
    if(user?.user?.role?.roleName === "superAdmin" || user?.user?.role?.roleName === "Administrator")
    {
      console.log('jfi')
      dispatch(getBookingRecord())
    }else{
      console.log('ajfei')
      dispatch(getBookingOnBranch(user?.user?.branch?.branchCode))
    }
     }, [user])

  useEffect(()=>{
    if(bookingRecord?.success && !bookingRecordLoading)
    {
      setTableData(bookingRecord?.booking)
      setTableRecord(bookingRecord?.booking)
      setLoadingData(false)
    }
  }, [bookingRecord, bookingRecordLoading])

  useEffect(()=>{
    if(bookingOnBranch?.success && !bookingOnBranchLoading)
    {
      setTableData(bookingOnBranch?.bookingData)
      setTableRecord(bookingOnBranch?.bookingData)
      setLoadingData(false)
    }
  }, [bookingOnBranch, bookingOnBranchLoading])

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  const deleteProduct = async (id) => {
    swal
    .fire({
      icon: "warning",
      title: "Warning",
      text: "Are You Sure You Want to Delete",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        popup: "custom-swal-popup", // This is the custom class you're adding
      },
    })
    .then(async (result) => {
      if (result.value) {
        console.log('fei')
         dispatch(deleteBookingRecords(id))
      }})
   
  };

  useEffect(()=>{
    if(deleteBookingRecordError?.data && !deleteBookingRecordLoading)
    {
      deleteErrorMessage(deleteBookingRecordError?.data?.message)
    }
  }, [deleteBookingRecordError, deleteBookingRecordLoading])

  useEffect(()=>{
    if(deleteBookingRecord?.success && !deleteBookingRecordLoading)
    {
      deleteSuccessMessage()
    }
  }, [deleteBookingRecord, deleteBookingRecordLoading])

  const deleteSuccessMessage = () => {
    dispatch(getBookingRecord())
    dispatch({
        type: DELETE_BOOKING_DETAIL_SUCCESS,
        payload: [],
      });
    return alert.success("Record Deleted Successfully")
  }

  const deleteErrorMessage = (message) =>{
    dispatch({
        type: DELETE_BOOKING_DETAIL_FAIL,
        payload: [],
      });
    return alert.error(message)
  }

  const handleStartingDate =(e) =>{
    setStartingDate(e.target.value)
    if(endingDate)
    {
      setLoadingData(true)
      if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
      {
        dispatch({
          type: GET_BOOKING_ONSHOP_SUCCESS,
          payload: [],
        });
        dispatch({
          type: GET_BOOKING_SUCCESS,
          payload: [],
        });
        dispatch(getBookingsRecordOnDatesForBranch(user?.user?.branch?._id, e.target.value, endingDate))
      } 
      else 
      {
        dispatch({
          type: GET_BOOKING_ONSHOP_SUCCESS,
          payload: [],
        });
        dispatch({
          type: GET_BOOKING_SUCCESS,
          payload: [],
        });
        dispatch(getBookingsRecordOnDates(e.target.value, endingDate))
      }
    }else{
      setStartingDate(e.target.value)
    }
    console.log(e.target.value)
  }

  const handleEndingDate = (e) => {
    setEndingDate(e.target.value)
    if(!startingDate)
    {
      return alert.error('Please Select Starting Date')
    }
    setLoadingData(true)
    if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
    {
      dispatch({
        type: GET_BOOKING_ONSHOP_SUCCESS,
        payload: [],
      });
      dispatch({
        type: GET_BOOKING_SUCCESS,
        payload: [],
      });
      dispatch(getBookingsRecordOnDatesForBranch(user?.user?.branch?._id, startingDate, e.target.value))
    } 
    else 
    {
      dispatch({
        type: GET_BOOKING_ONSHOP_SUCCESS,
        payload: [],
      });
      dispatch({
        type: GET_BOOKING_SUCCESS,
        payload: [],
      });
      dispatch(getBookingsRecordOnDates(startingDate, e.target.value))
    }
  }


  useEffect(()=>{
    if(!bookingsOnDatesForBranchLoading && bookingsOnDatesForBranchLoading?.success)
    {
      setTableData(bookingsOnDatesForBranch?.studentData)
      setTableRecord(bookingsOnDatesForBranch?.studentData)
      setLoadingData(false)
    }
  }, [bookingsOnDatesForBranch, bookingsOnDatesForBranchLoading])

  useEffect(()=>{
    if(!bookingOnDatesLoading && bookingOnDates?.success)
    {
      setTableData(bookingOnDates?.studentData)
      setTableRecord(bookingOnDates?.studentData)
      setLoadingData(false)
    }
  }, [bookingOnDates, bookingOnDatesLoading])

  const clear = () =>{
    setLoadingData(true)
    if(user?.user?.role?.roleName === "superAdmin" || user?.user?.role?.roleName === "Administrator")
    {
      console.log('jfi')
      dispatch(getBookingRecord())
    }else{
      console.log('ajfei')
      dispatch(getBookingOnBranch(user?.user?.branch?.branchCode))
    }
    setCustomerName('')
    setStartingDate('')
    setEndingDate('')
  }

  const handleSearch = async(customer) =>{
    const data = await TestInvoiceDataSearch(tableRecord, customer)
    setTableData(data)
  }

    const columns = [
        { field: "cust_id.name", label: "Customer Name" },
        { field: "branch_id.branchCode", label: "Branch Code" },
        { field: "test_id.testName", label: "Test Name" },
        { field: "testType", label: "Test Type" },
        { field: "pakistanDate", label: "Pakistan Date", format: "date" },
        { field: "pakistanTime", label: "Pakistan Time" },
        { field: "otherCountryDate", label: "Test Country Date", format: "date" },
        { field: "otherCountryTime", label: "Test Country Time" },
      ];
    
      const actions = [
        {
          label: "Delete",
          color: "yellow",
          handler: (itemId) => deleteProduct(itemId),
        },
        {
          label: "Update",
          color: "green",
          url: (itemId) => `/updateTestBooking/${itemId}`,
        },
        {
          label: "Preview",
          color: "green",
          url: (itemId) => `/previewTestBooking/${itemId}`,
        },
      ];
    
      const AddBookingRecord = async () => {
        navigate('/testBooking')
    }

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`testBookingMain ${colorTheme}`}>
        <div className="testBookingTable">
        <div className="heading">
            <h3>{"Bookings"}</h3> 
            <button onClick={AddBookingRecord}>
              Add Bookings
            </button>
            </div>
            <div className="searchTestBooking-withouInputfields-box">
            <div className="testBookingTableFirstInputDiv">
              <input
                label={"Enter Customer Name"}
                className="testBookingTableInputField"
                type="text"
                placeholder={"Enter Customer Name"}
                name="customerName"
                autoComplete="off"
                maxLength="40"
                value={customerName}
                required
                onChange={(e)=>{ name = e.target.value
                  setCustomerName(e.target.value)
                  handleSearch(name)
                }}
              />
               <input
              label={"Address"}
              className="testBookingTableInputField"
              type="date"
              placeholder={"Enter Starting Date"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              value = {startingDate}
              onChange={handleStartingDate}
              />
                <input
              label={"Address"}
              className="testBookingTableInputField"
              type="date"
              placeholder={"Enter Ending Date"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              value = {endingDate}
              onChange={handleEndingDate}
              />
               <div className="searchButton">
                <button onClick={clear}>
                    Clear
                </button>
                <ReactToPrint
            trigger={() => (
              <button >
                Print / Download
              </button>
            )}
            content={() => testBookingRef.current}
          />
            </div>
            </div>
            </div>
            <div className="testBookingTable-container">
            {!loadingData ? (
                <MainTableComponent
                    data={tableData}
                    columns={columns}
                    actions={actions}
                    actionUpdate={actionUpdate}
                    linkk= {linkk}
                    action3={action3}
                    actionBookingPreview ={actionBookingPreview}
                    bookingLinkk= {bookingLinkk}
                />
             ) : (<>
             <PageLoader />
             </>
                
             )} 
              <div className="printSection">
      <div ref={testBookingRef} style={{overflow: "auto",}} className="p-5">
        <div  className="printPagePrev" style={{margin: "10px", border: "2px solid black", padding: "10px",}}>
        {
                !loadingData && (<LaserPrintTable
                     data={tableData}
                     columns={columns}
                />)
            }
      </div>
      </div>
      </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default TestBookingRecord;
