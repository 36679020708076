import React, { useContext, useState, useEffect } from "react";
export const CustomerInvoiceDataSearch = async (pr, customerName, email) => {
  console.log(pr)
//   console.log(colorName)

  let Filtered= []

    Filtered = pr?.filter((data) => {
      //Filter by Products

      if (
        customerName &&
        !data?.name?.toString().toLowerCase()?.includes(customerName?.toString()?.toLowerCase())
      ) {
        return false;
      }

      if (
        email &&
        !data?.email?.toString().toLowerCase()?.includes(email?.toString()?.toLowerCase())
      ) {
        return false;
      }
      return true;
    });
  
  
  console.log(Filtered)
  return Filtered;
};
