import React, { useState, useEffect } from "react";
import MetaData from "../../MetaData";


import MainDash from "./MainDash/MainDash"
import RightSide from "./RigtSide/RightSide"
import './NewDash.css'
import { useSelector, useDispatch } from "react-redux";

let isCalled = "false";
const DashBoard = () => {
  const dispatch = useDispatch();
  const [colorTheme, setColorTheme] = useState();

  useEffect(() => {
    const currentColorTheme = localStorage.getItem("theme-color");
    if (currentColorTheme) {
      setColorTheme(currentColorTheme);
    }
  }, [colorTheme]);

  return (
    <>
    <MetaData title="Al-Saif ~~Dashboard" />
    <div className={`dashApp  ${colorTheme}`}>
      <div className="AppGlass">
        {/* <Sidebar/> */}
        <MainDash/>
        {/* <RightSide /> */}
      </div>
    </div>
    
    </>
  );
};

export default DashBoard;
