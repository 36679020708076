import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { PostTestRecord, getSpecificTestRecord, getTestRecord, updateTestRecord } from "../../../Actions/testAction";
import { getBranchRecord, getSpecificBranchRecord, updateBranchRecord } from "../../../Actions/branchAction";
import {useNavigate, useParams}  from "react-router-dom"
import "./branch.css"
const UpdateBranchForm = () => {
    const [branchCode, setBranchCode] = useState()
    const [branchPhoneNo, setBranchPhoneNO] = useState()
    const [branchAddress, setBranchAddress] = useState()
    const [branchDescription, setBranchDescription] = useState()
    const dispatch = useDispatch()
  const params = useParams()
  const [colorTheme, setColorTheme] = useState()
  const {branchDetailRecord, branchDetailRecordLoading } = useSelector((state)=> state.branchDetailRecord)
  const navigate = useNavigate()

  const ShowTableRecord = async () => {
      navigate('/testRecordTable')
  }

  useEffect(()=>{
    if(!branchDetailRecordLoading && branchDetailRecord)
    {
      setBranchCode(branchDetailRecord?.branch?.branchCode)
      setBranchAddress(branchDetailRecord?.branch?.branchAddress)
      setBranchPhoneNO(branchDetailRecord?.branch?.branchPhoneNo)
      setBranchDescription(branchDetailRecord?.branch?.branchDescription)
    }
  }, [branchDetailRecordLoading, branchDetailRecord])

  useEffect(()=>{
    dispatch(getSpecificBranchRecord(params.id))
  }, [])

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  const updateRecord = () =>{
        dispatch(updateBranchRecord(params.id, branchCode, branchAddress, branchPhoneNo, branchDescription))
        navigate("/branchRecordTable")
    }

  const clearRecord = ()=>{
        setBranchCode('')
        setBranchAddress('')
        setBranchPhoneNO('')
        setBranchDescription('')
    }

  return (
    <>
      <MetaData title="Al-Saif ~~Customers" />
      <div className={`branchMain ${colorTheme}`}>
        <div className={`branch`}>
          <div className="branchSecond">
            <div className="heading">
            <h3 >{"Update Branch"}</h3> 
            </div>
            <div className="branchFirstInputDiv">
                  <div className="inputDiv">
                    <label className="labelDiv">Branch Code</label>
                    <input
                        label={"productCode"}
                        className="inputField"
                        type="text"
                        placeholder={"Enter Branch Code"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={branchCode}
                        onChange={(e)=> setBranchCode(e.target.value)}
                        disabled
                        />
                    </div>
                    <div className="inputDiv">
                    <label className="labelDiv">Phone NO </label>
                    <input
                        label={"productCode"}
                        type="Number"
                        className="inputField"
                        placeholder={"Enter Phone NO"}
                        name="productCode"
                        autoComplete="off"
                        required
                        value={branchPhoneNo}
                        onChange={(e)=> setBranchPhoneNO(e.target.value)}
                    />
                    </div>
                    </div>
                     <div className="branchFirstInputDiv">
                     <div className="inputDiv">
                    <label className="labelDiv">Address</label>
                    <input
                        className="inputField"
                        type="text"
                        placeholder={"Enter Address"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={branchAddress}
                        onChange={(e)=> setBranchAddress(e.target.value)}
                    />
                    </div>
                    <div className="inputDiv">
                    <label className="labelDiv">Branch Description</label>
                    <input
                        className="inputField"
                        type="text"
                        placeholder={"Enter Description"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={branchDescription}
                        onChange={(e)=> setBranchDescription(e.target.value)}
                    />
                     </div>
                </div>
                <div>
                <button onClick={updateRecord}>Update Record</button>
                <button onClick={clearRecord}>Clear</button>
                </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateBranchForm;
