import { DELETE_SPECIFIC_TEST_FAIL, DELETE_SPECIFIC_TEST_REQUEST, DELETE_SPECIFIC_TEST_SUCCESS, GET_SPECIFIC_TEST_FAIL, GET_SPECIFIC_TEST_REQUEST, GET_SPECIFIC_TEST_SUCCESS, GET_TEST_FAIL, GET_TEST_REQUEST, GET_TEST_SUCCESS, POST_TEST_FAIL, POST_TEST_REQUEST, POST_TEST_SUCCESS, UPDATE_SPECIFIC_TEST_FAIL, UPDATE_SPECIFIC_TEST_REQUEST, UPDATE_SPECIFIC_TEST_SUCCESS } from "../Constants/testConstants";
import axiosInstance from "./baseURL";

export const getTestRecord = () => async (dispatch) => {
    try {
      dispatch({ type: GET_TEST_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getTests`);
      dispatch({
        type: GET_TEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEST_FAIL,
        payload: error.response,
      });
    }
  };

  export const getSpecificTestRecord = (id) => async (dispatch) => {
    try {
      dispatch({ type: GET_SPECIFIC_TEST_REQUEST });
      const { data } = await axiosInstance.get(`/api/v1/getTestDetails/${id}`);
      dispatch({
        type: GET_SPECIFIC_TEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SPECIFIC_TEST_FAIL,
        payload: error.response,
      });
    }
  };

  export const updateTestRecordd= (id, testName, testPrice, testDescription) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SPECIFIC_TEST_REQUEST });
      console.log(testName)
      console.log(testPrice)
      console.log(testDescription)
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } =await axiosInstance.put(`/api/v1/editTest/${id}`, {
        testName, testPrice, testDescription
      },
      config);
      console.log(data)
      dispatch({
        type: UPDATE_SPECIFIC_TEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SPECIFIC_TEST_FAIL,
        payload: error.response,
      });
    }
  };

  export const PostTestRecord = (testName, testPrice, testDescription) => async (dispatch) => {
    try {
      dispatch({ type: POST_TEST_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axiosInstance.post(`/api/v1/createTest`, {
        testName, testPrice, testDescription
      },
      config);
      dispatch({
        type: POST_TEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_TEST_FAIL,
        payload: error.response,
      });
    }
  };

  
  export const deleteTestRecord = (id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_SPECIFIC_TEST_REQUEST });
      const { data } = await axiosInstance.delete(`/api/v1/deleteTest/${id}`);
      dispatch({
        type: DELETE_SPECIFIC_TEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_SPECIFIC_TEST_FAIL,
        payload: error.response,
      });
    }
  };