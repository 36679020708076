import React, { useEffect, useState } from "react";
import { UseDispatch, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSpecificCustomerVisaRecord, updateCustomerVisaRecord, updateCustomerVisaStatus } from "../../../Actions/visaCustomerPaymentAction";
import MainTableComponent from "../../TableComponent/MainTableComponent";
import "./customerVisa.css"
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import LaserPrintTable from "../../TableComponent/LaserPrintTable"
import { UPDATE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS } from "../../../Constants/visaCustomerPaymentConstants";
import Dates from "./Dates";
import Header from "./Header";
let legnthOfPrevData;
let payedAmount;
let remains;
let initialPayments;
const PreviewVisaRecord = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const statusDropdownRef = useRef(null);
    const visaRef = useRef();
    const params = useParams()
    const alert = useAlert()
    const [colorTheme, setColorTheme] = useState()
    const {visaCustomerPaymentDetailRecord, visaCustomerPaymentDetailRecordLoading} = useSelector((state)=> state.visaCustomerPaymentDetailRecord)
    const {updateVisaCustomerPayment, updateVisaCustomerPaymentLoading, updateVisaCustomerPaymentError} = useSelector((state)=> state.updateVisaCustomerPayment)
    const [payments, setPayments] = useState([]);
    const [isOptionSelectedOpen, setIsOptionSelectedOpen] = useState(false)
    const [isStatusSelectedOpen, setIsStatusSelectedOpen] = useState(false)
    const [recordUpdate, setRecordUpdate] = useState('paymentUpdate')
    const [selectedValue, setSelectedValue] = useState('Payments Update')
    const [paymentStatus, setPaymentStatus] = useState("Unpaid")
    const [statusUpdate, setStatusUpdate] = useState()
    const [selectedStatus, setSelectedStatus] = useState()
    const statusOptions = [
        { key: "1", text: "Processing", value: "Processing" },
        { key: "2", text: "Applied", value: "Applied" },
        { key: "3", text: "Re-Processing", value: "Re-Processing" },
        { key: "4", text: "Re-Applied", value: "Re-Applied" },
        { key: "5", text: "Accepted", value: "Accepted" },
        { key: "6", text: "Rejected", value: "Rejected" },
      ];

      const Options = [
        { key: "1", text: "Payments Update", value: "paymentUpdate" },
        { key: "2", text: "Status Update", value: "statusUpdate" },
       
      ];

    const handleDownPaymentValue = (e) => {
        console.log(e)
      
        console.log(visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.payments[legnthOfPrevData])
        const prevValue = visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.payments[legnthOfPrevData]
        console.log(prevValue?.paymentPayed)
        payedAmount = parseFloat(prevValue?.paymentPayed) + parseFloat(e.target.value)
        const discount = visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.discount
        remains = (visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.totalPayment - discount) - payedAmount
        if(payedAmount > visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.totalPayment){
            return alert.error(`Payed Amount should less than Total Amount`)
          }
          if(remains < 0){
            initialPayments=0
            payedAmount=0
            remains=0
            return alert.error(`Payment Completed`)
          }
        
        console.log(payedAmount)
        console.log(remains)
        if(remains==0){
          console.log('fe')
          setPaymentStatus("Paid")
        }else{
          setPaymentStatus("Unpaid")
        }
        const newPayments = [
            ...visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.payments, // Copy previous array
            {
              downPayment: e.target.value, // Default value for down payment
              paymentPayed: payedAmount, // Default value for payment payed
              remaining: remains, // Default value for remaining payment
            }
          ];
          console.log(newPayments)
          setPayments(newPayments);
          initialPayments = parseInt(e.target.value)
    }

    const updateCustomerVisa= () => {
        dispatch(updateCustomerVisaRecord(params.id, payments, paymentStatus))
    }
 
    
    const updateCustomerVisStatus= () => {
      dispatch(updateCustomerVisaStatus(params.id, selectedStatus))
  }

  useEffect(()=> {
    if(!updateVisaCustomerPaymentLoading && updateVisaCustomerPayment?.success)
    {
      console.log(updateVisaCustomerPayment)
      updateSuccessMessage()
    }
  }, [updateVisaCustomerPayment, updateVisaCustomerPayment])

  useEffect(()=> {
    if(!updateVisaCustomerPaymentLoading && updateVisaCustomerPaymentError)
    {
      updateErrorMessage(updateVisaCustomerPaymentError)
    }
  }, [updateVisaCustomerPaymentLoading, updateVisaCustomerPaymentError])

  useEffect(()=>{
    remains = ''
    initialPayments = ''
    payedAmount = ''
  }, [])

  const updateSuccessMessage =() => {
    initialPayments = ''
    payedAmount = ''
    remains = ''
    dispatch(getSpecificCustomerVisaRecord(params.id))
    dispatch({
      type: UPDATE_VISACUSTOMERPAYMENT_DETAIL_SUCCESS,
      payload: [],
    });
    return alert.success('Record Updated Successfully')
  }
  
  const updateErrorMessage = (message) => {
    return alert.error(message)
  }
  

      useEffect(()=>{
        setColorTheme(localStorage.getItem('theme-color'))
      }, [localStorage.getItem('theme-color')])

    useEffect(()=>{
        dispatch(getSpecificCustomerVisaRecord(params.id))
    }, [])

    useEffect(()=>{
        if(!visaCustomerPaymentDetailRecordLoading && visaCustomerPaymentDetailRecord)
        {
        legnthOfPrevData = visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.payments.length - 1
        const initialPayments =visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.payments
        setPayments(initialPayments)
        console.log(legnthOfPrevData)
        }
    

    }, [visaCustomerPaymentDetailRecordLoading, visaCustomerPaymentDetailRecord])

    const optionToggleDropdown = () => {
      setIsOptionSelectedOpen(!isOptionSelectedOpen)
    }

    const statusOptionToggleDropdown = () => {
      setIsStatusSelectedOpen(!isStatusSelectedOpen)
    }

    const handleOptionSelection = (value, text) => {
      console.log(value)
      if(value.startsWith("p"))
      {
        console.log('p')
        setRecordUpdate(value)
        setStatusUpdate('')
        setSelectedValue(text)
        setIsOptionSelectedOpen(!isOptionSelectedOpen)
      }else{
        console.log('s')
        setStatusUpdate(value)
        setRecordUpdate('')
        setSelectedValue(text)
        setIsOptionSelectedOpen(!isOptionSelectedOpen)
      }
    }

    const back = () =>{
      navigate("/customerRecordVisa")
    }
    
    const handleStatusOptionSelection = (value) => {
      console.log(value)
      setIsStatusSelectedOpen(!isStatusSelectedOpen)
      setSelectedStatus(value)
    }

    
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
          console.log('fie')
          setIsOptionSelectedOpen(false);
        }
        console.log('clikced')
      };
      window.addEventListener('click', handleClickOutside);
      return () => {
        window.removeEventListener('click', handleClickOutside);
      };
    }, []); 
    const columns = [
        { field: "downPayment", label: "Amount Payed" },
         { field: "paymentPayed", label: "Total Payed Amount" },
        { field: "remaining", label: "Remaining" },
        {field: "createdAt", label: "Date", format: "date"},
        {field: "createdAt", label: "Time", format: "time"}
      ];
    return(
        <>
           <div className={`customerVisaRecordTable ${colorTheme}`}>
        <div className={`customerRecordVisa`}>
        <div className="previewDropdownDiv">
          <button onClick={back}>Back</button>
        <div ref={statusDropdownRef} className="custom-dropdown">
                <div className="selected-option" onClick={optionToggleDropdown}>
                  {selectedValue || 'Select Category option'}
                </div>
                {isOptionSelectedOpen && (
                  <div className="options">
                    {Options?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleOptionSelection(option.value, option.text)}
                    >
                    {option?.text}
                    </div>
                    ))}
                </div>
                )}
              </div> 
        </div>
        {
          statusUpdate ? (<>
        <div className="previewCustomerRecordVisa-Inputfields-box">
           <div className="previewCustomerVisaInputDiv">
           <div className="custom-dropdown">
                <div className="selected-option" onClick={statusOptionToggleDropdown}>
                  { selectedStatus || 'Select Category option'}
                </div>
                {isStatusSelectedOpen && (
                  <div className="options">
                    {statusOptions?.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleStatusOptionSelection(option.value)}
                    >
                    {option?.text}
                    </div>
                    ))}
                </div>
                )}
              </div>
              <button className="customerVisaUpdateButton" onClick={updateCustomerVisStatus}>Update Status</button>
           </div>
           </div>
          </>) : (<>
          {
            recordUpdate && (<>
             <div className="previewCustomerRecordVisa-Inputfields-box">
            <div className="previewCustomerVisaInputDiv">
            <input
              label={"productCode"}
              type="Number"
              className="customerVisaInputField"
              placeholder={"Enter Initial Payment"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
              value={initialPayments}
              required
              onChange={handleDownPaymentValue}
              />
             <input
              label={"totalPayment"}
              type="Number"
              className="customerVisaInputField"
              placeholder={"Enter Total Payment"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
              required
              value = {payedAmount}
              disabled
              />
                <input
              label={"productCode"}
              type="Number"
              className="customerVisaInputField"
              placeholder={"Enter Remaining Payment"}
              name="productCode"
              autoComplete="off"
              maxLength="40"
             
              required
              value = {remains}
              disabled
              />
              <button className="customerVisaUpdateButton" onClick={updateCustomerVisa}>Update Record</button>
            </div>
            </div>
            </>)
          }
         
          </>)
        }
      
      <div className="previewTable">
      <ReactToPrint
            trigger={() => (
              <button style={{padding: "10px", marginTop: "5px"}} >
                Print / Download
              </button>
            )}
            content={() => visaRef.current}
          />
          <div ref={visaRef} style={{overflow: "auto", height: "90%"}} className="p-5">
            <div  className="printPagePrev" style={{margin: "10px", border: "2px solid black", padding: "10px"}}>
            <Header />
            <Dates />
            {
                !visaCustomerPaymentDetailRecordLoading && (<LaserPrintTable 
                     data={visaCustomerPaymentDetailRecord?.visaCustomerPaymentData?.payments}
                     columns={columns}
                />)
            }   
          </div>
          </div>
        </div>
        </div>
        </div> 
    
      
        </>
    )
}
export default PreviewVisaRecord