import React, { useEffect, useState, useRef } from "react";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { getCustomerVisaRecord, getCustomerVisaRecordOnBranch, getVisaRecordPaymentOnDates, getVisaRecordPaymentOnDatesForBranch } from "../../../Actions/visaCustomerPaymentAction";
import MainTableComponent from "../../TableComponent/MainTableComponent";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../Loader/PageLoader";
import { VisaInvoiceDataSearch } from "../../SearchComponent/VisaInvoiceSearch";
import LaserPrintTable from "../../TableComponent/LaserPrintTable"
import { GET_VISACUSTOMERPAYMENT_ONSHOP_SUCCESS, GET_VISACUSTOMERPAYMENT_SUCCESS } from "../../../Constants/visaCustomerPaymentConstants";
let name;
let visa
const CustomerVisaTable = () => {
  const visaRef = useRef();
    const dispatch = useDispatch()
    const [customerName, setCustomerName] = useState()
    const [visaName, setVisaName] = useState()
    const [colorTheme, setColorTheme] = useState()
    const navigate = useNavigate()
    const [startingDate, setStartingDate] = useState()
    const [endingDate, setEndingDate]= useState()
    const linkk = "PreviewVisaRecord"
    const actionPreview = "Preview"
    const [tableData, setTableData] = useState()
    const [tableRecord, setTableRecord] = useState()
    const [loadingData, setLoadingData] = useState(true)
    const {user} = useSelector((state)=> state.user)
    const {visaCustomerPaymentRecord, visaCustomerPaymentRecordLoading, visaCustomerPaymentRecordError} = useSelector((state)=> state.visaCustomerPaymentRecord)
    const {visaCustomerPaymentRecordOnShop, visaCustomerPaymentRecordOnShopLoading} = useSelector((state)=> state.visaCustomerPaymentRecordOnShop)
    const {visaCustomerPaymentOnDates, visaCustomerPaymentOnDatesLoading} = useSelector((state)=> state.visaCustomerPaymentOnDates)
    const {visaCustomerPaymentOnDatesForBranch, visaCustomerPaymentOnDatesForBranchLoading} = useSelector((state)=> state.visaCustomerPaymentOnDatesForBranch)
    useEffect(()=>{
      if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
      {
        console.log('jfi')
        dispatch(getCustomerVisaRecordOnBranch(user?.user?.branch?.branchCode))
      }else{
        console.log('ajfei')
        dispatch(getCustomerVisaRecord())
      }
    }, [user])

    useEffect(()=>{
      setColorTheme(localStorage.getItem('theme-color'))
    }, [localStorage.getItem('theme-color')])


    const AddCustomerRecord = async () => {
      navigate('/customerAdd')
  }

  useEffect(()=>{
    if(visaCustomerPaymentRecord?.success && !visaCustomerPaymentRecordLoading)
    {
      setTableData(visaCustomerPaymentRecord?.visaCustomerPaymentData)
      setTableRecord(visaCustomerPaymentRecord?.visaCustomerPaymentData)
      setLoadingData(false)
    }

  }, [visaCustomerPaymentRecord, visaCustomerPaymentRecordLoading])

  
  useEffect(()=>{
    if(visaCustomerPaymentRecordOnShop?.success && !visaCustomerPaymentRecordOnShopLoading)
    {
      setTableData(visaCustomerPaymentRecordOnShop?.visaPayments)
      setTableRecord(visaCustomerPaymentRecordOnShop?.visaPayments)
      setLoadingData(false)
    }

  }, [visaCustomerPaymentRecordOnShop, visaCustomerPaymentRecordOnShopLoading])

  useEffect(()=>{
    if(visaCustomerPaymentOnDates?.success && !visaCustomerPaymentOnDatesLoading)
    {
      setTableData(visaCustomerPaymentOnDates?.studentData)
      setTableRecord(visaCustomerPaymentOnDates?.studentData)
      setLoadingData(false)
    }

  }, [visaCustomerPaymentOnDates, visaCustomerPaymentOnDatesLoading])

  useEffect(()=>{
    if(visaCustomerPaymentOnDatesForBranch?.success && !visaCustomerPaymentOnDatesForBranchLoading)
    {
      setTableData(visaCustomerPaymentOnDatesForBranch?.studentData)
      setTableRecord(visaCustomerPaymentOnDatesForBranch?.studentData)
      setLoadingData(false)
    }

  }, [visaCustomerPaymentOnDatesForBranch, visaCustomerPaymentOnDatesForBranchLoading])

  const handleStartingDate =(e) =>{
    setStartingDate(e.target.value)
    if(endingDate)
    {
      setLoadingData(true)
      if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
      {
        dispatch(getVisaRecordPaymentOnDatesForBranch(user?.user?.branch?._id, e.target.value, endingDate))
      } 
      else 
      {
        dispatch(getVisaRecordPaymentOnDates(e.target.value, endingDate))
      }
    }else{
      setStartingDate(e.target.value)
    }
    console.log(e.target.value)
  }

  const handleEndingDate = (e) => {
    setEndingDate(e.target.value)
    if(!startingDate)
    {
      return alert.error('Please Select Starting Date')
    }
    setLoadingData(true)
    if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
    {
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_SUCCESS,
        payload: [],
      });
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_ONSHOP_SUCCESS,
        payload: [],
      });
      dispatch(getVisaRecordPaymentOnDatesForBranch(user?.user?.branch?._id, startingDate, e.target.value))
    } 
    else 
    {
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_SUCCESS,
        payload: [],
      });
      dispatch({
        type: GET_VISACUSTOMERPAYMENT_ONSHOP_SUCCESS,
        payload: [],
      });
      dispatch(getVisaRecordPaymentOnDates(startingDate, e.target.value))
    }
  }

  
  const handleSearch = async(customer, visa) =>{
    const data = await VisaInvoiceDataSearch(tableRecord, customer, visa)
    console.log(data)
    setTableData(data)
  }


  const clear = () =>{
    setLoadingData(true)
    if(user?.user?.role?.roleName !== "superAdmin" && user?.user?.role?.roleName !== "Administrator")
    {
      console.log('jfi')
      dispatch(getCustomerVisaRecordOnBranch(user?.user?.branch?.branchCode))
    }else{
      console.log('ajfei')
      dispatch(getCustomerVisaRecord())
    }
    setVisaName('')
    setCustomerName('')
    setStartingDate('')
    setEndingDate('')
  }
    const columns = [
        { field: "cust_id.name", label: "Customer Name" },
        { field: "branch_id.branchCode", label: "Branch" },
        { field: "visa_id.visaName", label: "Visa" },
        { field: "cust_id.contactNo", label: "Contact NO" },
        { field: "cust_id.email", label: "Email" },
        { field: "totalPayment", label: "Total Payment" },
        { field: "Status", label: "Status" },
        { field: "paymentStatus", label: "Payment Status" },
        { field: "createdAt", label: "Date", format: "date" },
        { field: "createdAt", label: "Time", format: "time" },
      ];
    
      const actions = [
        // {
        //   label: "Preview",
        //   color: "yellow",
        //   handler: (itemId) => p(itemId),
        // },
        {
          label: "Preview",
          color: "green",
          url: (itemId) => `/PreviewVisaRecord/${itemId}`,
        },
      ];

    return(<>
     <div className={`customerVisaRecordTable ${colorTheme}`}>
        <div className={`customerRecordVisa`}>
        <div className="heading">
            <h3>{"Visa Record"}</h3> 
            <button onClick={AddCustomerRecord}>
              Add Customer
            </button>
            </div>
    <div className="searchCustomerRecordVisa-withouInputfields-box" >
    <div className="customerRecordVisaTableFirstInputDiv">
              <input
                label={"Enter Customer Name"}
                className="customerRecordVisaTableInputField"
                type="text"
                placeholder={"Enter Customer Name"}
                name="customerName"
                autoComplete="off"
                maxLength="40"
                required
                value={customerName}
                onChange={(e)=>{ 
                  name = e.target.value
                  setCustomerName(e.target.value) 
                  handleSearch(name, visa)}}
              />
              <input
              label={"Contact No"}
              type="Number"
              className="customerRecordVisaTableInputField"
              placeholder={"Enter Contact No"}
              name="contactNo"
              autoComplete="off"
              maxLength="40"
              required
              value={visaName}
              onChange={(e)=>{ 
                visa = e.target.value
                setVisaName(e.target.value) 
                handleSearch(name, visa)}}
              />
              <input
              className="customerRecordVisaTableInputField"
              type="date"
              placeholder={"Enter Starting Date"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              value = {startingDate}
              onChange={handleStartingDate}
              />
                <input
              className="customerRecordVisaTableInputField"
              type="date"
              placeholder={"Enter Ending Date"}
              name="address"
              autoComplete="off"
              maxLength="40"
              required
              value = {endingDate}
              onChange={handleEndingDate}
              />
               <div className="searchButton">
                <button onClick={clear}>
                    Clear
                </button>
                <ReactToPrint
            trigger={() => (
              <button >
                Print / Download
              </button>
            )}
            content={() => visaRef.current}
          />
            </div>
            </div>
    </div>
    <div className="customerVisaTable-container">
      {
        !loadingData ? (<>
         <MainTableComponent
                    data={tableData}
                    columns={columns}
                    actions={actions}
                    actionPreview={actionPreview}
                    linkk= {linkk}
                    // action3={action3}
                />
        </>) : (<>
        <PageLoader />
        </>)
      }
         <div className="printSection">
      <div ref={visaRef} style={{overflow: "auto",}} className="p-5">
        <div  className="printPagePrev" style={{margin: "10px", border: "2px solid black", padding: "10px",}}>
        {
                !loadingData && (<LaserPrintTable
                     data={tableData}
                     columns={columns}
                />)
            }
      </div>
      </div>
      </div>
   
    </div>
    </div>
        </div>
    {/* <div className="searchCustomerRecord-withouInputfields-box" >
    <div className="customerFirstInputDiv">
              <input
                label={"Enter Customer Name"}
                className="customerInputField"
                type="text"
                placeholder={"Enter Customer Name"}
                name="customerName"
                autoComplete="off"
                maxLength="40"
                required
                value={customerName}
                onChange={(e)=> setCustomerName(e.target.value)}
              />
            </div>
    </div>
    <div className="customerVisaTable-container">
    <MainTableComponent
                    data={visaCustomerPaymentRecord?.visaCustomerPaymentData}
                    columns={columns}
                    actions={actions}
                    actionPreview={actionPreview}
                    linkk= {linkk}
                    // action3={action3}
                />
    </div> */}
    </>)
}
export default CustomerVisaTable;