import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../../MetaData"
import { PostBranchRecord, getBranchRecord } from "../../../Actions/branchAction";
import { useNavigate } from "react-router-dom";
import "./register.css"
import { GetRoleRecord } from "../../../Actions/roleAction";
import { registerUserRecord } from "../../../Actions/registerUserAction";
import {useAlert} from 'react-alert'
let filteredRoles = []
const RegisterForm = () => {
  const dispatch = useDispatch()  
  const roleDropdownRef = useRef(null);
  const branchDropdownRef = useRef(null)
  const alert = useAlert()
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [branchCode, setBranchCode] = useState()
  const [branch, setBranch] = useState()
  const [roleName, setRoleName] = useState()
  const [role, setRole] = useState()
  const [avatar, setAvatar] =useState()
  const [file, setFile] = useState()
  const [error, setError] = useState();
  const [confirmError, setConfirmError] = useState();
  const [passwordError, setPasswordError] = useState(
    "Password must contain a combination of letters, digits, and special characters."
  );
  const [confirmPasswordBool, setConfirmPasswordBool] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isRoleOpen, setIsRoleOpen] = useState(false)
  const [isBranchOpen, setIsBranchOpen] = useState(false)
  const {branchRecord} = useSelector((state)=> state.branchRecord)
  const {user} = useSelector((state)=> state.user)
  const {roleRecord, roleRecordLoading, roleRecordError} = useSelector((state)=> state.roleRecord)
  const {registerUser, registerUserLoading, registerUserError} = useSelector((state)=> state.registerUser)
  const [colorTheme, setColorTheme] = useState()
  const navigate = useNavigate()

  useEffect(()=>{
    setColorTheme(localStorage.getItem('theme-color'))
  }, [localStorage.getItem('theme-color')])

  useEffect(()=>{
    dispatch(getBranchRecord())
    dispatch(GetRoleRecord())
  }, [])

  const branchToggleDropdown = () => {
    setIsBranchOpen(!isBranchOpen);
  };

  const roleToggleDropdown = () => {
    setIsRoleOpen(!isRoleOpen);
  };

  const handleBranchValue = (value, code) => {
    setBranchCode(code)
    setBranch(value)
    setIsBranchOpen(!isBranchOpen);
  }

  const handleRoleValue = (value, code) => {
    setRoleName(code)
    setRole(value)
    setIsRoleOpen(!isRoleOpen);
  }

  const addRecord = () => {
    dispatch(registerUserRecord(name,email,password, avatar, branch, role));
  };

  useEffect(()=>{
    const filterOne = roleRecord?.role?.filter((roles) => roles.roleName !== "superAdmin" );
    filteredRoles = filterOne?.filter((roles) => roles.roleName !== "Administrator" );
  }, [roleRecord])

  useEffect(()=>{
    if(registerUser && registerUserLoading)
    {
      registerSuccessMessage()
    }

  }, [registerUser, registerUserLoading])

  const registerSuccessMessage = () =>{
    setEmail('')
    setName('')
    setPassword('')
    setConfirmPassword('')
    setBranch('')
    setBranchCode('')
    setRole('')
    setRoleName('')
    setAvatar('')
    return alert.success('User Created SuccessFully')
  }



  const validatePassword = (input) => {
    // Define a regex pattern that enforces the requirements
    const pattern =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&!*_])[A-Za-z\d@#$%^&!*_]{8,15}$/;
    // Test the input against the pattern
    return pattern.test(input);
  };

  const handlePasswordChange = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);
    setConfirmError(false)
    // Check if the input matches the pattern
    if (!validatePassword(inputValue)) {
      setError(true);
      setPasswordError(
        "Password must contain a combination of letters, digits, and special characters."
      );
    } else {
      setConfirmPasswordError("");
      setError(false);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const inputValue = e.target.value;
    setConfirmPassword(inputValue);
    // Check if the input matches the pattern
    if (inputValue === password) {
      setConfirmPasswordError("");
      setConfirmError(false);
      setConfirmPasswordBool(true);
      // Display an error message or apply some styling to indicate invalid input
    } else {
      setConfirmError(true);
      setConfirmPasswordError("Confirm Password Must Match with New Password");
      setConfirmPasswordBool(false);
    }
  };



    const clearRecord = ()=>{
      setEmail('')
      setName('')
      setPassword('')
      setConfirmPassword('')
      setBranch('')
      setBranchCode('')
      setRole('')
      setRoleName('')
      setAvatar(null)
    
    }

    const handleImageValue = (e) => {
      const file= e.target.files?.[0];
      console.log(file)
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            // setPhotoPrev(reader.result);
            console.log('hfei')
            console.log(file)
            setAvatar(file);
          }
        };
      }
      // console.log(e.target.files[0])
      // console.log(e.target.value)
      // setAvatar(e.target.value)
      // setFile(e.target.files[0])
    }
    const ShowTableRecord = async () => {
      navigate('/branchRecordTable')
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (roleDropdownRef.current && !roleDropdownRef.current.contains(event.target)) {
        console.log('fie')
        setIsRoleOpen(false);
      }
      console.log('clikced')
    };
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []); 

  useEffect(()=>{
 const handleClickBranchOutside = (event) => {
      if (branchDropdownRef.current && !branchDropdownRef.current.contains(event.target)) {
        setIsBranchOpen(false);
      }
      console.log('clikced')
    };
    window.addEventListener('click', handleClickBranchOutside);
    return () => {
      window.removeEventListener('click', handleClickBranchOutside);
    };
  }, [])


  return (
    <>
      <MetaData title="Al-Saif ~~Branch" />
      <div className={`registerMain ${colorTheme}`}>
      <div className={`register`}>
        <div className="registerSecond">
          <div className="heading">
            <h3>{"Register"}</h3> 
            <button onClick={ShowTableRecord}>
              View Records
            </button>
            </div>
                  
                  <div className="registerFirstInputDiv">
                    <div className="labeAndInputDiv">
                    <label className="labelDiv">Name</label>
                    <input
                        className="registerInputField"
                        type="text"
                        placeholder={"Enter User Name"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={name}
                        onChange={(e)=> setName(e.target.value)}

                        />
                    </div>
                    <div className="labeAndInputDiv">
                    <label className="labelDiv">Email</label>
                    <input
                        type="email"
                        className="registerInputField"
                        placeholder={"Enter Email"}
                        name="productCode"
                        autoComplete="off"
                        required
                        value={email}
                        onChange={(e)=> setEmail(e.target.value)}
                    />
                    </div>
                    <div className="labeAndInputDiv">
                    <label className="labelDiv">Password</label>
                    <input
                        className="registerInputField"
                        type="password"
                        placeholder={"Enter Password"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={password}
                        onChange={handlePasswordChange}
                    />
                     {error ? (
                      <div
                          style={{ fontSize: "12px", color: "red", }}>
                          {passwordError}
                      </div>
                      ) : (
                          <></>
                        )}
                    </div>
                    </div>
                  
                 
                  <div className="registerFirstInputDiv">
                    <div className="labeAndInputDiv">
               
                    <label className="labelDiv">Confirm password</label>
                    <input
                        className="registerInputField"
                        type="password"
                        placeholder={"Enter Confirm Password"}
                        name="productCode"
                        autoComplete="off"
                        maxLength="40"
                        required
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                    />
                       {confirmError ? (
                <div
                  style={{ fontSize: "12px", color: "red", }}
                >
                  {confirmPasswordError}
                </div>
              ) : (
                <></>
              )}
                  </div>
                    
                  <div className="labeAndInputDiv">
                    <label>Branch Name</label>
                    <div ref={branchDropdownRef} className="custom-dropdown">
                    <div className="selected-option" onClick={branchToggleDropdown}>
                      {branchCode || 'Select Branch'}
                      </div>
                        {isBranchOpen && (
                          <div className="options">
                            {branchRecord?.branch?.map((option, index) => (
                              <div
                                key={index}
                                className="option"
                                onClick={() => handleBranchValue(option._id, option.branchCode)}
                              >
                            {option?.branchCode}
                          </div>
                        ))}
                      </div>
                        )}
                      </div>
                    </div>         
                    <div className="labeAndInputDiv">
                    <label>Role</label>
                    <div ref={roleDropdownRef} className="custom-dropdown">
                    <div className="selected-option" onClick={roleToggleDropdown}>
                        {roleName || 'Select Role'}
                    </div>
                    {isRoleOpen && (
                      <div className="options">
                        {
                          user?.user?.role?.roleName === "superAdmin" ? (<>
                            {roleRecord?.role?.map((option, index) => (
                              <div
                              key={index}
                              className="option"
                              onClick={() => handleRoleValue(option._id, option.roleName)}
                            >
                              {option?.roleName}
                            </div>
                            ))}
                          </>) : (<>
                            {filteredRoles?.map((option, index) => (
                              <div
                              key={index}
                              className="option"
                              onClick={() => handleRoleValue(option._id, option.roleName)}
                            >
                              {option?.roleName}
                            </div>
                            ))}
                          </>)
                        }
                      
                      </div>
                    )}
                  </div>
                </div>         
                </div>
                <div className="LastRegisterInputDiv">
                <div className="lastLabeAndInputDiv">
                <label className="labelDiv">Profile Pic</label>
                <input
                  className="registerInputField"
                  type="file"
                  placeholder="Select Avatar"
                  accept="image/*"
                  // value={avatar}
                  onChange={handleImageValue}
                />

                </div>
                </div>


                <div className="buttons">
                <button onClick={clearRecord}>Clear</button>
                <button onClick={addRecord}>Add Record</button>
                </div>
          
        </div>
      </div> 
    </div>
    </>
  );
};

export default RegisterForm;
