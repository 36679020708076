import { CLEAR_ERRORS, DELETE_SPECIFIC_VISITORS_FAIL, DELETE_SPECIFIC_VISITORS_REQUEST, DELETE_SPECIFIC_VISITORS_SUCCESS, GET_SPECIFIC_VISITORS_FAIL, GET_SPECIFIC_VISITORS_REQUEST, GET_SPECIFIC_VISITORS_SUCCESS, GET_VISITORS_FAIL, GET_VISITORS_ONDATES_FAIL, GET_VISITORS_ONDATES_FORBRANCH_FAIL, GET_VISITORS_ONDATES_FORBRANCH_REQUEST, GET_VISITORS_ONDATES_FORBRANCH_SUCCESS, GET_VISITORS_ONDATES_REQUEST, GET_VISITORS_ONDATES_SUCCESS, GET_VISITORS_ONSHOP_FAIL, GET_VISITORS_ONSHOP_REQUEST, GET_VISITORS_ONSHOP_SUCCESS, GET_VISITORS_REQUEST, GET_VISITORS_SUCCESS, POST_VISITORS_FAIL, POST_VISITORS_REQUEST, POST_VISITORS_SUCCESS, UPDATE_SPECIFIC_VISITORS_FAIL, UPDATE_SPECIFIC_VISITORS_REQUEST, UPDATE_SPECIFIC_VISITORS_SUCCESS } from "../Constants/visitorsConstants";


export const getVisitorsReducer = (state = { visitorsRecord: []}, action)=>{
    switch (action.type){
        case GET_VISITORS_REQUEST:
            return{
                visitorsRecordLoading: true,
                visitorsRecord: [],
            };
        case GET_VISITORS_SUCCESS: 
            return {
                visitorsRecordLoading: false,
                visitorsRecord: action.payload
            }
        case GET_VISITORS_FAIL:
            return {
                visitorsRecordLoading: false,
                visitorsRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                visitorsRecordError: null,
            }
            default:
            return state;
            
    }
}

export const getVisitorsOnBranchReducer = (state = { visitorsRecordOnBranch: []}, action)=>{
    switch (action.type){
        case GET_VISITORS_ONSHOP_REQUEST:
            return{
                visitorsRecordOnBranchLoading: true,
                visitorsRecordOnBranch: [],
            };
        case GET_VISITORS_ONSHOP_SUCCESS: 
            return {
                visitorsRecordOnBranchLoading: false,
                visitorsRecordOnBranch: action.payload
            }
        case GET_VISITORS_ONSHOP_FAIL:
            return {
                visitorsRecordOnBranchLoading: false,
                visitorsRecordOnBranchError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                visitorsRecordOnBranchError: null,
            }
            default:
            return state;
            
    }
}


export const getVisitorsDetailReducer = (state = { visitorsDetailRecord: []}, action)=>{
    switch (action.type){
        case GET_SPECIFIC_VISITORS_REQUEST:
            return{
                visitorsDetailRecordLoading: true,
                visitorsDetailRecord: [],
            };
        case GET_SPECIFIC_VISITORS_SUCCESS: 
            return {
                visitorsDetailRecordLoading: false,
                visitorsDetailRecord: action.payload
            }
        case GET_SPECIFIC_VISITORS_FAIL:
            return {
                visitorsDetailRecordLoading: false,
                visitorsDetailRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                visitorsDetailRecordError: null,
            }
            default:
            return state;
            
    }
}


export const getVistorsOnDatesReducer = (state = { visitorsOnDates: []}, action)=>{
    switch (action.type){
        case GET_VISITORS_ONDATES_REQUEST:
            return{
                visitorsOnDatesLoading: true,
                visitorsOnDates: [],
            };
        case GET_VISITORS_ONDATES_SUCCESS: 
            return {
                visitorsOnDatesLoading: false,
                visitorsOnDates: action.payload
            }
        case GET_VISITORS_ONDATES_FAIL:
            return {
                visitorsOnDatesLoading: false,
                visitorsOnDatesError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                visitorsOnDatesError: null,
            }
            default:
            return state;
            
    }
}

export const getVisitorsOnDatesForBranchReducer = (state = { visitorsOnDatesForBranch: []}, action)=>{
    switch (action.type){
        case GET_VISITORS_ONDATES_FORBRANCH_REQUEST:
            return{
                visitorsOnDatesForBranchLoading: true,
                visitorsOnDatesForBranch: [],
            };
        case GET_VISITORS_ONDATES_FORBRANCH_SUCCESS: 
            return {
                visitorsOnDatesForBranchLoading: false,
                visitorsOnDatesForBranch: action.payload
            }
        case GET_VISITORS_ONDATES_FORBRANCH_FAIL:
            return {
                visitorsOnDatesForBranchLoading: false,
                visitorsOnDatesForBranchError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                visitorsOnDatesForBranchError: null,
            }
            default:
            return state;
            
    }
}



export const updateVisitorsDetailReducer = (state = { updateVisitorsRecord: []}, action)=>{
    switch (action.type){
        case UPDATE_SPECIFIC_VISITORS_REQUEST:
            return{
                updateVisitorsRecordLoading: true,
                updateVisitorsRecord: [],
            };
        case UPDATE_SPECIFIC_VISITORS_SUCCESS: 
            return {
                updateVisitorsRecordLoading: false,
                updateVisitorsRecord: action.payload
            }
        case UPDATE_SPECIFIC_VISITORS_FAIL:
            return {
                updateVisitorsRecordLoading: false,
                updateVisitorsRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                updateVisitorsRecordError: null,
            }
            default:
            return state;
            
    }
}

export const deleteVisitorsDetailReducer = (state = { deleteVisitorsRecord: []}, action)=>{
    switch (action.type){
        case DELETE_SPECIFIC_VISITORS_REQUEST:
            return{
                deleteVisitorsRecordLoading: true,
                deleteVisitorsRecord: [],
            };
        case DELETE_SPECIFIC_VISITORS_SUCCESS: 
            return {
                deleteVisitorsRecordLoading: false,
                deleteVisitorsRecord: action.payload
            }
        case DELETE_SPECIFIC_VISITORS_FAIL:
            return {
                deleteVisitorsRecordLoading: false,
                deleteVisitorsRecordError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                deleteVisitorsRecordError: null,
            }
            default:
            return state;
            
    }
}


export const postVisitorsReducer = (state = { postVisitors: []}, action)=>{
    switch (action.type){
        case POST_VISITORS_REQUEST:
            return{
                postVisitorsLoading: true,
                postVisitors: [],
            };
        case POST_VISITORS_SUCCESS: 
            return {
                postVisitorsLoading: false,
                postVisitors: action.payload
            }
        case POST_VISITORS_FAIL:
            return {
                postVisitorsLoading: false,
                postVisitorsError: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                postVisitorsError: null,
            }
            default:
            return state;
            
    }
}